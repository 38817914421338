import { isValidNumber } from "libphonenumber-js";

import ControlledInputController from "./ControlledInputController";

class PhoneInputController extends ControlledInputController {
	private isRequired = true;

	set required(required: boolean) {
		this.isRequired = required;
	}

	isValid(): boolean {
		const value = this.getValue();

		if (!this.isRequired && value.isEmpty()) return true;

		if (!this.getValue() || this.getValue() === "") return false;
		return isValidNumber(this.getValue());
	}
}

export default PhoneInputController;
