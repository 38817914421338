import type { UserInfoDto } from "@somewear-labs/swl-web-api/src/proto/user_info_proto_pb";
import * as React from "react";
import type { RouteComponentProps } from "react-router";
import { Redirect , Route, Switch, withRouter } from "react-router";

import type { IAuthUser } from "../common/AuthUtil";
import { AuthController } from "../common/AuthUtil";
import type { IError, IErrorLoading } from "../common/Common";
import ControlledInputController from "../common/ControlledInputController";
import EmailInputController from "../common/EmailInputController";
import Loader from "../common/Loader";
import { Mappers } from "../common/Mappers";
import Paths from "../common/Paths";
import PhoneInputController from "../common/PhoneInputController";
import { RestClient } from "../common/RestClient";
import { Sentry } from "../common/SentryUtil";
import { modifyState } from "../common/utils";
import Config from "../config/Config";
import EmergencyContactsView from "./EmergencyContactsView";
import UserInfoView from "./UserInfoView";

interface IState extends IErrorLoading {
	dataLoaded: boolean;
}

export interface IUserInfoControllerDataProps {
	enterAction?: () => void;
	validChanged?: (valid: boolean) => void;
}

export class UserInfoControllerData {
	nameController: ControlledInputController;
	lastNameController: ControlledInputController;
	emailController?: ControlledInputController;
	usernameController?: ControlledInputController;
	phoneController: PhoneInputController;
	citizenshipController: ControlledInputController;
	streetController: ControlledInputController;
	cityController: ControlledInputController;
	stateController: ControlledInputController;
	countryController: ControlledInputController;
	zipCodeController: ControlledInputController;

	emergencyName1: ControlledInputController;
	emergencyLast1: ControlledInputController;
	emergencyPhone1: PhoneInputController;
	emergencyEmail1: EmailInputController;

	emergencyName2: ControlledInputController;
	emergencyLast2: ControlledInputController;
	emergencyPhone2: PhoneInputController;
	emergencyEmail2: EmailInputController;

	constructor(props?: IUserInfoControllerDataProps) {
		this.nameController = new ControlledInputController({
			name: "First Name",
			enterAction: props && props.enterAction,
			validChanged: props && props.validChanged,
		});
		this.lastNameController = new ControlledInputController({
			name: "Last Name",
			enterAction: props && props.enterAction,
			validChanged: props && props.validChanged,
		});

		if (Config.firebase.enable) {
			this.emailController = new EmailInputController({
				name: "Email",
				enterAction: props && props.enterAction,
				validChanged: props && props.validChanged,
			});
		} else {
			this.usernameController = new ControlledInputController({
				name: "Email / Username",
				enterAction: props && props.enterAction,
				validChanged: props && props.validChanged,
			});
		}

		this.phoneController = new PhoneInputController({
			name: "Phone",
			enterAction: props && props.enterAction,
			validChanged: props && props.validChanged,
		});
		this.citizenshipController = new ControlledInputController({
			name: "Citizenship",
			enterAction: props && props.enterAction,
			validChanged: props && props.validChanged,
		});
		this.streetController = new ControlledInputController({
			name: "Address",
			enterAction: props && props.enterAction,
			validChanged: props && props.validChanged,
		});
		this.cityController = new ControlledInputController({
			name: "City",
			enterAction: props && props.enterAction,
			validChanged: props && props.validChanged,
		});
		this.stateController = new ControlledInputController({
			name: "State",
			enterAction: props && props.enterAction,
			validChanged: props && props.validChanged,
		});
		this.countryController = new ControlledInputController({
			name: "Country",
			enterAction: props && props.enterAction,
			validChanged: props && props.validChanged,
		});
		this.zipCodeController = new ControlledInputController({
			name: "Zip Code",
			enterAction: props && props.enterAction,
			validChanged: props && props.validChanged,
		});
		this.emergencyName1 = new ControlledInputController({
			name: "Primary Emergency Name",
			enterAction: props && props.enterAction,
			validChanged: props && props.validChanged,
		});
		this.emergencyLast1 = new ControlledInputController({
			name: "Primary Emergency Last Name",
			enterAction: props && props.enterAction,
			validChanged: props && props.validChanged,
		});
		this.emergencyPhone1 = new PhoneInputController({
			name: "Primary Emergency Phone",
			enterAction: props && props.enterAction,
			validChanged: props && props.validChanged,
		});
		this.emergencyEmail1 = new EmailInputController({
			name: "Primary Emergency Email",
			enterAction: props && props.enterAction,
			validChanged: props && props.validChanged,
		});
		this.emergencyName2 = new ControlledInputController({
			name: "Secondary Emergency Name",
			enterAction: props && props.enterAction,
			validChanged: props && props.validChanged,
		});
		this.emergencyLast2 = new ControlledInputController({
			name: "Secondary Emergency Last Name",
			enterAction: props && props.enterAction,
			validChanged: props && props.validChanged,
		});
		this.emergencyPhone2 = new PhoneInputController({
			name: "Secondary Emergency Phone",
			enterAction: props && props.enterAction,
			validChanged: props && props.validChanged,
		});
		this.emergencyEmail2 = new EmailInputController({
			name: "Secondary Emergency Email",
			enterAction: props && props.enterAction,
			validChanged: props && props.validChanged,
		});
	}
}

class UserInfoController extends React.Component<RouteComponentProps, IState> {
	userInfoDto?: UserInfoDto;
	userInfoData: UserInfoControllerData;
	user?: IAuthUser;

	constructor(props: RouteComponentProps) {
		super(props);

		this.state = {
			loading: true,
			dataLoaded: false,
		};

		this.user = AuthController.service.getCurrentAuthUser();

		this.userInfoData = new UserInfoControllerData();
		this.createUserInfo = this.createUserInfo.bind(this);
		this.registerUserInfo = this.registerUserInfo.bind(this);
		this.validateUserInputs = this.validateUserInputs.bind(this);
		this.validateEmergencyInputs = this.validateEmergencyInputs.bind(this);
		this.back = this.back.bind(this);
	}

	componentDidMount() {
		try {
			RestClient.getInstance()
				.getUserInfo()
				.then((userInfo) => {
					this.userInfoDto = userInfo;
					this.userInfoData = Mappers.userInfoDtoToUserInfoControllerData(userInfo);
					this.setState(
						modifyState(this.state, {
							dataLoaded: true,
							loading: false,
						})
					);
				})
				.catch((e) => {
					this.onGetUserInfoError();
				});
		} catch (e) {
			this.onGetUserInfoError();
		}
	}

	onGetUserInfoError() {
		this.userInfoData.nameController.setValue(
			this.user?.displayName ? this.user.displayName.split(" ", 2).first() : ""
		);
		this.userInfoData.lastNameController.setValue(
			this.user?.displayName ? this.user.displayName.split(" ", 2).last() : ""
		);

		this.userInfoData.emailController?.setValue(this.user?.email ? this.user.email : "");
		this.userInfoData.usernameController?.setValue(
			this.user?.username ? this.user.username : ""
		);

		this.userInfoData.phoneController.setValue(
			this.user?.phoneNumber ? this.user.phoneNumber : ""
		);
		this.setState(
			modifyState(this.state, {
				dataLoaded: true,
				loading: false,
			})
		);
	}

	validateUserInputs(): IError | null {
		for (const controller of Object.values(this.userInfoData)
			.filter((controller) => controller !== undefined)
			.filter((controller) => !controller.name.includes("Emergency"))) {
			if (!controller.isValid()) {
				return { message: "Invalid " + controller.name };
			}
		}
		return null;
	}

	validateEmergencyInputs(): IError | null {
		for (const controller of Object.values(this.userInfoData)
			.filter((controller) => controller !== undefined)
			.filter((controller) => controller.name.includes("Emergency"))) {
			if (!controller.isValid()) {
				return { message: "Invalid " + controller.name };
			}
		}
		return null;
	}

	createUserInfo() {
		const error = this.validateUserInputs();
		if (error) {
			this.setState(modifyState(this.state, { error: error }));
			return;
		} else {
			this.setState(modifyState(this.state, { error: undefined }));
		}

		this.props.history.push(Paths.EmergencyInfo);
	}

	registerUserInfo() {
		let error = this.validateUserInputs();
		if (error) {
			this.props.history.push(Paths.UserInfo);
		}
		error = this.validateEmergencyInputs();
		if (error) {
			this.setState(modifyState(this.state, { error: error }));
			return;
		}
		this.userInfoDto = Mappers.userInfoControllerDataToUserInfoDto(this.userInfoData);

		this.setState(modifyState(this.state, { loading: true }));
		RestClient.getInstance()
			.updateUserInfo(this.userInfoDto!)
			.then(() => {
				if (this.user!.email!.includes("someweartest")) {
					RestClient.getInstance()
						.updateSosInfo(this.userInfoDto!)
						.then(() => {
							this.props.history.push(Paths.Plans);
						})
						.catch((error) => {
							Sentry.captureException(error);
							this.setState(
								modifyState(this.state, {
									loading: false,
									error: {
										message: "There was an error while saving your information",
									},
								})
							);
						});
				} else {
					this.props.history.push(Paths.Plans);
				}
			})
			.catch(() => {
				Sentry.captureException(error);
				this.setState(
					modifyState(this.state, {
						loading: false,
						error: {
							message: "There was an error while saving your information",
						},
					})
				);
			});
	}

	back() {
		this.setState(modifyState(this.state, { error: undefined }));
		this.props.history.push(Paths.UserInfo);
	}

	render(): React.ReactNode {
		if (!this.state.dataLoaded) {
			return null;
		}
		if (this.state.loading) {
			return <Loader />;
		} else
			return (
				<Switch>
					<Route
						path={Paths.UserInfo}
						render={(_props) => (
							<UserInfoView
								{..._props}
								{...this.state}
								userInfoControllers={this.userInfoData}
								createUserInfo={this.createUserInfo}
							/>
						)}
					/>
					<Route
						path={Paths.EmergencyInfo}
						render={(_props) => (
							<EmergencyContactsView
								{..._props}
								{...this.state}
								userInfoControllers={this.userInfoData}
								registerUserInfo={this.registerUserInfo}
								back={this.back}
							/>
						)}
					/>
					<Redirect path={Paths.Setup + "/*"} to={Paths.UserInfo} />
				</Switch>
			);
	}
}

export default withRouter(UserInfoController);
