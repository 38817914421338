import { createAction } from "@reduxjs/toolkit";
import type {
	ArchiveMembersResponse,
	CreateResourceAccountResponse,
	ToggleWorkspaceIntegrationRequest,
} from "@somewear-labs/swl-web-api/src/proto/api/workspace_pb";
import type { DeviceList } from "@somewear-labs/swl-web-api/src/proto/device_proto_pb";
import type {
	UserResponse,
	WorkspaceResponseList,
} from "@somewear-labs/swl-web-api/src/proto/user_proto_pb";

import { createActionSet } from "../../common/EpicUtils";

export const workspaceActions = {
	// fetchWorkspaces: createActionSet("workspaces/fetch"),
	// fetchAccounts: createActionSet("workspace/accounts/fetch"),
	fetchDevices: createActionSet<void, DeviceList.AsObject>("workspace/devices/fetch"),
	leaveWorkspace: createActionSet<string, ArchiveMembersResponse.AsObject>("workspace/leave"),
	toggleIntegration: createActionSet<
		ToggleWorkspaceIntegrationRequest.AsObject,
		UserResponse.AsObject
	>("workspace/integration/toggle"),
	bulkToggleIntegrations: createActionSet<
		ToggleWorkspaceIntegrationRequest.AsObject[],
		UserResponse.AsObject[]
	>("workspace/integration/toggle/bulk"),
	fetchWorkspaces: createActionSet<void, WorkspaceResponseList.AsObject>("workspaces/fetch"),
	createResource: createActionSet<string, CreateResourceAccountResponse.AsObject>(
		"workspace/resources/create"
	),
};

// set the active workspace, but do not clear state
export const setActiveWorkspaceId = createAction<string>("setActiveWorkspaceId");

// clear the state and set the active workspace
export const updateActiveWorkspaceId = createAction<string>("updateActiveWorkspaceId");
