import type { Dictionary } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

import { selectVisibilityOverrides } from "../../app/assets/workspaceAssetSelectors";
import type { RootState } from "../../app/rootReducer";
import { selectActiveTrackingUser  } from "../trackingSelectors";

export const selectGlobalDateFilter = (state: RootState) => {
	return state.tracking.dateFilter;
};

export const selectHiddenUsers = createSelector([selectVisibilityOverrides], (overrides) => {
	return Object.keys(overrides).filter((assetId) => overrides[assetId] === false);
});

export const selectIsHiddenByUser = createSelector([selectHiddenUsers], (hiddenUsers) => {
	const isHiddenDict: Dictionary<boolean> = {};
	hiddenUsers.forEach((it) => {
		isHiddenDict[it] = true;
	});
	return isHiddenDict;
});

export const selectShownUsers = createSelector(
	[selectVisibilityOverrides, selectActiveTrackingUser],
	(overrides, activeTrackingUser) => {
		const shownUsers = Object.keys(overrides).filter((assetId) => overrides[assetId] === true);
		if (activeTrackingUser !== undefined && !shownUsers.includes(activeTrackingUser.userId))
			shownUsers.push(activeTrackingUser.userId);
		return shownUsers;
	}
);
