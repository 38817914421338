import { createAction } from "@reduxjs/toolkit";
import type { LocationResponse } from "@somewear-labs/swl-web-api/src/proto/location_proto_pb";

import type { DateRange } from "./trackingSlice";

export const getLocationsFulfilled = createAction<LocationResponse.AsObject[]>(
	"tracking/locations/get/fulfilled"
);

export const setDateFilter = createAction<DateRange>("tracking/date-filter/set");

export const startGeolocating = createAction("tracking/geolocate/start");
export const stopGeolocating = createAction("tracking/geolocate/stop");

export const initMapView = createAction("tracking/map-view/init");
