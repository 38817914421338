import * as React from "react";
import styled from "styled-components";

interface IProps {
	className?: string;
	fill?: string;
}

const Icon = styled.svg`
	height: 14px;
	width: 21px;
`;

export const ViewIconHidden: React.FunctionComponent<IProps> = (props) => {
	return (
		<Icon className={props.className ?? ""} viewBox="0 0 21 18">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3.46841 3.85379L0.949347 1.33473L2.27467 0L18.9399 16.6747L17.6146 18L14.4 14.7854C13.1405 15.2836 11.7681 15.5561 10.3394 15.5561C5.63969 15.5561 1.62611 12.6329 0 8.50653C0.72376 6.65483 1.93629 5.05692 3.46841 3.85379ZM10.3395 3.33696C13.9019 3.33696 17.0789 5.33905 18.6298 8.50667C18.0753 9.65341 17.2951 10.6404 16.3646 11.4393L17.6899 12.7646C18.9964 11.6085 20.0304 10.161 20.6789 8.50667C19.0528 4.3803 15.0392 1.45706 10.3395 1.45706C9.14577 1.45706 7.99903 1.64505 6.91809 1.99283L8.469 3.54375C9.07997 3.42155 9.70034 3.33696 10.3395 3.33696ZM9.33398 4.4083L11.2797 6.35399C11.8154 6.58897 12.2478 7.02135 12.4828 7.55712L14.4285 9.50281C14.5037 9.18323 14.5601 8.84485 14.5601 8.49707C14.5695 6.166 12.6708 4.2767 10.3397 4.2767C9.99195 4.2767 9.66297 4.3237 9.33398 4.4083ZM7.99866 8.38422L10.4519 10.8375L10.4519 10.8375C10.4143 10.8469 10.3767 10.8563 10.3391 10.8563C9.042 10.8563 7.98926 9.80354 7.98926 8.50641C7.98926 8.48291 7.99161 8.46411 7.99396 8.44531C7.99631 8.42651 7.99866 8.40771 7.99866 8.38422ZM6.44791 6.83348L4.803 5.18857C3.66566 6.05332 2.70691 7.16246 2.04895 8.50658C3.59986 11.6742 6.77689 13.6763 10.3393 13.6763C11.2322 13.6763 12.097 13.5447 12.9241 13.3191L12.003 12.398C11.4954 12.6142 10.9315 12.7363 10.3393 12.7363C8.00822 12.7363 6.10952 10.8377 6.10952 8.50658C6.10952 7.91442 6.23172 7.35045 6.44791 6.83348Z"
				fill={props.fill ?? "black"}
				fillOpacity="0.54"
			/>
		</Icon>
	);
};
