import type { Dictionary, PayloadAction } from "@reduxjs/toolkit";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { IdentityRecord } from "@somewear-labs/swl-web-api/src/proto/identity_record_proto_pb";
import type * as message_proto_pb from "@somewear-labs/swl-web-api/src/proto/message_proto_pb";
import type { StyleSettings } from "@somewear-labs/swl-web-api/src/proto/style_proto_pb";
import type {
	IdentityTypeMap,
	UserResponse,
} from "@somewear-labs/swl-web-api/src/proto/user_proto_pb";
import { IdentityType } from "@somewear-labs/swl-web-api/src/proto/user_proto_pb";
import type * as workspace_role_proto_pb from "@somewear-labs/swl-web-api/src/proto/workspace_role_proto_pb";
import isEqual from "fast-deep-equal";
import { original } from "immer";

import type { IPendingPayload, IRejectedPayload } from "../../common/EpicUtils";
import { organizationActions } from "../../settings/organization/organizationsSlice";
import { workspaceMemberActions } from "../../settings/workspace/members/workspaceMemberActions";
import { workspaceActions } from "../../settings/workspace/workspaceActions";
import {
	hideAllUsers,
	setActiveUser,
	setUserVisibility,
	showAllUsers,
} from "../../tracking/trackingSlice";
import {
	appActions,
	emitAddUserAccountFromServer,
	emitUserAccountChangeFromServer,
} from "../appActions";
import type { RootState } from "../rootReducer";

export type WorkspaceRole =
	workspace_role_proto_pb.WorkspaceRoleMap[keyof workspace_role_proto_pb.WorkspaceRoleMap];

export interface IWorkspaceAsset {
	id: string;
	fullname: string;
	email: string;
	identityId: string;
	type: IdentityTypeMap[keyof IdentityTypeMap] | undefined;
	isArchived: boolean;
	workspaceRole: WorkspaceRole;
	workspaceId: string;
	username?: string;
	phonenumber?: string;
	externalId?: string;
	serial?: string;
	isNew?: boolean;
	integrationtype?: message_proto_pb.IntegrationTypeMap[keyof message_proto_pb.IntegrationTypeMap];
	status?: UserResponse.AccountStatusMap[keyof UserResponse.AccountStatusMap];
	styleSettings?: StyleSettings.AsObject;
	visibilityOverride?: boolean;
}

export interface IWorkspaceIntegrationAsset extends IWorkspaceAsset {
	inboundEnabled: boolean;
	outboundEnabled: boolean;
}

export const getDisplayNameForWorkspaceAsset = (asset?: IWorkspaceAsset) => {
	return asset !== undefined ? asset.fullname.replaceIfEmpty(asset.email) : "";
};
const adapter = createEntityAdapter<IWorkspaceAsset>({
	selectId: (user) => user.id,
	sortComparer: (a, b) => {
		if (a.isNew && b.isNew) {
		} else if (a.isNew) {
			return -1;
		} else if (b.isNew) {
			return 1;
		}

		const aName = a.fullname.toLowerCase();
		const bName = b.fullname.toLowerCase();
		if (aName && bName) {
			if (aName < bName) return -1;
			else if (aName > bName) return 1;
		} else if (aName) {
			return -1;
		} else if (bName) {
			return 1;
		}

		if (a.email && b.email) {
			if (a.email < b.email) return -1;
			else if (a.email > b.email) return 1;
		} else if (a.email) {
			return 1;
		} else if (b.email) {
			return -1;
		}
		return 0;
	},
});

// Rename the exports for readability in component usage
export const {
	selectAll: selectAllWorkspaceAssets,
	selectById: selectWorkspaceAssetById,
	selectEntities: selectWorkspaceAssetEntities,
} = adapter.getSelectors((state: RootState) => state.workspaceAssets);

const workspaceAssetsSlice = createSlice({
	name: "workspaceAssets",
	initialState: adapter.getInitialState(),
	reducers: {
		_() {}, // every slice needs at least one reducer of it's own
	},
	extraReducers: (builder) => {
		/*builder.addCase(workspaceActions.fetchAccounts.fulfilled, (state, action) => {
			adapter.upsertMany(state, action.payload.data);

			const storeUserIds = adapter
				.getSelectors()
				.selectAll(state)
				.map((user) => user.id);
			const latestUserIds = action.payload.data.map((user) => user.id);
			const removedUserIds = _.difference(storeUserIds, latestUserIds);
			adapter.removeMany(state, removedUserIds);
		});*/
		builder.addCase(appActions.fetchAssets.fulfilled, (state, action) => {
			adapter.upsertMany(state, action.payload.data.usersList);

			const identityDict: Dictionary<IdentityRecord.AsObject | undefined> = {};
			const assetTypeDict: Dictionary<IdentityTypeMap[keyof IdentityTypeMap] | undefined> =
				{};

			action.payload.data.identitiesList.forEach((identity) => {
				let type: IdentityTypeMap[keyof IdentityTypeMap] | undefined;
				switch (identity.type) {
					case IdentityRecord.Type.USER:
						type = IdentityType.USER;
						break;
					case IdentityRecord.Type.RESOURCE:
						type = IdentityType.RESOURCE;
						break;
					case IdentityRecord.Type.DEVICE:
						type = IdentityType.DEVICE;
						break;
					case IdentityRecord.Type.INTEGRATION:
						type = IdentityType.INTEGRATION;
						break;
				}
				assetTypeDict[identity.id] = type;
				identityDict[identity.id] = identity;
			});

			const members = action.payload.data.accountsList
				.flatMap((account) => {
					const identity = identityDict[account.identityId];
					const type = assetTypeDict[account.identityId];
					if (identity === undefined || type === undefined) return undefined;
					const asset: IWorkspaceAsset = {
						id: account.id,
						workspaceId: account.workspaceId,
						workspaceRole: account.workspaceRole,
						fullname: identity.fullName,
						email: identity.email,
						identityId: identity.id,
						isArchived: false,
						type: type,
					};
					return asset;
				})
				.mapNotNull((it) => it);

			adapter.upsertMany(state, members);
		});

		/*builder.addCase(
			workspaceMemberActions.add.pending,
			(state, action: PayloadAction<IPendingPayload<string[]>>) => {
				const pendingUsers: UserResponse.AsObject[] = action.payload.data.map(email => {
					const user = new UserResponse().toObject();
					user.id = email;
					user.email = email;
					return user;
				});

				adapter.upsertMany(state, pendingUsers);
			}
		);*/
		builder.addCase(workspaceMemberActions.add.fulfilled, (state, action) => {
			if (action.payload.data.usersList !== undefined) {
				adapter.upsertMany(state, action.payload.data.usersList);
			}
		});
		builder.addCase(
			workspaceMemberActions.remove.pending,
			(state, action: PayloadAction<IPendingPayload<string[]>>) => {
				// ideally this would be done after receiving a response
				// adapter.removeMany(state, action.payload.data);
			}
		);
		builder.addCase(workspaceMemberActions.remove.fulfilled, (state, action) => {
			const archivedUserIds = action.payload.data.usersList.map((user) => user.id);
			const users = archivedUserIds.mapNotNull((userId) => state.entities[userId]);
			users.forEach((user) => (user.isArchived = true));
			adapter.upsertMany(state, users);
		});
		builder.addCase(workspaceMemberActions.assignRole.fulfilled, (state, action) => {
			if (action.payload.data.user !== undefined)
				adapter.upsertOne(state, action.payload.data.user);
		});
		builder.addCase(
			workspaceMemberActions.remove.rejected,
			(state, action: PayloadAction<IRejectedPayload<string[]>>) => {
				// remove all pending users
				/*const pendingUsers = adapter
					.getSelectors()
					.selectAll(state)
					.filter((user) => user.id === user.email);
				adapter.removeMany(
					state,
					pendingUsers.map((user) => user.id)
				);*/
			}
		);
		builder.addCase(emitUserAccountChangeFromServer, (state, action) => {
			const previousDraft = state.entities[action.payload.id];
			if (previousDraft !== undefined) {
				const previousObject = original(previousDraft);
				const matches = isEqual(previousObject, action.payload);
				// don't update anything if there are no changes
				if (matches) return;
			}

			adapter.upsertOne(state, action.payload);
		});

		builder.addCase(emitAddUserAccountFromServer, (state, action) => {
			console.log("added user");
			const userResponse: IWorkspaceAsset = action.payload;
			if (!action.payload.isArchived) {
				// user is not archived, mark them as new and upsert them
				userResponse.isNew = true;
				adapter.upsertOne(state, userResponse);
			}
		});
		builder.addCase(workspaceMemberActions.update.fulfilled, (state, action) => {
			const updatedUserAccounts = action.payload.data.updatedUserAccounts?.responsesList;
			if (updatedUserAccounts !== undefined) adapter.upsertMany(state, updatedUserAccounts);
		});
		builder.addCase(workspaceActions.toggleIntegration.fulfilled, (state, action) => {
			adapter.upsertOne(state, action.payload.data);
		});
		builder.addCase(workspaceActions.bulkToggleIntegrations.fulfilled, (state, action) => {
			adapter.upsertMany(state, action.payload.data);
		});
		builder.addCase(organizationActions.getIntegrationAccounts.fulfilled, (state, action) => {
			const accounts = action.payload.data.accountsList;
			if (accounts === undefined) return;

			const integrationAccounts: IWorkspaceIntegrationAsset[] = accounts.mapNotNull((it) => {
				if (it.account === undefined) return undefined;
				return {
					...it.account,
					inboundEnabled: it.inboundEnabled,
					outboundEnabled: it.outboundEnabled,
				};
			});

			adapter.upsertMany(state, integrationAccounts);
		});
		builder.addCase(organizationActions.configureIntegration.fulfilled, (state, action) => {
			const accounts = action.payload.data.accountsList;
			if (accounts === undefined) return;

			const integrationAccounts: IWorkspaceIntegrationAsset[] = accounts.mapNotNull((it) => {
				if (it.account === undefined) return undefined;
				return {
					...it.account,
					inboundEnabled: it.inboundEnabled,
					outboundEnabled: it.outboundEnabled,
				};
			});

			adapter.upsertMany(state, integrationAccounts);
		});
		builder.addCase(setActiveUser, (state, action) => {
			const user = state.entities[action.payload.userId];
			if (user === undefined) return;
			user.visibilityOverride = undefined;
			adapter.upsertOne(state, user);
		});
		builder.addCase(setUserVisibility, (state, action) => {
			const user = state.entities[action.payload.userId];
			if (user === undefined) return;
			if (action.payload.visibility === undefined) {
				user.visibilityOverride = undefined;
			} else {
				user.visibilityOverride = action.payload.visibility;
			}
			adapter.upsertOne(state, user);
		});
		builder.addCase(showAllUsers, (state, action) => {
			const users = adapter
				.getSelectors()
				.selectAll(state)
				.map((user) => {
					return { ...user, visibilityOverride: true };
				});
			adapter.upsertMany(state, users);
		});
		builder.addCase(hideAllUsers, (state, action) => {
			const users = adapter
				.getSelectors()
				.selectAll(state)
				.map((user) => {
					return { ...user, visibilityOverride: false };
				});
			adapter.upsertMany(state, users);
		});
	},
});

export default workspaceAssetsSlice;
