import type { GetOrganizationDevicesResponse } from "@somewear-labs/swl-web-api/src/proto/api/organization_pb";
import { combineEpics } from "redux-observable";

import type { ActionSetEpic} from "../../../common/EpicUtils";
import { createActionSetEpicHandler } from "../../../common/EpicUtils";
import { grpc } from "../../../common/GrpcClient";
import { someGrpc } from "../../../common/SomewearGrpc";
import { organizationDeviceActions } from "./organizationDeviceActions";

const getOrganizationDevicesEpic: ActionSetEpic<void, GetOrganizationDevicesResponse.AsObject> = (
	action$,
	state$
) => {
	return createActionSetEpicHandler(action$, state$, organizationDeviceActions.getDevices, () =>
		grpc.prepareRequest(someGrpc.fetchOrganizationDevices)
	);
};

export default combineEpics(getOrganizationDevicesEpic);
