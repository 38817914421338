// Define the base interface of layers.
import type { IShape } from "../tracking/shapes/shapeSlice";
import type { KmlLink, KmlNetworkLink, KmlScreenOverlay, KmlStyle } from "./KmlParser";

interface LayerState {
	visible: boolean;
	loading: boolean;
}

// Define the Map Layer interface.
export interface MapLayerState extends LayerState {
	removed?: boolean;
}

export enum MapLayerType {
	Kml,
	Shape,
	Raster,
}

// Define the properties of a Map Layer.
export interface MapLayer {
	id: string;
	fileName: string;
	index: number;
	order: number;
	uploadDate?: string;
	state: MapLayerState;
	data?: GeoJSON.FeatureCollection<GeoJSON.Geometry>;
	shape?: IShape;
	styles?: KmlStyle[];
	overlays?: KmlScreenOverlay[];
	links?: KmlNetworkLink[];
	srcLink?: KmlLink;
	fromPublic?: boolean;
	type: MapLayerType;
}

// Define the custom type of an array of Map Layers.
export type MapLayers = MapLayer[];

// Define the properties used in the Map Layer's "Uploading" component.
export interface UploadingFileProps {
	fileName: string;
	loading: boolean;
	errored: boolean;
}

// Define the properties of the Map Layer's "Modal" container component.
export interface MapLayersModalProps {
	state: LayerState;
	onUploadFiles: (files: FileList | null) => void;
	type: MapLayerType;
}

export interface MapLayersFileDropComponentProps {
	state: number;
	onUploadFiles: (files: FileList | null) => void;
}

// Define the properties of the Map Layer's master "Controller" component.
export interface MapLayersControllerProps {
	state: number;
	uploadingFile: string;
}

// Define the values used by the MapLayersControllerProps "state" property.
export enum MapLayersControllerState {
	Default = 1,
	DraggingOver = 2,
	Dropping = 4,
	Uploading = 8,
	Uploaded = 16,
	Errored = 32,
}

export enum MapLayersFileDropComponentState {
	Default = 1,
	DraggingOver = 2,
	Dropping = 4,
}
