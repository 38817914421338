enum Paths {
	All = "/*",
	Root = "/",
	SignInPhone = "/login/phone",
	Email = "/email",
	Reset = "/email/reset",
	Signup = "/signup",
	Phone = "/phone",
	Subscription = "/subscription",
	Device = "/setup/device",
	Confirmation = "/setup/confirmation",
	Setup = "/setup",
	SetupOrder = "/setup/:order",
	SetupUser = "/setup/user",
	UserInfo = "/setup/user/userinfo",
	EmergencyInfo = "/setup/user/emergency",
	Plans = "/setup/plans",
	App = "/app",
	Settings = "/app/settings",
	SettingsType = "/app/settings/:type",
	SettingsTypeSubtype = "/app/settings/:type/:slug",
	SettingsStaff = "/app/settings/staff",
	SettingsProfile = "/app/settings/profile",
	SettingsEmergency = "/app/settings/emergency",
	SettingsSubscription = "/app/settings/subscription",
	SettingsSubscriptionEdit = "/app/settings/subscription/edit",
	SettingsOrganizationTab = "/app/settings/organization/:slug",
	SettingsWorkspace = "/app/settings/workspace",
	SettingsWorkspaces = "/app/settings/workspaces",
	SettingsJoinWorkspace = "/app/settings/join-workspace",
	SettingsJoinWorkspaceMatch = "/app/settings/join-workspace/:name/:token",
	SettingsWorkspaceTab = "/app/settings/workspace/:slug",
	SettingsOrganization = "/app/settings/organization",
	SettingsDevices = "/app/settings/devices",
	SettingsDevicesNewAccount = "/app/settings/devices/newAccount",
	SettingsDevicesSubscriptions = "/app/settings/devices/subscription",
	SettingsDevicesSubscriptionsMatch = "/app/settings/devices/subscription/:subscriptionCode",
	SettingsDevicesMatch = "/app/settings/devices/:success?",
	SettingsDevicesActivate = "/app/settings/devices/activate",
	SettingsDevicesActivateSingle = "/app/settings/devices/activate/single",
	SettingsSubscriptionAdd = "/app/settings/subscription/add",
	SettingsBilling = "/app/settings/billing",
	SettingsUsers = "/app/settings/users",
	Tracking = "/app/tracking",
	TrackingMatch = "/app/tracking/:id?",
	Conversations = "/app/conversations",
	ConversationsMatch = "/app/conversations/:key",
	ClaimOrganization = "/app/claim-organization",
	LocationMessageMatch = "/app/conversations/:key/:messageId",
	AppMatch = "/app/:type",
	PublicTrackingMatch = "/public/tracking/:uuid",
}

export default Paths;
