import type { CreateHealthActivityResponse } from "@somewear-labs/swl-web-api/src/proto/api/health_activity_pb";

import { createActionSet } from "../../common/EpicUtils";
import type { CreateHealthActivityForm } from "./biometric.model";

export const biometricActions = {
	createHealthActivity: createActionSet<CreateHealthActivityForm, CreateHealthActivityResponse>(
		"biometrics/create"
	),
};
