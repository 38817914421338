import type { Observable} from "rxjs";
import { iif, of, Subject } from "rxjs";

import Config from "../config/Config";

interface IPasswordArgs {
	password: string;
}

interface IEmailArgs {
	email: string;
}

export type EmailSignInArgs = IPasswordArgs & IEmailArgs;

export type UpdatePasswordArgs = {
	newPassword: string;
	newPasswordConfirm: string;
	currentPassword: string;
};

export interface IAuthService {
	initialize: () => void;
	signInWithEmailAndPassword$: (args: EmailSignInArgs) => Observable<IAuthUser | undefined>;
	createUserWithEmailAndPassword$: (args: EmailSignInArgs) => Observable<IAuthUser | undefined>;
	signInAnonymously$: () => Observable<IAuthUser | undefined>;
	sendPasswordResetEmail$: (email: string) => Observable<void>;
	sendEmailVerification$: () => Observable<void>;
	updateProfile$: (displayName: string) => Observable<void>;
	signOut$: () => Observable<void>;
	reauthenticate$: (currentPassword: string) => Observable<boolean>;
	getUserIdToken$: () => Observable<string | undefined>;
	getTokenString$: () => Observable<string | undefined>;
	getCurrentAuthUser: () => IAuthUser | undefined;
	reloadUser: () => void;
	onAuthStateChanged: (next: Subject<IAuthUser | undefined>) => () => void;
}

class AbstractAuthController {
	constructor() {
		this.initController();
	}

	private serviceSubject = new Subject<IAuthService>();
	private _service: IAuthService | undefined;

	get isServiceLoaded(): boolean {
		return this._service !== undefined;
	}

	get service$(): Observable<IAuthService> {
		return iif(() => this._service !== undefined, of(this._service!), this.serviceSubject);
	}

	get service(): IAuthService {
		if (this._service === undefined) throw Error("Service has not been loaded yet");
		return this._service;
	}

	tokenString$(omitAuth?: boolean): Observable<string | undefined> {
		if (this._service === undefined) throw Error("Service has not been loaded yet");
		return iif(() => omitAuth === true, of(""), this._service.getTokenString$());
	}

	private initController() {
		if (this._service !== undefined) return;
		loadAuthService().then((service) => {
			this._service = service;
			this.serviceSubject.next(service);
			service.initialize();
		});
	}
}

export interface IAuthUser {
	id: string;
	isAnonymous: boolean;
	email?: string;
	username?: string;
	displayName?: string;
	phoneNumber?: string;
}

export const AuthController = new AbstractAuthController();

export async function loadAuthService(): Promise<IAuthService> {
	// lazily execute dynamic import to load implemented auth service
	if (Config.firebase.enable) {
		const util = await import("../common/FirebaseUtil");
		return util.FirebaseAuthService;
	} else {
		const util = await import("./SomewearAuthUtil");
		return util.SomewearAuthService;
	}
}
