import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/browser";
import type { GetOrganizationLicenseSummaryResponse } from "@somewear-labs/swl-web-api/src/proto/api/organization_pb";
import type { LicenseUsageNotification } from "@somewear-labs/swl-web-api/src/proto/license_proto_pb";
import type { Timestamp } from "@somewear-labs/swl-web-api/src/proto/timestamp_proto_pb";
import type { WorkspaceResponse } from "@somewear-labs/swl-web-api/src/proto/user_proto_pb";

import { AuthController } from "../common/AuthUtil";
import StoreController from "../common/StoreController";
import { UserSource } from "../common/UserSource";
import { organizationActions } from "../settings/organization/organizationsSlice";
import { setActiveWorkspaceId, workspaceActions } from "../settings/workspace/workspaceActions";
import WorkspaceUtil from "../settings/workspace/workspaceUtil";
import {
	apiSubscriptionsError,
	apiSubscriptionsSuccess,
	appActions,
	setUser,
	signedOut,
} from "./appActions";
import type { Dict } from "./appModel";
import type { Contact } from "./contacts/contactModels";

export interface DataMap<T> {
	data: Dict<T>;
	loaded: boolean;
}

export interface IUser {
	uid: string;
	name: string;
	email?: string;
	username?: string;
	phone?: string;
	isAnonymous: boolean;
	hasWorkspace: boolean;
	hasOrganization: boolean;
}

export interface IPendingSignIn {
	email: string;
	pendingCredentialJson: object;
}

interface AppState {
	// workspace "contacts" indexed by workspace id. Only set if a team workspace is active
	workspaceInfo: Dict<Contact>;
	// subscriptions: Dict<SubscriptionRequest.AsObject>;
	// includes personal
	// workspaces: WorkspaceObject[];
	// undefined if personal workspace
	activeAuthId?: string;
	activeIdentityId?: string;
	activeOrganizationId?: string;
	// undefined if unintialized. Can be personal.
	activeWorkspaceId?: string;
	activeUserAccountId?: string;
	isInitialLoadComplete: boolean;
	sidebarVisible: boolean;
	globalError: boolean;
	pushToken?: string;
	upgrade: boolean;
	// workspaceLoaded: boolean;
	// sosEvents: Dict<SosEventResponse.AsObject[]>;
	user?: IUser;
	useMgrs?: boolean;
	pendingSignIn?: IPendingSignIn;
	requireRefreshMessage?: string;
	licenseSummary?: GetOrganizationLicenseSummaryResponse.AsObject;
	lastPingSent?: Timestamp.AsObject;
	lastPongReceived?: Timestamp.AsObject;
}

const initialState: AppState = {
	workspaceInfo: {},
	// subscriptions: {},
	// workspaces: {},
	isInitialLoadComplete: false,
	sidebarVisible: false,
	globalError: false,
	upgrade: false,
	// workspaceLoaded: false,
	// sosEvents: {},
	useMgrs: localStorage.getItem("coordinateSystem") === "mgrs",
};

/*
App reducers and actions. Empty functions are there to automatically generate the actions.
Loading and error states are handled in loadingReducer and errorReducer
 */
const appSlice = createSlice({
	name: "app",
	initialState,
	reducers: {
		setActiveIdentityId(state, action: PayloadAction<string>) {
			state.activeIdentityId = action.payload;
		},
		setActiveAuthId(state, action: PayloadAction<string>) {
			state.activeAuthId = action.payload;
		},
		setActiveOrganizationId(state, action: PayloadAction<string>) {
			UserSource.getInstance().updateActiveOrganization(action.payload);
			state.activeOrganizationId = action.payload;
			if (action.payload === "-1") {
				UserSource.getInstance().updateActiveWorkspaceId(action.payload);
				state.activeWorkspaceId = action.payload;
			}
		},
		updateActiveOrganizationId(state, action: PayloadAction<string>) {},
		apiNotificationStreamOpen() {},
		apiNotificationStreamClose() {},
		apiNotificationStreamDisconnected() {},
		emitWorkspacesReceived(state, action: PayloadAction<WorkspaceResponse.AsObject[]>) {
			action.payload
				.filter((workspace) => WorkspaceUtil.isTeam(workspace))
				.forEach((workspace) => {
					state.workspaceInfo[workspace.id] = {
						id: workspace.id,
						email: "",
						username: "",
						phone: "",
						name: workspace.name,
						// displayName: workspace.name,
						type: undefined,
					};
				});
		},
		setUserPhone(state, action: PayloadAction<string>) {
			if (state.user) {
				state.user.phone = action.payload;
			}
		},
		resetState() {},
		refreshInitialAppData() {},
		refreshDetailedAppData(state) {
			state.lastPongReceived = undefined;
		},
		setGlobalError(state) {
			state.globalError = true;
		},
		setSidebarState(state, action: PayloadAction<boolean>) {
			state.sidebarVisible = action.payload;
		},
		toggleSidebar(state) {
			state.sidebarVisible = !state.sidebarVisible;
		},
		setPushToken(state, action: PayloadAction<string | undefined>) {
			state.pushToken = action.payload;
		},
		setIsInitialLoadComplete(state, action: PayloadAction<boolean>) {
			state.isInitialLoadComplete = action.payload;
		},
		/*setActiveWorkspaceId(state, action: PayloadAction<string>) {
			state.activeWorkspaceId = action.payload;
			// UserSource.getInstance().updateActiveWorkspaceId(workspaceId);
			// window.location.reload();
		},*/
		setActiveUserAccountId(state, action: PayloadAction<string>) {
			state.activeUserAccountId = action.payload;
		},
		setUpgrade(state, action: PayloadAction<boolean>) {
			state.upgrade = action.payload;
		},
		setUseMgrs(state, action: PayloadAction<boolean>) {
			if (action.payload) {
				localStorage.setItem("coordinateSystem", "mgrs");
			} else {
				localStorage.setItem("coordinateSystem", "mgrs");
			}
			state.useMgrs = action.payload;
		},
		setPendingSignIn(state, action: PayloadAction<IPendingSignIn>) {},
		updateLicenseUsage(state, action: PayloadAction<LicenseUsageNotification.AsObject>) {
			if (state.licenseSummary === undefined) return;
			state.licenseSummary.licenseUsage = action.payload;
		},
		requireRefresh(state, action: PayloadAction<string>) {
			state.requireRefreshMessage = action.payload;
		},
		unauthorizedError() {
			AuthController.service?.signOut$().subscribe();
		},

		noOp() {},
	},
	extraReducers: (builder) => {
		builder.addCase(setActiveWorkspaceId, (state, action) => {
			UserSource.getInstance().updateActiveWorkspaceId(action.payload);
			state.activeWorkspaceId = action.payload;
		});
		builder.addCase(apiSubscriptionsSuccess, (state, action) => {
			state.isInitialLoadComplete = true;
		});
		builder.addCase(apiSubscriptionsError, (state, action) => {
			state.isInitialLoadComplete = true;
		});
		builder.addCase(setUser, (state, action) => {
			state.user = action.payload;
		});
		/*builder.addCase(workspaceActions.fetchAccounts.fulfilled, (state, action) => {
			state.workspaceLoaded = true;
		});
		builder.addCase(workspaceAssetActions.fetchAssets.fulfilled, (state, action) => {
			state.workspaceLoaded = true;
		});*/
		builder.addCase(workspaceActions.leaveWorkspace.fulfilled, (state, action) => {
			const user = AuthController.service.getCurrentAuthUser();
			if (user === undefined) return;
			const storedWorkspaceId = UserSource.getInstance().getStoredWorkspaceId(user.id);
			if (action.payload.data.usersList.first().workspaceId === storedWorkspaceId) {
				UserSource.getInstance().clearActiveWorkspaceId();
			}
			state.requireRefreshMessage = "You have been removed from the workspace.";
		});
		builder.addCase(organizationActions.getLicense.fulfilled, (state, action) => {
			state.licenseSummary = action.payload.data;
		});
		builder.addCase(signedOut, (state) => {
			StoreController.clearAllStores()
				.then(() => {
					console.log("Successfully cleared stores on sign out");
				})
				.catch((e) => {
					console.error("unable to clear stores on sign out");
					Sentry.captureException(e);
				});
		});
		builder.addCase(appActions.sendPing.request, (state, action) => {
			state.lastPingSent = action.payload.data;
		});
		builder.addCase(appActions.receivePongEvent, (state, action) => {
			state.lastPongReceived = action.payload.pongTimestamp;
		});
	},
});

export const {
	setActiveIdentityId,
	setActiveAuthId,
	apiNotificationStreamOpen,
	apiNotificationStreamClose,
	apiNotificationStreamDisconnected,
	emitWorkspacesReceived,
	setActiveOrganizationId,
	updateActiveOrganizationId,
	setUserPhone,
	resetState,
	setGlobalError,
	setSidebarState,
	toggleSidebar,
	setPushToken,
	setIsInitialLoadComplete,
	// setActiveWorkspaceId,
	setActiveUserAccountId,
	setUpgrade,
	setUseMgrs,
	setPendingSignIn,
	refreshInitialAppData,
	refreshDetailedAppData,
	updateLicenseUsage,
	requireRefresh,
	unauthorizedError,
	noOp,
} = appSlice.actions;

export default appSlice;
