import { SubscriptionRequest } from "@somewear-labs/swl-web-api/src/proto/subscription_proto_pb";
import {
	AddressDto,
	EmergencyContactDto,
	UserInfoDto,
} from "@somewear-labs/swl-web-api/src/proto/user_info_proto_pb";

import type { IUserInfoControllerDataProps} from "../setup/UserInfoController";
import { UserInfoControllerData } from "../setup/UserInfoController";

export class Mappers {
	static userInfoDtoToUserInfoControllerData(
		userInfoDto: UserInfoDto,
		props?: IUserInfoControllerDataProps
	): UserInfoControllerData {
		const userInfoData = new UserInfoControllerData(props);
		userInfoData.nameController.setValue(userInfoDto.getFirstName());
		userInfoData.lastNameController.setValue(userInfoDto.getLastName());
		userInfoData.emailController?.setValue(userInfoDto.getEmail());
		userInfoData.usernameController?.setValue(userInfoDto.getUsername());
		userInfoData.phoneController.setValue(userInfoDto.getPhone());
		userInfoData.citizenshipController.setValue(userInfoDto.getCitizenship());

		if (userInfoDto.getAddress()) {
			userInfoData.streetController.setValue(userInfoDto.getAddress()!.getAddressLine());
			userInfoData.cityController.setValue(userInfoDto.getAddress()!.getCity());
			userInfoData.stateController.setValue(userInfoDto.getAddress()!.getState());
			userInfoData.countryController.setValue(userInfoDto.getAddress()!.getCountry());
			userInfoData.zipCodeController.setValue(userInfoDto.getAddress()!.getPostalCode());
		}

		if (userInfoDto.getEmergencyContactsList().length) {
			userInfoData.emergencyName1.setValue(
				userInfoDto.getEmergencyContactsList()[0].getFullName().split(" ")[0] || ""
			);
			userInfoData.emergencyLast1.setValue(
				userInfoDto.getEmergencyContactsList()[0].getFullName().split(" ")[1] || ""
			);
			userInfoData.emergencyPhone1.setValue(
				userInfoDto.getEmergencyContactsList()[0].getPhone()
			);
			userInfoData.emergencyEmail1.setValue(
				userInfoDto.getEmergencyContactsList()[0].getEmail()
			);
		}

		if (userInfoDto.getEmergencyContactsList().length > 1) {
			userInfoData.emergencyName2.setValue(
				userInfoDto.getEmergencyContactsList()[1].getFullName()!.split(" ")[0] || ""
			);
			userInfoData.emergencyLast2.setValue(
				userInfoDto.getEmergencyContactsList()[1].getFullName()!.split(" ")[1] || ""
			);
			userInfoData.emergencyPhone2.setValue(
				userInfoDto.getEmergencyContactsList()[1].getPhone()
			);
			userInfoData.emergencyEmail2.setValue(
				userInfoDto.getEmergencyContactsList()[1].getEmail()
			);
		}

		return userInfoData;
	}

	static userInfoControllerDataToUserInfoDto(userInfoData: UserInfoControllerData): UserInfoDto {
		const userInfoDto = new UserInfoDto();
		userInfoDto.setAddress(new AddressDto());
		userInfoDto.getEmergencyContactsList().push(new EmergencyContactDto());
		userInfoDto.getEmergencyContactsList().push(new EmergencyContactDto());

		userInfoDto.getAddress()!.setAddressLine(userInfoData.streetController.getValue());
		userInfoDto.getAddress()!.setCity(userInfoData.cityController.getValue());
		userInfoDto.getAddress()!.setState(userInfoData.stateController.getValue());
		userInfoDto.getAddress()!.setPostalCode(userInfoData.zipCodeController.getValue());
		userInfoDto.getAddress()!.setCountry(userInfoData.countryController.getValue());

		userInfoDto.setFirstName(userInfoData.nameController.getValue());
		userInfoDto.setLastName(userInfoData.lastNameController.getValue());
		userInfoDto.setPhone(userInfoData.phoneController.getValue());
		if (userInfoData.emailController !== undefined)
			userInfoDto.setEmail(userInfoData.emailController.getValue());
		if (userInfoData.usernameController !== undefined)
			userInfoDto.setUsername(userInfoData.usernameController.getValue());
		userInfoDto.setCitizenship(userInfoData.citizenshipController.getValue());

		userInfoDto
			.getEmergencyContactsList()[0]
			.setFullName(
				userInfoData.emergencyName1.getValue() +
					" " +
					userInfoData.emergencyLast1.getValue()
			);
		userInfoDto.getEmergencyContactsList()[0].setEmail(userInfoData.emergencyEmail1.getValue());
		userInfoDto.getEmergencyContactsList()[0].setPhone(userInfoData.emergencyPhone1.getValue());
		userInfoDto
			.getEmergencyContactsList()[1]
			.setFullName(
				userInfoData.emergencyName2.getValue() +
					" " +
					userInfoData.emergencyLast2.getValue()
			);
		userInfoDto.getEmergencyContactsList()[1].setEmail(userInfoData.emergencyEmail2.getValue());
		userInfoDto.getEmergencyContactsList()[1].setPhone(userInfoData.emergencyPhone2.getValue());

		return userInfoDto;
	}

	static planToPlanDetail(
		plan: SubscriptionRequest.PlanMap[keyof SubscriptionRequest.PlanMap]
	): string {
		switch (plan) {
			case SubscriptionRequest.Plan.PLAN1M:
			case SubscriptionRequest.Plan.PLAN1Y:
				return "20 Messages or 150 Pin Drops";

			case SubscriptionRequest.Plan.PLAN2M:
			case SubscriptionRequest.Plan.PLAN2Y:
				return "75 Messages or 600 Pin Drops";

			case SubscriptionRequest.Plan.PLAN3M:
			case SubscriptionRequest.Plan.PLAN3Y:
				return "Unlimited Messages and Pin Drops";

			case SubscriptionRequest.Plan.PLAN4Y:
				return "10 Messages or 75 Pin Drops";

			default:
				return "";
		}
	}

	static planToPlanName(
		plan: SubscriptionRequest.PlanMap[keyof SubscriptionRequest.PlanMap]
	): string {
		switch (plan) {
			case SubscriptionRequest.Plan.PLAN1M:
			case SubscriptionRequest.Plan.PLAN1Y:
				return "Plan 1";

			case SubscriptionRequest.Plan.PLAN2M:
			case SubscriptionRequest.Plan.PLAN2Y:
				return "Plan 2";

			case SubscriptionRequest.Plan.PLAN3M:
			case SubscriptionRequest.Plan.PLAN3Y:
				return "Plan 3";

			case SubscriptionRequest.Plan.PLAN4Y:
				return "Plan Ultralight";

			case SubscriptionRequest.Plan.PLAN5M:
			case SubscriptionRequest.Plan.PLAN5Y:
				return "Plan 5";

			case SubscriptionRequest.Plan.PLAN6Y:
				return "Plan 6";

			default:
				return "";
		}
	}

	static planToPrice(
		plan: SubscriptionRequest.PlanMap[keyof SubscriptionRequest.PlanMap]
	): number {
		switch (plan) {
			case SubscriptionRequest.Plan.PLAN1M:
				return 15;
			case SubscriptionRequest.Plan.PLAN1Y:
				return 165;

			case SubscriptionRequest.Plan.PLAN2M:
				return 30;
			case SubscriptionRequest.Plan.PLAN2Y:
				return 330;

			case SubscriptionRequest.Plan.PLAN3M:
				return 50;
			case SubscriptionRequest.Plan.PLAN3Y:
				return 550;

			case SubscriptionRequest.Plan.PLAN4Y:
				return 100;

			default:
				return 0;
		}
	}

	static subscriptionStatusName(
		status: SubscriptionRequest.StatusMap[keyof SubscriptionRequest.StatusMap]
	): string {
		switch (status) {
			case SubscriptionRequest.Status.ACTIVE:
				return "Active";
			case SubscriptionRequest.Status.EXPIRED:
				return "Paused";
			case SubscriptionRequest.Status.CANCELED:
				return "Paused";
		}
	}

	static subscriptionPlanIsUnlimited(
		plan: SubscriptionRequest.PlanMap[keyof SubscriptionRequest.PlanMap]
	): boolean {
		return plan === SubscriptionRequest.Plan.PLAN3Y || plan === SubscriptionRequest.Plan.PLAN3M;
	}

	static planToData(
		plan: SubscriptionRequest.PlanMap[keyof SubscriptionRequest.PlanMap]
	): number {
		switch (plan) {
			case SubscriptionRequest.Plan.PLAN1M:
			case SubscriptionRequest.Plan.PLAN1Y:
				return 3000;

			case SubscriptionRequest.Plan.PLAN2M:
			case SubscriptionRequest.Plan.PLAN2Y:
				return 12000;

			case SubscriptionRequest.Plan.PLAN4Y:
				return 1500;

			case SubscriptionRequest.Plan.PLAN5M:
			case SubscriptionRequest.Plan.PLAN5Y:
				return 8000;

			case SubscriptionRequest.Plan.PLAN6Y:
				return 150000;

			default:
				return 0;
		}
	}
}
