import type { EntityState } from "@reduxjs/toolkit";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { RouteResponse } from "@somewear-labs/swl-web-api/src/proto/route_proto_pb";
import type * as timestamp_proto_pb from "@somewear-labs/swl-web-api/src/proto/timestamp_proto_pb";

import type { Contact } from "../../app/contacts/contactModels";
import type { RootState } from "../../app/rootReducer";
import { trackingLocationActions } from "../locations/trackingLocationActions";
import { getLocationsFulfilled } from "../trackingActions";
import { trackingRouteActions } from "./trackingRouteActions";

export interface ITrackingRoute extends Omit<RouteResponse.AsObject, "owner" | "location"> {
	timestamp?: timestamp_proto_pb.Timestamp.AsObject;
}
// export interface ITrackingRoute extends RouteResponse.AsObject {}

export interface ITrackingRouteWithContact extends ITrackingRoute {
	contact: Contact;
}

const adapter = createEntityAdapter<ITrackingRoute>({
	selectId: (route) => route.id,
});

// Rename the exports for readability in component usage
export const {
	selectAll: selectAllTrackingRoutes,
	selectById: selectTrackingRoutesById,
	selectEntities: selectTrackingRouteEntities,
} = adapter.getSelectors((state: RootState) => state.trackingRoutes);

const upsertMany = (state: EntityState<ITrackingRoute>, routes: ITrackingRoute[]) => {
	const _routes = routes.map((route) => {
		const _route = { ...route } as RouteResponse.AsObject;
		(_route as ITrackingRoute).timestamp = _route.location?.timestamp;
		delete _route.owner;
		delete _route.location;
		return _route;
	});
	adapter.upsertMany(state, _routes);
};

const trackingRoutesSlice = createSlice({
	name: "routes",
	initialState: adapter.getInitialState(),
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(trackingRouteActions.getLive.fulfilled, (state, action) => {
			upsertMany(state, action.payload.data);
		});
		builder.addCase(trackingRouteActions.getLastKnown.fulfilled, (state, action) => {
			upsertMany(state, action.payload.data);
		});
		builder.addCase(trackingRouteActions.getRoutes.fulfilled, (state, action) => {
			upsertMany(state, action.payload.data);
		});
		builder.addCase(trackingLocationActions.shareLocation.fulfilled, (state, action) => {
			const routes = action.payload.data.map((location) => {
				const route: ITrackingRoute = {
					id: location.routeId,
					name: "",
					viewersList: [],
					type: RouteResponse.Type.TRACKING,
					isSelf: true,
					notes: "",
					ownerId: location.userId,
					workspaceIdsList: [],
				};
				return route;
			});
			upsertMany(state, routes);
		});
		builder.addCase(getLocationsFulfilled, (state, action) => {
			const routes = action.payload.mapNotNull((location) => {
				const route: ITrackingRoute = {
					id: location.routeId,
					name: "",
					viewersList: [],
					type: RouteResponse.Type.TRACKING,
					isSelf: true,
					notes: "",
					ownerId: location.userId,
					workspaceIdsList: [],
				};
				if (adapter.getSelectors().selectById(state, route.id) === undefined) return route;
				return;
			});
			upsertMany(state, routes);
		});
	},
});

export default trackingRoutesSlice;
