import type {
	GetOrganizationIntegrationsResponse,
	GetOrganizationMembersResponse,
	InviteOrganizationMembersResponse,
	RemoveOrganizationMembersResponse,
	UpdateOrganizationRoleRequest,
	UpdateOrganizationRoleResponse,
} from "@somewear-labs/swl-web-api/src/proto/api/organization_pb";
import type { IdentityRecord } from "@somewear-labs/swl-web-api/src/proto/identity_record_proto_pb";
import { combineEpics } from "redux-observable";

import type { RootState } from "../../../app/rootReducer";
import type {
	ActionSetEpic,
	IRequestPayloadAction,
	VoidableRequestPayload,
} from "../../../common/EpicUtils";
import { createActionSetEpicHandler } from "../../../common/EpicUtils";
import { grpc } from "../../../common/GrpcClient";
import { someGrpc } from "../../../common/SomewearGrpc";
import type { IOrganizationAsset } from "../organizationsSlice";
import { organizationMemberActions } from "./organizationMemberActions";

const getOrganizationMembersEpic: ActionSetEpic<void, GetOrganizationMembersResponse.AsObject> = (
	action$,
	state$
) => {
	return createActionSetEpicHandler(action$, state$, organizationMemberActions.getMembers, () =>
		grpc.prepareRequest(someGrpc.fetchOrganizationMembers)
	);
};

const getOrganizationIntegrationsEpic: ActionSetEpic<
	void,
	GetOrganizationIntegrationsResponse.AsObject
> = (action$, state$) => {
	return createActionSetEpicHandler(
		action$,
		state$,
		organizationMemberActions.getIntegrations,
		() => grpc.prepareRequest(someGrpc.fetchOrganizationIntegrations)
	);
};

const assignOrganizationRoleEpic: ActionSetEpic<
	UpdateOrganizationRoleRequest.AsObject,
	UpdateOrganizationRoleResponse.AsObject
> = (action$, state$) => {
	return createActionSetEpicHandler(
		action$,
		state$,
		organizationMemberActions.assignRole,
		(payload) => grpc.prepareRequestWithPayload(someGrpc.assignOrganizationRole, payload.data)
	);
};

const addOrganizationMembersEpic: ActionSetEpic<
	IdentityRecord.AsObject[],
	InviteOrganizationMembersResponse.AsObject
> = (action$, state$) => {
	return createActionSetEpicHandler(
		action$,
		state$,
		organizationMemberActions.addMembers,
		(payload) => grpc.prepareRequestWithPayload(someGrpc.addOrganizationMembers, payload.data),
		(payload) => {
			return {
				onRejected: "Failed to add organization members",
				onFulfilled: "Successfully added organization members",
				onPending: "Adding organization members",
			};
		}
	);
};

const removeOrganizationMembersEpic: ActionSetEpic<
	IOrganizationAsset[],
	RemoveOrganizationMembersResponse.AsObject
> = (action$, state$) => {
	return createActionSetEpicHandler(
		action$,
		state$,
		organizationMemberActions.removeMembers,
		(payload) =>
			grpc.prepareRequestWithPayload(someGrpc.removeOrganizationMembers, payload.data)
	);
};

export default combineEpics<
	IRequestPayloadAction<VoidableRequestPayload>,
	IRequestPayloadAction<unknown>,
	RootState
>(
	assignOrganizationRoleEpic,
	getOrganizationMembersEpic,
	getOrganizationIntegrationsEpic,
	addOrganizationMembersEpic,
	removeOrganizationMembersEpic
);
