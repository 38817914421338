import type { AssignDeviceResponse } from "@somewear-labs/swl-web-api/src/proto/api/device_pb";
import { AssignDeviceRequest } from "@somewear-labs/swl-web-api/src/proto/api/device_pb";
import type { StateObservable } from "redux-observable";
import { combineEpics } from "redux-observable";
import type { Observable } from "rxjs";

import type { RootState } from "../../app/rootReducer";
import type {
	IRequestPayload,
	IRequestPayloadAction,
	VoidableRequestPayload,
} from "../../common/EpicUtils";
import { actionSetEpicHandlerBuilder } from "../../common/EpicUtils";
import { grpc } from "../../common/GrpcClient";
import { someGrpc } from "../../common/SomewearGrpc";
import { deviceTransferActions } from "./deviceTransfersSlice";

const applyDeviceTransfersEpic = actionSetEpicHandlerBuilder(
	deviceTransferActions.applyQueuedTransfers,
	transferDevicesRequestBuilder,
	{
		onPending: `Transferring devices...`,
		onRejected: `Error transferring devices.`,
		onFulfilled: `Successfully transferred devices.`,
	}
);

const transferDeviceEpic = actionSetEpicHandlerBuilder(
	deviceTransferActions.unaryAssign,
	(payload) => grpc.prepareRequestWithPayload(someGrpc.unaryAssignDevice, payload.data)
);

const bulkTransferDeviceEpic = actionSetEpicHandlerBuilder(
	deviceTransferActions.bulkAssign,
	(payload) => {
		const requests = payload.data.map((requestObject) => {
			const request = new AssignDeviceRequest();
			request.setSerial(requestObject.serial);
			request.setTargetWorkspaceId(requestObject.targetWorkspaceId);
			request.setTargetIdentityId(requestObject.targetIdentityId);
			return request;
		});

		return grpc.prepareRequestWithPayload(someGrpc.bulkAssignDevice, requests);
	}
);

function transferDevicesRequestBuilder(
	payload: IRequestPayload<any>,
	state$: StateObservable<any>
): Observable<AssignDeviceResponse.AsObject[] | undefined> {
	const pendingDeviceTransfers = state$.value.deviceTransfers.entities;
	const transferRequests = state$.value.deviceTransfers.ids.map((serial: string) => {
		const deviceTransfer = pendingDeviceTransfers[serial]!;
		const request = new AssignDeviceRequest();
		request.setSerial(deviceTransfer.serial);
		request.setTargetWorkspaceId(deviceTransfer.targetWorkspaceId);
		request.setTargetIdentityId(deviceTransfer.targetIdentityId);
		return request;
	});

	return grpc.prepareRequestWithPayload(someGrpc.bulkAssignDevice, transferRequests);
}

export default combineEpics<
	IRequestPayloadAction<VoidableRequestPayload>,
	IRequestPayloadAction<unknown>,
	RootState
>(applyDeviceTransfersEpic, transferDeviceEpic, bulkTransferDeviceEpic);
