import { combineEpics } from "redux-observable";
import { from } from "rxjs";
import { mergeMap } from "rxjs/operators";

import type { RootState } from "../../app/rootReducer";
import type {
	IRequestPayloadAction,
	VoidableRequestPayload} from "../../common/EpicUtils";
import {
	actionSetEpicHandlerBuilder
} from "../../common/EpicUtils";
import { grpc } from "../../common/GrpcClient";
import { someGrpc } from "../../common/SomewearGrpc";
import { shapeActions } from "./shapeActions";
import { selectAllShapes } from "./shapeSlice";

const getShapesEpic = actionSetEpicHandlerBuilder(shapeActions.getShapes, () =>
	grpc.prepareRequest(someGrpc.getShapes)
);

const createShapeEpic = actionSetEpicHandlerBuilder(
	shapeActions.createShape,
	(payload) => grpc.prepareRequestWithPayload(someGrpc.createShape, payload.data),
	{
		onPending: "Creating shape...",
		onFulfilled: `Shape created!`,
		onRejected: `Failed to create shape.`,
	}
);

const editShapeEpic = actionSetEpicHandlerBuilder(shapeActions.editShape, (payload) =>
	grpc.prepareRequestWithPayload(someGrpc.editShape, payload.data)
);

const deleteAllShapesEpic = actionSetEpicHandlerBuilder(shapeActions.deleteAll, (_, state$) => {
	const shapes = selectAllShapes(state$.value);
	return from(shapes).pipe(
		mergeMap((shape) => grpc.prepareRequestWithPayload(someGrpc.deleteShape, shape.id))
	);
});

const deleteShapeEpic = actionSetEpicHandlerBuilder(shapeActions.deleteOne, (payload) =>
	grpc.prepareRequestWithPayload(someGrpc.deleteShape, payload.data)
);

export default combineEpics<
	IRequestPayloadAction<VoidableRequestPayload>,
	IRequestPayloadAction<unknown>,
	RootState
>(getShapesEpic, createShapeEpic, editShapeEpic, deleteAllShapesEpic, deleteShapeEpic);
