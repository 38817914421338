import type {
	CreateConversationRequest,
	CreateConversationResponse,
	DeleteConversationResponse,
	GetConversationsResponse,
} from "@somewear-labs/swl-web-api/src/proto/api/messaging_pb";

import { createActionSet } from "../../common/EpicUtils";

export const conversationActions = {
	fetch: createActionSet<void, GetConversationsResponse.AsObject>("conversations/fetch"),
	create: createActionSet<
		CreateConversationRequest.AsObject,
		CreateConversationResponse.AsObject
	>("conversations/create"),
	delete: createActionSet<string, DeleteConversationResponse.AsObject>("conversations/delete"),
};
