import "../index.css";
import "../components/Components.css";
import "../tracking/Map.css";
import "../messaging/SideBar.css";
import "../messaging/Conversation.css";
import "../common/reset.css";
import "../landing/LandingCommon.css";
import "react-phone-number-input/style.css";
import "../common/Common.css";

import { Component } from "react";
import { batch, connect } from "react-redux";
import { Route, Switch } from "react-router";

import { PublicTracking } from "@/tracking/PublicTracking";

import { setGlobalError, setUpgrade } from "../app/appSlice";
import type { RootState } from "../app/rootReducer";
import type { DispatchProps } from "../common/Interfaces";
import Paths from "../common/Paths";
import { Sentry } from "../common/SentryUtil";
import GlobalErrorComponent from "../components/GlobalErrorComponent";
import Config from "../config/Config";
import Main from "./Main";

interface IProps {
	error: boolean;
}

class MainController extends Component<IProps & DispatchProps> {
	//private didSetup = false;

	constructor(props: IProps & DispatchProps) {
		super(props);

		Sentry.init({
			dsn: Config.sentry.dsn,
			enabled: Config.sentry.enable,
			environment: Config.sentry.environment,
			integrations: [new Sentry.Integrations.Breadcrumbs({ console: false, dom: false })],
		});

		/*if (!this.didSetup) {
			try {
				firebase.messaging.usePublicVapidKey(Config.firebase.vapidKey);
			} catch (error) {
				console.log(error);
			}
		}
		this.didSetup = true;*/
	}

	componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
		if (error.name === "ChunkLoadError") {
			batch(() => {
				this.props.dispatch(setUpgrade(true));
				this.props.dispatch(setGlobalError());
			});
		} else {
			Sentry.withScope((scope) => {
				scope.setExtra("componentStack", errorInfo.componentStack);
				Sentry.captureException(error);
			});

			this.props.dispatch(setGlobalError());
		}
	}

	render(): React.ReactNode {
		if (this.props.error) {
			return <GlobalErrorComponent />;
		} else {
			return (
				<Switch>
					<Route path={Paths.PublicTrackingMatch}>
						<PublicTracking />
					</Route>
					<Route>
						<Main />
					</Route>
				</Switch>
			);
		}
	}
}

function mapStateToProps(state: RootState): IProps {
	return { error: state.app.globalError };
}

export default connect(mapStateToProps)(MainController);
