import type { Epic } from "redux-observable";
import { combineEpics } from "redux-observable";
import { BehaviorSubject } from "rxjs";
import { mergeMap } from "rxjs/operators";

import appEpics from "../app/appEpics";
import mapLayersEpics from "../mapLayers/mapLayersEpics";
import conversationEpics from "../messaging/conversations/conversationEpics";
import {
	apiMessagesEpic,
	downloadFileEpic,
	getReadTimestampsEpic,
	getWorkspaceFilesEpic,
	sendMessageEpic,
	setConversationTimestampEpic,
	setWorkspaceTimestampEpic,
	uploadFileEpic,
} from "../messaging/messagesEpic";
import organizationDeviceEpics from "../settings/organization/devices/organizationDeviceEpics";
import organizationMemberEpics from "../settings/organization/member/organizationMemberEpics";
import organizationEpics from "../settings/organization/organizationEpics";
import deviceTransfersEpics from "../settings/workspace/deviceTransfersEpics";
import workspaceMemberEpics from "../settings/workspace/members/workspaceMemberEpics";
import workspaceEpics from "../settings/workspace/workspaceEpics";
import workspaceKeyEpics from "../settings/workspace/workspaceKeyEpics";
import trackingRouteEpics from "../tracking/routes/trackingRouteEpics";
import shapeEpics from "../tracking/shapes/shapeEpics";
import {
	apiLocationsSetDateFilterEpic,
	deleteWaypointEpic,
	fetchTrackingFiltersEpic,
	fetchTrackingSettingsEpic,
	fetchWorkspaceFiltersEpic,
	initMapViewEpic,
	resolveSosSessionEpic,
	saveWaypointEpic,
	shareGeolocationEpic,
	shareWaypointEpic,
	startGeolocatingEpic,
	startSosSessionEpic,
} from "../tracking/trackingEpic";
import biometricEpics from "./biometrics/biometricEpics";
import deviceEpics from "./devices/deviceEpics";
import notificationEpics from "./notifications/notificationEpics";
import type { RootState } from "./rootReducer";
import sosEpics from "./sos/sosEpics";
import waypointEpics from "./waypoints/waypointEpics";

export const epic$ = new BehaviorSubject(
	combineEpics<any, any, RootState>(
		appEpics,
		apiMessagesEpic,
		sendMessageEpic,
		downloadFileEpic,
		uploadFileEpic,
		getWorkspaceFilesEpic,
		apiLocationsSetDateFilterEpic,
		startSosSessionEpic,
		resolveSosSessionEpic,
		startGeolocatingEpic,
		shareGeolocationEpic,
		shareWaypointEpic,
		saveWaypointEpic,
		deleteWaypointEpic,
		getReadTimestampsEpic,
		fetchTrackingFiltersEpic,
		fetchTrackingSettingsEpic,
		fetchWorkspaceFiltersEpic,
		setConversationTimestampEpic,
		setWorkspaceTimestampEpic,
		initMapViewEpic,
		// apiDeviceSettingsEpic,
		deviceTransfersEpics,
		notificationEpics,
		workspaceMemberEpics,
		workspaceEpics,
		workspaceKeyEpics,
		organizationEpics,
		organizationDeviceEpics,
		organizationMemberEpics,
		sosEpics,
		biometricEpics,
		conversationEpics,
		waypointEpics,
		deviceEpics,
		shapeEpics,
		// ideally we would lazy load these, but we suspect they are causing issues with app load
		mapLayersEpics,
		trackingRouteEpics
	)
);

export const rootEpic: Epic = (action$, state$, dependencies) =>
	epic$.pipe(mergeMap((epic) => epic(action$, state$, dependencies)));
