import * as React from "react";
import { Col, Row } from "reactstrap";
import styled from "styled-components";

interface IProps {
	text?: string;
	fullScreen?: boolean;
}

const Loader: React.FunctionComponent<IProps> = (props: IProps) => (
	<Container className={`${props.fullScreen ? "full-screen" : ""}`}>
		<Row className={`loading-row`}>
			<Col md="12" className="d-flex flex-column justify-content-center">
				{props.text ? <div className="loader-text">{props.text}</div> : <div />}
				<div className="loader-container">
					<div className="loader" />
				</div>
			</Col>
		</Row>
	</Container>
);

const Container = styled.div`
	&.full-screen {
		position: fixed;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background: rgba(255, 255, 255, 0.5);
		display: flex;
		z-index: 9998;
	}
`;

export default Loader;
