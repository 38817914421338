import type { PayloadAction } from "@reduxjs/toolkit";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import type { DeviceActivationStateTypeMap } from "@somewear-labs/swl-web-api/src/proto/api/device_pb";

import { deviceActions } from "../../app/devices/deviceActions";
import type { RootState } from "../../app/rootReducer";

export type DeviceActivationState =
	DeviceActivationStateTypeMap[keyof DeviceActivationStateTypeMap];

export enum DeviceActivationChange {
	NONE,
	ACTIVATE,
	SUSPEND,
}
export interface IQueuedDeviceActivationChange {
	serial: string;
	currentState: DeviceActivationState;
	change: DeviceActivationChange;
}

const adapter = createEntityAdapter<IQueuedDeviceActivationChange>({
	selectId: (change) => change.serial,
});

export const {
	selectAll: selectAllQueuedDeviceActivationChanges,
	selectById: selectQueuedDeviceActivationChangeBySerial,
	selectEntities: selectQueuedDeviceActivationChangeEntities,
} = adapter.getSelectors((state: RootState) => state.queuedDeviceActivationChanges);

const queuedDeviceActivationChangeSlice = createSlice({
	name: "queuedDeviceActivations",
	initialState: adapter.getInitialState(),
	reducers: {
		queueActivationChange: (state, action: PayloadAction<IQueuedDeviceActivationChange>) => {
			adapter.upsertOne(state, action.payload);
		},
		removeAllChanges: (state) => {
			adapter.removeAll(state);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(deviceActions.applyQueuedActivationChanges.fulfilled, (state, action) => {
			const serials = action.payload.data;
			adapter.removeMany(state, serials);
		});
	},
});

export default queuedDeviceActivationChangeSlice;
