import type { EntityState} from "@reduxjs/toolkit";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import type { WritableDraft } from "immer/dist/types/types-external";

import type { RootState } from "../../app/rootReducer";
import { nowToTimestamp } from "../../common/utils";
import { emitFileReceivedFromStream, messageActions } from "../messagingSlice";
import type { FileMetadataWithState} from "./files.model";
import { FileState } from "./files.model";

const adapter = createEntityAdapter<FileMetadataWithState>({
	selectId: (entity) => entity.id,
	sortComparer: (a, b) => (a.createdTimestamp?.seconds ?? 0) - (b.createdTimestamp?.seconds ?? 0),
});

export const { selectAll: selectAllFiles, selectById: selectFilesById } = adapter.getSelectors(
	(state: RootState) => state.files
);

function findFileByRequestId(
	state: WritableDraft<EntityState<FileMetadataWithState>>,
	requestId: string
) {
	return adapter
		.getSelectors()
		.selectAll(state)
		.find((file) => file.requestId === requestId);
}

const slice = createSlice({
	name: "messages",
	initialState: adapter.getInitialState(),
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(messageActions.uploadFile.pending, (state, action) => {
			adapter.upsertOne(state, {
				id: action.payload.requestId,
				requestId: action.payload.requestId,
				name: action.payload.data.file.name,
				mimeType: action.payload.data.file.type,
				workspaceId: action.payload.data.workspaceId,
				userId: action.payload.data.senderId,
				createdTimestamp: nowToTimestamp(),
				fileSize: action.payload.data.file.size,
				state: FileState.Pending,
				file: action.payload.data.file, // used to retry uploads that fail
			});
		});

		builder.addCase(messageActions.uploadFile.fulfilled, (state, action) => {
			const file = findFileByRequestId(state, action.payload.requestId);

			if (file) {
				adapter.removeOne(state, file.id);
			}

			adapter.upsertOne(state, {
				...action.payload.data.fileMetadata,
				state: FileState.Pending,
			});
		});

		builder.addCase(messageActions.uploadFile.rejected, (state, action) => {
			const file = findFileByRequestId(state, action.payload.requestId);

			if (!file) return;

			adapter.upsertOne(state, {
				...file,
				state: FileState.Error,
			});
		});

		builder.addCase(messageActions.getFiles.fulfilled, (state, action) => {
			adapter.upsertMany(
				state,
				action.payload.data.map((file) => ({ ...file, state: FileState.Success }))
			);
		});

		builder.addCase(emitFileReceivedFromStream, (state, action) => {
			adapter.upsertOne(state, {
				...action.payload,
				state: FileState.Success,
			});
		});
	},
});

export default slice;
