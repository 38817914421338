import type { Dictionary } from "@reduxjs/toolkit";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import type { GetOrganizationIntegrationAccountsResponse } from "@somewear-labs/swl-web-api/src/proto/api/organization_pb";
import { IdentityRecord } from "@somewear-labs/swl-web-api/src/proto/identity_record_proto_pb";
import type { IntegrationResponse } from "@somewear-labs/swl-web-api/src/proto/integration_proto_pb";
import type { OrganizationRoleMap } from "@somewear-labs/swl-web-api/src/proto/organization_role_proto_pb";
import { OrganizationRole } from "@somewear-labs/swl-web-api/src/proto/organization_role_proto_pb";
import type { Timestamp } from "@somewear-labs/swl-web-api/src/proto/timestamp_proto_pb";
import type { UserResponse } from "@somewear-labs/swl-web-api/src/proto/user_proto_pb";
import { IdentityType } from "@somewear-labs/swl-web-api/src/proto/user_proto_pb";
import _ from "lodash";

import {
	appActions,
	emitAddUserAccountFromServer,
	emitIdentityChangeFromServer,
	emitUserAccountChangeFromServer,
} from "../../app/appActions";
import type { IWorkspaceAsset } from "../../app/assets/workspaceAssetsSlice";
import type { RootState } from "../../app/rootReducer";
import Config from "../../config/Config";
import { trackingRouteActions } from "../../tracking/routes/trackingRouteActions";
import { organizationMemberActions } from "../organization/member/organizationMemberActions";
import { organizationActions } from "../organization/organizationsSlice";
import { formatIntegration, getIntegrationType } from "./identities.utils";

export interface IIdentity extends IdentityRecord.AsObject {
	organizationRole?: OrganizationRoleMap[keyof OrganizationRoleMap];
	joinedOrganizationDate?: Timestamp.AsObject;
	integrationData?: IntegrationData;
}

type IntegrationType =
	GetOrganizationIntegrationAccountsResponse.IntegrationIdentity.IntegrationTypeMap[keyof GetOrganizationIntegrationAccountsResponse.IntegrationIdentity.IntegrationTypeMap];

type IntegrationData = {
	webhook?: IntegrationResponse.Webhook.AsObject;
	takServer?: IntegrationResponse.TakServer.AsObject;
};

export interface IIntegrationIdentity extends IIdentity {
	beamEnabled: boolean;
	inboundEnabled: boolean;
	outboundEnabled: boolean;
	inboundLastUpdated?: Timestamp.AsObject;
	outboundLastUpdated?: Timestamp.AsObject;
	defaultWorkspaceId: string;
	integrationType: IntegrationType;
}

export const getDisplayNameForIdentity = (asset: IIdentity, isSelf: boolean) => {
	if (isSelf) return "Me";
	return asset !== undefined
		? asset.fullName.replaceIfEmpty(
				Config.firebase.enable ? asset.email : asset.username ?? asset.email
		  )
		: "";
};

const adapter = createEntityAdapter<IIdentity>({
	selectId: (identity) => identity.id,
});

// Rename the exports for readability in component usage
export const {
	selectAll: selectAllIdentities,
	selectById: selectIdentityById,
	selectEntities: selectIdentityEntities,
} = adapter.getSelectors((state: RootState) => state.identities);

const slice = createSlice({
	name: "identities",
	initialState: adapter.getInitialState(),
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(appActions.fetchAssets.fulfilled, (state, action) => {
			const identities = action.payload.data.identitiesList.mapNotNull(
				(identity) => identity
			);
			adapter.upsertMany(state, identities);
		});
		builder.addCase(organizationActions.getAssets.fulfilled, (state, action) => {
			adapter.upsertMany(state, action.payload.data.identitiesList);
		});
		builder.addCase(organizationMemberActions.getMembers.fulfilled, (state, action) => {
			const identities: IdentityRecord.AsObject[] = action.payload.data.membersList
				.map((member) => {
					const identity: IIdentity | undefined = member.identity;
					if (identity !== undefined) {
						identity.organizationId = member.organizationId;
						identity.organizationRole = member.role;
						identity.joinedOrganizationDate = member.createdDate;
					}
					return identity;
				})
				.filter((identity) => identity !== undefined) as IdentityRecord.AsObject[];
			adapter.upsertMany(state, identities);
		});
		builder.addCase(organizationMemberActions.getIntegrations.fulfilled, (state, action) => {
			const identities: IdentityRecord.AsObject[] = action.payload.data.integrationsOldList
				.map((member) => {
					const identity: IIdentity | undefined = member.identity;
					if (identity !== undefined) {
						identity.joinedOrganizationDate = identity.createdDate;

						const integrationData = action.payload.data.integrationsList.find(
							(item) => item.identity?.id === identity.id
						);

						identity.integrationData = {
							webhook: integrationData?.webhook,
							takServer: integrationData?.takServer,
						};
					}

					return identity;
				})
				.filter((identity) => identity !== undefined) as IdentityRecord.AsObject[];
			adapter.upsertMany(state, identities);
		});
		builder.addCase(organizationMemberActions.assignRole.fulfilled, (state, action) => {
			const member = action.payload.data.member;
			if (member?.identity?.id !== undefined) {
				const upsertIdentity = state.entities[member.identity.id];
				if (upsertIdentity !== undefined) {
					upsertIdentity.organizationRole = member.role;
					adapter.upsertOne(state, upsertIdentity);
				}
			}
		});
		builder.addCase(organizationMemberActions.addMembers.fulfilled, (state, action) => {
			const identityDict: Dictionary<IIdentity> = {};
			action.payload.data.identityRecordsList.forEach((record) => {
				identityDict[record.id] = record;
			});

			const identities: IIdentity[] = action.payload.data.membershipsList.mapNotNull(
				(member) => {
					const identity: IIdentity | undefined = identityDict[member.identityId];
					if (identity !== undefined) {
						identity.organizationRole = member.role;
					}
					return identity;
				}
			);
			adapter.upsertMany(state, identities);
		});
		builder.addCase(organizationMemberActions.removeMembers.fulfilled, (state, action) => {
			const removedIdentities = action.payload.data.membershipsList.mapNotNull((record) => {
				const removedIdentity = state.entities[record.identityId];
				if (removedIdentity !== undefined) {
					removedIdentity.organizationRole = OrganizationRole.ORGANIZATIONROLENONE;
					removedIdentity.organizationId = "";
					return removedIdentity;
				}
			});
			adapter.upsertMany(state, removedIdentities);
		});

		builder.addCase(organizationMemberActions.membershipChanged.fulfilled, (state, action) => {
			const response = action.payload.data;
			if (response.role === OrganizationRole.ORGANIZATIONROLENONE) {
				const identity: IIdentity = response.identity!;
				identity.organizationRole = response.role;
				identity.organizationId = "";
				adapter.upsertOne(state, identity);
			} else {
				const identity: IIdentity = response.identity!;
				identity.organizationRole = response.role;
				adapter.upsertOne(state, identity);
			}
		});

		builder.addCase(emitIdentityChangeFromServer, (state, action) => {
			adapter.upsertOne(state, action.payload);
		});

		builder.addCase(appActions.updateIdentityStyle.fulfilled, (state, action) => {
			const identityId = action.payload.data.id;
			const identity = state.entities[identityId];
			if (identity === undefined) return; // this is not the first we should be hearing of an identity
			const copy = _.cloneDeep(identity);
			copy.styleSettings = action.payload.data.styleSettings;
			adapter.upsertOne(state, copy);
		});
		builder.addCase(trackingRouteActions.getLastKnown.fulfilled, (state, action) => {
			action.payload.data.forEach((route) => {
				if (route.owner === undefined) return;

				let type: IdentityRecord.TypeMap[keyof IdentityRecord.TypeMap] | undefined;
				const account = route.owner;
				switch (account.type) {
					case IdentityType.USER:
						type = IdentityRecord.Type.USER;
						break;
					case IdentityType.RESOURCE:
						type = IdentityRecord.Type.RESOURCE;
						break;
					case IdentityType.DEVICE:
						type = IdentityRecord.Type.DEVICE;
						break;
					case IdentityType.INTEGRATION:
						type = IdentityRecord.Type.INTEGRATION;
						break;
				}

				const identity = {
					id: route.owner.identityId,
					fullName: route.owner.fullname,
					username: route.owner.username,
					type: type ?? IdentityRecord.Type.NONE,
				} as IIdentity;

				adapter.upsertOne(state, identity);
			});
		});
		builder.addCase(organizationActions.createResource.fulfilled, (state, action) => {
			if (action.payload.data.identity === undefined) return;
			adapter.upsertOne(state, action.payload.data.identity);
		});
		builder.addCase(emitUserAccountChangeFromServer, (state, action) => {
			const account = action.payload;
			const identity = accountToIdentity(account);
			if (identity === undefined) return;

			adapter.upsertOne(state, identity);
		});
		builder.addCase(emitAddUserAccountFromServer, (state, action) => {
			const account = action.payload;
			const identity = accountToIdentity(account);
			if (identity === undefined) return;

			adapter.upsertOne(state, identity);
		});
		builder.addCase(organizationActions.createIntegration.fulfilled, (state, action) => {
			const { integration } = action.payload.data;

			if (integration?.identity === undefined) return;

			adapter.upsertOne(state, {
				...integration.identity,
				integrationType: getIntegrationType(integration),
				inboundEnabled: false,
				outboundEnabled: true,
			} as IIntegrationIdentity);
		});

		builder.addCase(organizationActions.getIntegrationAccounts.fulfilled, (state, action) => {
			const identities = action.payload.data.identitiesList;

			if (identities === undefined) return;

			const integrationIdentities = identities.mapNotNull(formatIntegration);

			adapter.upsertMany(state, integrationIdentities);
		});
		builder.addCase(organizationActions.configureIntegration.fulfilled, (state, action) => {
			const identities = action.payload.data.identitiesList;
			if (identities === undefined) return;

			const integrationIdentities = identities.mapNotNull(formatIntegration);

			adapter.upsertMany(state, integrationIdentities);
		});
	},
});

const accountToIdentity = (account: UserResponse.AsObject | IWorkspaceAsset) => {
	// removed this as identity type is unreliable when part of a user account
	/*let type: IdentityRecord.TypeMap[keyof IdentityRecord.TypeMap] | undefined;
	switch (account.type) {
		case IdentityType.USER:
			type = IdentityRecord.Type.USER;
			break;
		case IdentityType.RESOURCE:
			type = IdentityRecord.Type.RESOURCE;
			break;
		case IdentityType.DEVICE:
			type = IdentityRecord.Type.DEVICE;
			break;
		case IdentityType.INTEGRATION:
			type = IdentityRecord.Type.INTEGRATION;
			break;
	}
	if (type === undefined) return undefined;*/
	return {
		id: account.identityId,
		fullName: account.fullname,
		// type: type,
	} as IIdentity;
};

export default slice;
