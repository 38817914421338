import type { Dictionary, PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { LocationResponse } from "@somewear-labs/swl-web-api/src/proto/location_proto_pb";
import type { RouteResponse } from "@somewear-labs/swl-web-api/src/proto/route_proto_pb";
import type { StyleSettings } from "@somewear-labs/swl-web-api/src/proto/style_proto_pb";
import type { Feature } from "geojson";
import { original } from "immer";
import _ from "lodash";
import { LngLatBounds } from "mapbox-gl";
import moment from "moment";
import type { ViewState } from "react-map-gl";

import { emitUserAccountChangeFromServer } from "../app/appActions";
import type { Dict } from "../app/appModel";
import type { DataMap } from "../app/appSlice";
import type { IContactForDisplay } from "../app/contacts/contactModels";
import type { IError } from "../common/errorReducer";
import FirebaseClient from "../common/FirebaseClient";
import { Sentry } from "../common/SentryUtil";
import { showMarkersModal } from "../mapLayers/mapLayersSlice";
import { trackingLocationActions } from "./locations/trackingLocationActions";
import { getNextMapStyle } from "./map/controls/utils/getNextMapStyle";
import { MapViewStore } from "./mapViewStore";
import { trackingRouteActions } from "./routes/trackingRouteActions";
import {
	setDateFilter,
	startGeolocating,
	stopGeolocating,
} from "./trackingActions";
import type { IClientLocation } from "./trackingModel";

export interface IClusterModel {
	count: number;
	coordinates: [number, number];
	id: number;
	state: AssetState;
}

export interface DateRange {
	start: number;
	end: number;
	set: boolean;
}

export enum AssetState {
	Active = "active",
	Stale = "stale",
	Old = "old",
	Danger = "danger",
	Warning = "warning",
	Sos = "sos",
	Unassigned = "unassigned",
}

export const PreviewLocationCount = 10;

export const DangerZone = 39.7222;
export const WarningZone = 38.8889;

export const ACTIVE_TRACKING_TIMEOUT = 10;
export const BIOMETRIC_TIMEOUT_IN_HOURS = 12;

export interface Locations<T> extends DataMap<T> {
	mostRecent: number;
}

export type ActiveUser = {
	userId: string;
	jump: boolean;
};

export type ActiveShape = {
	shapeId: string;
	jump: boolean;
};

export interface Timer {
	timeout: number;
	timestamp: number;
}

export type MapView = Pick<ViewState, "bearing" | "latitude" | "longitude" | "pitch" | "zoom">;

export interface ITrackingFilters {
	showActive: boolean;
	showInactive: boolean;
	showUnassignedResources: boolean;
	showAssigned: boolean;
	onlyShowLatest: boolean;
}

export enum WaypointEcosystem {
	SOMEWEAR,
	TAK,
}

interface IShareWaypointSettings {
	isShareWithWorkspace?: boolean;
	workspaceId?: string;
	affiliation?: StyleSettings.AffiliationMap[keyof StyleSettings.AffiliationMap];
	dimension?: StyleSettings.DimensionMap[keyof StyleSettings.DimensionMap];
	ecosystem?: WaypointEcosystem;
}

export enum MapStyle {
	Satellite = "satellite-streets-v11",
	Outdoors = "outdoors-v11",
}

export interface ITrackingSettings {
	useCustomMapUrl: boolean;
	useMetric: boolean;
	showImageOverlays?: boolean;
	customMapUrl?: string;
	shareWaypointSettings?: IShareWaypointSettings;
	mapStyle?: MapStyle;
	useBetaMap?: boolean;
	useWorkspaceColor?: boolean;
	disableStale?: boolean;
	cleanTracks?: boolean;
	clusterByWorkspace?: boolean;
}

export type Location = LocationResponse.AsObject | RouteResponse.AsObject;

export enum MapMode {
	Select = "select",
	Draw = "draw",
}

export enum DrawMode {
	Rectangle = "draw_rectangle",
	Circle = "draw_circle",
	Free = "draw_polygon",
	SimpleSelect = "simple_select",
}

type TrackingState = {
	mode: MapMode;
	drawMode?: DrawMode;
	// locations indexed by user id
	// routes (waypoints) indexed by user id
	waypoints: Dict<DataMap<RouteResponse.AsObject>>;
	// health data indexed by user id
	// healthActivity: Dict<DataMap<HealthActivityResponse.AsObject>>;
	activeTrackingUser?: ActiveUser;
	activeTrackingShape?: ActiveShape;
	dateFilter?: DateRange;
	// assetVisibilityOverrides: Dict<boolean | undefined>;
	shownUsers: Dict<boolean>;
	// userStates?: Dict<AssetState>;
	expandedUsers: Dict<boolean>;
	selectedUserIds: string[];
	focusedUserId?: string;
	selectedLocation?: Location;
	selectedLocationId?: string;
	selectedWaypointId?: string;
	sidebarVisible: boolean;
	userListVisible: boolean;
	trackingIntervalsLoaded: boolean;
	isPathSelected: boolean;
	selfUserId?: string;
	updateTimestamp: number;
	sosDismissed: boolean;
	mapView?: MapView;
	userInView?: string[];
	geolocation?: GeolocationPosition;
	liveTracking: boolean;
	showGlobalDateFilter: boolean;
	showWorkspacePicker: boolean;
	shareLocation: boolean;
	emitSos: boolean;
	shareLocationOnLongPress: boolean;
	workspaceFilters?: Dictionary<boolean>;
	filters?: ITrackingFilters;
	settings?: ITrackingSettings;
	closeAllFilters: boolean;
	pendingWaypointLocation?: IClientLocation;
	pendingShape?: Feature;
	measurementDialogOpen: boolean;
	totalMeasurement: number;
	clearMeasurements?: boolean;
	/** REMOVE WITH FF: web_clusterUpdates **/
	unclusteredUserIds: string[];
	clusterModels: IClusterModel[];
	/** */
	fullBounds: LngLatBounds; // The bounds enclosing all assets on the map
	showMarkerNames?: boolean;
	showMarkerIcons?: boolean;
	editAsset?: IContactForDisplay;
};

const LIVE_TRACKING_DEFAULT = false;

export const TRACKING_FILTER_DEFAULTS: ITrackingFilters = {
	showActive: true,
	showInactive: false,
	showUnassignedResources: false,
	showAssigned: false,
	onlyShowLatest: true,
};

export const TRACKING_SETTING_DEFAULTS: ITrackingSettings = {
	useCustomMapUrl: false,
	useMetric: false,
	showImageOverlays: true,
	shareWaypointSettings: {},
	mapStyle: MapStyle.Outdoors,
	useBetaMap: false,
	useWorkspaceColor: true,
	disableStale: false,
	cleanTracks: undefined,
	clusterByWorkspace: undefined,
};

export const TRACKING_FILTER_SHOW_ALL: ITrackingFilters = {
	showActive: true,
	showInactive: true,
	showUnassignedResources: true,
	showAssigned: true,
	onlyShowLatest: false,
};

const initialState: TrackingState = {
	mode: MapMode.Select,
	drawMode: undefined,
	waypoints: {},
	// healthActivity: {},
	activeTrackingUser: undefined,
	activeTrackingShape: undefined,
	selectedUserIds: [],
	// assetVisibilityOverrides: {},
	shownUsers: {},
	expandedUsers: {},
	sidebarVisible: false,
	userListVisible: false,
	trackingIntervalsLoaded: false,
	isPathSelected: false,
	updateTimestamp: moment().valueOf(),
	sosDismissed: false,
	liveTracking: LIVE_TRACKING_DEFAULT,
	showWorkspacePicker: false,
	showGlobalDateFilter: false,
	shareLocation: false,
	shareLocationOnLongPress: false,
	emitSos: false,
	closeAllFilters: false,
	measurementDialogOpen: false,
	totalMeasurement: 0,
	/** REMOVE WITH FF: web_clusterUpdates **/
	unclusteredUserIds: [],
	clusterModels: [],
	/** */
	fullBounds: new LngLatBounds(),
};

export interface DateFilterPayload {
	id: string;
	filter?: DateRange;
}

const trackingSlice = createSlice({
	name: "tracking",
	initialState,
	reducers: {
		setShareLocationOnLongPress(state, action: PayloadAction<boolean>) {
			state.shareLocationOnLongPress = action.payload;
		},
		toggleSosSession(state, action: PayloadAction<boolean>) {
			state.emitSos = action.payload;
		},
		/*apiTrackingIntervalRequest() {},
		apiTrackingIntervalSuccess(
			state,
			action: PayloadAction<TrackingIntervalResponseWithLocation.AsObject[]>
		) {
			action.payload.forEach((intervalResponse) => {
				const userId = intervalResponse.interval!.userId;

				if (intervalResponse.interval!.isSelf) {
					state.selfUserId = userId;
				}

				upsetLocation(state, intervalResponse.mostRecentLocation);
			});

			state.trackingIntervalsLoaded = true;
		},
		apiTrackingIntervalError(state, action: PayloadAction<IError>) {},*/
		// apiRoutesRequest() {},
		apiRoutesSuccess(state, action: PayloadAction<RouteResponse.AsObject[]>) {
			action.payload.forEach((route) => {
				const userId = route.owner!.id;

				if (!(userId in state.waypoints)) {
					state.waypoints[userId] = {
						data: {},
						loaded: false,
					};
				}

				state.waypoints[userId].data[route.id] = route;
			});
		},
		apiRoutesError(state, action: PayloadAction<IError>) {},
		// apiLocationsRequest(state, action: PayloadAction<string>) {},
		apiLocationsSuccess(state, action: PayloadAction<LocationResponse.AsObject[]>) {
			// handleLocations(state, action);
		},
		apiLocationsError(state, action: PayloadAction<IError>) {},

		/*apiLocationStreamOpen() {},
		apiLocationStreamClose() {},
		apiLocationStreamDisconnected() {},*/
		toggleEngagementSidebar(state) {
			state.sidebarVisible = !state.sidebarVisible;
		},
		setEngagementSidebar(state, action: PayloadAction<boolean>) {
			state.sidebarVisible = action.payload;
		},
		setUserList(state, action: PayloadAction<boolean>) {
			state.userListVisible = action.payload;
		},
		selectTrackingUserId(state, action: PayloadAction<string>) {
			if (state.selectedUserIds.includes(action.payload)) {
				state.selectedUserIds.splice(state.selectedUserIds.indexOf(action.payload), 1);
			}
			state.selectedUserIds.push(action.payload);
		},
		setFocusedUserId(state, action: PayloadAction<string>) {
			state.focusedUserId = action.payload;
			if (state.selectedUserIds.includes(action.payload)) {
				state.selectedUserIds.splice(state.selectedUserIds.indexOf(action.payload), 1);
			}
			state.selectedUserIds.push(action.payload);
			// state.sidebarVisible = false;
			// state.userListVisible = false;
		},
		clearFocusedUser(state) {
			state.focusedUserId = undefined;
		},
		setActiveUser(state, action: PayloadAction<ActiveUser>) {
			state.activeTrackingUser = action.payload;
			state.focusedUserId = action.payload.userId;
			if (state.selectedUserIds.includes(action.payload.userId)) {
				state.selectedUserIds.splice(
					state.selectedUserIds.indexOf(action.payload.userId),
					1
				);
			}
			state.selectedUserIds.push(action.payload.userId);
			/*if (state.assetVisibilityOverrides[action.payload.userId] === false) {
				delete state.assetVisibilityOverrides[action.payload.userId];
			}*/
			state.sidebarVisible = true;
			state.userListVisible = false;
		},
		clearActiveUser(state) {
			state.activeTrackingUser = undefined;
			// state.sidebarVisible = false;
		},
		setActiveShape(state, action: PayloadAction<ActiveShape>) {
			state.activeTrackingShape = action.payload;
		},
		clearActiveShape(state) {
			state.activeTrackingShape = undefined;
		},
		fitUsers(state, action: PayloadAction<string[] | undefined>) {
			state.userInView = action.payload;
		},
		setActiveUserWaypoint(state, action: PayloadAction<string>) {
			state.activeTrackingUser = { userId: action.payload, jump: false };
			// state.sidebarVisible = true;
		},

		resetDateFilter(state) {
			state.dateFilter = undefined;
			state.liveTracking = LIVE_TRACKING_DEFAULT;
		},
		setWorkspaceFilter(
			state,
			action: PayloadAction<{ workspaceId: string; visible: boolean }>
		) {
			if (state.workspaceFilters === undefined) state.workspaceFilters = {};
			state.workspaceFilters[action.payload.workspaceId] = action.payload.visible;
			FirebaseClient.setWorkspaceFilters(state.workspaceFilters);
		},
		setWorkspaceFilters(state, action: PayloadAction<Dictionary<boolean>>) {
			state.workspaceFilters = action.payload;
			FirebaseClient.setWorkspaceFilters(state.workspaceFilters);
		},
		setWorkspaceFilterOnly(
			state,
			action: PayloadAction<{ enableWorkspaceId: string; allWorkspaceIds: string[] }>
		) {
			state.workspaceFilters = {};
			action.payload.allWorkspaceIds.forEach((workspaceId) => {
				state.workspaceFilters![workspaceId] = false;
			});
			state.workspaceFilters[action.payload.enableWorkspaceId] = true;
			FirebaseClient.setWorkspaceFilters(state.workspaceFilters);
		},
		setAllWorkspaceFilters(
			state,
			action: PayloadAction<{ value: boolean; allWorkspaceIds: string[] }>
		) {
			state.workspaceFilters = {};
			action.payload.allWorkspaceIds.forEach((workspaceId) => {
				state.workspaceFilters![workspaceId] = action.payload.value;
			});
			FirebaseClient.setWorkspaceFilters(state.workspaceFilters);
		},
		resetWorkspaceFilters(state) {
			state.workspaceFilters = {};
			FirebaseClient.setWorkspaceFilters(state.workspaceFilters);
		},
		setSelectedLocation(state, action: PayloadAction<Location | undefined>) {
			state.selectedLocation = action.payload;

			if (action.payload && "type" in action.payload) {
				state.activeTrackingUser = undefined;
			}
		},
		setSelectedLocationId(state, action: PayloadAction<string | undefined>) {
			state.selectedLocationId = action.payload;
		},
		setSelectedWaypointId(state, action: PayloadAction<string | undefined>) {
			state.selectedWaypointId = action.payload;
		},
		setUserVisibility(
			state,
			action: PayloadAction<{ userId: string; visibility?: boolean }>
		) {},
		hideAllUsers(state, action: PayloadAction<string | undefined>) {},
		showAllUsers(state) {
			// state.assetVisibilityOverrides = {};
		},
		setIsPathSelected(state, action: PayloadAction<boolean>) {
			state.isPathSelected = action.payload;
		},
		clearSelectedUsers(state) {
			const previousDraft = state;
			const previousObject =
				previousDraft !== undefined ? original(previousDraft) : undefined;
			if (previousObject === undefined) return;
			if (previousObject.selectedUserIds.length > 0) state.selectedUserIds = [];
			// state.sidebarVisible = false;
			state.focusedUserId = undefined;
			// state.activeTrackingUser = undefined;
			state.selectedLocation = undefined;
			if (Object.keys(previousObject.expandedUsers).length > 0) state.expandedUsers = {};
		},
		clearDateFilters(state) {
			state.dateFilter = undefined;
		},
		/*createUserStates(state) {
			state.userStates = {};
		},
		updateUserStates(state, action: PayloadAction<number>) {
			state.updateTimestamp = action.payload;
		},*/
		setUserExpandedState(state, action: PayloadAction<{ userId: string; expanded: boolean }>) {
			state.expandedUsers[action.payload.userId] = action.payload.expanded;
		},
		sosDismissed(state, action: PayloadAction<boolean>) {
			state.sosDismissed = action.payload;
		},
		updatedMapView(state, action: PayloadAction<MapView>) {
			state.mapView = action.payload;
			updateMapViewStore(state.mapView);
		},
		setLiveTracking(state, action: PayloadAction<boolean>) {
			state.liveTracking = action.payload;
		},
		setShowGlobalDateFilter(state, action: PayloadAction<boolean>) {
			state.showGlobalDateFilter = action.payload;
		},
		setShowWorkspacePicker(state, action: PayloadAction<boolean>) {
			state.showWorkspacePicker = action.payload;
		},
		setTrackingFilters(state, action: PayloadAction<ITrackingFilters>) {
			state.filters = action.payload;
			FirebaseClient.setTrackingFilters(state.filters);
		},
		setTrackingSettings(state, action: PayloadAction<ITrackingSettings>) {
			state.settings = action.payload;
			FirebaseClient.setTrackingSettings(state.settings);
		},
		setMapStyle(state, action: PayloadAction<MapStyle>) {
			const settings = _.cloneDeep(state.settings!);
			settings.mapStyle = action.payload;
			state.settings = settings;
			FirebaseClient.setTrackingSettings(state.settings);
		},
		toggleMapStyle(state) {
			state.settings!.mapStyle = getNextMapStyle(state.settings?.mapStyle);
			FirebaseClient.setTrackingSettings(state.settings!);
		},
		setFilterShowAll(state, action: PayloadAction<boolean>) {
			if (action.payload) {
				state.filters = TRACKING_FILTER_SHOW_ALL;
			} else {
			}
			FirebaseClient.setTrackingFilters(state.filters);
		},
		setFilterShowActive(state, action: PayloadAction<boolean>) {
			state.filters!.showActive = action.payload;
			FirebaseClient.setTrackingFilters(state.filters!);
		},
		setFilterShowInactive(state, action: PayloadAction<boolean>) {
			state.filters!.showInactive = action.payload;
			FirebaseClient.setTrackingFilters(state.filters!);
		},
		setFilterShowUnassignedResources(state, action: PayloadAction<boolean>) {
			state.filters!.showUnassignedResources = action.payload;
			FirebaseClient.setTrackingFilters(state.filters!);
		},
		setFilterShowAssigned(state, action: PayloadAction<boolean>) {
			state.filters!.showAssigned = action.payload;
			FirebaseClient.setTrackingFilters(state.filters!);
		},
		setFilterOnlyShowLatest(state, action: PayloadAction<boolean>) {
			state.filters!.onlyShowLatest = action.payload;
			FirebaseClient.setTrackingFilters(state.filters!);
		},
		setSettingUseBetaMap(state, action: PayloadAction<boolean>) {
			state.settings!.useBetaMap = action.payload;
			FirebaseClient.setTrackingSettings(state.settings!);
		},
		setUseCustomMapUrl(state, action: PayloadAction<boolean>) {
			state.settings!.useCustomMapUrl = action.payload;
			FirebaseClient.setTrackingSettings(state.settings!);
		},
		setUseMetricSystem(state, action: PayloadAction<boolean>) {
			state.settings!.useMetric = action.payload;
			FirebaseClient.setTrackingSettings(state.settings!);
		},
		setDisableStale(state, action: PayloadAction<boolean>) {
			state.settings!.disableStale = action.payload;
			FirebaseClient.setTrackingSettings(state.settings!);
		},
		setCleanTracks(state, action: PayloadAction<boolean>) {
			state.settings!.cleanTracks = action.payload;
			FirebaseClient.setTrackingSettings(state.settings!);
		},
		setClusterByWorkspace(state, action: PayloadAction<boolean>) {
			state.settings!.clusterByWorkspace = action.payload;
			FirebaseClient.setTrackingSettings(state.settings!);
		},
		setUseWorkspaceColor(state, action: PayloadAction<boolean>) {
			state.settings!.useWorkspaceColor = action.payload;
			FirebaseClient.setTrackingSettings(state.settings!);
		},
		setShowImageOverlays(state, action: PayloadAction<boolean>) {
			state.settings!.showImageOverlays = action.payload;
			FirebaseClient.setTrackingSettings(state.settings!);
		},
		setCustomMapUrl(state, action: PayloadAction<string>) {
			state.settings!.customMapUrl = action.payload;
			FirebaseClient.setTrackingSettings(state.settings!);
		},
		setResetFilters(state) {
			state.filters = TRACKING_FILTER_DEFAULTS;
			FirebaseClient.setTrackingFilters(state.filters);
		},
		setCloseAllFilters(state, action: PayloadAction<boolean>) {
			state.closeAllFilters = action.payload;
		},
		openedFilter(state) {
			state.sidebarVisible = false;
		},
		setPendingWaypointLocation(state, action: PayloadAction<IClientLocation | undefined>) {
			state.pendingWaypointLocation = action.payload;
		},
		setPendingShape(state, action: PayloadAction<Feature | undefined>) {
			state.pendingShape = action.payload;
		},
		setShareWaypointWithWorkspace(state, action: PayloadAction<boolean>) {
			if (state.settings?.shareWaypointSettings === undefined) {
				console.warn("Share waypoint settings not initialized");
				return;
			}
			state.settings.shareWaypointSettings.isShareWithWorkspace = action.payload;
		},
		setShareWaypointWorkspaceId(state, action: PayloadAction<string>) {
			if (state.settings?.shareWaypointSettings === undefined) {
				console.warn("Share waypoint settings not initialized");
				return;
			}
			state.settings.shareWaypointSettings.workspaceId = action.payload;
		},
		setShareWaypointAffiliation(
			state,
			action: PayloadAction<
				StyleSettings.AffiliationMap[keyof StyleSettings.AffiliationMap] | undefined
			>
		) {
			if (state.settings?.shareWaypointSettings === undefined) {
				console.warn("Share waypoint settings not initialized");
				return;
			}
			state.settings.shareWaypointSettings.affiliation = action.payload;
		},
		setShareWaypointDimension(
			state,
			action: PayloadAction<
				StyleSettings.DimensionMap[keyof StyleSettings.DimensionMap] | undefined
			>
		) {
			if (state.settings?.shareWaypointSettings === undefined) {
				console.warn("Share waypoint settings not initialized");
				return;
			}
			state.settings.shareWaypointSettings.dimension = action.payload;
		},
		setMeasurementDialogOpen(state, action: PayloadAction<boolean>) {
			state.measurementDialogOpen = action.payload;
		},
		setTotalMeasurement(state, action: PayloadAction<number>) {
			state.totalMeasurement = action.payload;
		},
		setClearMeasurements(state, action: PayloadAction<boolean | undefined>) {
			state.clearMeasurements = action.payload;
		},
		setShareWaypointEcosystem(state, action: PayloadAction<WaypointEcosystem>) {
			if (state.settings?.shareWaypointSettings === undefined) {
				console.warn("Share waypoint settings not initialized");
				return;
			}
			state.settings!.shareWaypointSettings.ecosystem = action.payload;
		},
		/** REMOVE WITH FF: web_clusterUpdates **/
		setUnclusteredUserIds(state, action: PayloadAction<string[]>) {
			state.unclusteredUserIds = action.payload;
		},
		setClusterModels(state, action: PayloadAction<IClusterModel[]>) {
			state.clusterModels = action.payload;
		},
		/** */
		setFullBounds(state, action: PayloadAction<LngLatBounds>) {
			state.fullBounds = action.payload;
		},
		setShowMarkerNames(state, action: PayloadAction<boolean>) {
			state.showMarkerNames = action.payload;
		},
		setShowMarkerIcons(state, action: PayloadAction<boolean>) {
			state.showMarkerIcons = action.payload;
		},
		setEditAsset(state, action: PayloadAction<IContactForDisplay>) {
			state.editAsset = action.payload;
		},
		clearEditAsset(state) {
			state.editAsset = undefined;
		},
		setMapMode(state, action: PayloadAction<MapMode>) {
			console.log(`setMapMode: ${action.payload}`);
			state.mode = action.payload;
			if (action.payload === MapMode.Select) {
				state.drawMode = undefined;
			}
		},
		setDrawMode(state, action: PayloadAction<DrawMode>) {
			state.drawMode = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(setDateFilter, (state, action) => {
			state.dateFilter = action.payload;
		});
		builder.addCase(startGeolocating, (state) => {
			state.shareLocation = true;
		});
		builder.addCase(stopGeolocating, (state) => {
			state.shareLocation = false;
		});
		builder.addCase(emitUserAccountChangeFromServer, (state, action) => {
			if (action.payload.isArchived) {
				// user is archived, hide them
				// state.assetVisibilityOverrides[action.payload.id] = false;
			}
		});
		builder.addCase(trackingRouteActions.getLastKnown.fulfilled, (state, action) => {
			state.trackingIntervalsLoaded = true;
		});
		builder.addCase(trackingLocationActions.shareLocation.request, (state, action) => {
			state.geolocation = action.payload.data;
		});
		builder.addCase(trackingLocationActions.saveWaypoint.fulfilled, (state, action) => {
			state.selectedLocation = action.payload.data;
			state.pendingWaypointLocation = undefined;
		});
		builder.addCase(showMarkersModal, (state, action) => {
			if (action.payload) {
				state.selectedLocation = undefined;
			}
		});
	},
});

function updateMapViewStore(view: MapView) {
	MapViewStore.updateStore(view).subscribe(
		(r) => {},
		(e) => {
			Sentry.captureException(e);
		}
	);
}

export const {
	// apiTrackingIntervalSuccess,
	// apiTrackingIntervalRequest,
	// apiTrackingIntervalError,
	// apiRoutesRequest,
	apiRoutesSuccess,
	apiRoutesError,
	// apiLocationsRequest,
	apiLocationsSuccess,
	apiLocationsError,
	toggleEngagementSidebar,
	setEngagementSidebar,
	setUserList,
	setActiveUser,
	setActiveShape,
	setActiveUserWaypoint,
	fitUsers,
	resetDateFilter,
	setSelectedLocation,
	setUserVisibility,
	hideAllUsers,
	showAllUsers,
	setIsPathSelected,
	clearSelectedUsers,
	// createUserStates,
	// updateUserStates,
	setUserExpandedState,
	sosDismissed,
	updatedMapView,
	setLiveTracking,
	setFocusedUserId,
	selectTrackingUserId,
	clearFocusedUser,
	setShowGlobalDateFilter,
	setShowWorkspacePicker,
	setWorkspaceFilter,
	setWorkspaceFilterOnly,
	setAllWorkspaceFilters,
	resetWorkspaceFilters,
	setShareLocationOnLongPress,
	setFilterShowAll,
	setFilterShowActive,
	setFilterShowInactive,
	setFilterShowUnassignedResources,
	setFilterShowAssigned,
	setFilterOnlyShowLatest,
	setSettingUseBetaMap,
	setCloseAllFilters,
	setTrackingFilters,
	setTrackingSettings,
	setWorkspaceFilters,
	setResetFilters,
	setUseCustomMapUrl,
	setCustomMapUrl,
	setPendingWaypointLocation,
	setPendingShape,
	setShareWaypointWithWorkspace,
	setShareWaypointWorkspaceId,
	setShareWaypointAffiliation,
	setShareWaypointDimension,
	setUseMetricSystem,
	setDisableStale,
	setCleanTracks,
	setClusterByWorkspace,
	setUseWorkspaceColor,
	setMeasurementDialogOpen,
	setTotalMeasurement,
	setClearMeasurements,
	setShowImageOverlays,
	setShareWaypointEcosystem,
	toggleSosSession,
	/** REMOVE WITH FF: web_clusterUpdates **/
	setUnclusteredUserIds,
	setClusterModels,
	/** */
	setFullBounds,
	setMapStyle,
	toggleMapStyle,
	setShowMarkerNames,
	setShowMarkerIcons,
	setSelectedLocationId,
	setSelectedWaypointId,
	clearActiveUser,
	clearActiveShape,
	setEditAsset,
	clearEditAsset,
	openedFilter,
	setMapMode,
	setDrawMode,
} = trackingSlice.actions;

export default trackingSlice.reducer;
