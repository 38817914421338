import type {
	AssignDeviceRequest,
	AssignDeviceResponse,
	GetDevicesResponse,
	UnassignDeviceResponse,
} from "@somewear-labs/swl-web-api/src/proto/api/device_pb";
import type {
	DeviceEventResponseList,
	DeviceSettingsResponse,
	DeviceSummaryResponseList,
} from "@somewear-labs/swl-web-api/src/proto/device_proto_pb";

import { createActionSet } from "../../common/EpicUtils";
import type { IQueuedDeviceActivationChange } from "../../settings/device/queuedDeviceActivationChangeSlice";

export const deviceActions = {
	fetch: createActionSet<void, GetDevicesResponse.AsObject>("devices/fetch"),
	fetchSummary: createActionSet<void, DeviceSummaryResponseList.AsObject>(
		"devices/settings/fetch"
	),
	submitSettings: createActionSet<
		DeviceSettingsResponse.AsObject[],
		DeviceEventResponseList.AsObject
	>("devices/settings/submit"),
	applyQueuedActivationChanges: createActionSet<IQueuedDeviceActivationChange[], string[]>(
		"devices/activations/change",
		true
	),
	assign: createActionSet<AssignDeviceRequest.AsObject, AssignDeviceResponse.AsObject>(
		"workspace/device/assign"
	),
	unassign: createActionSet<string, UnassignDeviceResponse.AsObject>("workspace/device/unassign"),
	suspendDevice: createActionSet<IQueuedDeviceActivationChange, string>(
		"devices/activation/suspend",
		true
	),
};
