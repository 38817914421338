import type * as React from "react";

export interface IControlledInput {
	setValue(value: string): void;
	didPressEnter(evt: React.KeyboardEvent<HTMLInputElement>): void;
	didUpdateValue(value: string): void;
	getValue(): string;
}

export interface IControlledInputControllerProps {
	enterAction?: () => void;
	validChanged?: (valid: boolean) => void;
	name?: string;
}

class ControlledInputController implements IControlledInput {
	private value = "";
	public name?: string;
	public error?: boolean;
	private valid: boolean;
	enterAction?(): void;
	validChanged?(valid: boolean): void;

	constructor(props?: IControlledInputControllerProps) {
		if (props) {
			this.enterAction = props.enterAction;
			this.validChanged = props.validChanged;
			this.name = props.name;
		}

		this.valid = this.isValid();
		this.error = !this.valid;
	}

	didPressEnter(evt: React.KeyboardEvent<HTMLInputElement>) {
		if (this.enterAction) this.enterAction();
	}

	setValue(value: string) {
		this.didUpdateValue(value);
	}

	getValue(): string {
		return this.value;
	}

	didUpdateValue(value: string) {
		this.value = value;
		if (this.validChanged) {
			if (this.valid !== this.isValid()) {
				this.valid = this.isValid();
				this.error = !this.valid;
				this.validChanged(this.valid);
			}
		}
	}

	isValid(): boolean {
		return this.value !== "";
	}
}

export default ControlledInputController;
