import type { PayloadAction } from "@reduxjs/toolkit";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import type { DeleteRouteRequest } from "@somewear-labs/swl-web-api/src/proto/api/tracking_pb";
import type { RouteResponse } from "@somewear-labs/swl-web-api/src/proto/route_proto_pb";
import { StyleSettings } from "@somewear-labs/swl-web-api/src/proto/style_proto_pb";

import { trackingLocationActions } from "../../tracking/locations/trackingLocationActions";
import type { RootState } from "../rootReducer";
import { waypointActions } from "./waypointActions";

export interface IWaypoint extends RouteResponse.AsObject {}

export const isTakWaypoint = (metadata?: StyleSettings.AsObject): boolean => {
	return (
		metadata !== undefined &&
		metadata.affiliation !== StyleSettings.Affiliation.AFFILIATIONNONE &&
		metadata.dimension !== StyleSettings.Dimension.DIMENSIONNONE
	);
};

const adapter = createEntityAdapter<IWaypoint>({
	selectId: (entity) => entity.id,
});

// Rename the exports for readability in component usage
export const {
	selectAll: selectAllWaypoints,
	selectById: selectWaypointById,
	selectEntities: selectWaypointEntities,
} = adapter.getSelectors((state: RootState) => state.waypoints);

const waypointsSlice = createSlice({
	name: "waypoints",
	initialState: adapter.getInitialState(),
	reducers: {
		// _: (state) => {},
		addWaypoint: (state, action: PayloadAction<RouteResponse.AsObject>) => {
			adapter.addOne(state, action.payload);
		},
		updateWaypoint: (state, action: PayloadAction<RouteResponse.AsObject>) => {
			adapter.addOne(state, action.payload);
		},
		removeWaypoint: (state, action: PayloadAction<DeleteRouteRequest.AsObject>) => {
			adapter.removeOne(state, action.payload.routeId);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(waypointActions.fetch.fulfilled, (state, action) => {
			adapter.upsertMany(state, action.payload.data.routesList);
		});
		builder.addCase(trackingLocationActions.saveWaypoint.fulfilled, (state, action) => {
			adapter.upsertOne(state, action.payload.data);
		});
		builder.addCase(trackingLocationActions.deleteWaypoint.fulfilled, (state, action) => {
			adapter.removeOne(state, action.payload.data.routeId);
		});
	},
});

export default waypointsSlice;
