import configs from "./default.json";
import type IConfig from "./IConfig";

const Config: IConfig = configs;

const env = import.meta.env;

console.warn(env);

Config.firebase.enable = env.VITE_FIREBASE_ENABLE === "false" ? false : Config.firebase.enable;
Config.recurly.url = env.VITE_RECURLY_URL!;
Config.splitIO.enable = env.VITE_SPLIT_IO_ENABLE === "false" ? false : Config.splitIO.enable;
Config.sentry.dsn = env.VITE_SENTRY_DSN ?? Config.sentry.dsn;
Config.sentry.enable = env.VITE_SENTRY_ENABLE === "false" ? false : Config.sentry.enable;
Config.sentry.environment = env.VITE_SENTRY_ENVIRONMENT ?? Config.sentry.environment;
Config.somewear.baseUrl = env.VITE_SOMEWEAR_BASEURL ?? Config.somewear.baseUrl;
Config.somewear.cors = env.VITE_SOMEWEAR_CORS ?? Config.somewear.cors;
Config.somewear.publicUrl = env.VITE_SOMEWEAR_PUBLIC_URL ?? Config.somewear.publicUrl;
Config.somewear.proxyUrl = env.VITE_SOMEWEAR_PROXY_URL ?? Config.somewear.proxyUrl;
Config.somewear.gRPCDomain = env.VITE_SOMEWEAR_GRPC_DOMAIN ?? Config.somewear.gRPCDomain;
Config.somewear.map.enable =
	env.VITE_SOMEWEAR_MAP_ENABLE === "false" ? false : Config.somewear.map.enable;
Config.somewear.messages.enable =
	env.VITE_SOMEWEAR_MESSAGES_ENABLE === "false" ? false : Config.somewear.map.enable;

// console.log("config", Config);

export default Config;
