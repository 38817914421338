import type { Dictionary } from "@reduxjs/toolkit";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import type {
	DevicePlanResponse,
	GetDeviceUsageResponse,
} from "@somewear-labs/swl-web-api/src/proto/api/device_pb";
import type { DeviceRecord } from "@somewear-labs/swl-web-api/src/proto/device_record_proto_pb";
import _ from "lodash";

import { organizationDeviceActions } from "../../settings/organization/devices/organizationDeviceActions";
import { organizationActions } from "../../settings/organization/organizationsSlice";
import { apiDeviceRecordUpdate } from "../../settings/settingsSlice";
import { deviceTransferActions } from "../../settings/workspace/deviceTransfersSlice";
import { selectActiveWorkspaceId } from "../appSelectors";
import type { RootState } from "../rootReducer";
import { deviceActions } from "./deviceActions";

export interface IDevice extends DeviceRecord.AsObject {
	plan?: DevicePlanResponse.AsObject;
	hasDataUsage?: boolean;
	kbIncluded?: number;
	kbUsed?: number;
	isBorrowed?: boolean;
}

const adapter = createEntityAdapter<IDevice>({
	selectId: (entity) => entity.serial,
});

// Rename the exports for readability in component usage
export const {
	selectAll: selectAllDevices,
	selectById: selectDeviceBySerial,
	selectEntities: selectDeviceEntities,
} = adapter.getSelectors((state: RootState) => state.devices);

const devicesSlice = createSlice({
	name: "devices",
	initialState: adapter.getInitialState(),
	reducers: {
		_: (state) => {},
	},
	extraReducers: (builder) => {
		builder.addCase(deviceActions.fetch.fulfilled, (state, action) => {
			adapter.upsertMany(state, action.payload.data.deviceList);
		});

		builder.addCase(organizationDeviceActions.getDevices.fulfilled, (state, action) => {
			const planDict: Dictionary<DevicePlanResponse.AsObject> = {};
			action.payload.data.plansList.forEach((plan) => {
				if (plan.serial) {
					planDict[plan.serial] = plan;
				}
			});

			const devices = action.payload.data.devicesList.map((device) => {
				const clonedDevice: IDevice = _.cloneDeep(device);
				clonedDevice.plan = planDict[device.serial];
				return clonedDevice;
			});
			adapter.upsertMany(state, devices);
		});
		builder.addCase(organizationActions.getDeviceUsage.fulfilled, (state, action) => {
			const result = action.payload.data;
			const device = adapter.getSelectors().selectById(state, result.serial);
			if (device === undefined) return;

			const updatedDevice = addDataToDevice(device, result);
			adapter.upsertOne(state, updatedDevice);
		});
		builder.addCase(organizationActions.getAllDeviceUsage.fulfilled, (state, action) => {
			const devices = action.payload.data.dataUsageList.mapNotNull((result) => {
				const device = adapter.getSelectors().selectById(state, result.serial);
				if (device === undefined) return;

				const updatedDevice = addDataToDevice(device, result);
				return updatedDevice;
			});
			adapter.upsertMany(state, devices);
		});
		builder.addCase(apiDeviceRecordUpdate, (state, action) => {
			adapter.upsertOne(state, action.payload.record);
		});
		builder.addCase(deviceTransferActions.unaryAssign.fulfilled, (state, action) => {
			const device = action.payload.data.device;
			if (device === undefined) return;
			adapter.upsertOne(state, device);
		});
		builder.addCase(deviceTransferActions.bulkAssign.fulfilled, (state, action) => {
			const results = action.payload.data;
			const devices = results.mapNotNull((result) => result.device);
			adapter.upsertMany(state, devices);
		});
		builder.addCase(deviceTransferActions.applyQueuedTransfers.fulfilled, (state, action) => {
			const results = action.payload.data;
			const devices = results.mapNotNull((result) => result.device);
			adapter.upsertMany(state, devices);
		});
	},
});

const BYTES_IN_A_KILOBYTE = 1000;

export const selectDevicesByWorkspaceId = (state: RootState) => {
	const deviceDict: Dictionary<IDevice[]> = {};
	selectAllDevices(state).forEach((device) => {
		const devices: IDevice[] = deviceDict[device.workspaceId] ?? [];
		devices.push(device);
		deviceDict[device.workspaceId] = devices;
	});
	return deviceDict;
};

export const selectDevicesInWorkspace = (state: RootState, workspaceId: string) => {
	return selectDevicesByWorkspaceId(state)[workspaceId];
};

export const selectActiveWorkspaceDeviceSerials = (state: RootState) => {
	const activeWorkspaceId = selectActiveWorkspaceId(state);
	if (activeWorkspaceId === undefined) return [];
	const devices = selectDevicesInWorkspace(state, activeWorkspaceId);
	if (devices === undefined) return [];
	return devices.map((it) => it.serial);
};

function addDataToDevice(device: IDevice, result: GetDeviceUsageResponse.AsObject) {
	const clonedDevice = _.cloneDeep(device);
	clonedDevice.hasDataUsage = true;
	clonedDevice.kbUsed = Math.round((10 * result.bytesUsed) / BYTES_IN_A_KILOBYTE) / 10;
	clonedDevice.kbIncluded = Math.round((10 * result.bytesIncluded) / BYTES_IN_A_KILOBYTE) / 10;
	return clonedDevice;
}

export default devicesSlice;
