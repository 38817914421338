import type { PayloadAction } from "@reduxjs/toolkit";
import type { StateObservable } from "redux-observable";
import { combineEpics } from "redux-observable";
import type { Observable } from "rxjs";
import { of } from "rxjs";
import { catchError, filter, switchMap } from "rxjs/operators";

import type { IResponsePayload } from "../../common/EpicUtils";
import { EPIC_ACTION_PENDING, EPIC_ACTION_REJECTED } from "../../common/EpicUtils";
import type { RootState } from "../rootReducer";
import notificationsSlice from "./notificationsSlice";

const emitSuccessNotificationEpic = (
	action$: Observable<PayloadAction<IResponsePayload>>,
	state$: StateObservable<RootState>
) =>
	action$.pipe(
		filter((action) => action.type.endsWith("/fulfilled")),
		// filter((action) => Boolean(action.payload.message)),
		filter((action) => action.payload.requestId !== undefined),
		switchMap((action) => {
			return of(notificationsSlice.actions.emitSuccessNotification(action.payload));
		}),
		catchError((error) => of(error))
	);

const emitErrorNotificationEpic = (
	action$: Observable<PayloadAction<IResponsePayload>>,
	state$: StateObservable<RootState>
) =>
	action$.pipe(
		filter((action) => action.type.endsWith(EPIC_ACTION_REJECTED)),
		// filter((action) => Boolean(action.payload.message)),
		switchMap((action) => {
			return of(notificationsSlice.actions.emitErrorNotification(action.payload));
		}),
		catchError((error) => of(error))
	);

const emitPendingNotificationEpic = (
	action$: Observable<PayloadAction<IResponsePayload>>,
	state$: StateObservable<RootState>
) =>
	action$.pipe(
		filter((action) => action.type.endsWith(EPIC_ACTION_PENDING)),
		// filter((action) => Boolean(action.payload.message)),
		switchMap((action) => {
			return of(notificationsSlice.actions.emitPendingNotification(action.payload));
		}),
		catchError((error) => of(error))
	);

export default combineEpics(
	emitSuccessNotificationEpic,
	emitErrorNotificationEpic,
	emitPendingNotificationEpic
);
