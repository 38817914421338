import type { CreateBeamAuthRequest } from "@somewear-labs/swl-web-api/src/proto/api/organization_pb";
import { IdentityRecord } from "@somewear-labs/swl-web-api/src/proto/identity_record_proto_pb";
import type { IntegrationResponse } from "@somewear-labs/swl-web-api/src/proto/integration_proto_pb";

import type { IIdentity, IIntegrationIdentity } from "../../identity/identitiesSlice";

export interface WebhookIntegrationRequest extends IntegrationResponse.Webhook.AsObject {
	name: string;
}

export interface TAKServerIntegrationRequest extends IntegrationResponse.TakServer.AsObject {
	name: string;
	beamAuth?: CreateBeamAuthRequest.AsObject;
}

export type CreateIntegrationRequest = WebhookIntegrationRequest | TAKServerIntegrationRequest;

export function isWebhookIntegrationRequest(
	request: CreateIntegrationRequest
): request is WebhookIntegrationRequest {
	return (request as WebhookIntegrationRequest).url !== undefined;
}

export function isTakServerIntegrationRequest(
	request: CreateIntegrationRequest
): request is TAKServerIntegrationRequest {
	return (request as TAKServerIntegrationRequest).host !== undefined;
}

export function identityIsIntegration(identity: IIdentity): identity is IIntegrationIdentity {
	return identity.type === IdentityRecord.Type.INTEGRATION;
}
