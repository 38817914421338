import type { PayloadAction } from "@reduxjs/toolkit";
import type {
	DeleteRouteRequest} from "@somewear-labs/swl-web-api/src/proto/api/tracking_pb";
import {
	GetLocationsRequest,
	GetRoutesRequest,
} from "@somewear-labs/swl-web-api/src/proto/api/tracking_pb";
import type { LocationResponse } from "@somewear-labs/swl-web-api/src/proto/location_proto_pb";
import type { RouteResponse } from "@somewear-labs/swl-web-api/src/proto/route_proto_pb";
import type { SosEventResponse } from "@somewear-labs/swl-web-api/src/proto/sos_message_proto_pb";
import moment from "moment";
import type { Action } from "redux";
import type { Epic} from "redux-observable";
import { ofType } from "redux-observable";
import { asyncScheduler, forkJoin, Observable, of } from "rxjs";
import { catchError, map, mergeMap, switchMap, takeUntil, throttleTime } from "rxjs/operators";

import { signedOut } from "../app/appActions";
import { noOp } from "../app/appSlice";
import type { RootState } from "../app/rootReducer";
import type { ActionSetEpic} from "../common/EpicUtils";
import { createActionSetEpicHandler } from "../common/EpicUtils";
import type { IError } from "../common/errorReducer";
import FirebaseClient from "../common/FirebaseClient";
import { grpc } from "../common/GrpcClient";
import someGrpc from "../common/SomewearGrpc";
import { timestampFromMoment } from "../common/utils";
import Config from "../config/Config";
import {
	fetchTrackingFilters,
	fetchTrackingSettings,
	fetchWorkspaceFilters,
} from "../messaging/messagingSlice";
import { trackingLocationActions } from "./locations/trackingLocationActions";
import { MapViewStore } from "./mapViewStore";
import {
	getLocationsFulfilled,
	initMapView,
	setDateFilter,
	startGeolocating,
	stopGeolocating,
} from "./trackingActions";
import type { ISaveWaypointPayload } from "./trackingModel";
import type {
	DateRange,
	MapView} from "./trackingSlice";
import {
	apiLocationsError,
	setTrackingFilters,
	setTrackingSettings,
	setWorkspaceFilters,
	TRACKING_FILTER_DEFAULTS,
	TRACKING_SETTING_DEFAULTS,
	updatedMapView,
} from "./trackingSlice";

/*
Get the users tracking sessions and dispatch the action to set it on the store
 */
/*
export const apiTrackingIntervalsEpic: Epic<Action<string>> = (action$) =>
	action$.pipe(
		ofType(apiTrackingIntervalRequest.type),
		switchMap(() =>
			Api.getTrackingIntervals().pipe(
				mergeMap((intervalsResponse) => {
					return of(
						apiTrackingIntervalSuccess(intervalsResponse.toObject().intervalsList)
					);
				}),
				/!*mergeMap((intervalsResponse) =>
					from([
						addContactsFromResponses(intervalsResponse.toObject().intervalsList),
						apiTrackingIntervalSuccess(intervalsResponse.toObject().intervalsList),
					])
				),*!/
				takeUntil(action$.pipe(ofType(signedOut.type))),
				catchError((error) => of(apiTrackingIntervalError(error)))
			)
		)
	);
*/

/*
@deprecated should be using grpc to get waypoints
Get the users tracking sessions and dispatch the action to set it on the store
 */
/*export const apiRoutesEpic: Epic<Action<string>> = (action$) =>
	action$.pipe(
		ofType(apiRoutesRequest.type),
		switchMap(() =>
			Api.getRoutes().pipe(
				mergeMap((routeList) =>
					from([
						addContacts(
							routeList.toObject().routesList.mapNotNull((routes) => routes.owner)
						),
						apiRoutesSuccess(routeList.toObject().routesList),
					])
				),
				takeUntil(action$.pipe(ofType(signedOut.type))),
				catchError((error) => of(apiRoutesError(error)))
			)
		)
	);*/

/*
Get the locations for a user and dispatch the action to set it on the store
 */
/*export const apiLocationsEpic: Epic<AnyAction, AnyAction> = (action$) =>
	action$.pipe(
		ofType(apiLocationsRequest.type),
		switchMap((action) =>
			Api.getLocations(action.payload).pipe(
				map((locationResponse) => {
					return apiLocationsSuccess(locationResponse.toObject().locationsList);
				}),
				takeUntil(action$.pipe(ofType(signedOut.type))),
				catchError((error) => of(apiLocationsError(error)))
			)
		)
	);*/

/*
type ApiLocationsFilterForUserInActions = PayloadAction<DateFilterPayload>;
type ApiLocationsFilterForUserOutActions =
	| PayloadAction<LocationResponse.AsObject[]>
	| PayloadAction<IError>;
export const apiLocationsFilterForUser: Epic<
	ApiLocationsFilterForUserInActions | ApiLocationsFilterForUserOutActions,
	ApiLocationsFilterForUserOutActions
> = (action$) =>
	action$.pipe(
		ofType(setDateFilterForUser.type),
		map((action) => action as ApiLocationsFilterForUserInActions),
		mergeMap((action) =>
			Api.getLocations(
				action.payload.id,
				action.payload.filter && moment(action.payload.filter.start),
				action.payload.filter && moment(action.payload.filter.end)
			).pipe(
				map((locationResponse) => {
					return apiLocationsSuccess(locationResponse.toObject().locationsList);
				}),
				takeUntil(action$.pipe(ofType(signedOut.type))),
				catchError((error) => of(apiLocationsError(error)))
			)
		)
	);
*/

/*export const apiLocationsResetFilterForUser: Epic<
	ApiLocationsFilterForUserInActions | ApiLocationsFilterForUserOutActions,
	ApiLocationsFilterForUserOutActions
> = (action$) =>
	action$.pipe(
		ofType(resetDateFilterForUser.type),
		map((action) => action as ApiLocationsFilterForUserInActions),
		mergeMap((action) =>
			Api.getLocations(
				action.payload.id,
				action.payload.filter && moment(action.payload.filter.start),
				action.payload.filter && moment(action.payload.filter.end)
			).pipe(
				map((locationResponse) => {
					return apiLocationsSuccess(locationResponse.toObject().locationsList);
				}),
				takeUntil(action$.pipe(ofType(signedOut.type))),
				catchError((error) => of(apiLocationsError(error)))
			)
		)
	);*/

type ApiLocationsFilterOutActions = PayloadAction<LocationResponse.AsObject[] | IError>;
export const apiLocationsSetDateFilterEpic: Epic<
	PayloadAction<DateRange> | ApiLocationsFilterOutActions,
	ApiLocationsFilterOutActions,
	RootState
> = (action$, state$) =>
	action$.pipe(
		ofType(setDateFilter.type),
		map((action) => action as PayloadAction<DateRange>),
		switchMap((action) => of(action)),
		mergeMap((action) => {
			const from = moment(action.payload!.start);
			const to = moment(action.payload!.end);

			const locationsRequest = new GetLocationsRequest();
			locationsRequest.setFrom(timestampFromMoment(from));
			locationsRequest.setTo(timestampFromMoment(to));

			const routesRequest = new GetRoutesRequest();
			routesRequest.setFrom(timestampFromMoment(from));
			routesRequest.setTo(timestampFromMoment(to));

			return forkJoin({
				personalLocations: grpc
					.prepareRequestWithPayload(someGrpc.getLocations, locationsRequest)
					.pipe(map((location) => location.toObject().locationsList)),
				routeLocations: grpc
					.prepareRequestWithPayload(someGrpc.getRoutes, routesRequest)
					.pipe(
						map((routes) =>
							routes
								.toObject()
								.routesList.mapNotNull<LocationResponse.AsObject>((route) => {
									if (route.location === undefined) return undefined;
									return { ...route.location, userId: route.ownerId };
								})
						)
					),
			});
		}),
		map((response) => {
			const locationsList = response.personalLocations.concat(...response.routeLocations);
			return getLocationsFulfilled(locationsList);
		}),
		takeUntil(action$.pipe(ofType(signedOut.type))),
		catchError((error) => of(apiLocationsError(error)))
	);

const geoObs$ = new Observable<GeolocationPosition>((subscriber) => {
	console.log("start listening for locations");
	navigator.geolocation.getCurrentPosition(function (position) {
		console.log("Latitude is :", position.coords.latitude);
		console.log("Longitude is :", position.coords.longitude);
		subscriber.next(position);
	});
	navigator.geolocation.watchPosition(function (position) {
		console.log("Latitude is :", position.coords.latitude);
		console.log("Longitude is :", position.coords.longitude);
		subscriber.next(position);
	});
});

export const startSosSessionEpic: ActionSetEpic<
	LocationResponse.AsObject,
	SosEventResponse.AsObject
> = (action$, state$) => {
	return createActionSetEpicHandler(
		action$,
		state$,
		trackingLocationActions.startSosSession,
		(payload) => {
			return grpc.prepareRequestWithPayload(someGrpc.startSosSession, payload.data);
		}
	);
};

export const resolveSosSessionEpic: ActionSetEpic<void, SosEventResponse.AsObject> = (
	action$,
	state$
) => {
	return createActionSetEpicHandler(
		action$,
		state$,
		trackingLocationActions.resolveSosSession,
		(payload) => {
			return grpc.prepareRequest(someGrpc.resolveSosSession);
		}
	);
};

export const startGeolocatingEpic: Epic<Action> = (action$) =>
	action$.pipe(
		ofType(startGeolocating.type),
		switchMap((action) => {
			console.log("get locations");
			return geoObs$.pipe(
				map((location) =>
					trackingLocationActions.shareLocation.request({ data: location })
				),
				throttleTime(5000, asyncScheduler, { leading: true, trailing: true })
			);
		}),
		takeUntil(action$.pipe(ofType(stopGeolocating.type))),
		catchError((error) => of(error))
	);

export const shareGeolocationEpic: ActionSetEpic<
	GeolocationPosition,
	LocationResponse.AsObject[]
> = (action$, state$) => {
	return createActionSetEpicHandler(
		action$,
		state$,
		trackingLocationActions.shareLocation,
		(payload) =>
			grpc
				.prepareRequestWithPayload(someGrpc.createTrackingLocations, payload.data)
				.pipe(map((r) => r.toObject().locationsList))
	);
};

export const shareWaypointEpic: ActionSetEpic<GeolocationPosition, RouteResponse.AsObject> = (
	action$,
	state$
) => {
	return createActionSetEpicHandler(
		action$,
		state$,
		trackingLocationActions.shareWaypoint,
		(payload) => grpc.prepareRequestWithPayload(someGrpc.createWaypoint, payload.data)
	);
};

export const saveWaypointEpic: ActionSetEpic<ISaveWaypointPayload, RouteResponse.AsObject> = (
	action$,
	state$
) => {
	return createActionSetEpicHandler(
		action$,
		state$,
		trackingLocationActions.saveWaypoint,
		(payload) => grpc.prepareRequestWithPayload(someGrpc.saveWaypoint, payload.data),
		(payload) => {
			return {
				onFulfilled: "",
				onPending: "Adding your new waypoint...",
				onRejected: "An error occurred creating your waypoint",
			};
		}
	);
};

export const deleteWaypointEpic: ActionSetEpic<string, DeleteRouteRequest.AsObject> = (
	action$,
	state$
) => {
	return createActionSetEpicHandler(
		action$,
		state$,
		trackingLocationActions.deleteWaypoint,
		(payload) => grpc.prepareRequestWithPayload(someGrpc.deleteWaypoint, payload.data),
		(payload) => {
			return {
				onFulfilled: "Successfully deleted the waypoint",
				onPending: "Deleting your waypoint...",
				onRejected: "An error occurred deleting your waypoint",
			};
		}
	);
};

export const fetchTrackingFiltersEpic: Epic<Action> = (action$) =>
	action$.pipe(
		ofType(fetchTrackingFilters.type),
		switchMap(() => {
			if (!Config.firebase.enable) {
				return of(setTrackingFilters(TRACKING_FILTER_DEFAULTS));
			} else {
				return FirebaseClient.getTrackingFilters().pipe(
					map((filters) => setTrackingFilters(filters)),
					takeUntil(action$.pipe(ofType(signedOut.type))),
					catchError(() => of(noOp()))
				);
			}
		})
	);

export const fetchTrackingSettingsEpic: Epic<Action> = (action$) =>
	action$.pipe(
		ofType(fetchTrackingSettings.type),
		switchMap(() => {
			if (!Config.firebase.enable) {
				return of(setTrackingSettings(TRACKING_SETTING_DEFAULTS));
			} else {
				return FirebaseClient.getTrackingSettings().pipe(
					map((filters) => setTrackingSettings(filters)),
					takeUntil(action$.pipe(ofType(signedOut.type))),
					catchError(() => of(noOp()))
				);
			}
		})
	);

export const fetchWorkspaceFiltersEpic: Epic<Action> = (action$) =>
	action$.pipe(
		ofType(fetchWorkspaceFilters.type),
		switchMap(() => {
			if (!Config.firebase.enable) {
				return of(setWorkspaceFilters({}));
			} else {
				return FirebaseClient.getWorkspaceFilters().pipe(
					map((filters) => setWorkspaceFilters(filters)),
					takeUntil(action$.pipe(ofType(signedOut.type))),
					catchError(() => of(noOp()))
				);
			}
		})
	);

export const initMapViewEpic: Epic<Action, PayloadAction<MapView> | Action> = (action$) =>
	action$.pipe(
		ofType(initMapView.type),
		switchMap(() => {
			return MapViewStore.loadStoredView();
		}),
		mergeMap((view) => {
			if (view === undefined) return of(noOp());
			return of(updatedMapView(view));
		})
	);
