import * as React from "react";
import PhoneInput from "react-phone-number-input";
import styled from "styled-components";

import type { IControlledInputProps } from "./ControlledInputView";
import ControlledInputView from "./ControlledInputView";
import { modifyState } from "./utils";

interface IPhoneInputProps extends IControlledInputProps {
	country?: string;
	inputClassName?: string;
}

class PhoneInputView extends ControlledInputView<IPhoneInputProps> {
	updatePhone(phone: string) {
		this.setState(modifyState(this.state, { value: phone }));
		this.props.controlledInput.didUpdateValue(phone);
	}

	render(): React.ReactNode {
		return (
			<Container className={this.props.disabled ? "disabled" : ""}>
				<PhoneInput
					placeholder={this.props.placeholder}
					country={this.props.country ?? "US"}
					defaultCountry={"US"}
					value={this.props.value ?? ""}
					onChange={(phone: string) => this.updatePhone(phone)}
					onKeyDown={(evt: React.KeyboardEvent<HTMLInputElement>) =>
						this.handleKeyDown(evt)
					}
					className={this.props.className}
					disabled={this.props.disabled}
					displayInitialValueAsLocalNumber={true}
				/>
			</Container>
		);
	}
}

const Container = styled.div`
	font-family: var(--somewear-font);
	&.disabled {
		.PhoneInputInput {
			background: none;
			border-bottom: 0px;
		}
		.PhoneInputCountrySelectArrow {
			display: none;
		}
	}

	.PhoneInputInput {
		line-height: 1.38;
		border-top: 0px;
		border-left: 0px;
		border-right: 0px;
		border-bottom: solid 1px #4a4a4a7a;
	}
`;

export default PhoneInputView;
