// workspaceId -> enabled
import { UpdateOrganizationIntegrationRoutingRequest } from "@somewear-labs/swl-web-api/src/proto/api/organization_pb";
import type { ToggleWorkspaceIntegrationRequest } from "@somewear-labs/swl-web-api/src/proto/api/workspace_pb";
import type { OptionalBoolMap } from "@somewear-labs/swl-web-api/src/proto/command_proto_pb";
import { OptionalBool } from "@somewear-labs/swl-web-api/src/proto/command_proto_pb";

import type { OnCompleteHandler } from "@/app/notifications/PendingButton";

import type { Dict } from "../../app/appModel";
import { getDictionaryValue } from "../../common/utils";

export type IntegrationProps = {
	identityId: string;
	serverName: string;
	beamEnabled: boolean;
};

export type IntegrationManagementDialogProps = IntegrationProps & {
	open: boolean;
	onClose: OnCompleteHandler;
};

export type IntegrationWorkspaceConfig = { workspaceId: string; enabled: boolean };

// identityId -> workspaceId
export type IntegrationAssetConfig = { identityId: string; workspaceId: string | undefined };

export type InboundIntegrationConfig = {
	enabled: boolean | undefined;
	workspaces: IntegrationWorkspaceConfig[];
	resources: IntegrationAssetConfig[];
};

export type OutboundIntegrationConfig = {
	enabled: boolean | undefined;
	workspaces: IntegrationWorkspaceConfig[];
};

export type IntegrationConfig = {
	identityId: string;
	inbound: InboundIntegrationConfig | undefined;
	outbound: OutboundIntegrationConfig;
};

export type SingleWorkspaceIntegrationConfig = IntegrationConfig & {
	defaultWorkspaceId: string;
};

export function mapToToggleWorkspaceIntegrations(
	config: IntegrationConfig
): ToggleWorkspaceIntegrationRequest.AsObject[] {
	return config.outbound.workspaces.map((it) => {
		return {
			identityId: config.identityId,
			workspaceId: it.workspaceId,
			enableIntegration: it.enabled,
		};
	});
}

export function isSingleWorkspaceIntegrationConfig(
	config: IntegrationConfig | SingleWorkspaceIntegrationConfig
): config is SingleWorkspaceIntegrationConfig {
	return Boolean((config as SingleWorkspaceIntegrationConfig).defaultWorkspaceId);
}

function toOptionalBool(value: boolean | undefined): OptionalBoolMap[keyof OptionalBoolMap] {
	if (value === undefined) return OptionalBool.OPTIONALBOOLNONE;
	return value ? OptionalBool.OPTIONALBOOLTRUE : OptionalBool.OPTIONALBOOLFALSE;
}

export function mapToEnableIntegration(
	config: IntegrationConfig | SingleWorkspaceIntegrationConfig
) {
	const model = new UpdateOrganizationIntegrationRoutingRequest.EnableIntegration();
	model.setIdentityId(config.identityId);
	if (config.inbound?.enabled !== undefined)
		model.setInboundEnabled(toOptionalBool(config.inbound.enabled));
	if (config.outbound.enabled !== undefined)
		model.setOutboundEnabled(toOptionalBool(config.outbound.enabled));
	if (isSingleWorkspaceIntegrationConfig(config))
		model.setDefaultWorkspaceId(config.defaultWorkspaceId);
	return model;
}

export function mapToEnableIntegrationAccounts(config: IntegrationConfig) {
	const inboundEnabledDict: Dict<boolean> = {};
	config.inbound?.workspaces.forEach((it) => {
		inboundEnabledDict[it.workspaceId] = it.enabled;
	});

	const outboundEnabledDict: Dict<boolean> = {};
	config.outbound.workspaces.forEach((it) => {
		outboundEnabledDict[it.workspaceId] = it.enabled;
	});

	const allWorkspaces = config.outbound.workspaces.concat(config.inbound?.workspaces ?? []);

	return allWorkspaces.map((it) => {
		const model = new UpdateOrganizationIntegrationRoutingRequest.EnableIntegrationAccount();
		model.setIdentityId(config.identityId);
		model.setWorkspaceId(it.workspaceId);
		const inboundEnabled = getDictionaryValue(inboundEnabledDict, it.workspaceId);
		if (inboundEnabled !== undefined) model.setInboundEnabled(toOptionalBool(inboundEnabled));

		const outboundEnabled = getDictionaryValue(outboundEnabledDict, it.workspaceId);
		if (outboundEnabled !== undefined)
			model.setOutboundEnabled(toOptionalBool(outboundEnabled));

		return model;
	});
}

export function mapToUpdateTakResourceAssignedWorkspaces(config: IntegrationConfig) {
	if (config.inbound === undefined) return [];
	return config.inbound.resources.map((resource) => {
		const model =
			new UpdateOrganizationIntegrationRoutingRequest.UpdateTakResourceAssignedWorkspace();
		model.setIdentityId(resource.identityId);
		model.setWorkspaceId(resource.workspaceId ?? "");
		return model;
	});
}
