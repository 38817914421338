import { StyleSettings } from "@somewear-labs/swl-web-api/src/proto/style_proto_pb";

import type { Contact } from "../../app/contacts/contactModels";
import { Sentry } from "../../common/SentryUtil";
import { shufff } from "../../common/utils";
import type { IIdentity } from "../identity/identitiesSlice";
import type { IWorkspace } from "../workspace/workspacesSlice";
import { COLORCUSTOM, SWL_COLOR_BLUE } from "./colors.config";

export type ColorName = keyof StyleSettings.ColorMap;
export type ColorValue = StyleSettings.ColorMap[ColorName];
export type CustomizableColorValue = ColorValue | -1;

export function isStandardColor(
	color: CustomizableColorValue | ColorValue | undefined
): color is ColorValue {
	if (color === undefined) return false;
	if (color === COLORCUSTOM) return false;
	if (color === StyleSettings.Color.COLORNONE) return false;
	if (color === StyleSettings.Color.COLORREDORANGE) return false;
	if (color === StyleSettings.Color.COLORYELLOWORANGE) return false;
	return true;
}

export const getEntityColor = (
	fullName: string | undefined,
	standardColor: ColorValue | undefined
): string => {
	let hexColor: string | undefined;

	if (isStandardColor(standardColor)) {
		hexColor = getHexForStandardColor(standardColor);
	}

	if (hexColor === undefined && fullName !== undefined) {
		hexColor = getColorFromString(fullName);
	}

	if (fullName === undefined) {
		console.log();
	}

	if (hexColor === undefined) {
		return SWL_COLOR_BLUE;
	}

	return hexColor;
};

export const getHexForStandardColor = (value: ColorValue): string | undefined => {
	switch (value) {
		case StyleSettings.Color.COLORBLUE:
			return "#48A4E1";
		case StyleSettings.Color.COLORBLUEVIOLET:
			return "#1D4E97";

		case StyleSettings.Color.COLORVIOLET:
			return "#693083";
		case StyleSettings.Color.COLORREDVIOLET:
			return "#BD2E7A";
		case StyleSettings.Color.COLORRED:
			return "#C63731";
		// case StyleSettings.Color.COLORREDORANGE:
		// 	return "#ED6242";
		case StyleSettings.Color.COLORORANGE:
			return "#E47B39";
		// case StyleSettings.Color.COLORYELLOWORANGE:
		// 	return "#ffc342";
		case StyleSettings.Color.COLORYELLOW:
			return "#FBD243";
		case StyleSettings.Color.COLORYELLOWGREEN:
			return "#A5BE52";
		case StyleSettings.Color.COLORGREEN:
			return "#6BA953";
		case StyleSettings.Color.COLORGREENBLUE:
			return "#459C9D";

		case StyleSettings.Color.COLORNONE:
			return;

		default:
			return;
	}
};

export const getNameForStandardColor = (value: ColorValue): string | undefined => {
	switch (value) {
		case StyleSettings.Color.COLORBLUE:
			return "Blue";
		case StyleSettings.Color.COLORBLUEVIOLET:
			return "Blue Violet";
		case StyleSettings.Color.COLORGREEN:
			return "Green";
		case StyleSettings.Color.COLORRED:
			return "Red";
		case StyleSettings.Color.COLORGREENBLUE:
			return "Green Blue";
		case StyleSettings.Color.COLORORANGE:
			return "Orange";
		case StyleSettings.Color.COLORNONE:
			return;
		case StyleSettings.Color.COLORREDORANGE:
			return "Red Orange";
		case StyleSettings.Color.COLORREDVIOLET:
			return "Red Violet";
		case StyleSettings.Color.COLORVIOLET:
			return "Violet";
		case StyleSettings.Color.COLORYELLOW:
			return "Yellow";
		case StyleSettings.Color.COLORYELLOWGREEN:
			return "Yellow Green";
		case StyleSettings.Color.COLORYELLOWORANGE:
			return "Yellow Orange";
		default:
			return;
	}
};

const getColorFromString = (str: string): string => {
	if (str.toLowerCase() === "personal" || str.isEmpty()) {
		return SWL_COLOR_BLUE;
	}
	const input = shufff(str, str.length);
	let hash = 0;
	for (let i = 0; i < input.length; i++) {
		hash = input.charCodeAt(i) + ((hash << 5) - hash);
	}
	let colour = "#";
	for (let i = 0; i < 3; i++) {
		const value = (hash >> (i * 8)) & 0xff;
		colour += ("00" + value.toString(16)).substr(-2);
	}
	return colour;
};

export const getColorForWorkspace = (workspace: IWorkspace) => {
	const standardColor = workspace.styleSettings?.standardColor;
	if (isStandardColor(standardColor)) return getHexForStandardColor(standardColor) ?? "";
	return getColorFromString(workspace.name);
};

export const getColorForAsset = (contact: Contact, identity?: IIdentity) => {
	if (contact === undefined) {
		Sentry.captureException("getColorForAsset: contact is undefined - defaulting to blue");
		return SWL_COLOR_BLUE;
	}
	const standardColor = identity?.styleSettings?.standardColor;
	if (isStandardColor(standardColor)) {
		const color = getHexForStandardColor(standardColor);
		if (color !== undefined) return color;
	}
	return getColorFromString("SALT" + contact.name + "PEPPER");
};
