import type { PayloadAction } from "@reduxjs/toolkit";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

import type { IError } from "../../common/Common";
import type { IResponsePayload } from "../../common/EpicUtils";
import type { RootState } from "../rootReducer";

export enum SomewearNotificationStatus {
	success,
	error,
	pending,
}

export interface ISomewearNotification {
	requestId: string;
	status: SomewearNotificationStatus;
	message?: string;
	details?: string;
	autoRemove?: boolean;
	inline?: boolean;
}

export interface ISomewearSuccessNotification<T> extends ISomewearNotification {
	data?: T;
}

export interface ISomewearErrorNotification extends ISomewearNotification {
	error?: IError;
}

const adapter = createEntityAdapter<ISomewearNotification>({
	selectId: (entity) => entity.requestId,
});

// Rename the exports for readability in component usage
export const { selectAll: selectAllNotifications, selectById: selectNotificationByRequestId } =
	adapter.getSelectors((state: RootState) => state.notifications);

export const useSelectNotificationById = (
	requestId?: string
): ISomewearNotification | undefined => {
	return useSelector((state: RootState) =>
		requestId === undefined ? undefined : selectNotificationByRequestId(state, requestId)
	);
};

const buildSuccessNotification = <T = unknown>(
	payload: IResponsePayload<T>
): ISomewearSuccessNotification<T> | undefined => {
	return {
		requestId: payload.requestId,
		status: SomewearNotificationStatus.success,
		message: payload.message,
		details: payload.details,
		autoRemove: true,
		data: payload.data,
	};
};

export const isSuccessNotification = <T>(
	obj: ISomewearNotification | undefined
): obj is ISomewearSuccessNotification<T> => obj?.status === SomewearNotificationStatus.success;

export const isErrorNotification = (
	obj: ISomewearNotification | undefined
): obj is ISomewearErrorNotification => obj?.status === SomewearNotificationStatus.error;

const buildPendingNotification = <T = undefined>(
	payload: IResponsePayload<T>
): ISomewearNotification | undefined => {
	return {
		requestId: payload.requestId,
		status: SomewearNotificationStatus.pending,
		message: payload.message,
		details: payload.details,
	};
};

const buildErrorNotification = <T = unknown>(
	payload: IResponsePayload<T>
): ISomewearNotification | undefined => {
	return {
		requestId: payload.requestId,
		status: SomewearNotificationStatus.error,
		message: payload.message,
		details: payload.details,
	};
};

function upsertNotification(state: any, notification: ISomewearNotification | undefined) {
	if (notification?.requestId !== undefined) adapter.upsertOne(state, notification);
}

const notificationSlice = createSlice({
	name: "notifications",
	initialState: adapter.getInitialState(),
	reducers: {
		emitPendingNotification: (state, action: PayloadAction<IResponsePayload>) => {
			const notification = buildPendingNotification(action.payload);
			upsertNotification(state, notification);
		},
		emitErrorNotification: (state, action: PayloadAction<IResponsePayload>) => {
			const notification = buildErrorNotification(action.payload);
			upsertNotification(state, notification);
		},
		emitSuccessNotification: (state, action: PayloadAction<IResponsePayload>) => {
			const notification = buildSuccessNotification(action.payload);
			upsertNotification(state, notification);
		},
		removeNotificationById: (state, action: PayloadAction<string>) => {
			adapter.removeOne(state, action.payload);
		},
		removeAllNotifications: (state) => {
			adapter.removeAll(state);
		},
	},
});

export default notificationSlice;
