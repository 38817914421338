import type { Dictionary } from "@reduxjs/toolkit";
import type {
	DeviceActivationStateTypeMap} from "@somewear-labs/swl-web-api/src/proto/api/device_pb";
import {
	DeviceActivationStateType
} from "@somewear-labs/swl-web-api/src/proto/api/device_pb";
import type {
	DeviceSettingsResponse,
	DeviceStatusResponse,
} from "@somewear-labs/swl-web-api/src/proto/device_proto_pb";
import { DeviceRecord } from "@somewear-labs/swl-web-api/src/proto/device_record_proto_pb";
import { createSelector } from "reselect";

import { getDeviceModelName } from "@/app/devices/device.model";
import { Mappers } from "@/common/Mappers";

import { selectActiveOrganizationId, selectActiveWorkspaceId } from "../app/appSelectors";
import type { IWorkspaceAsset } from "../app/assets/workspaceAssetsSlice";
import {
	selectAllWorkspaceAssets,
	selectWorkspaceAssetEntities,
} from "../app/assets/workspaceAssetsSlice";
import { selectDeviceEntities } from "../app/devices/devicesSlice";
import type { RootState } from "../app/rootReducer";
import { getDictionaryValue, timestampToMoment } from "../common/utils";
import {
	DeviceActivationChange,
	selectQueuedDeviceActivationChangeEntities,
} from "./device/queuedDeviceActivationChangeSlice";
import type { IIdentity } from "./identity/identitiesSlice";
import { selectIdentityEntities } from "./identity/identitiesSlice";
import { ChangeableFields } from "./settingsSlice";
import { selectDeviceTransferEntities } from "./workspace/deviceTransfersSlice";
import { selectQueuedDeviceNameChangesEntities } from "./workspace/queuedDeviceNameChangesSlice";
import { selectActiveWorkspace } from "./workspace/workspaceSelectors";
import WorkspaceUtil from "./workspace/workspaceUtil";

const deviceStatusSelector = (state: RootState) => {
	return state.settings.deviceStatus;
};

const deviceSettingsSelector = (state: RootState) => {
	return state.settings.deviceSettings;
};

export const selectDeviceSettingsPending = (state: RootState) => {
	return state.settings.deviceSettingsPending;
};

const selectDeviceUserIds = (state: RootState) => state.settings.deviceUserIds;

export const selectShowArchived = (state: RootState) => state.settings.showArchived;

export const selectShowBorrowed = (state: RootState) => state.settings.showBorrowed;

export const deviceManagementVisible = createSelector([selectActiveWorkspace], (workspace) => {
	if (!workspace) return true;
	return WorkspaceUtil.isPersonal(workspace);
});

export interface DeviceStatusSummary {
	serial: string;
	imei: string;
	displayName: string;
	assignedAsset?: IIdentity;
	settings?: DeviceSettingsResponse.AsObject;
	status?: DeviceStatusResponse.AsObject;
	originalWorkspaceId: string;
	workspaceId?: string;
	organizationId?: string;
	changedFields: ChangeableFields[];
}

export const selectDeviceAssetsDict = createSelector(
	[selectDeviceUserIds, selectWorkspaceAssetEntities],
	(deviceAssetIds, assetDict) => {
		const deviceAssetDict: Dictionary<IWorkspaceAsset> = {};
		Object.values(deviceAssetIds)
			.mapNotNull((it) => it)
			.forEach((id) => {
				deviceAssetDict[id] = assetDict[id];
			});
		return deviceAssetDict;
	}
);

export const selectDeviceSummary = createSelector(
	[
		selectDeviceEntities,
		deviceStatusSelector,
		deviceSettingsSelector,
		// selectDeviceUserIds,
		selectDeviceSettingsPending,
		selectQueuedDeviceNameChangesEntities,
		selectDeviceTransferEntities,
		selectQueuedDeviceActivationChangeEntities,
		selectDeviceAssetsDict,
		selectIdentityEntities,
		selectAllWorkspaceAssets,
	],
	(
		deviceDict,
		deviceStatus,
		deviceSettings,
		// deviceUserIds,
		deviceSettingsPending,
		queuedDeviceNameChangesDict,
		deviceTransferDict,
		queuedDeviceActivationChangesDict,
		assetsDict,
		identityDict,
		workspaceAssets
	) => {
		if (Object.keys(deviceDict).isEmpty()) return [];
		// if (Object.keys(deviceStatus).isEmpty() || Object.keys(deviceSettings).isEmpty()) return [];

		const mergedSettings = Object.assign({ ...deviceSettings }, deviceSettingsPending);
		return (
			Object.values(deviceDict)
				.mapNotNull((it) => it)
				/*
			.sort((userA, userB) => {
				let aName = userA[1]?.fullname;
				let bName = userB[1]?.fullname;
				if (aName === undefined) return 1;
				if (bName === undefined) return -1;
				if (aName === bName) return 0;
				return aName < bName ? -1 : 1;
			})
			 */
				.map((device) => {
					const serial = device.serial;

					const assignedUserId = device.userAccountId; // getDictionaryValue(deviceUserIds, serial);
					const changedFields: ChangeableFields[] = [];

					const transfer = getDictionaryValue(deviceTransferDict, serial);
					const activationChange = getDictionaryValue(
						queuedDeviceActivationChangesDict,
						serial
					);
					const assignedAssetAccount = getDictionaryValue(
						assetsDict,
						device.userAccountId
					);
					const assignedAsset = getDictionaryValue(
						identityDict,
						assignedAssetAccount?.identityId
					);
					const transferAsset = getDictionaryValue(
						identityDict,
						transfer?.targetIdentityId
					);

					const deviceIdentity = getDictionaryValue(identityDict, device.identityId);
					const nameChange = getDictionaryValue(queuedDeviceNameChangesDict, serial);

					if (nameChange !== undefined) {
						changedFields.push(ChangeableFields.Name);
					}
					const displayName = nameChange?.name ?? deviceIdentity?.fullName ?? serial;

					if (transfer && transfer.targetWorkspaceId !== device.workspaceId) {
						changedFields.push(ChangeableFields.Workspace);
					}

					if (transfer && transfer.targetIdentityId !== assignedAsset?.id) {
						changedFields.push(ChangeableFields.User);
					}

					if (
						activationChange &&
						activationChange.change !== DeviceActivationChange.NONE
					) {
						changedFields.push(ChangeableFields.ActivationStatus);
					}

					[
						ChangeableFields.Battery,
						ChangeableFields.Altitude,
						ChangeableFields.TrackingInterval,
					].forEach((field) => {
						if (settingHasChanged(field)) {
							changedFields.push(field);
						}
					});

					const summary: DeviceStatusSummary = {
						assignedAsset:
							transfer?.targetIdentityId || transfer?.targetIdentityId === ""
								? transferAsset
								: assignedAsset,
						changedFields,
						displayName: displayName,
						serial: serial,
						imei: device.imei,
						status: deviceStatus[serial],
						settings: mergedSettings[serial],
						originalWorkspaceId: device.workspaceId,
						organizationId: device?.organizationId,
						workspaceId: transfer?.targetWorkspaceId ?? device.workspaceId,
					};

					return summary;

					function settingHasChanged(field: ChangeableFields) {
						const { settings: pendingSettings } = deviceSettingsPending[serial] ?? {};
						const { settings: originalSettings } = deviceSettings[serial] ?? {};

						if (!pendingSettings) return false;

						const vals: Partial<Record<ChangeableFields, boolean>> = {
							[ChangeableFields.Altitude]:
								pendingSettings?.enableAltitudeReporting !==
								originalSettings?.enableAltitudeReporting,
							[ChangeableFields.Battery]:
								pendingSettings?.batteryReporting !==
								originalSettings?.batteryReporting,
							[ChangeableFields.TrackingInterval]:
								pendingSettings?.trackingInterval !==
									originalSettings?.trackingInterval ||
								pendingSettings?.gpsInterval !== originalSettings?.gpsInterval,
						};

						return vals[field] ?? false;
					}
				})
				.sort((a, b) => {
					return a.serial.localeCompare(b.serial);
				})
		);
	}
);

export const selectDeviceSummaryForActiveWorkspace = createSelector(
	[selectDeviceSummary, selectActiveWorkspaceId],
	(summaryList, workspaceId) => {
		return summaryList.filter((it) => it.originalWorkspaceId === workspaceId);
	}
);

export const selectPendingDeviceChangeCount = createSelector(
	[selectDeviceSummary],
	(summaryList) => {
		return summaryList.reduce((acc, item) => {
			if (item.changedFields.length > 0) {
				acc += 1;
			}
			return acc;
		}, 0);
	}
);

const getActivationStateLabel = (
	activationState?: DeviceActivationStateTypeMap[keyof DeviceActivationStateTypeMap]
): string | undefined => {
	if (activationState === DeviceActivationStateType.STATUS_ACTIVE) {
		return "Active";
	} else if (activationState === DeviceActivationStateType.STATUS_DISABLED) {
		return "Disabled";
	} else if (
		activationState === DeviceActivationStateType.STATUS_SUSPENDED ||
		activationState === DeviceActivationStateType.STATUS_SUSPENDING
	) {
		return "Suspended";
	} else if (activationState === DeviceActivationStateType.STATUS_INACTIVE) {
		return "Inactive";
	}
	return undefined;
};

export const selectDeviceCsvDataForActiveOrganization = createSelector(
	[selectDeviceEntities, selectDeviceSummary, selectActiveOrganizationId],
	(deviceDict, summaryList, activeOrganizationId) => {
		return summaryList
			.filter((it) => it.organizationId === activeOrganizationId)
			.mapNotNull((it, idx) => {
				const device = getDictionaryValue(deviceDict, it.serial);
				if (device === undefined) return undefined;

				const supportsDeviceUsage =
					device.gateway === DeviceRecord.Gateway.COMMERCIAL &&
					device.plan !== undefined &&
					device.model !== DeviceRecord.Model.SHOUTNANO &&
					!Mappers.subscriptionPlanIsUnlimited(device.plan.subscriptionPlan);

				const dataUsage = supportsDeviceUsage
					? {
							"SATELLITE DATA USED (kBs)": supportsDeviceUsage
								? device.kbUsed
								: undefined,
					  }
					: {};

				const address =
					idx === 0
						? {
								"IP ADDRESS": "sbd.somewear.app:10800",
						  }
						: undefined;

				return {
					"SERIAL #s": it.serial,
					"IMEI #s": it.imei,
					TYPE: getDeviceModelName(device),
					"LAST ACTIVE": it.status?.lastHeartbeat
						? timestampToMoment(it.status?.lastHeartbeat).toDate()
						: "--",
					"SATELLITE STATUS": getActivationStateLabel(device.plan?.activationState),
					...dataUsage,
					...address,
				};
			});
	}
);

export const selectSelectedDeviceDict = (state: RootState) => {
	return state.settings.selectedDeviceDict;
};
