import type { SignInResponse } from "@somewear-labs/swl-web-api/src/proto/user_proto_pb";
import { SignInRequest } from "@somewear-labs/swl-web-api/src/proto/user_proto_pb";
import jwt_decode from "jwt-decode";
import type { Observable } from "rxjs";
import { BehaviorSubject, defer, of, Subject, throwError } from "rxjs";
import { catchError, delay, map, switchMap, take } from "rxjs/operators";

import { Api } from "./Api";
import type { IAuthService, IAuthUser } from "./AuthUtil";
import grpcClient from "./GrpcClient";
import somewearGrpc from "./SomewearGrpc";
import StoreController from "./StoreController";

const ACCESS_TOKEN_KEY = "swl_access_token";

type AuthModelV1 = {
	token: string;
	uid: string;
};

type AuthModelV2 = {
	accessToken: string;
	refreshToken: string;
	accessExpiration: number;
	uid: string;
};

type AuthModel = AuthModelV1 | AuthModelV2;

function isModelV1(input: AuthModel | undefined): input is AuthModelV1 {
	if (input === undefined) return false;
	return (input as AuthModelV1).token !== undefined;
}

function isModelV2(input: AuthModel | undefined): input is AuthModelV2 {
	if (input === undefined) return false;
	return (input as AuthModelV2).accessToken !== undefined;
}

type AuthSubjectV1 = {
	token: string;
	user: IAuthUser;
};

type AuthSubjectV2 = {
	accessToken: string;
	refreshToken: string;
	accessExpiration: number;
	user: IAuthUser;
};

type AuthSubject = AuthSubjectV1 | AuthSubjectV2;

function isSubjectV1(input: AuthSubject | undefined): input is AuthSubjectV1 {
	if (input === undefined) return false;
	return (input as AuthSubjectV1).token !== undefined;
}

function isSubjectV2(input: AuthSubject | undefined): input is AuthSubjectV2 {
	if (input === undefined) return false;
	return (input as AuthSubjectV2).accessToken !== undefined;
}

function getAccessToken(subject: AuthSubject | undefined): string | undefined {
	if (isSubjectV1(subject)) {
		return subject.token;
	} else if (isSubjectV2(subject)) {
		return subject.accessToken;
	}
	return undefined;
}

const auth$ = new BehaviorSubject<AuthSubject | undefined>(undefined);
const authChange$ = new Subject<AuthSubject | undefined>();

authChange$.subscribe(auth$);

// avoid eslint converting this to a const
let AUTH_VERSION: 1 | 2 = 1;
AUTH_VERSION = 2;

const init = () => {
	StoreController.getItem<AuthModel | undefined>(
		StoreController.STORE_AUTH_DATA,
		ACCESS_TOKEN_KEY
	).subscribe(
		(storedToken) => {
			if (isModelV1(storedToken)) {
				if (AUTH_VERSION === 2) {
					// there is no upgrade path from v1 to v2, the user will need to log in again
					initializeToken(undefined);
				} else {
					// continue using v1
					initializeToken({
						token: storedToken.token,
						uid: storedToken.uid,
					});
				}
			} else if (isModelV2(storedToken)) {
				initializeToken({
					accessToken: storedToken.accessToken,
					refreshToken: storedToken.refreshToken,
					accessExpiration: storedToken.accessExpiration,
					uid: storedToken.uid,
				});
			} else {
				initializeToken(storedToken);
			}
		},
		(error) => {
			initializeToken(undefined);
		}
	);
};

const expiration$ = new Subject<AuthSubjectV2>();

expiration$
	.pipe(
		switchMap((auth) => {
			return of(auth).pipe(
				delay((auth.accessExpiration / 2) * 1000), // refresh the token when it gets halfway to expiring
				refreshAccessToken()
			);
		})
	)
	.subscribe((auth) => {
		console.log("refreshed token", auth);
	});

function refreshAccessToken() {
	return switchMap((subject: AuthSubjectV2) => {
		return grpcClient
			.prepareRequestWithPayload(
				somewearGrpc.refreshAccessToken,
				subject.refreshToken,
				true,
				true
			)
			.pipe(handleSignInResponse(subject.user.email ?? ""));
	});
}

function subscribeToAuthChanges() {
	authChange$.subscribe((auth) => {
		if (auth === undefined) {
			StoreController.removeItem(StoreController.STORE_AUTH_DATA, ACCESS_TOKEN_KEY).subscribe(
				() => {
					console.log("successfully removed token");
				},
				(error) => {
					console.error("unable to remove token");
				}
			);
		} else if (isSubjectV1(auth)) {
			const decoded = jwt_decode<TokenClaims>(auth.token);
			StoreController.setItem<AuthModel>(StoreController.STORE_AUTH_DATA, ACCESS_TOKEN_KEY, {
				token: auth.token,
				uid: decoded.uid,
			}).subscribe(
				() => {
					console.log("successfully stored token");
				},
				(error) => {
					console.error("unable to store token");
				}
			);
		} else if (isSubjectV2(auth)) {
			// v2
			const decoded = jwt_decode<TokenClaims>(auth.accessToken);
			StoreController.setItem<AuthModel>(StoreController.STORE_AUTH_DATA, ACCESS_TOKEN_KEY, {
				accessToken: auth.accessToken,
				refreshToken: auth.refreshToken,
				accessExpiration: auth.accessExpiration,
				uid: decoded.uid,
			}).subscribe(
				() => {
					console.log("successfully stored token");
				},
				(error) => {
					console.error("unable to store token");
				}
			);

			expiration$.next(auth);
		} else {
			console.warn("unknown auth version");
		}
	});
}

function initializeToken(authModel: AuthModel | undefined) {
	subscribeToAuthChanges();

	if (isModelV1(authModel)) {
		const decoded = jwt_decode<TokenClaims>(authModel.token);
		authChange$.next({
			token: authModel.token,
			user: {
				id: decoded.uid,
				displayName: decoded.externalName,
				username: decoded.externalName,
				isAnonymous: false,
			},
		});
	} else if (isModelV2(authModel)) {
		const decoded = jwt_decode<TokenClaims>(authModel.accessToken);
		authChange$.next({
			accessToken: authModel.accessToken,
			refreshToken: authModel.refreshToken,
			accessExpiration: authModel.accessExpiration,
			user: {
				id: decoded.uid,
				displayName: decoded.externalName,
				username: decoded.externalName,
				isAnonymous: false,
			},
		});
	} else {
		authChange$.next(undefined);
	}
}

type TokenClaims = {
	apiClient: string;
	externalName: string;
	uid: string;
};

const handleSignInResponse = (email: string) => {
	return map((r: SignInResponse) => {
		const decoded = jwt_decode<TokenClaims>(r.getAccessToken());

		if (AUTH_VERSION === 1) {
			authChange$.next({
				token: r.getAccessToken(),
				user: { id: decoded.uid, displayName: email, username: email, isAnonymous: false },
			});
		} else if (AUTH_VERSION === 2) {
			authChange$.next({
				accessToken: r.getAccessToken(),
				refreshToken: r.getRefreshToken(),
				accessExpiration: r.getAccessExpiration(),
				user: { id: decoded.uid, displayName: email, username: email, isAnonymous: false },
			});
		}

		return {
			id: decoded.uid,
			displayName: email,
			username: email,
		} as IAuthUser;
	});
};

export interface ISomewearAuthService extends IAuthService {
	setCurrentAuthUser(user: IAuthUser): void;
	refreshAccessToken$(): Observable<IAuthUser | undefined>;
}

export const SomewearAuthService: ISomewearAuthService = {
	initialize: () => {
		init();
	},
	signInWithEmailAndPassword$: (args) => {
		const request = new SignInRequest();
		request.setUsername(args.email);
		request.setPassword(args.password);
		return Api.signIn(request).pipe(handleSignInResponse(args.email));
	},
	reauthenticate$: (currentPassword) => {
		const username = auth$.value?.user.username;

		if (!username) {
			return throwError(() => "No current user");
		}

		const request = new SignInRequest();
		request.setUsername(username);
		request.setPassword(currentPassword);

		return Api.signIn(request).pipe(
			map(() => true),
			catchError((error) => {
				console.error("Reauthentication failed:", error);
				return throwError(() => new Error("Current password is invalid"));
			})
		);
	},
	createUserWithEmailAndPassword$: (args) => {
		const request = new SignInRequest();
		request.setUsername(args.email);
		request.setPassword(args.password);
		return Api.signUp(request).pipe(handleSignInResponse(args.email));
	},

	signInAnonymously$: () => of(),
	sendPasswordResetEmail$: (email: string) => of(),
	sendEmailVerification$: () => of(),
	updateProfile$: (displayName: string) => of(),
	signOut$: () =>
		defer(() => {
			authChange$.next(undefined);
			return of();
		}),
	getUserIdToken$: () =>
		auth$.pipe(
			map((it) => getAccessToken(it)),
			take(1) // make sure this observable completes when we get the value
		),
	getTokenString$: () =>
		SomewearAuthService.getUserIdToken$().pipe(map((token) => `Token ${token}`)),
	getCurrentAuthUser: () => auth$.value?.user,
	reloadUser: () => {},
	onAuthStateChanged: (obs$: Subject<IAuthUser | undefined>) => {
		authChange$.pipe(map((it) => it?.user)).subscribe((user) => {
			obs$.next(user);
		});
		// on unregister
		return () => {
			obs$.next(undefined);
		};
	},
	setCurrentAuthUser: (user: IAuthUser) => {
		const subject = auth$.value!;

		if (isSubjectV1(subject)) {
			const token = subject.token;
			authChange$.next({
				token: token,
				user: user,
			});
		} else if (isSubjectV2(subject)) {
			const accessToken = subject.accessToken;
			const accessExpiration = subject.accessExpiration;
			const refreshToken = subject.refreshToken;
			authChange$.next({
				accessToken: accessToken,
				accessExpiration: accessExpiration,
				refreshToken: refreshToken,
				user: user,
			});
		}
	},
	refreshAccessToken$: () => {
		const subject = auth$.value;
		if (!isSubjectV2(subject)) return of(undefined);

		return of(subject).pipe(refreshAccessToken());
	},
};

export function isSomewearAuthService(
	authService: IAuthService | undefined
): authService is ISomewearAuthService {
	if (authService === undefined) return false;
	return (authService as ISomewearAuthService).refreshAccessToken$ !== undefined;
}
