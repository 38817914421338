import type { PayloadAction } from "@reduxjs/toolkit";
import { createAction, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import type { UpdateWorkspaceAccessResponse } from "@somewear-labs/swl-web-api/src/proto/api/workspace_pb";
import type {
	WorkspaceResponse} from "@somewear-labs/swl-web-api/src/proto/user_proto_pb";
import {
	WorkspaceAccess
} from "@somewear-labs/swl-web-api/src/proto/user_proto_pb";

import { emitWorkspaceChangeFromServer } from "../../app/appActions";
import { emitWorkspacesReceived } from "../../app/appSlice";
import type { RootState } from "../../app/rootReducer";
import type { IOrganization} from "../organization/organizationsSlice";
import { organizationActions } from "../organization/organizationsSlice";
import type { IWorkspaceRole } from "./members/AssignRoleToMemberPopover";

export const WorkspaceRoles: IWorkspaceRole[] = [
	{ id: 0, name: "Guest", active: false },
	{ id: 1, name: "Member", active: true },
	{ id: 2, name: "Admin", active: true },
];

export interface IWorkspace extends Omit<WorkspaceResponse.AsObject, "meshKey" | "externalId"> {
	userIsMember?: boolean;
	isNew?: boolean;
}

export interface IWorkspaceEntity extends IWorkspace {
	organization?: IOrganization;
	assetCount?: number;
	visibleAssetCount?: number;
}

const adapter = createEntityAdapter<IWorkspace>({
	selectId: (workspace) => workspace.id,
	sortComparer: (a, b) => {
		return a.name.localeCompare(b.name);
	},
});

export const updateWorkspaceAccess =
	createAction<UpdateWorkspaceAccessResponse.AsObject>("workspace/access/update");

// Rename the exports for readability in component usage

export const {
	selectAll: selectAllWorkspaces,
	selectById: selectWorkspaceById,
	selectEntities: selectWorkspaceEntities,
} = adapter.getSelectors((state: RootState) => state.workspaces);

const workspacesSlice = createSlice({
	name: "workspaces",
	initialState: adapter.getInitialState(),
	reducers: {
		emitWorkspaceUpdate(state, action: PayloadAction<WorkspaceResponse.AsObject>) {
			adapter.upsertOne(state, action.payload);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(organizationActions.getWorkspaces.fulfilled, (state, action) => {
			adapter.upsertMany(state, action.payload.data.workspacesList);
		});
		builder.addCase(organizationActions.createWorkspace.fulfilled, (state, action) => {
			if (action.payload.data.workspace !== undefined) {
				const workspace: IWorkspace = action.payload.data.workspace;
				workspace.userIsMember = true;
				adapter.upsertOne(state, workspace);
			}
		});
		builder.addCase(organizationActions.removeWorkspace.fulfilled, (state, action) => {
			const access = action.payload.data.access;
			const id = action.payload.data.id;
			if (access === WorkspaceAccess.WORKSPACEACCESSCLOSED) {
				adapter.removeOne(state, id);
			} else {
				console.warn("Received an unsupported workspace access change");
			}
		});
		builder.addCase(updateWorkspaceAccess, (state, action) => {
			const access = action.payload.access;
			const id = action.payload.id;
			if (access === WorkspaceAccess.WORKSPACEACCESSCLOSED) {
				adapter.removeOne(state, id);
			} else {
				console.warn("Received an unsupported workspace access change");
			}
		});
		builder.addCase(emitWorkspacesReceived, (state, action) => {
			const workspaces: IWorkspace[] = action.payload.map((workspace: IWorkspace) => {
				if (workspace.name.isEmpty()) workspace.name = "Personal";
				if (workspace.id.isEmpty()) workspace.id = "-1";
				workspace.userIsMember = true;
				return workspace;
			});
			adapter.upsertMany(state, workspaces);
		});
		builder.addCase(emitWorkspaceChangeFromServer, (state, action) => {
			adapter.upsertOne(state, action.payload);
		});
	},
});

export default workspacesSlice;
