import type { Draft, EntityState } from "@reduxjs/toolkit";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import type { ConversationResponse } from "@somewear-labs/swl-web-api/src/proto/conversation_proto_pb";
import { IdentityType } from "@somewear-labs/swl-web-api/src/proto/user_proto_pb";
import _ from "lodash";
import { v4 as uuid } from "uuid";

import { PageSize } from "../../app/appModel";
import { emitWorkspacesReceived } from "../../app/appSlice";
import type { Contact } from "../../app/contacts/contactModels";
import type { RootState } from "../../app/rootReducer";
import { getConversationKey } from "../../common/utils";
import WorkspaceUtil from "../../settings/workspace/workspaceUtil";
import type {
	ConversationOrWorkspace} from "../messagingSlice";
import {
	emitMessageReceivedFromStream,
	messageActions,
} from "../messagingSlice";
import type { IConversationEntity, IDirectConversationEntity } from "./conversation.model";
import { isDirectMessageConversation, isWorkspaceConversation } from "./conversation.utils";
import { conversationActions } from "./conversationActions";

export const createContactFromConversationResponse = (
	conversationResponse: ConversationResponse.AsObject
): Contact => {
	const userId = conversationResponse.participantId;
	// let contact = state.contacts[userId];
	return {
		id: userId,
		// conversationId: conversationId,
		name: conversationResponse.participantFullName,
		email: conversationResponse.participantEmail,
		username: conversationResponse.participantUsername,
		phone: conversationResponse.participantPhone,
		identityId: conversationResponse.participantIdentityId,
		/*displayName: conversationResponse
			.conversation!.participantFullName.replaceIfEmpty(
				conversationResponse.conversation!.participantEmail
			)
			.replaceIfEmpty(conversationResponse.conversation!.participantPhone),*/
		// we can only talk to users, so assume it's a user
		type: IdentityType.USER,
	};
};

const adapter = createEntityAdapter<IConversationEntity>({
	selectId: (entity) => getConversationKey(entity),
});

export const {
	selectAll: selectAllConversations,
	selectById: selectConversationByKey,
	selectEntities: selectConversationEntities,
} = adapter.getSelectors((state: RootState) => state.conversations);

function removeOneById(state: Draft<EntityState<IConversationEntity>>, convId: string) {
	const conv = selectByConversationId(state, convId);
	if (conv !== undefined) adapter.removeOne(state, getConversationKey(conv));
}

function selectByConversationId(
	state: Draft<EntityState<IConversationEntity>>,
	convId: string
): IConversationEntity | undefined {
	return adapter
		.getSelectors()
		.selectAll(state)
		.find((conv) => conv.id === convId);
}

function selectDirectMessageById(
	state: Draft<EntityState<IConversationEntity>>,
	convId: string
): IConversationEntity | undefined {
	return adapter
		.getSelectors()
		.selectAll(state)
		.find((conv) => !isWorkspaceConversation(conv) && conv.id === convId);
}

function selectByWorkspaceId(
	state: Draft<EntityState<IConversationEntity>>,
	workspaceId: string
): IConversationEntity | undefined {
	return adapter
		.getSelectors()
		.selectById(state, getConversationKey({ workspaceId: workspaceId }));
}

function selectConversationFromInfo(
	state: Draft<EntityState<IConversationEntity>>,
	info: ConversationOrWorkspace
) {
	if (isWorkspaceConversation(info)) {
		return selectByWorkspaceId(state, info.workspaceId);
	} else if (isDirectMessageConversation(info)) {
		return selectDirectMessageById(state, info.conversationId);
	}

	console.error("no conversation id or workspace id");
}

const slice = createSlice({
	name: "conversations",
	initialState: adapter.getInitialState(),
	reducers: {},
	extraReducers: (builder) => {
		/*builder.addCase(apiConversationsSuccess, (state, action) => {
			const conversations: IConversationEntity[] = action.payload
				.map((messageResponse) => messageResponse.conversation)
				.filter((conv) => conv !== undefined) as IConversationEntity[];
			adapter.upsertMany(state, conversations);
		});*/
		builder.addCase(emitMessageReceivedFromStream, (state, action) => {
			if (Boolean(action.payload.conversationId)) {
				// add a conversation for the conversation id
				const prevConv = selectByConversationId(state, action.payload.conversationId);

				if (prevConv !== undefined) {
					const conv = _.cloneDeep(prevConv);
					conv.id = action.payload.conversationId;
					conv.participantId = action.payload.senderId;
					adapter.upsertOne(state, conv);
				} else {
					adapter.upsertOne(state, {
						id: action.payload.conversationId ?? uuid(),
						participantId: action.payload.senderId,
					} as IDirectConversationEntity);
				}
			}
		});
		builder.addCase(conversationActions.delete.request, (state, action) => {
			removeOneById(state, action.payload.data);
		});
		builder.addCase(conversationActions.delete.fulfilled, (state, action) => {
			removeOneById(state, action.payload.data.conversationId);
		});
		/*builder.addCase(apiCreateConversationSuccess, (state, action) => {
			removeOneById(state, "null");
		});*/
		/*builder.addCase(deleteContactConversation, (state, action) => {
			removeOneById(state, action.payload);
		});*/
		builder.addCase(emitWorkspacesReceived, (state, action) => {
			const conversations = action.payload
				.filter((workspace) => WorkspaceUtil.isTeam(workspace))
				.map((workspace) => {
					return {
						id: workspace.id,
						participantId: "",
						participantFullName: "",
						participantPhone: "",
						participantEmail: "",
						workspaceId: workspace.id,
						isWorkspace: true,
					};
				});

			adapter.upsertMany(state, conversations);
		});
		builder.addCase(messageActions.get.fulfilled, (state: any, action) => {
			const conversation = selectConversationFromInfo(
				state,
				action.payload.data.conversationInfo
			);

			if (conversation !== undefined) {
				const conv = _.cloneDeep(conversation);
				conv.loaded = action.payload.data.messages.length < PageSize;
				adapter.upsertOne(state, conv);
			}
		});
		builder.addCase(messageActions.get.rejected, (state: any, action) => {
			const conversationInfo = action.payload.request.data.conversationInfo;
			const conversation = selectConversationFromInfo(state, conversationInfo);
			if (conversation === undefined) return;
			const conv = _.cloneDeep(conversation);
			conv.loaded = true;
			adapter.upsertOne(state, conv);
		});
		builder.addCase(conversationActions.fetch.fulfilled, (state, action) => {
			adapter.upsertMany(
				state,
				action.payload.data.conversationsList.mapNotNull((record) => {
					if (record.conversation === undefined) return undefined;
					return record.conversation;
				})
			);
		});
		builder.addCase(conversationActions.create.request, (state, action) => {
			if (action.payload.data.targetUserId.isNotEmpty()) {
				adapter.upsertOne(state, {
					id: "tmp",
				} as IDirectConversationEntity);
			}
		});
		builder.addCase(conversationActions.create.fulfilled, (state, action) => {
			adapter.removeOne(state, "c_null");
			const conversation = action.payload.data.conversation;
			if (conversation !== undefined) {
				adapter.upsertOne(state, conversation);
			}
		});
	},
});

export default slice;
