import type { Action } from "redux";
import { combineReducers } from "redux";

import errorReducer from "../common/errorReducer";
import loadingReducer from "../common/loadingReducer";
import { Sentry } from "../common/SentryUtil";
import mapLayersSlice from "../mapLayers/mapLayersSlice";
import conversationsSlice from "../messaging/conversations/conversationsSlice";
import filesSlice from "../messaging/files/files.slice";
import messagesSlice from "../messaging/messages/messagesSlice";
import messagesReducer from "../messaging/messagingSlice";
import queuedDeviceActivationChangeSlice from "../settings/device/queuedDeviceActivationChangeSlice";
import identitiesSlice from "../settings/identity/identitiesSlice";
import organizationsSlice from "../settings/organization/organizationsSlice";
import settingsReducer from "../settings/settingsSlice";
import deviceTransfersSlice from "../settings/workspace/deviceTransfersSlice";
import queuedDeviceNameChangesSlice from "../settings/workspace/queuedDeviceNameChangesSlice";
import workspaceDeviceSlice from "../settings/workspace/workspaceDevicesSlice";
import workspaceKeysSlice from "../settings/workspace/workspaceKeysSlice";
import workspacesSlice from "../settings/workspace/workspacesSlice";
import locationSlice from "../tracking/locations/location.slice";
import clusteringSlice from "../tracking/map/clustering/store/clustering.slice";
import trackingRoutesSlice from "../tracking/routes/trackingRoutesSlice";
import shapeSlice from "../tracking/shapes/shapeSlice";
import trackingReducer from "../tracking/trackingSlice";
import appSlice, { resetState, setGlobalError } from "./appSlice";
import workspaceAssetsSlice from "./assets/workspaceAssetsSlice";
import biometricsSlice from "./biometrics/biometricsSlice";
import contactsSlice from "./contacts/contactsSlice";
import devicesSlice from "./devices/devicesSlice";
import featuresSlice from "./features/store/features.slice";
import notificationsSlice from "./notifications/notificationsSlice";
import redirectReducer from "./redirectSlice";
import sosSlice from "./sos/sosSlice";
import subscriptionsSlice from "./subscriptions/subscriptionsSlice";
import waypointsSlice from "./waypoints/waypointsSlice";

const reducers = {
	app: appSlice.reducer,
	redirect: redirectReducer,
	loading: loadingReducer,
	error: errorReducer,
	tracking: trackingReducer,
	messaging: messagesReducer,
	settings: settingsReducer,
	mapLayers: mapLayersSlice.reducer,
	messages: messagesSlice.reducer,
	files: filesSlice.reducer,
	conversations: conversationsSlice.reducer,
	workspaceUserChanges: queuedDeviceNameChangesSlice.reducer,
	workspaceAssets: workspaceAssetsSlice.reducer,
	workspaceDevices: workspaceDeviceSlice.reducer,
	notifications: notificationsSlice.reducer,
	features: featuresSlice.reducer,
	deviceTransfers: deviceTransfersSlice.reducer,
	contacts: contactsSlice.reducer,
	subscriptions: subscriptionsSlice.reducer,
	workspaceKeys: workspaceKeysSlice.reducer,
	workspaces: workspacesSlice.reducer,
	organizations: organizationsSlice.reducer,
	identities: identitiesSlice.reducer,
	trackingRoutes: trackingRoutesSlice.reducer,
	clustering: clusteringSlice.reducer,
	shapes: shapeSlice.reducer,
	sosEvents: sosSlice.reducer,
	biometrics: biometricsSlice.reducer,
	waypoints: waypointsSlice.reducer,
	devices: devicesSlice.reducer,
	queuedDeviceActivationChanges: queuedDeviceActivationChangeSlice.reducer,
	locations: locationSlice.reducer,
};

const mainReducer = combineReducers(reducers);

/*
Define the RootState that can be accessed throughout the app
 */
export type RootState = ReturnType<typeof mainReducer>;

const rootReducer = (state: any, action: Action<string>): RootState => {
	if (action.type === resetState.type) {
		state = { redirect: state.redirect };
	}
	try {
		return mainReducer(state, action);
	} catch (e) {
		console.log(e);
		Sentry.captureException(e);
		return mainReducer(state, setGlobalError);
	}
};

export default rootReducer;
