import type { PayloadAction } from "@reduxjs/toolkit";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import type { SosEventResponse } from "@somewear-labs/swl-web-api/src/proto/sos_message_proto_pb";

import type { IError } from "../../common/errorReducer";
import type { RootState } from "../rootReducer";

const adapter = createEntityAdapter<SosEventResponse.AsObject>({
	selectId: (entity) => entity.sessionId,
});

// Rename the exports for readability in component usage
export const {
	selectAll: selectAllSosEvents,
	selectById: selectSosEventById,
	selectIds: selectAllSosEventSessionIds,
} = adapter.getSelectors((state: RootState) => state.sosEvents);

const sosSlice = createSlice({
	name: "sos",
	initialState: adapter.getInitialState(),
	reducers: {
		apiSosEventsRequest() {},
		apiSosEventsError(state, action: PayloadAction<IError>) {},
		apiSosEventsSuccess(state, action: PayloadAction<SosEventResponse.AsObject[]>) {
			action.payload.forEach((event) => {
				const prevEvent = adapter.getSelectors().selectById(state, event.sessionId);
				if (prevEvent?.timestamp !== undefined) {
					if (event.timestamp!.seconds > prevEvent.timestamp.seconds) {
						adapter.upsertOne(state, event);
					}
				} else {
					adapter.upsertOne(state, event);
				}
			});
		},
	},
	extraReducers: (builder) => {},
});

export const { apiSosEventsRequest, apiSosEventsError, apiSosEventsSuccess } = sosSlice.actions;

export default sosSlice;
