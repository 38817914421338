import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import type { DeviceRecord } from "@somewear-labs/swl-web-api/src/proto/device_record_proto_pb";

import type { RootState } from "../../app/rootReducer";
import { apiDeviceRecordUpdate } from "../settingsSlice";
import { workspaceActions } from "./workspaceActions";

export interface IWorkspaceDevice {
	serial: string;
	userAccountId: string;
	model: DeviceRecord.ModelMap[keyof DeviceRecord.ModelMap];
}

const adapter = createEntityAdapter<IWorkspaceDevice>({
	selectId: (device) => device.serial,
});

const workspaceDevicesSlice = createSlice({
	name: "workspaceDevices",
	initialState: adapter.getInitialState(),
	reducers: {
		_() {}, // every slice needs at least one reducer of it's own
	},
	extraReducers: (builder) => {
		builder.addCase(workspaceActions.fetchDevices.fulfilled, (state, action) => {
			adapter.upsertMany(state, action.payload.data.devicesList);
		});
		builder.addCase(apiDeviceRecordUpdate, (state, action) => {
			// if the device belongs to the active workspace add it to the workspace device list
			if (action.payload.asset !== undefined) adapter.upsertOne(state, action.payload.record);
		});
	},
});

// Rename the exports for readability in component usage
export const { selectAll: selectAllWorkspaceDevices, selectById: selectWorkspaceDeviceBySerial } =
	adapter.getSelectors((state: RootState) => state.workspaceDevices);

export default workspaceDevicesSlice;
