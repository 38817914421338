import type {
	GetOrganizationIntegrationsResponse,
	GetOrganizationMembersResponse,
	InviteOrganizationMembersResponse,
	RemoveOrganizationMembersResponse,
	UpdateOrganizationRoleRequest,
	UpdateOrganizationRoleResponse,
} from "@somewear-labs/swl-web-api/src/proto/api/organization_pb";
import type { IdentityRecord } from "@somewear-labs/swl-web-api/src/proto/identity_record_proto_pb";
import type { OrganizationMemberResponse } from "@somewear-labs/swl-web-api/src/proto/organization_membership_proto_pb";

import { createActionSet } from "../../../common/EpicUtils";
import type { IOrganizationAsset } from "../organizationsSlice";

export const organizationMemberActions = {
	assignRole: createActionSet<
		UpdateOrganizationRoleRequest.AsObject,
		UpdateOrganizationRoleResponse.AsObject
	>("organization/members/role/update"),
	addMembers: createActionSet<
		IdentityRecord.AsObject[],
		InviteOrganizationMembersResponse.AsObject
	>("organization/members/add"),
	getMembers: createActionSet<void, GetOrganizationMembersResponse.AsObject>(
		"organization/members/get"
	),
	getIntegrations: createActionSet<void, GetOrganizationIntegrationsResponse.AsObject>(
		"organization/integrations/get"
	),
	removeMembers: createActionSet<
		IOrganizationAsset[],
		RemoveOrganizationMembersResponse.AsObject
	>("organization/members/remove"),
	membershipChanged: createActionSet<void, OrganizationMemberResponse.AsObject>(
		"organization/members/changed"
	),
};
