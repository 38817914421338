import type { Dictionary } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

import type { RootState } from "../../rootReducer";
import type { IFeature } from "../features.model";
import { getFeatureIdFromKey, isFeatureEnabled } from "../features.utils";
import { featuresCoreAdapter, featuresOverridesAdapter } from "./features.adapter";

const { selectEntities: selectCoreFeatureEntities } = featuresCoreAdapter.getSelectors(
	(state: RootState) => state.features.core
);
const { selectEntities: selectFeatureOverrideEntities } = featuresOverridesAdapter.getSelectors(
	(state: RootState) => state.features.overrides
);

export const selectFeatureEntities = createSelector(
	[selectCoreFeatureEntities, selectFeatureOverrideEntities],
	(core, overrides) => {
		const additionalOverrides = { ...overrides };

		const features = Object.keys(core).reduce((acc, key) => {
			const override = overrides[key];

			if (override) {
				acc[key] = override;
				delete additionalOverrides[key];
			} else {
				acc[key] = core[key];
			}

			return acc;
		}, {} as Dictionary<IFeature>);

		return { ...features, ...additionalOverrides };
	}
);

export const selectAllFeatures = createSelector([selectFeatureEntities], (entities) => {
	return Object.values(entities).mapNotNull((it) => it);
});

export const selectFeatureByKey = createSelector(
	[
		selectFeatureEntities,
		(state: RootState, featureKey: string) => getFeatureIdFromKey(featureKey),
	],
	(entities, featureId) => {
		return entities[featureId];
	}
);

export const selectIsFeatureOn = createSelector(
	(state: RootState, featureKey: string) => selectFeatureByKey(state, featureKey.toUpperCase()),
	(feature: IFeature | undefined) => {
		return isFeatureEnabled(feature);
	}
);
