import { DeviceRecord } from "@somewear-labs/swl-web-api/src/proto/device_record_proto_pb";

import type { IDevice } from "./devicesSlice";

export const getDeviceModelName = (device: IDevice | undefined) => {
	let modelName = "Other";
	switch (device?.model) {
		case DeviceRecord.Model.SWL1: {
			modelName = "Hotspot";
			break;
		}
		case DeviceRecord.Model.SHOUTNANO: {
			modelName = "Shout";
			break;
		}
		case DeviceRecord.Model.NODE: {
			modelName = "Node";
			break;
		}
	}
	return modelName;
};
