import type { Dictionary, PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { FeatureInfo } from "@somewear-labs/swl-web-api/src/proto/feature_proto_pb";

import { someUtils } from "../../../common/utils";
import { appActions } from "../../appActions";
import { featuresLocalStorageKey } from "../features.config";
import type { IFeature } from "../features.model";
import { getFeatureConfig, getFeatureIdFromKey } from "../features.utils";
import {
	featuresOverridesAdapter,
	getFeatureOverridesFromLocalStorage,
	getInitialCoreFeatures,
	switchFeatures,
} from "./features.adapter";

type FeatureName = keyof FeatureInfo.FeatureNameMap;
type FeatureValue = FeatureInfo.FeatureNameMap[FeatureName];

const featureValueToNameDict = Object.keys(FeatureInfo.FeatureName).reduce((acc, name) => {
	const featureName = name as FeatureName;
	const value = FeatureInfo.FeatureName[featureName] as FeatureValue;
	acc[value] = featureName;
	return acc;
}, {} as Dictionary<FeatureName>);

const featuresSlice = createSlice({
	name: "features",
	initialState: {
		core: getInitialCoreFeatures(),
		overrides: getFeatureOverridesFromLocalStorage(),
	},
	reducers: {
		emitFeatureUpdates: (state, action: PayloadAction<IFeature[]>) => {
			switchFeatures(state.core, action.payload);
		},
		resetFeatureOverrides: (state) => {
			featuresOverridesAdapter.removeAll(state.overrides);
			localStorage.setItem(featuresLocalStorageKey, "[]");
		},
		removeFeatureOverride: (state, action: PayloadAction<{ key: string }>) => {
			const id = getFeatureIdFromKey(action.payload.key);
			featuresOverridesAdapter.removeOne(state.overrides, id);
			localStorage.setItem(featuresLocalStorageKey, JSON.stringify(state.overrides.entities));
		},
		toggleFeatureOverride: (state, action: PayloadAction<{ key: string }>) => {
			const { key } = action.payload;
			const id = getFeatureIdFromKey(key);
			const override = state.overrides.entities[id];
			const coreFeature = state.core.entities[id];
			let wasCreatedLocally = override?.wasCreatedLocally;

			if (someUtils.isUndefined(override) && someUtils.isUndefined(coreFeature)) {
				wasCreatedLocally = true;
			}

			const enabled = !(override?.enabled ?? coreFeature?.enabled);

			// If the override value is the same as the core feature, remove it
			if (coreFeature && enabled === coreFeature.enabled) {
				featuresOverridesAdapter.removeOne(state.overrides, id);
			} else {
				featuresOverridesAdapter.upsertOne(state.overrides, {
					...(override ?? getFeatureConfig(key, false)),
					enabled,
					isOverride: true,
					wasCreatedLocally,
				});
			}

			localStorage.setItem(featuresLocalStorageKey, JSON.stringify(state.overrides.entities));
		},
	},
	extraReducers: (builder) => {
		builder.addCase(appActions.fetchFeatures.fulfilled, (state, action) => {
			const features: IFeature[] = action.payload.data.featuresList.map((it) => {
				const name =
					(it.name > 0 ? featureValueToNameDict[it.name] : undefined) ?? it.nameText;
				return {
					key: name,
					name: it.displayName.isNotEmpty() ? it.displayName : name,
					enabled: it.treatmentboolean,
				} as IFeature;
			});
			switchFeatures(state.core, features);
		});
		builder.addCase(appActions.fetchCapabilities.fulfilled, (state, action) => {
			const features: IFeature[] = action.payload.data.capabilitiesList.mapNotNull((it) => {
				if (it.capability?.name === undefined) return undefined;
				return {
					...it.capability,
					key: it.capability.name,
					name: it.capability.displayName.isNotEmpty()
						? it.capability.displayName
						: it.capability.name,
					enabled: true,
				};
			});
			switchFeatures(state.core, features);
		});
	},
});

export const {
	emitFeatureUpdates,
	toggleFeatureOverride,
	resetFeatureOverrides,
	removeFeatureOverride,
} = featuresSlice.actions;

export default featuresSlice;
