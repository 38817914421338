import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

interface RedirectState {
	path: string;
}

const initialState: RedirectState = {
	path: "/",
};

const redirectSlice = createSlice({
	name: "redirect",
	initialState,
	reducers: {
		setRedirectPath(state, action: PayloadAction<string>) {
			state.path = action.payload;
		},
	},
});

export const { setRedirectPath } = redirectSlice.actions;

export default redirectSlice.reducer;
