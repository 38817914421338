import type { Dictionary, EntityState } from "@reduxjs/toolkit";
import type { IdentityTypeMap } from "@somewear-labs/swl-web-api/src/proto/user_proto_pb";
import { IdentityType } from "@somewear-labs/swl-web-api/src/proto/user_proto_pb";
import { createSelector } from "reselect";

import { SWL_COLOR_BLUE } from "@/settings/styles/colors.config";

import { selectIdentityEntities } from "../../settings/identity/identitiesSlice";
import {
	getColorForAsset,
	getColorForWorkspace,
	getEntityColor,
} from "../../settings/styles/colors.utils";
import {
	selectWorkspaceById,
	selectWorkspaceEntities,
} from "../../settings/workspace/workspacesSlice";
import { selectActiveIdentityId, selectEcho } from "../appSelectors";
import type { IDevice } from "../devices/devicesSlice";
import { selectAllDevices } from "../devices/devicesSlice";
import type { RootState } from "../rootReducer";
import type { IContactForDisplay, IContactForDisplayWithWorkspace } from "./contactModels";
import {
	getDisplayNameForContact,
	selectAllContacts,
	selectContactById,
	selectContactEntities,
} from "./contactsSlice";

export const selectIsUseWorkspaceColor = (state: RootState) => {
	return state.tracking.settings?.useWorkspaceColor ?? true;
};

export const selectUnarchivedContacts = createSelector(
	[selectAllContacts, selectIdentityEntities, selectActiveIdentityId],
	(contacts, identityDict, activeIdentityId) => {
		return contacts.mapNotNull((contact) => {
			const identity =
				contact.identityId === undefined ? undefined : identityDict[contact.identityId];
			return {
				...contact,
				color: getColorForAsset(contact, identity),
				displayName: getDisplayNameForContact(
					contact,
					contact.identityId === activeIdentityId
				),
			} as IContactForDisplay;
		});
	}
);

export const selectUnarchivedContactEntities = createSelector(
	[selectContactEntities, selectActiveIdentityId],
	(contacts, activeIdentityId) => {
		const unarchivedContacts: Dictionary<IContactForDisplay> = {};
		Object.values(contacts).forEach((contact) => {
			if (!contact?.isArchived && contact?.id !== undefined) {
				unarchivedContacts[contact.id] = {
					...contact,
					displayName: getDisplayNameForContact(
						contact,
						contact.identityId === activeIdentityId
					),
				} as IContactForDisplay;
			}
		});
		return unarchivedContacts;
	}
);

export const selectUnarchivedContactById = createSelector(
	[selectUnarchivedContactEntities, selectEcho<string>],
	(entities, id) => {
		return entities[id];
	}
);

export const selectAllPublicTrackContacts = createSelector(
	[selectUnarchivedContacts],
	(contacts) => {
		return contacts.filter((contact) => {
			return !Boolean(contact.email) && !Boolean(contact.phone);
		});
	}
);

export const selectAllMemberContacts = createSelector([selectUnarchivedContacts], (accounts) => {
	return accounts.filter((account) => account.type === IdentityType.USER);
});

export const workspaceContactsSelector = createSelector([selectUnarchivedContacts], (contacts) => {
	return contacts
		.filter((contact) => contact.id !== "newUser")
		.filter((contact) => contact.workspace)
		.sort((a, b) => {
			const aName = a.displayName;
			const bName = b.displayName;
			if (aName < bName) return -1;
			if (aName > bName) return 1;
			return 0;
		});
});

export const selectPersonalContacts = createSelector([selectUnarchivedContacts], (contacts) => {
	return contacts.filter((contact) => !contact.workspace);
});

export const selectWorkspaceMemberContacts = createSelector(
	[workspaceContactsSelector],
	(contacts) => {
		return contacts
			.filter((contact) => !contact.isArchived)
			.filter((contact) => contact.displayName.length > 0)
			.filter((contact) => contact.type === IdentityType.USER);
	}
);

export const selectAllUserContacts = createSelector(
	[selectPersonalContacts, selectWorkspaceMemberContacts],
	(personalContacts, workspaceContacts) => {
		return personalContacts.concat(...workspaceContacts);
	}
);

export const selectWorkspaceMemberContactCount = createSelector(
	[selectWorkspaceMemberContacts],
	(contacts) => {
		return contacts.length;
	}
);

export const selectWorkspaceMemberContactCountForWorkspaceId = createSelector(
	[selectWorkspaceMemberContacts, selectWorkspaceById],
	(contacts, workspace) => {
		if (workspace === undefined) return 0;
		return contacts.filter((contact) => contact.workspaceId === workspace.id).length;
	}
);

export const selectContactDeviceMap = createSelector(
	[selectAllContacts, selectAllDevices],
	(assets, devices) => {
		const assetDeviceMap: Dictionary<IDevice> = {};
		assets.forEach((asset) => {
			const device = devices.find((device) => device.userAccountId === asset.id);
			assetDeviceMap[asset.id] = device;
		});
		return assetDeviceMap;
	}
);

export const selectContactDevice = createSelector(
	[selectContactDeviceMap, selectContactById],
	(deviceDict, contact) => {
		if (contact?.id === undefined) return undefined;
		return deviceDict[contact.id];
	}
);

export const selectContactHasDeviceMap = createSelector(
	[selectAllContacts, selectAllDevices],
	(assets, devices) => {
		const assetHasDeviceMap: Dictionary<boolean> = {};
		assets.forEach((asset) => {
			const device = devices.find((device) => device.userAccountId === asset.id);
			assetHasDeviceMap[asset.id] = device !== undefined;
		});
		return assetHasDeviceMap;
	}
);

export const selectContactTypeMap = createSelector([selectAllContacts], (assets) => {
	const assetTypeMap: Dictionary<IdentityTypeMap[keyof IdentityTypeMap]> = {};
	assets.forEach((asset) => {
		assetTypeMap[asset.id] = asset.type;
	});
	return assetTypeMap;
});

export const selectContactWorkspaceColor = createSelector(
	[selectAllContacts, selectIdentityEntities, selectWorkspaceEntities],
	(contacts, identityDict, workspaceDict) => {
		const results: Dictionary<string> = {};
		if (Object.keys(workspaceDict).length > 1) {
			contacts.forEach((contact) => {
				const identity =
					contact.identityId !== undefined ? identityDict[contact.identityId] : undefined;
				const standardIdentityColor = identity?.styleSettings?.standardColor;

				const identityColor = getEntityColor(contact.name, standardIdentityColor);

				const workspace =
					contact.workspaceId !== undefined
						? workspaceDict[contact.workspaceId]
						: undefined;

				const standardWorkspaceColor = workspace?.styleSettings?.standardColor;

				const workspaceColor = getEntityColor(workspace?.name, standardWorkspaceColor);

				if (workspace !== undefined) {
					return workspaceColor;
				} else {
					return identityColor;
				}
			});
		} else {
			contacts.forEach((contact) => {
				results[contact.id] = SWL_COLOR_BLUE;
			});
		}
		return results;
	}
);

export const selectAllContactsWithWorkspace = createSelector(
	[selectUnarchivedContacts, selectWorkspaceEntities, selectIsUseWorkspaceColor],
	(contacts, workspaces, useWorkspaceColor) => {
		return contacts.mapNotNull((contact) => {
			if (contact.isArchived) return undefined;
			const workspace =
				contact.workspaceId !== undefined ? workspaces[contact.workspaceId] : undefined;
			const workspaceName = workspace !== undefined ? workspace.name : "Personal";
			if (workspace === undefined && contact.identityId === undefined) return undefined; // the user likely doesn't have access to this workspace anymore, this also occurs when the contact is public

			const workspaceColor =
				workspace !== undefined ? getColorForWorkspace(workspace) : SWL_COLOR_BLUE;

			return {
				...contact,
				workspaceColor: workspaceColor,
				workspaceName: workspaceName,
			} as IContactForDisplayWithWorkspace;
		});
	}
);

export const selectAllUsersWithWorkspace = createSelector(
	[selectAllContactsWithWorkspace],
	(contacts) => {
		return contacts.filter((asset) => asset.type === IdentityType.USER);
	}
);

export const selectAllWorkspaceUsers = createSelector([selectAllUsersWithWorkspace], (users) =>
	users.filter((user) => user.workspace)
);

export const selectAssetCountsByWorkspaceId = createSelector([selectAllContacts], (assets) => {
	const assetCounts: Dictionary<number> = {};
	assets.forEach((asset) => {
		if (asset.workspaceId !== undefined) {
			if (assetCounts[asset.workspaceId] === undefined) assetCounts[asset.workspaceId] = 0;
			assetCounts[asset.workspaceId] = assetCounts[asset.workspaceId]! + 1;
		}
	});
	return assetCounts;
});

export const selectAllContactsWithWorkspaceAsEntityState = createSelector(
	[selectAllContactsWithWorkspace],
	(contacts) => {
		const entities: Dictionary<IContactForDisplay> = {};
		contacts.forEach((contact) => {
			entities[contact.id] = contact;
		});
		return {
			ids: Object.keys(entities),
			entities: entities,
		} as EntityState<IContactForDisplayWithWorkspace>;
	}
);

export const selectSelfAccounts = createSelector(
	[selectAllContacts, selectActiveIdentityId],
	(contacts, identityId) => {
		return contacts.filter((contact) => contact.identityId === identityId);
	}
);

export const selectAllSelfAccountIds = createSelector([selectSelfAccounts], (contacts) => {
	return contacts.mapNotNull((contact) => contact.id);
});

export const selectJoinedWorkspaceIds = createSelector([selectSelfAccounts], (accounts) => {
	return accounts.mapNotNull((account) => account.workspaceId);
});
