import { createSelector } from "reselect";

import { selectActiveOrganizationId , selectActiveWorkspaceId } from "../../app/appSelectors";
import { selectAdminAccountsForActiveIdentity } from "../../app/assets/workspaceAssetSelectors";
import type { WorkspacePickerOption } from "../../components/WorkspacePickerInput";
import { SWL_COLOR_BLUE, SWL_COLOR_GREY_INACTIVE } from "../styles/colors.config";
import { getColorForWorkspace } from "../styles/colors.utils";
import type { IWorkspace} from "./workspacesSlice";
import { selectAllWorkspaces, selectWorkspaceEntities } from "./workspacesSlice";
import WorkspaceUtil from "./workspaceUtil";

export const selectJoinedWorkspaces = createSelector([selectAllWorkspaces], (workspaces) => {
	return workspaces.filter((workspace) => workspace.userIsMember);
});

export const selectTeamWorkspaces = createSelector([selectAllWorkspaces], (workspaces) => {
	return workspaces.filter((workspace) => WorkspaceUtil.isTeam(workspace));
});

export const selectJoinedTeamWorkspaces = createSelector([selectJoinedWorkspaces], (workspaces) => {
	return workspaces.filter((workspace) => WorkspaceUtil.isTeam(workspace));
});

export const selectHasTeamWorkspaces = createSelector(
	[selectJoinedTeamWorkspaces],
	(workspaces) => {
		return workspaces.length > 0;
	}
);

export const selectHasMultipleWorkspaces = createSelector([selectAllWorkspaces], (workspaces) => {
	return workspaces.length > 1;
});

export const selectHasPersonalWorkspace = createSelector([selectAllWorkspaces], (workspaces) => {
	return workspaces.find((w) => WorkspaceUtil.isPersonal(w)) !== undefined;
});

export const selectWorkspacesActiveIdentityAdministers = createSelector(
	[selectWorkspaceEntities, selectAdminAccountsForActiveIdentity],
	(workspaceDict, assets) => {
		if (assets === undefined) return [];
		return assets.mapNotNull((it) => workspaceDict[it.workspaceId]);
	}
);

export const selectAllWorkspacePickerOptions = createSelector(
	[selectAllWorkspaces, selectHasPersonalWorkspace],
	(workspaces, hasPersonalWorkspace) => {
		if (hasPersonalWorkspace && workspaces.length <= 1) return [];

		const sortedWorkspaces = sortWorkspaces(workspaces);

		const workspaceOptions: WorkspacePickerOption[] = sortedWorkspaces.map((w) => {
			if (WorkspaceUtil.isPersonal(w)) {
				return { id: "-1", name: "Personal", color: SWL_COLOR_BLUE };
			}
			return {
				id: w.id,
				name: w.name,
				color: getColorForWorkspace(w),
				organizationId: w.organizationId,
			};
		});
		return workspaceOptions;
	}
);

/**
 * Returns empty list when no workspace picker options should be shown.
 */
export const selectWorkspacePickerOptions = createSelector(
	[selectJoinedWorkspaces, selectHasPersonalWorkspace],
	(workspaces, hasPersonalWorkspace) => {
		if (hasPersonalWorkspace && workspaces.length <= 1) return [];
		const sortedWorkspaces = sortWorkspaces(workspaces);

		const workspaceOptions: WorkspacePickerOption[] = sortedWorkspaces.map((w) => {
			if (WorkspaceUtil.isPersonal(w)) {
				return { id: "-1", name: "Personal", workspace: w, color: SWL_COLOR_BLUE };
			}
			return { id: w.id, name: w.name, color: getColorForWorkspace(w) };
		});
		return workspaceOptions;
	}
);

export const selectWorkspacePickerOptionsWithAll = createSelector(
	[selectWorkspacePickerOptions],
	(workspacePickerOptions) => {
		const allOption: WorkspacePickerOption = {
			id: "_all",
			name: "All",
			color: "#000",
		};
		return [allOption].concat(...workspacePickerOptions);
	}
);

export const selectJoinedTeamWorkspacePickerOptions = createSelector(
	[selectWorkspacePickerOptions],
	(workspacePickerOptions) => {
		const options: WorkspacePickerOption[] = workspacePickerOptions.filter(
			(option) => option.id !== "-1"
		);
		return options;
	}
);

const selectAllTeamWorkspacePickerOptions = createSelector(
	[selectAllWorkspacePickerOptions],
	(workspacePickerOptions) => {
		const options: WorkspacePickerOption[] = workspacePickerOptions.filter(
			(option) => option.id !== "-1"
		);
		return options;
	}
);

const selectOrganizationWorkspacePickerOptions = createSelector(
	[selectAllTeamWorkspacePickerOptions, selectActiveOrganizationId],
	(workspacePickerOptions, activeOrganizationId) => {
		if (activeOrganizationId === undefined) return [];
		return workspacePickerOptions.filter((it) => it.organizationId === activeOrganizationId);
	}
);

const noneWorkspaceOption: WorkspacePickerOption = {
	id: "-1",
	name: "None",
	color: "#000000",
};

export const selectOrganizationWorkspacePickerOptionsWithNone = createSelector(
	[selectOrganizationWorkspacePickerOptions],
	(workspacePickerOptions) => {
		return [noneWorkspaceOption].concat(...workspacePickerOptions);
	}
);

export const selectTeamWorkspacePickerOptionsWithNone = createSelector(
	[selectAllTeamWorkspacePickerOptions],
	(workspacePickerOptions) => {
		return [noneWorkspaceOption].concat(...workspacePickerOptions);
	}
);

export const selectTeamWorkspacePickerOptionsWithNoneAndUnknown = createSelector(
	[selectTeamWorkspacePickerOptionsWithNone],
	(workspacePickerOptions) => {
		const unknownOption: WorkspacePickerOption = {
			id: "-2",
			name: "Unknown",
			color: SWL_COLOR_GREY_INACTIVE,
		};
		return [unknownOption].concat(...workspacePickerOptions);
	}
);

export const selectedWorkspacePickerOption = createSelector(
	[selectWorkspacePickerOptions, selectActiveWorkspaceId],
	(options, active) => {
		if (options.isEmpty()) return undefined;
		const activeWorkspaceId = active?.isNotEmpty() ? active : "0"; // we're relying on personal's workspace id to be 0
		return options.find((o) => o.id === activeWorkspaceId);
	}
);

export const selectAdministeredWorkspacePickerOptions = createSelector(
	[selectWorkspacePickerOptions, selectWorkspacesActiveIdentityAdministers],
	(workspacePickerOptions, adminWorkspaces) => {
		const adminWorkspaceIds = adminWorkspaces.mapNotNull((it) => it.id);
		return workspacePickerOptions.filter((it) => adminWorkspaceIds.includes(it.id));
	}
);

export const selectActiveWorkspace = createSelector(
	[selectActiveWorkspaceId, selectAllWorkspaces],
	(id, workspaces) => {
		return workspaces.find((workspace) => workspace.id === id);
	}
);

export const selectActiveWorkspaceName = createSelector([selectActiveWorkspace], (workspace) => {
	return workspace?.name;
});

export const selectIsActiveWorkspacePersonal = createSelector(
	[selectActiveWorkspace],
	(workspace) => {
		return WorkspaceUtil.isPersonal(workspace);
	}
);

export const selectActiveOrganizationWorkspaces = createSelector(
	[selectTeamWorkspaces, selectActiveOrganizationId],
	(workspaces, orgId) => {
		// If the active org is the personal org, return all workspaces the user is a member of
		if (orgId === "-1") return workspaces.filter((workspace) => workspace.userIsMember);
		return workspaces.filter((workspace) => workspace.organizationId === orgId);
	}
);

export const selectNonActiveOrganizationWorkspaces = createSelector(
	[selectTeamWorkspaces, selectActiveOrganizationId],
	(workspaces, orgId) => {
		return workspaces.filter((workspace) => workspace.organizationId !== orgId);
	}
);

/*
export const selectAssetDeviceMap = createSelector(
	[selectAllWorkspaceAssets, selectAllWorkspaceDevices],
	(assets, devices) => {
		const assetDeviceMap: Record<string, IWorkspaceDevice | undefined> = {};
		assets.forEach((asset) => {
			const device = devices.find((device) => device.userAccountId === asset.id);
			assetDeviceMap[asset.id] = device;
		});
		return assetDeviceMap;
	}
);
*/

export const selectIsActiveWorkspaceInActiveOrganization = createSelector(
	[selectActiveWorkspace, selectActiveOrganizationId],
	(workspace, orgId) => {
		return workspace !== undefined && workspace.organizationId === orgId;
	}
);

function sortWorkspaces(workspaces: IWorkspace[]) {
	return [...workspaces].sort((a, b) => {
		const aIsPersonal = WorkspaceUtil.isPersonal(a);
		const bIsPersonal = WorkspaceUtil.isPersonal(b);
		if (aIsPersonal) {
			return -1;
		} else if (bIsPersonal) {
			return 1;
		}
		const aName = a.name;
		const bName = b.name;
		if (aName < bName) return -1;
		if (aName > bName) return 1;
		return 0;
	});
}
