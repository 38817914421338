import type { PayloadAction} from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { LngLatBounds } from "mapbox-gl";

const clusteringSlice = createSlice({
	name: "clustering",
	initialState: {
		superclusterBounds: new LngLatBounds(),
		superclusterZoom: 0,
	},
	reducers: {
		setSuperclusterBounds(state, action: PayloadAction<LngLatBounds>) {
			state.superclusterBounds = action.payload;
		},
		setSuperclusterZoom(state, action: PayloadAction<number>) {
			state.superclusterZoom = Math.floor(action.payload);
		},
	},
});

export const { setSuperclusterBounds, setSuperclusterZoom } = clusteringSlice.actions;

export default clusteringSlice;
