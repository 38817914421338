import type { LocationResponse } from "@somewear-labs/swl-web-api/src/proto/location_proto_pb";
import { createSelector } from "reselect";

import { selectWorkspaceAssetById } from "@/app/assets/workspaceAssetsSlice";

import type { Dict } from "../app/appModel";
import type { IContactForDisplayWithWorkspace } from "../app/contacts/contactModels";
import { selectUnarchivedContacts } from "../app/contacts/contactSelectors";
import { selectContactById } from "../app/contacts/contactsSlice";
import type { RootState } from "../app/rootReducer";
import { selectSortedMapLayers } from "../mapLayers/mapLayersSlice";
import type { IIdentity } from "../settings/identity/identitiesSlice";
import { getNextMapStyle } from "./map/controls/utils/getNextMapStyle";
import type { AssetState, Timer } from "./trackingSlice";
import {
	MapStyle,
	TRACKING_FILTER_DEFAULTS,
	TRACKING_FILTER_SHOW_ALL,
	WaypointEcosystem,
} from "./trackingSlice";

/*export interface SortedLocations {
	locations: LocationResponse.AsObject[];
	locationsById: Dict<LocationResponse.AsObject>;
	loaded: boolean;
}*/

export interface MostRecentLocation {
	location: LocationResponse.AsObject;
	contact: IContactForDisplayWithWorkspace;
	assetState: AssetState;
	identity: IIdentity;
}

export interface UserStates {
	states: Dict<AssetState>;
	timer: Timer;
}

const selectTrackingState = (state: RootState) => state.tracking;

export const selectShowMarkerIcons = (state: RootState) => state.tracking.showMarkerIcons;
export const selectShowMarkerNames = (state: RootState) => state.tracking.showMarkerNames;

export const selectShowGlobalDateFilter = (state: RootState) => {
	return state.tracking.showGlobalDateFilter;
};

export const selectShowWorkspacePicker = (state: RootState) => {
	return state.tracking.showWorkspacePicker;
};

export const selectFocusedTrackingUserId = (state: RootState) => state.tracking.focusedUserId;

export const selectActiveTrackingUserId = (state: RootState) =>
	state.tracking.activeTrackingUser?.userId;

export const selectIsFocusedTrackingUserForId = createSelector(
	[selectFocusedTrackingUserId, selectContactById],
	(userId, contact) => {
		return userId === contact?.id;
	}
);

export const selectIsActiveTrackingUserForId = createSelector(
	[selectActiveTrackingUserId, selectContactById],
	(userId, contact) => {
		return userId === contact?.id;
	}
);

export const selectMapView = (state: RootState) => state.tracking.mapView;

export const selectTrackingClusterModels = (state: RootState) => state.tracking.clusterModels;

export const selectTrackingSelectedUserIds = (state: RootState) => state.tracking.selectedUserIds;

/** REMOVE WITH FF: web_clusterUpdates **/
export const selectUnclusteredUserIds = (state: RootState) => state.tracking.unclusteredUserIds;

const selectIsUnclusteredByUserId = createSelector([selectUnclusteredUserIds], (userIds) => {
	const dict: Dict<boolean> = {};
	userIds.forEach((it) => (dict[it] = true));
	return dict;
});

export const selectIsUserIdUnclustered = createSelector(
	[selectIsUnclusteredByUserId, selectContactById],
	(unclusteredDict, contact) => {
		if (contact === undefined) return false;
		return unclusteredDict[contact.id] ?? false;
	}
);
/* **/

export const selectFullBounds = (state: RootState) => state.tracking.fullBounds;

export const selectWorkspaceFilters = (state: RootState) => state.tracking.workspaceFilters;

export const selectVisibilityOverrideForAsset = createSelector(
	[selectWorkspaceAssetById],
	(asset) => asset?.visibilityOverride
);

export const selectActiveTrackingUser = createSelector(
	[selectTrackingState],
	(state) => state.activeTrackingUser
);

export const selectActiveTrackingContact = createSelector(
	[selectActiveTrackingUser, selectUnarchivedContacts],
	(user, contacts) => {
		return contacts.find((contact) => contact.id === user?.userId);
	}
);

export const selectActiveTrackingShape = createSelector(
	[selectTrackingState],
	(state) => state.activeTrackingShape
);

export const selectIsEmitSos = (state: RootState) => state.tracking.emitSos;

export const selectShareLocation = (state: RootState) => state.tracking.shareLocation;

export const selectGeolocation = (state: RootState) => state.tracking.geolocation;

export const selectPendingWaypointLocation = (state: RootState) =>
	state.tracking.pendingWaypointLocation;

export const selectPendingShape = (state: RootState) => state.tracking.pendingShape;

export const selectShareLocationOnLongPress = (state: RootState) =>
	state.tracking.shareLocationOnLongPress;

export const selectTrackingCloseAllFilters = (state: RootState) => state.tracking.closeAllFilters;

export const selectSelectedLocation = (state: RootState) => state.tracking.selectedLocation;

export const selectTrackingSelectedLocationId = (state: RootState) =>
	state.tracking.selectedLocationId;

export const selectTrackingSelectedWaypointId = (state: RootState) =>
	state.tracking.selectedWaypointId;

export const selectMapMode = (state: RootState) => state.tracking.mode;

export const selectDrawMode = (state: RootState) => state.tracking.drawMode;

/*------------------ TRACKING FILTERS [START] ---------------------- */
export const selectTrackingFilters = (state: RootState) => state.tracking.filters;

export const selectHasStatusFilter = createSelector([selectTrackingFilters], (filters) => {
	if (filters === undefined) return false;
	return (
		filters.showActive !== TRACKING_FILTER_SHOW_ALL.showActive ||
		filters.showInactive !== TRACKING_FILTER_SHOW_ALL.showInactive
	);
});

export const selectHasSettingsFilter = createSelector([selectTrackingFilters], (filters) => {
	if (filters === undefined) return false;
	return (
		filters.showUnassignedResources !== TRACKING_FILTER_DEFAULTS.showUnassignedResources ||
		filters.showAssigned !== TRACKING_FILTER_DEFAULTS.showAssigned ||
		filters.onlyShowLatest !== TRACKING_FILTER_DEFAULTS.onlyShowLatest
	);
});

/*------------------ TRACKING FILTERS [END] ---------------------- */

/*------------------ TRACKING SETTINGS [START] ---------------------- */
export const selectTrackingSettings = (state: RootState) => state.tracking.settings;

export const selectMapStyle = createSelector(
	[selectTrackingSettings],
	(settings): MapStyle => settings?.mapStyle ?? MapStyle.Outdoors
);

export const selectNextMapStyle = createSelector(
	[selectMapStyle],
	(mapStyle): MapStyle => getNextMapStyle(mapStyle)
);

export const selectIsStaleDisabled = createSelector(
	[selectTrackingSettings],
	(trackingSettings) => trackingSettings?.disableStale
);

export const selectIsCleanTracksEnabled = createSelector(
	[selectTrackingSettings],
	(trackingSettings) => trackingSettings?.cleanTracks
);

export const selectIsClusterByWorkspaceEnabled = createSelector(
	[selectTrackingSettings],
	(trackingSettings) => trackingSettings?.clusterByWorkspace
);

export const selectShowImageOverlays = createSelector([selectTrackingSettings], (settings) => {
	return settings?.showImageOverlays;
});

const selectIsShareWaypointSettings = createSelector([selectTrackingSettings], (settings) => {
	return settings?.shareWaypointSettings;
});

export const selectIsShareWaypointWithWorkspace = createSelector(
	[selectIsShareWaypointSettings],
	(settings) => {
		return settings?.isShareWithWorkspace;
	}
);

export const selectShareWaypointWithWorkspaceId = createSelector(
	[selectIsShareWaypointSettings],
	(settings) => {
		return settings?.workspaceId;
	}
);

export const selectShareWaypointAffiliation = createSelector(
	[selectIsShareWaypointSettings],
	(settings) => {
		return settings?.affiliation;
	}
);

export const selectShareWaypointDimension = createSelector(
	[selectIsShareWaypointSettings],
	(settings) => {
		return settings?.dimension;
	}
);

export const selectShareWaypointEcosystem = createSelector(
	[selectIsShareWaypointSettings],
	(settings) => {
		return settings?.ecosystem ?? WaypointEcosystem.SOMEWEAR;
	}
);
/*------------------ TRACKING SETTINGS [END] ---------------------- */

export const selectImageOverlays = createSelector([selectSortedMapLayers], (mapLayers) => {
	const layers = mapLayers.filter((layer) => layer.state.visible);
	const overlays = layers.flatMap((layer) => layer.overlays);
	return overlays.mapNotNull((it) => it);
});

export const selectHasImageOverlays = createSelector([selectImageOverlays], (overlays) => {
	return overlays.isNotEmpty();
});

export const selectIsExpandedForUserId = (state: RootState, userId: string | undefined) => {
	if (userId === undefined) return undefined;
	return state.tracking.expandedUsers[userId];
};

export const selectIsSelectedForUserId = createSelector(
	[selectTrackingSelectedUserIds, selectContactById],
	(selectedUserIds, contact) => {
		if (contact === undefined) return undefined;
		return selectedUserIds.find((it) => it === contact.id) !== undefined;
	}
);
