import type { Dictionary } from "@reduxjs/toolkit";
import { DeviceRecord } from "@somewear-labs/swl-web-api/src/proto/device_record_proto_pb";
import { IridiumAccount } from "@somewear-labs/swl-web-api/src/proto/iridium_account_proto_pb";
import { createSelector } from "reselect";

import { createDeepEqualSelector, getDictionaryValue } from "../../common/utils";
import { selectDeviceSummary, selectShowBorrowed } from "../../settings/settingsSelectors";
import type { IWorkspace } from "../../settings/workspace/workspacesSlice";
import { selectWorkspaceEntities } from "../../settings/workspace/workspacesSlice";
import { selectActiveOrganizationId, selectActiveWorkspaceId, selectEcho } from "../appSelectors";
import type { IDevice } from "./devicesSlice";
import { selectAllDevices, selectDeviceBySerial } from "./devicesSlice";

export const selectDevicesForActiveWorkspace = createSelector(
	[selectAllDevices, selectActiveWorkspaceId],
	(devices, workspaceId) => {
		return devices.filter((device) => device.workspaceId === workspaceId);
	}
);

const isBorrowedDevice = (
	device: IDevice | undefined,
	workspace: IWorkspace | undefined,
	activeOrganizationId: string | undefined
) => {
	if (activeOrganizationId === undefined) return false;
	if (device === undefined) return false;
	if (device.organizationId === activeOrganizationId) return false;

	return workspace?.organizationId === activeOrganizationId;
};

export const selectDeviceByIdWithIsBorrowed = createDeepEqualSelector(
	[selectDeviceBySerial, selectActiveOrganizationId, selectWorkspaceEntities],
	(device, activeOrganizationId, workspaceDict) => {
		if (device === undefined) return undefined;
		const workspace = getDictionaryValue(workspaceDict, device.workspaceId);
		const isBorrowed = isBorrowedDevice(device, workspace, activeOrganizationId);
		return { isBorrowed: isBorrowed, ...device };
	}
);

export const selectDevicesForActiveOrganization = createDeepEqualSelector(
	[
		selectAllDevices,
		selectActiveOrganizationId,
		selectDeviceSummary,
		selectWorkspaceEntities,
		selectShowBorrowed,
	],
	(devices, activeOrganizationId, deviceStatus, workspaceDict, showBorrowed) => {
		const sortedDevices = [...devices]
			.map<IDevice>((device) => {
				const workspace = getDictionaryValue(workspaceDict, device.workspaceId);
				const isBorrowed = isBorrowedDevice(device, workspace, activeOrganizationId);
				return { isBorrowed: isBorrowed, ...device };
			})
			.filter((device) => {
				if (activeOrganizationId === undefined) return undefined;
				const isOwnedByOrg = device.organizationId === activeOrganizationId;
				if (!showBorrowed) return isOwnedByOrg;
				return isOwnedByOrg || device.isBorrowed;
			})
			.sort((a, b) => {
				if (a.isBorrowed && !b.isBorrowed) return 1;
				if (!a.isBorrowed && b.isBorrowed) return -1;
				const aTime =
					deviceStatus.find((it) => it.serial === a.serial)?.status?.lastHeartbeat
						?.seconds ?? 0;
				const bTime =
					deviceStatus.find((it) => it.serial === b.serial)?.status?.lastHeartbeat
						?.seconds ?? 0;
				if (aTime !== bTime) return bTime - aTime;
				return a.serial.localeCompare(b.serial);
			});
		return sortedDevices;
	}
);

interface IDeviceUsage {
	device: IDevice;
	usage: number;
}
export const selectDeviceUsageWarningsForActiveOrganization = createSelector(
	[selectDevicesForActiveOrganization],
	(devices) => {
		const usages: IDeviceUsage[] = devices
			.mapNotNull((it) => {
				if (it.kbUsed === undefined || it.kbIncluded === undefined) return undefined;
				if (it.kbIncluded === 0) {
					return {
						device: it,
						usage: -1,
					};
				}
				return {
					device: it,
					usage: Math.ceil((100 * it.kbUsed) / it.kbIncluded),
				};
			})
			.filter((it) => it.usage > 75 && it.device.gateway === DeviceRecord.Gateway.COMMERCIAL)
			.sort((a, b) => b.usage - a.usage);

		return usages;
	}
);

export const selectDeviceUsageAlertsForActiveOrganization = createSelector(
	[selectDeviceUsageWarningsForActiveOrganization],
	(warnings) => {
		return warnings.filter((it) => it.usage > 100);
	}
);

export const selectHasDeviceUsageWarnings = createSelector(
	[selectDeviceUsageWarningsForActiveOrganization, selectDeviceUsageAlertsForActiveOrganization],
	(warnings, alerts) => {
		return warnings.length > 0;
	}
);

export const selectHasDeviceUsageAlerts = createSelector(
	[selectDeviceUsageWarningsForActiveOrganization, selectDeviceUsageAlertsForActiveOrganization],
	(warnings, alerts) => {
		return alerts.length > 0;
	}
);

export const selectDevicesByUserId = createSelector([selectAllDevices], (devices) => {
	const deviceDict: Dictionary<IDevice> = {};
	devices.forEach((device) => {
		if (device.userAccountId === undefined) return;
		deviceDict[device.userAccountId] = device;
	});
	return deviceDict;
});

export const selectDeviceByUserId = createSelector(
	[selectDevicesByUserId, selectEcho<string>],
	(devices, userId) => {
		return getDictionaryValue(devices, userId);
	}
);

export const selectActiveDeviceCount = createSelector(
	[selectDevicesForActiveOrganization],
	(orgDevices) => {
		const commercialDevices = orgDevices.filter(
			(device) => device.gateway === DeviceRecord.Gateway.COMMERCIAL
		);

		const emssDevices = orgDevices.filter(
			(device) => device.gateway === DeviceRecord.Gateway.EMSS
		);

		const activatedCommercialDevices = commercialDevices.filter(
			(device) => device.plan?.iridiumStatus === IridiumAccount.Status.ACTIVE
		);

		return emssDevices.length + activatedCommercialDevices.length;
	}
);

export const selectHasInactiveDevices = createSelector(
	[selectDevicesForActiveOrganization, selectActiveDeviceCount],
	(orgDevices, activeDeviceCount) => {
		return orgDevices.length > activeDeviceCount;
	}
);
