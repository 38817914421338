import type { Action } from "@reduxjs/toolkit";
import type { Epic } from "redux-observable";
import { combineEpics } from "redux-observable";
import { filter, mergeMap } from "rxjs/operators";

import { refreshDetailedAppData } from "../../app/appSlice";
import type { RootState } from "../../app/rootReducer";
import { actionSetEpicHandlerBuilder } from "../../common/EpicUtils";
import { grpc } from "../../common/GrpcClient";
import { someGrpc } from "../../common/SomewearGrpc";
import { setFilteredWorkspaceId, WORKSPACE_ID_ALL } from "../../messaging/messagingSlice";
import { resetDateFilter, resetWorkspaceFilters } from "../../tracking/trackingSlice";
import { setActiveWorkspaceId } from "../workspace/workspaceActions";
import { organizationActions } from "./organizationsSlice";

const createOrganizationWorkspaceEpic = actionSetEpicHandlerBuilder(
	organizationActions.createWorkspace,
	(payload) => grpc.prepareRequestWithPayload(someGrpc.createOrganizationWorkspace, payload.data)
);

const createIntegrationEpic = actionSetEpicHandlerBuilder(
	organizationActions.createIntegration,
	(payload) =>
		grpc.prepareRequestWithPayload(someGrpc.createOrganizationIntegration, payload.data)
);

const createTakIntegrationEpic = actionSetEpicHandlerBuilder(
	organizationActions.createTakIntegration,
	(payload) =>
		grpc.prepareRequestWithPayload(someGrpc.createOrganizationTakIntegration, payload.data)
);

const removeIntegrationEpic = actionSetEpicHandlerBuilder(
	organizationActions.removeIntegration,
	(payload) =>
		grpc.prepareRequestWithPayload(someGrpc.removeOrganizationIntegration, payload.data)
);

const configureIntegrationEpic = actionSetEpicHandlerBuilder(
	organizationActions.configureIntegration,
	(payload) => grpc.prepareRequestWithPayload(someGrpc.configureIntegration, payload.data)
);

const fetchIntegrationAccountsEpic = actionSetEpicHandlerBuilder(
	organizationActions.getIntegrationAccounts,
	() => grpc.prepareRequest(someGrpc.fetchIntegrationAccounts)
);

const createResourceEpic = actionSetEpicHandlerBuilder(
	organizationActions.createResource,
	(payload) => grpc.prepareRequestWithPayload(someGrpc.createOrganizationResource, payload.data)
);

const getOrganizationLicenseEpic = actionSetEpicHandlerBuilder(organizationActions.getLicense, () =>
	grpc.prepareRequest(someGrpc.fetchOrganizationLicenseSummary)
);

const getOrganizationWorkspacesEpic = actionSetEpicHandlerBuilder(
	organizationActions.getWorkspaces,
	() => grpc.prepareRequest(someGrpc.fetchOrganizationWorkspaces)
);

const getOrganizationIdentitiesEpic = actionSetEpicHandlerBuilder(
	organizationActions.getAssets,
	() => grpc.prepareRequest(someGrpc.fetchOrganizationIdentities)
);

const fetchOrganizationDeviceUsageEpic = actionSetEpicHandlerBuilder(
	organizationActions.getDeviceUsage,
	(payload) => grpc.prepareRequestWithPayload(someGrpc.fetchOrganizationDeviceUsage, payload.data)
);

const fetchAllDeviceUsageByOrganizationEpic = actionSetEpicHandlerBuilder(
	organizationActions.getAllDeviceUsage,
	() => grpc.prepareRequest(someGrpc.fetchAllDeviceUsageByOrganization)
);

const fetchBillingInfoEpic = actionSetEpicHandlerBuilder(organizationActions.getBillingInfo, () =>
	grpc.prepareRequest(someGrpc.fetchOrganizationBillingInfo)
);

const joinOrganizationWorkspaceEpic = actionSetEpicHandlerBuilder(
	organizationActions.joinWorkspace,
	(payload) => grpc.prepareRequestWithPayload(someGrpc.joinOrganizationWorkspace, payload.data)
);

const removeOrganizationWorkspaceEpic = actionSetEpicHandlerBuilder(
	organizationActions.removeWorkspace,
	(payload) => grpc.prepareRequestWithPayload(someGrpc.archiveOrganizationWorkspace, payload.data)
);

const confirmOrganizationClaimCodeEpic = actionSetEpicHandlerBuilder(
	organizationActions.confirmCode,
	(payload) => grpc.prepareRequestWithPayload(someGrpc.confirmOrganizationClaimCode, payload.data)
);

const claimOrganizationEpic = actionSetEpicHandlerBuilder(organizationActions.claim, (payload) =>
	grpc.prepareRequestWithPayload(someGrpc.claimOrganization, payload.data)
);

const joinOrganizationWorkspaceFulfilledEpic: Epic<Action> = (actions$) =>
	actions$.pipe(
		filter(organizationActions.joinWorkspace.fulfilled.match),
		mergeMap((response) => {
			return [
				setActiveWorkspaceId(response.payload.data.usersList.first().workspaceId),
				refreshDetailedAppData(),
				resetDateFilter(),
				resetWorkspaceFilters(),
				setFilteredWorkspaceId(WORKSPACE_ID_ALL),
			];
		})
	);

const updateOrganizationAssetPasswordEpic = actionSetEpicHandlerBuilder(
	organizationActions.changeUserPassword,
	(payload) =>
		grpc.prepareRequestWithPayload(someGrpc.updateOrganizationAssetPassword, payload.data)
);

export default combineEpics<any, any, RootState>(
	createOrganizationWorkspaceEpic,
	createIntegrationEpic,
	createTakIntegrationEpic,
	createResourceEpic,
	removeIntegrationEpic,
	configureIntegrationEpic,
	fetchIntegrationAccountsEpic,
	getOrganizationLicenseEpic,
	getOrganizationWorkspacesEpic,
	getOrganizationIdentitiesEpic,
	joinOrganizationWorkspaceEpic,
	joinOrganizationWorkspaceFulfilledEpic,
	getOrganizationIdentitiesEpic,
	fetchOrganizationDeviceUsageEpic,
	fetchAllDeviceUsageByOrganizationEpic,
	removeOrganizationWorkspaceEpic,
	fetchBillingInfoEpic,
	confirmOrganizationClaimCodeEpic,
	claimOrganizationEpic,
	updateOrganizationAssetPasswordEpic
);
