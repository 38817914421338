import type { Action } from "redux";
import type { Epic } from "redux-observable";
import { combineEpics, ofType } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, switchMap, takeUntil } from "rxjs/operators";

import { grpc } from "../../common/GrpcClient";
import { someGrpc } from "../../common/SomewearGrpc";
import { signedOut } from "../appActions";
import { apiSosEventsError, apiSosEventsRequest, apiSosEventsSuccess } from "./sosSlice";

/*
Get active sos events
 */
const apiSosEventsEpic: Epic<Action<string>> = (action$) =>
	action$.pipe(
		ofType(apiSosEventsRequest.type),
		switchMap(() =>
			grpc.prepareRequest(someGrpc.getActiveSosEvents).pipe(
				map((events) => apiSosEventsSuccess(events.toObject().eventsList)),
				takeUntil(action$.pipe(ofType(signedOut.type))),
				catchError((error) => of(apiSosEventsError(error)))
			)
		)
	);

export default combineEpics(apiSosEventsEpic);
