import * as React from "react";

import CountrySelectView from "./CountrySelectView";

class SetupCountrySelectView extends CountrySelectView {
	render(): React.ReactNode {
		return (
			<select
				className={this.props.className}
				disabled={this.props.disabled}
				autoComplete="country"
				value={this.state.value}
				onChange={(evt) => this.updateSelectedValue(evt)}
			>
				<option value=""> Select </option>
				{this.options.map((option, index) => (
					<option key={index} value={option}>
						{option}
					</option>
				))}
			</select>
		);
	}
}

export default SetupCountrySelectView;
