import * as React from "react";
import { Suspense, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { RouteComponentProps } from "react-router";
import { Redirect, Route, Switch, withRouter } from "react-router";

import { selectActiveAuthId, selectIsInitialLoadComplete } from "../app/appSelectors";
import {
	useActiveAccountChangeHandler,
	useLoginCallback,
	useLogoutCallback,
	useRegisterOnAuthServiceLoaded,
	useRegisterToAuthChanges,
	useWorkspaceTokenState,
} from "../app/hooks";
import { setRedirectPath } from "../app/redirectSlice";
import type { RootState } from "../app/rootReducer";
import type { IAuthUser } from "../common/AuthUtil";
import { AuthController } from "../common/AuthUtil";
import Loader from "../common/Loader";
import Paths from "../common/Paths";
import { Sentry } from "../common/SentryUtil";
import { UserSource } from "../common/UserSource";
import Config from "../config/Config";

const LandingRouter = React.lazy(() => import("../landing/LandingRouter"));
const SetupRouter = React.lazy(() => import("../setup/SetupRouter"));
const AppRouter = React.lazy(() => import("../app/App"));

const Main: React.FunctionComponent<RouteComponentProps> = (props) => {
	const dispatch = useDispatch();
	const appUser = useSelector((state: RootState) => state.app.user);
	const subscriptions = useSelector((state: RootState) => state.subscriptions);
	const isInitialLoadComplete = useSelector(selectIsInitialLoadComplete);
	const savedPath = useRef(props.location.pathname);
	const [workspaceToken, setWorkspaceToken] = useWorkspaceTokenState(props);
	const [authLoaded, setAuthLoaded] = useState(false);

	const activeAuthId = useSelector(selectActiveAuthId);

	// handlers that listen for the auth service to load
	const [authServiceLoaded, setAuthServiceLoaded] = useState(false);
	useRegisterOnAuthServiceLoaded(setAuthServiceLoaded);

	// handlers that listen for auth state changes
	const onAuthStateChangeLogout = useLogoutCallback(props, setAuthLoaded);

	const onAuthStateChangeLogin = useLoginCallback(workspaceToken, setWorkspaceToken);

	const onAuthStateChange = useCallback(
		(user: IAuthUser | undefined) => {
			AuthController.service.getUserIdToken$().subscribe((token) => {
				console.log(`on auth state change; hasToken: ${token !== undefined}`);
				const prevUid = UserSource.getInstance().getCurrentUser()?.id;
				if (user !== undefined) {
					if (prevUid !== user.id) {
						onAuthStateChangeLogin(user);
						setAuthLoaded(true);
					}
				} else if (prevUid !== undefined) {
					onAuthStateChangeLogout();
				} else if (token === undefined) {
					onAuthStateChangeLogout();
				}
			});
		},
		[onAuthStateChangeLogin, onAuthStateChangeLogout, setAuthLoaded]
	);

	// handler that listens for auth change events
	useRegisterToAuthChanges(
		authServiceLoaded,
		workspaceToken,
		setWorkspaceToken,
		onAuthStateChange
	);

	// handlers that listen for org and workspace changes
	useActiveAccountChangeHandler(authServiceLoaded, setAuthLoaded, onAuthStateChange);

	// handler that listens for a saved path and stores it into redux
	useEffect(() => {
		dispatch(setRedirectPath(savedPath.current));
	}, [savedPath, dispatch]);

	const isSomewearCoUser = appUser?.email?.endsWith("@somewear.co");

	const isTestUser =
		appUser?.email?.includes("someweardev") || appUser?.email?.includes("someweartest");
	const phoneNotNeeded =
		appUser?.phone ||
		appUser?.hasWorkspace ||
		appUser?.hasOrganization ||
		isSomewearCoUser ||
		!Config.firebase.enable;
	const subscriptionNotNeeded =
		subscriptions.ids.isNotEmpty() ||
		appUser?.hasWorkspace ||
		appUser?.hasOrganization ||
		isSomewearCoUser ||
		!Config.firebase.enable;

	const currentAuthUser = AuthController.isServiceLoaded
		? AuthController.service.getCurrentAuthUser()
		: undefined;

	const isLoading = useMemo(() => {
		return !authLoaded || (!isInitialLoadComplete && currentAuthUser !== undefined);
	}, [authLoaded, isInitialLoadComplete, currentAuthUser]);

	// log the loading state each time it changes
	useEffect(() => {
		if (isLoading)
			console.log(
				`current user: ${currentAuthUser}; authLoaded: ${authLoaded}; isInitialLoadComplete: ${isInitialLoadComplete}`
			);
	}, [isLoading, currentAuthUser, authLoaded, isInitialLoadComplete]);

	useEffect(() => {
		if (!isLoading) return;
		const timeout = setTimeout(() => {
			console.error("The app did not load within ten seconds");
			Sentry.captureException("The app did not load within ten seconds");
		}, 10000);

		return () => {
			clearTimeout(timeout);
		};
	}, [isLoading]);

	if (isLoading) {
		return (
			<div className="setup-background-content">
				<Loader fullScreen={true} />
			</div>
		);
	} else if (
		activeAuthId !== undefined &&
		(phoneNotNeeded || isTestUser || appUser?.isAnonymous) &&
		subscriptionNotNeeded
	) {
		return (
			<Suspense fallback={<Loader fullScreen={true} />}>
				<Switch>
					<Route path={Paths.Setup} render={(props) => <SetupRouter {...props} />} />
					<Route path={Paths.AppMatch}>
						<AppRouter />
					</Route>
					{appUser?.isAnonymous ? (
						<Redirect from={Paths.All} to={Paths.Tracking} />
					) : (
						<Redirect from={Paths.All} to={Paths.Conversations} />
					)}
				</Switch>
			</Suspense>
		);
	} else {
		return (
			<Suspense fallback={<Loader fullScreen={true} />}>
				<LandingRouter />
			</Suspense>
		);
	}
};

export default withRouter(Main);
