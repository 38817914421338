import * as React from "react";

import type { IErrorLoading } from "../common/Common";
import { CenterContent, ErrorMessage } from "../common/Common";
import ControlledInputView from "../common/ControlledInputView";
import PhoneInputView from "../common/PhoneInputView";
import { Header, Title } from "./SetupCommon";
import type { UserInfoControllerData } from "./UserInfoController";

interface IProps extends IErrorLoading {
	userInfoControllers: UserInfoControllerData;
	registerUserInfo(): void;
	back(): void;
}

const EmergencyContactsView: React.FunctionComponent<IProps> = (props: IProps) => (
	<div className="content-rectangle">
		<form>
			<CenterContent>
				<Title text="EMERGENCY CONTACT SET UP" />
			</CenterContent>
			<CenterContent>
				<Header text="WHO SHOULD WE CONTACT IN CASE OF EMERGENCY?" />
			</CenterContent>
			<ErrorMessage error={props.error} />
			<div className={`row`}>
				<div className={`col-12`}>
					<div className="contact-title">PRIMARY CONTACT</div>
				</div>
			</div>
			<div className={`row`}>
				<div className={`col-12 col-md-6`}>
					<ControlledInputView
						type="text"
						className="setup-form"
						name="fname"
						placeholder="FIRST NAME"
						autoComplete={"off"}
						value={props.userInfoControllers.emergencyName1.getValue()}
						controlledInput={props.userInfoControllers.emergencyName1}
						maxSize={30}
					/>
				</div>
				<div className={`col-12 col-md-6`}>
					<ControlledInputView
						type="text"
						className="setup-form"
						name="lname"
						placeholder="LAST NAME"
						autoComplete={"off"}
						value={props.userInfoControllers.emergencyLast1.getValue()}
						controlledInput={props.userInfoControllers.emergencyLast1}
						maxSize={50}
					/>
				</div>
			</div>
			<div className={`row`}>
				<div className={`col-12`}>
					<ControlledInputView
						type="email"
						className="setup-form"
						id="email"
						placeholder="EMAIL"
						autoComplete={"off"}
						value={props.userInfoControllers.emergencyEmail1.getValue()}
						controlledInput={props.userInfoControllers.emergencyEmail1}
						maxSize={50}
					/>
				</div>
			</div>
			<div className={`row`}>
				<div className={`col-12`}>
					<PhoneInputView
						className="setup-form phone-input"
						placeholder="PHONE NUMBER"
						country="US"
						autoComplete={"off"}
						value={props.userInfoControllers.emergencyPhone1.getValue()}
						controlledInput={props.userInfoControllers.emergencyPhone1}
					/>
				</div>
			</div>
			<div className={`row`}>
				<div className={`col-12`}>
					<div className="contact-title">SECONDARY CONTACT</div>
				</div>
			</div>
			<div className={`row`}>
				<div className={`col-12 col-md-6`}>
					<ControlledInputView
						type="text"
						className="setup-form"
						name="fname"
						placeholder="FIRST NAME"
						autoComplete={"off"}
						value={props.userInfoControllers.emergencyName2.getValue()}
						controlledInput={props.userInfoControllers.emergencyName2}
						maxSize={30}
					/>
				</div>
				<div className={`col-12 col-md-6`}>
					<ControlledInputView
						type="text"
						className="setup-form"
						name="lname"
						placeholder="LAST NAME"
						autoComplete={"off"}
						value={props.userInfoControllers.emergencyLast2.getValue()}
						controlledInput={props.userInfoControllers.emergencyLast2}
						maxSize={50}
					/>
				</div>
			</div>
			<div className={`row`}>
				<div className={`col-12`}>
					<ControlledInputView
						type="email"
						className="setup-form"
						id="email"
						placeholder="EMAIL"
						autoComplete={"off"}
						value={props.userInfoControllers.emergencyEmail2.getValue()}
						controlledInput={props.userInfoControllers.emergencyEmail2}
						maxSize={50}
					/>
				</div>
			</div>
			<div className={`row`}>
				<div className={`col-12`}>
					<PhoneInputView
						className="setup-form phone-input"
						placeholder="PHONE NUMBER"
						country="US"
						autoComplete={"off"}
						value={props.userInfoControllers.emergencyPhone2.getValue()}
						controlledInput={props.userInfoControllers.emergencyPhone2}
					/>
				</div>
			</div>
		</form>
		<CenterContent>
			<button
				onClick={() => props.registerUserInfo()}
				className="btn btn-lg register-button d-flex align-items-center"
			>
				<p className="SIGN-IN-WITH-EMAIL"> REGISTER </p>
			</button>
		</CenterContent>
		<CenterContent>
			<div className="back-text" onClick={() => props.back()}>
				BACK
			</div>
		</CenterContent>
	</div>
);

export default EmergencyContactsView;
