import type { UserCredential } from "@firebase/auth";
import { deleteUser, fetchSignInMethodsForEmail, SAMLAuthProvider } from "@firebase/auth";
import { initializeApp } from "firebase/app";
import type {
	ApplicationVerifier,
	ConfirmationResult,
	PhoneAuthCredential,
	User,
} from "firebase/auth";
import {
	createUserWithEmailAndPassword,
	EmailAuthProvider,
	getAuth,
	GoogleAuthProvider,
	linkWithCredential,
	linkWithPhoneNumber,
	OAuthProvider,
	onAuthStateChanged,
	PhoneAuthProvider,
	reauthenticateWithCredential,
	RecaptchaVerifier,
	sendEmailVerification,
	sendPasswordResetEmail,
	signInAnonymously,
	signInWithEmailAndPassword,
	signInWithPhoneNumber,
	signInWithPopup,
	updatePhoneNumber,
	updateProfile,
} from "firebase/auth";
import type firebase from "firebase/compat";
import { getDatabase } from "firebase/database";
import type { Observable, OperatorFunction } from "rxjs";
import { BehaviorSubject, from, of, Subject, throwError } from "rxjs";
import { catchError, filter, map, switchMap, take } from "rxjs/operators";

import Config from "../config/Config";
import type { IAuthService, IAuthUser } from "./AuthUtil";

const config = {
	apiKey: Config.firebase.apiKey,
	authDomain: Config.firebase.authDomain,
	databaseURL: Config.firebase.databaseURL,
	projectId: Config.firebase.projectId,
	storageBucket: Config.firebase.storageBucket,
	messagingSenderId: Config.firebase.messagingSenderId,
	appId: Config.firebase.appId,
	vapidKey: Config.firebase.vapidKey,
};
initializeApp(config);
// const firebaseApp = initializeApp(config);
const auth = getAuth();
export const firebaseDb = getDatabase();

const authChange$ = new Subject<User | null | undefined>();
const auth$ = new BehaviorSubject<User | null | undefined>(undefined);

auth$.subscribe((it) => {
	console.log(it);
});

authChange$.subscribe(auth$);
const unsubscribe = onAuthStateChanged(auth, authChange$);

interface FirebaseUser extends User {}
export interface FirebaseConfirmationResult extends ConfirmationResult {}
export interface FirebaseRecaptchaVerifier extends RecaptchaVerifier {}
export class FirebasePhoneAuthProvider extends PhoneAuthProvider {}

const firebaseGetUserIdToken$ = () => {
	if (auth.currentUser === null)
		return auth$.pipe(
			filter((it) => it !== undefined) as OperatorFunction<
				User | null | undefined,
				User | null
			>,
			switchMap((it) => {
				if (it === null) return of(undefined);
				return it.getIdToken();
			}),
			take(1)
		);
	return to$(auth.currentUser.getIdToken());
};

const firebaseCurrentAuthUser = () => {
	if (auth.currentUser === null) return undefined;
	return auth.currentUser;
};

const toAuthUser = (firebaseUser?: FirebaseUser): IAuthUser | undefined => {
	if (firebaseUser === undefined) return undefined;
	return {
		id: firebaseUser.uid,
		isAnonymous: firebaseUser.isAnonymous,
		email: firebaseUser.email ?? undefined,
		displayName: firebaseUser.displayName ?? undefined,
		phoneNumber: firebaseUser.phoneNumber ?? undefined,
	};
};

const firebaseGoogleLogin = (checkAndHandleDifferentAccount: (error: any) => void) => {
	const provider = new GoogleAuthProvider();
	signInWithPopup(auth, provider)
		.then((res) => {
			// already have an auth observer which handles successful auth
			console.log("successful google auth");
		})
		.catch((error) => {
			checkAndHandleDifferentAccount(error);
			console.log(error);
		});
};

const firebaseAppleLogin = (checkAndHandleDifferentAccount: (error: any) => void) => {
	const provider = new OAuthProvider("apple.com");
	signInWithPopup(auth, provider)
		.then((res) => {
			//already have an auth observer
			//which handles successful auth
			console.log("successful apple auth");
		})
		.catch((error) => {
			checkAndHandleDifferentAccount(error);
			console.log(error);
		});
};

const firebaseFetchSignInMethodsForEmail = (email: string): Promise<string[]> => {
	return fetchSignInMethodsForEmail(auth, email);
};

const firebaseSignInWithEmailAndPassword = (email: string, password: string) => {
	return signInWithEmailAndPassword(auth, email, password);
};

const firebaseSignInWithPhoneNumber = (phone: string, verifier: ApplicationVerifier) => {
	return signInWithPhoneNumber(auth, phone, verifier);
};

const firebaseCreateUserWithEmailAndPassword = (email: string, password: string) => {
	return createUserWithEmailAndPassword(auth, email, password);
};

const firebaseLinkWithCredential = (user: User, credential: firebase.auth.AuthCredential) => {
	return linkWithCredential(user, credential);
};

export const firebaseCredentialFromJson = (json: string | object) => {
	return SAMLAuthProvider.credentialFromJSON(json);
};

const firebaseSignInAnonymously = () => {
	return signInAnonymously(auth);
};

const firebaseSendPasswordResetEmail = (email: string) => {
	return sendPasswordResetEmail(auth, email);
};

export const firebasePhoneProvider = () => {
	return new PhoneAuthProvider(auth);
};

const firebaseUpdatePhoneNumber = (credential: PhoneAuthCredential) => {
	return updatePhoneNumber(auth.currentUser!, credential);
};

const firebaseDeleteUser = () => {
	return deleteUser(auth.currentUser!);
};

export const firebaseReauthenticate = async (password: string) => {
	if (!auth.currentUser || !auth.currentUser.email) throw new Error("Not signed in");

	return reauthenticateWithCredential(
		auth.currentUser,
		EmailAuthProvider.credential(auth.currentUser.email, password)
	);
};

export const firebaseRecaptchaVerifier = (callback: () => void, expiredCallback: () => void) => {
	return new RecaptchaVerifier(
		"recaptcha",
		{
			size: "invisible",
			callback: () => callback(),
			"expired-callback": () => {
				expiredCallback();
			},
		},
		auth
	);
};

const firebaseLinkWithPhoneNumber = (phoneNumber: string, verifier: ApplicationVerifier) => {
	return linkWithPhoneNumber(auth.currentUser!, phoneNumber, verifier);
};

const firebaseUpdateProfile = (displayName: string) => {
	return updateProfile(auth.currentUser!, { displayName: displayName, photoURL: null });
};

const firebaseSendEmailVerification = () => {
	return sendEmailVerification(auth.currentUser!, {
		url: "https://www.somewear.co",
		handleCodeInApp: true,
	});
};

function to$<R>(promise: Promise<R>): Observable<R> {
	return from(promise).pipe(take(1));
}

export interface IFirebaseAuthService extends IAuthService {
	googleLogin: (checkAndHandleDifferentAccount: (error: any) => void) => void;
	appleLogin: (checkAndHandleDifferentAccount: (error: any) => void) => void;
	fetchSignInMethodsForEmail: (email: string) => Observable<string[]>;
	linkWithCredential: (credential: firebase.auth.AuthCredential) => Observable<UserCredential>;
	linkWithPhoneNumber: (
		phoneNumber: string,
		verifier: ApplicationVerifier
	) => Observable<ConfirmationResult>;
	loginWithPhoneNumber: (
		phoneNumber: string,
		verifier: ApplicationVerifier
	) => Observable<ConfirmationResult>;
	updatePhoneNumber: (credential: PhoneAuthCredential) => Observable<void>;
	deleteUser: () => Observable<void>;
}
export const FirebaseAuthService: IFirebaseAuthService = {
	initialize: () => {
		if (auth$.value !== undefined) {
			// firebase loaded prior to initialize the value. Replay the current user value.
			authChange$.next(auth$.value);
		}
	},
	signInWithEmailAndPassword$: (args) =>
		to$(firebaseSignInWithEmailAndPassword(args.email, args.password)).pipe(
			map((it) => toAuthUser(it.user))
		),
	createUserWithEmailAndPassword$: (args) =>
		to$(firebaseCreateUserWithEmailAndPassword(args.email, args.password)).pipe(
			map((it) => toAuthUser(it.user))
		),
	signInAnonymously$: () =>
		to$(firebaseSignInAnonymously()).pipe(map((cred) => toAuthUser(cred.user))),
	sendPasswordResetEmail$: (email: string) => to$(firebaseSendPasswordResetEmail(email)),
	sendEmailVerification$: () => to$(firebaseSendEmailVerification()),
	updateProfile$: (displayName: string) => to$(firebaseUpdateProfile(displayName)),
	signOut$: () => to$(auth.signOut()),
	getUserIdToken$: () => firebaseGetUserIdToken$(),
	getTokenString$: () =>
		FirebaseAuthService.getUserIdToken$().pipe(map((token) => `Bearer ${token}`)),
	getCurrentAuthUser: () => {
		return toAuthUser(firebaseCurrentAuthUser());
	},
	reloadUser: () => {
		firebaseCurrentAuthUser()?.reload();
	},
	reauthenticate$: (currentPassword) =>
		to$(firebaseReauthenticate(currentPassword)).pipe(
			map(() => true),
			catchError((error) => {
				console.error("Reauthentication failed:", error);
				return throwError(() => new Error("Current password is invalid"));
			})
		),
	googleLogin: (checkAndHandleDifferentAccount: (error: any) => void) =>
		firebaseGoogleLogin(checkAndHandleDifferentAccount),
	appleLogin: (checkAndHandleDifferentAccount: (error: any) => void) =>
		firebaseAppleLogin(checkAndHandleDifferentAccount),
	fetchSignInMethodsForEmail: (email: string) => to$(firebaseFetchSignInMethodsForEmail(email)),
	linkWithCredential: (credential: firebase.auth.AuthCredential) =>
		to$(firebaseLinkWithCredential(auth.currentUser!, credential)),
	onAuthStateChanged: (obs$: Subject<IAuthUser | undefined>) => {
		// in some cases (i.e. public tracking) the auth observer will already have a value
		// in these cases we use the behavior subject as a source rather than the change observer
		const source$ = Boolean(auth$.value) ? auth$ : authChange$;
		source$
			.pipe(
				map((it) => {
					// normalize null and undefined values to undefined
					if (it === null || it === undefined) return undefined;
					else return toAuthUser(it);
				})
			)
			.subscribe(obs$);
		return unsubscribe;
	},
	linkWithPhoneNumber: (phoneNumber: string, verifier: ApplicationVerifier) => {
		return to$(firebaseLinkWithPhoneNumber(phoneNumber, verifier));
	},
	loginWithPhoneNumber: (phoneNumber: string, verifier: ApplicationVerifier) => {
		return to$(firebaseSignInWithPhoneNumber(phoneNumber, verifier));
	},
	updatePhoneNumber: (credential: PhoneAuthCredential) => {
		return to$(firebaseUpdatePhoneNumber(credential));
	},
	deleteUser: () => {
		const user = auth.currentUser;
		if (user === undefined) return of(undefined);
		if (user!.email !== null) return of(undefined);
		return to$(firebaseDeleteUser());
	},
};
