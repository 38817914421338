import * as React from "react";
import styled from "styled-components";

interface IProps {
	className?: string;
	fill?: string;
}

const Icon = styled.svg`
	height: 14px;
	width: 21px;
`;

export const ViewIconVisible: React.FunctionComponent<IProps> = (props) => {
	return (
		<Icon className={props.className ?? ""} viewBox="0 0 21 14">
			<path
				fill={props.fill ?? "#226fee"}
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0 7C1.61467 2.90267 5.6 0 10.2667 0C14.9333 0 18.9187 2.90267 20.5333 7C18.9187 11.0973 14.9333 14 10.2667 14C5.6 14 1.61467 11.0973 0 7ZM18.4988 6.99997C16.9588 3.85464 13.8042 1.86664 10.2668 1.86664C6.7295 1.86664 3.57483 3.85464 2.03483 6.99997C3.57483 10.1453 6.7295 12.1333 10.2668 12.1333C13.8042 12.1333 16.9588 10.1453 18.4988 6.99997ZM10.2664 4.66667C11.5544 4.66667 12.5998 5.712 12.5998 7C12.5998 8.288 11.5544 9.33333 10.2664 9.33333C8.97844 9.33333 7.93311 8.288 7.93311 7C7.93311 5.712 8.97844 4.66667 10.2664 4.66667ZM6.06689 7.00003C6.06689 4.68536 7.95223 2.80003 10.2669 2.80003C12.5816 2.80003 14.4669 4.68536 14.4669 7.00003C14.4669 9.31469 12.5816 11.2 10.2669 11.2C7.95223 11.2 6.06689 9.31469 6.06689 7.00003Z"
			/>
		</Icon>
	);
};
