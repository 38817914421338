import type { Dictionary, PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type {
	DeviceEventResponse,
	DeviceSummaryResponse} from "@somewear-labs/swl-web-api/src/proto/device_proto_pb";
import {
	DeviceSettings,
	DeviceSettingsEventType,
	DeviceSettingsResponse,
	DeviceStatusResponse
} from "@somewear-labs/swl-web-api/src/proto/device_proto_pb";
import type { DeviceRecord } from "@somewear-labs/swl-web-api/src/proto/device_record_proto_pb";
import _, { cloneDeep, isEqual } from "lodash";

import { emitAddUserAccountFromServer, emitUserAccountChangeFromServer } from "../app/appActions";
import type { Dict } from "../app/appModel";
import type { IWorkspaceAsset } from "../app/assets/workspaceAssetsSlice";
import { deviceActions } from "../app/devices/deviceActions";
import { Sentry } from "../common/SentryUtil";
import type { IDeviceTransfer } from "./workspace/deviceTransfersSlice";
import { deviceTransferActions } from "./workspace/deviceTransfersSlice";

/*
export enum TrackingIntervals {
	OnFoot = 60,
	Vehicle = 30,
	Jump = 5
}
*/

export interface TrackingInterval {
	id: string;
	logRate: number;
	shareRate: number;
}

interface ITrackingIntervals {
	onFoot: TrackingInterval;
	vehicle: TrackingInterval;
	jump: TrackingInterval;
	oneMin: TrackingInterval;
	custom1: TrackingInterval;
	custom2: TrackingInterval;
}

export const TrackingIntervals: ITrackingIntervals = {
	onFoot: {
		id: "onFoot",
		logRate: 30,
		shareRate: 60,
	},
	vehicle: {
		id: "vehicle",
		logRate: 15,
		shareRate: 30,
	},
	jump: {
		id: "jump",
		logRate: 5,
		shareRate: 5,
	},
	oneMin: {
		id: "oneMin",
		logRate: 60,
		shareRate: 60,
	},
	custom1: {
		id: "custom1",
		logRate: 30,
		shareRate: 300,
	},
	custom2: {
		id: "custom2",
		logRate: 60,
		shareRate: 600,
	},
};

interface SettingsState {
	deviceSettings: Dict<DeviceSettingsResponse.AsObject>;
	deviceStatus: Dict<DeviceStatusResponse.AsObject>;
	deviceSettingsPending: Dict<DeviceSettingsResponse.AsObject>;
	deviceUserIds: Dict<string | undefined>;
	deviceTransfers: Dict<IDeviceTransfer>;
	globalTrackingInterval?: TrackingInterval;
	//globalGpsInterval?: number;
	loading: boolean;
	showArchived: boolean;
	showBorrowed: boolean;
	selectedDeviceDict: Dictionary<boolean>;
}

export enum ChangeableFields {
	ActivationStatus = "activationStatus",
	Altitude = "altitude",
	Battery = "battery",
	Name = "name",
	TrackingInterval = "trackingInterval",
	User = "user",
	Workspace = "workspace",
}

const initialState: SettingsState = {
	deviceSettings: {},
	deviceStatus: {},
	deviceUserIds: {},
	deviceSettingsPending: {},
	deviceTransfers: {},
	loading: true,
	showArchived: false,
	showBorrowed: true,
	selectedDeviceDict: {},
};

function getOrCreateSettings(
	state: SettingsState,
	serial: string
): DeviceSettingsResponse.AsObject {
	let settingsResponse: DeviceSettingsResponse.AsObject;
	if (serial in state.deviceSettings) {
		settingsResponse = _.cloneDeep(state.deviceSettings[serial]);
		settingsResponse.serial = serial;
		settingsResponse.settings!.trackingOn = true;
	} else {
		settingsResponse = new DeviceSettingsResponse().toObject();
		settingsResponse.serial = serial;
		settingsResponse.settings = new DeviceSettings().toObject();
		settingsResponse.settings.trackingOn = true;
	}
	return settingsResponse;
}

function setTracking(state: SettingsState, serial: string, set: boolean) {
	if (serial in state.deviceSettingsPending) {
		state.deviceSettingsPending[serial].settings!.trackingOn = set;
	} else {
		state.deviceSettingsPending[serial] = getOrCreateSettings(state, serial);
		state.deviceSettingsPending[serial].settings!.trackingOn = set;
		if (state.deviceSettingsPending[serial].settings!.trackingInterval === 0) {
			state.deviceSettingsPending[serial].settings!.trackingInterval = 1800;
		}
		if (state.deviceSettingsPending[serial].settings!.gpsInterval === 0) {
			state.deviceSettingsPending[serial].settings!.gpsInterval = 1800;
		}
	}
}

function removeOutdatedPendingChanges(state: SettingsState, serial: string) {
	if (
		isEqual(
			state.deviceSettingsPending[serial]?.settings,
			state.deviceSettings[serial]?.settings
		)
	) {
		const newDeviceSettingsPending = cloneDeep(state.deviceSettingsPending);
		delete newDeviceSettingsPending[serial];

		state.deviceSettingsPending = newDeviceSettingsPending;
	}
}

function updateSettings<T extends keyof DeviceSettings.AsObject>({
	state,
	serial,
	key,
	value,
}: {
	state: SettingsState;
	serial: string;
	value: DeviceSettings.AsObject[T];
	key: T;
}) {
	if (serial in state.deviceSettingsPending) {
		state.deviceSettingsPending[serial].settings![key] = value;
		removeOutdatedPendingChanges(state, serial);
	} else {
		state.deviceSettingsPending[serial] = getOrCreateSettings(state, serial);
		state.deviceSettingsPending[serial].settings![key] = value;
	}
}

function setDeviceSettings(
	state: SettingsState,
	serial: string,
	settings: DeviceSettingsResponse.AsObject
) {
	const THREE_HOURS_IN_SECONDS = 10800;
	if (
		settings.type === DeviceSettingsEventType.SETTINGSCOMMANDAPPLIED &&
		settings.settingsCommand !== undefined &&
		(settings.settingsCommand.gpsinterval > THREE_HOURS_IN_SECONDS ||
			settings.settingsCommand.trackinginterval > THREE_HOURS_IN_SECONDS)
	) {
		// guard to avoid extreme tracking intervals from being applied
		// this was added to protect against a firmware bug that suggested the device had a tracking interval measured in months
		console.warn(`Unexpected settings update`, settings.settingsCommand);
		Sentry.captureException(`Unexpected settings update: greater than three hours`);
		return;
	}

	if (!(serial in state.deviceSettings)) {
		// initialize the settings
		state.deviceSettings[serial] = new DeviceSettingsResponse().toObject();
	}

	if (
		settings.type === DeviceSettingsEventType.SETTINGSCOMMANDAPPLIED &&
		settings.settingsCommand !== undefined
	) {
		const command = settings.settingsCommand;

		state.deviceSettings[serial].settings = {
			gpsInterval: command.gpsinterval,
			batteryReporting: command.batteryReporting,
			enableAltitudeReporting: command.includeAltitude,
			trackingInterval: command.trackinginterval,
			trackingOn: false, // setting false so it can be flipped to true to initiate tracking
			// trackingOn: command.automaticTracking, // trackingOn is mapped to automaticTracking in the firmware
			sentTimestamp: command.sentTimestamp,
		} as DeviceSettings.AsObject;
	} else if (settings.settings !== undefined) {
		state.deviceSettings[serial].settings = settings.settings;
	} else {
		console.warn(`Unexpected settings update; type=${settings.type}`);
		Sentry.captureException(`Unexpected settings update: unknown type`);
		return;
	}
	state.deviceSettings[serial].timestamp = settings.timestamp;
	state.deviceSettings[serial].type = settings.type;
}

function setDeviceStatus(
	state: SettingsState,
	serial: string,
	status: DeviceStatusResponse.AsObject
) {
	if (!(serial in state.deviceStatus)) {
		state.deviceStatus[serial] = new DeviceStatusResponse().toObject();
	}
	state.deviceStatus[serial].battery = status.battery;
	state.deviceStatus[serial].firmwareversion = status.firmwareversion;
	state.deviceStatus[serial].timestamp = status.timestamp;
	if (status.lastHeartbeat?.seconds !== undefined && status.lastHeartbeat.seconds > 0)
		state.deviceStatus[serial].lastHeartbeat = status.lastHeartbeat;
}

export const settingsSlice = createSlice({
	name: "settings",
	initialState,
	reducers: {
		apiDeviceStatusUpdate(state, action: PayloadAction<DeviceSummaryResponse.AsObject>) {
			const deviceSummary = action.payload;

			if (deviceSummary.settings !== undefined) {
				setDeviceSettings(state, deviceSummary.serial, deviceSummary.settings);
			}

			if (deviceSummary.status !== undefined) {
				setDeviceStatus(state, deviceSummary.serial, deviceSummary.status);
			}

			if (deviceSummary.user !== undefined) {
				state.deviceUserIds[deviceSummary.serial] = deviceSummary.user.id;
			} else {
				state.deviceUserIds[deviceSummary.serial] = deviceSummary.userId;
			}
		},

		apiDeviceRecordUpdate(
			state,
			action: PayloadAction<{ record: DeviceRecord.AsObject; asset?: IWorkspaceAsset }>
		) {
			if (action.payload.asset !== undefined) {
				state.deviceUserIds[action.payload.record.serial] = action.payload.asset.id;
			}
		},
		apiDeviceEventSuccess(state, action: PayloadAction<DeviceEventResponse.AsObject[]>) {
			action.payload.forEach((event) => {
				if (event.settings !== undefined) {
					setDeviceSettings(state, event.settings.serial, event.settings);
				}
				if (event.status) {
					setDeviceStatus(state, event.status.serial, event.status);
				}
			});
		},
		toggleTracking(state, action: PayloadAction<{ serial: string; set: boolean }>) {
			setTracking(state, action.payload.serial, action.payload.set);
		},
		toggleBattery(state, action: PayloadAction<{ serial: string; set: boolean }>) {
			updateSettings({
				state,
				serial: action.payload.serial,
				value: action.payload.set,
				key: "batteryReporting",
			});
		},
		toggleEnableAltitudeReporting(
			state,
			action: PayloadAction<{ serial: string; set: boolean }>
		) {
			updateSettings({
				state,
				serial: action.payload.serial,
				value: action.payload.set,
				key: "enableAltitudeReporting",
			});
		},
		setTrackingInterval(
			state,
			action: PayloadAction<{ serial: string; value: TrackingInterval }>
		) {
			updateSettings({
				state,
				serial: action.payload.serial,
				value: action.payload.value.shareRate,
				key: "trackingInterval",
			});
			updateSettings({
				state,
				serial: action.payload.serial,
				value: action.payload.value.logRate,
				key: "gpsInterval",
			});
		},
		setGpsInterval(state, action: PayloadAction<{ serial: string; value: TrackingInterval }>) {
			updateSettings({
				state,
				serial: action.payload.serial,
				value: action.payload.value.logRate,
				key: "trackingInterval",
			});
		},
		bulkUpdateAutoTrackingForSelected(state, action: PayloadAction<boolean>) {
			Object.keys(state.selectedDeviceDict).forEach((serial) => {
				setTracking(state, serial, action.payload);
			});
		},
		bulkUpdateBatteryReportingForSelected(state, action: PayloadAction<boolean>) {
			Object.keys(state.selectedDeviceDict).forEach((serial) => {
				updateSettings({
					state,
					serial,
					value: action.payload,
					key: "batteryReporting",
				});
			});
		},
		bulkUpdateAltitudeReportingForSelected(state, action: PayloadAction<boolean>) {
			Object.keys(state.selectedDeviceDict).forEach((serial) => {
				updateSettings({
					state,
					serial,
					value: action.payload,
					key: "enableAltitudeReporting",
				});
			});
		},
		bulkUpdateTrackingIntervalsForSelected(state, action: PayloadAction<TrackingInterval>) {
			Object.keys(state.selectedDeviceDict).forEach((serial) => {
				updateSettings({
					state,
					serial,
					value: action.payload.shareRate,
					key: "trackingInterval",
				});
				updateSettings({
					state,
					serial,
					value: action.payload.logRate,
					key: "gpsInterval",
				});
			});
		},
		setAllGpsIntervals(state, action: PayloadAction<TrackingInterval>) {
			// state.globalGpsInterval = action.payload;
			Object.keys(state.deviceUserIds).forEach((serial) => {
				updateSettings({
					state,
					serial,
					value: action.payload.logRate,
					key: "gpsInterval",
				});
			});
		},
		setGlobalTrackingInterval(state, action: PayloadAction<TrackingInterval>) {
			state.globalTrackingInterval = action.payload;
		},
		/**
		setGlobalGpsInterval(state, action: PayloadAction<number>) {
			state.globalGpsInterval = action.payload;
		},
		 **/
		revertChanges(state) {
			state.deviceSettingsPending = {};
		},
		setShowArchived(state, action: PayloadAction<boolean>) {
			state.showArchived = action.payload;
		},
		setShowBorrowed(state, action: PayloadAction<boolean>) {
			state.showBorrowed = action.payload;
		},
		setSelectedDeviceDict(state, action: PayloadAction<Dictionary<boolean>>) {
			state.selectedDeviceDict = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(emitUserAccountChangeFromServer, (state, action) => {
			const user = action.payload;
			Object.keys(state.deviceUserIds).forEach((serial) => {
				if (state.deviceUserIds[serial] === user.id) {
					state.deviceUserIds[serial] = action.payload.id;
				}
			});
			// adapter.upsertOne(state, user);
		});
		builder.addCase(emitAddUserAccountFromServer, (state, action) => {
			console.log("added user");
			// maybe the user should be added to device users
		});
		builder.addCase(deviceActions.fetchSummary.fulfilled, (state, action) => {
			state.loading = false;
			const devices = action.payload.data.devicesList;
			devices.forEach((event) => {
				if (event.settings !== undefined) {
					setDeviceSettings(state, event.serial, event.settings);
				}
				if (event.status) {
					setDeviceStatus(state, event.serial, event.status);
				}
				state.deviceUserIds[event.serial] = event.userId;
			});
		});
		builder.addCase(deviceActions.submitSettings.fulfilled, (state, action) => {
			state.deviceSettingsPending = {};
			const events = action.payload.data.eventsList;
			events.forEach((event) => {
				if (event.settings !== undefined) {
					setDeviceSettings(state, event.settings.serial, event.settings);
				}
				if (event.status) {
					setDeviceStatus(state, event.status.serial, event.status);
				}
			});
		});
		builder.addCase(deviceTransferActions.applyQueuedTransfers.fulfilled, (state, action) => {
			const devices = action.payload.data.mapNotNull((it) => it.device);
			devices.forEach((device) => {
				state.deviceUserIds[device.serial] = device.userAccountId;
			});
		});
		builder.addCase(deviceTransferActions.unaryAssign.fulfilled, (state, action) => {
			const device = action.payload.data.device;
			if (device === undefined) return;
			state.deviceUserIds[device.serial] = device.userAccountId;
		});
		builder.addCase(deviceTransferActions.bulkAssign.fulfilled, (state, action) => {
			const devices = action.payload.data.mapNotNull((it) => it.device);
			devices.forEach((device) => {
				state.deviceUserIds[device.serial] = device.userAccountId;
			});
		});
	},
});

export const {
	apiDeviceEventSuccess,
	toggleTracking,
	toggleBattery,
	toggleEnableAltitudeReporting,
	setTrackingInterval,
	bulkUpdateAutoTrackingForSelected,
	bulkUpdateBatteryReportingForSelected,
	bulkUpdateAltitudeReportingForSelected,
	bulkUpdateTrackingIntervalsForSelected,
	setGlobalTrackingInterval,
	revertChanges,
	apiDeviceRecordUpdate,
	setShowArchived,
	setShowBorrowed,
	setSelectedDeviceDict,
} = settingsSlice.actions;

export default settingsSlice.reducer;
