import type {
	IDirectConversationEntity,
	IWorkspaceConversationEntity} from "./conversation.model";



export function isWorkspaceConversation(conversation?: {
	workspaceId?: string;
}): conversation is IWorkspaceConversationEntity {
	return Boolean(conversation?.workspaceId);
}

export function isDirectMessageConversation(conversation?: {
	conversationId?: string;
}): conversation is IDirectConversationEntity {
	return Boolean(conversation?.conversationId);
}
