import type { RenameAssetRequest } from "@somewear-labs/swl-web-api/src/proto/api/user_pb";
import type { Epic } from "redux-observable";
import { combineEpics } from "redux-observable";
import { filter, map } from "rxjs/operators";

import { actionSetEpicHandlerBuilder } from "../../common/EpicUtils";
import { grpc } from "../../common/GrpcClient";
import someGrpc from "../../common/SomewearGrpc";
import { getDictionaryValue } from "../../common/utils";
import { organizationDeviceActions } from "../../settings/organization/devices/organizationDeviceActions";
import {
	queuedDeviceNameChangesActions,
	selectAllQueuedDeviceNameChanges,
} from "../../settings/workspace/queuedDeviceNameChangesSlice";
import type { RootState } from "../rootReducer";
import { deviceActions } from "./deviceActions";
import { selectDeviceEntities } from "./devicesSlice";

const fetchDevicesEpic = actionSetEpicHandlerBuilder(deviceActions.fetch, () =>
	grpc.prepareRequest(someGrpc.fetchDevices)
);

const fetchDevicesSummaryEpic = actionSetEpicHandlerBuilder(deviceActions.fetchSummary, () =>
	grpc.prepareRequest(someGrpc.fetchDevicesSummary)
);

const submitDevicesSettingsEpic = actionSetEpicHandlerBuilder(
	deviceActions.submitSettings,
	(payload) => grpc.prepareRequestWithPayload(someGrpc.submitDeviceSettings, payload.data),
	{
		onFulfilled: "",
		onPending: "Submitting your device settings changes...",
		onRejected: "An error occurred submitting your device settings changes",
	}
);

const applyQueuedDeviceActivationChangesEpic = actionSetEpicHandlerBuilder(
	deviceActions.applyQueuedActivationChanges,
	(payload) =>
		grpc.prepareRequestWithPayload(someGrpc.applyQueuedDeviceActivationChanges, payload.data),
	{
		onFulfilled: "",
		onPending: "Applying queued device activation changes...",
		onRejected: "An error occurred applying queued device activation changes",
	}
);

const suspendDeviceEpic = actionSetEpicHandlerBuilder(deviceActions.suspendDevice, (payload) =>
	grpc.prepareRequestWithPayload(someGrpc.suspendDevice, payload.data)
);

const unregisterDeviceFromUserEpic = actionSetEpicHandlerBuilder(
	deviceActions.unassign,
	(payload) => grpc.prepareRequestWithPayload(someGrpc.unassignDevice, payload.data),
	{
		onPending: `Unassigning device from asset.`,
		onRejected: `Error unassigning device from asset.`,
		onFulfilled: `Successfully unassigned device.`,
	}
);

const assignDeviceEpic = actionSetEpicHandlerBuilder(
	deviceActions.assign,
	(payload) => grpc.prepareRequestWithPayload(someGrpc.assignDevice, payload.data),
	{
		onPending: `Assigning device to asset.`,
		onRejected: `Error assigning device to asset.`,
		onFulfilled: `Successfully assigned device to asset.`,
	}
);

const successfullyApplyQueuedActivationChangesEpic: Epic = (action$) =>
	action$.pipe(
		filter(deviceActions.applyQueuedActivationChanges.fulfilled.match),
		map(() => organizationDeviceActions.getDevices.request())
	);

const successfullySuspendDeviceEpic: Epic = (action$) =>
	action$.pipe(
		filter(deviceActions.suspendDevice.fulfilled.match),
		map(() => organizationDeviceActions.getDevices.request())
	);

const applyDeviceNameChangesEpic = actionSetEpicHandlerBuilder(
	queuedDeviceNameChangesActions.apply,
	(_, state$) => {
		const state = state$.value;
		const changes = selectAllQueuedDeviceNameChanges(state);
		const deviceDict = selectDeviceEntities(state);
		const requests = changes.mapNotNull((it) => {
			const device = getDictionaryValue(deviceDict, it.serial);
			if (device === undefined) return undefined;
			return {
				identityId: device.identityId,
				fullName: it.name,
			} as RenameAssetRequest.AsObject;
		});
		return grpc.prepareRequestWithPayload(someGrpc.bulkRenameAssets, requests);
	}
);

export default combineEpics<any, any, RootState>(
	fetchDevicesEpic,
	fetchDevicesSummaryEpic,
	submitDevicesSettingsEpic,
	unregisterDeviceFromUserEpic,
	assignDeviceEpic,
	applyQueuedDeviceActivationChangesEpic,
	suspendDeviceEpic,
	applyDeviceNameChangesEpic,
	successfullyApplyQueuedActivationChangesEpic,
	successfullySuspendDeviceEpic
);
