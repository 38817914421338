export function getFormattedFileSize(sizeInBytes?: number) {
	const sizes = ["Bytes", "KB", "MB", "GB"];

	if (!sizeInBytes || sizeInBytes === 0) return "";

	const index = Math.floor(Math.log(sizeInBytes) / Math.log(1000));

	const value = (sizeInBytes / Math.pow(1000, index)).toFixed(index > 1 ? 2 : 0);

	return `(${value} ${sizes[index]})`;
}

/**
 * Programmatically download a file after we obtain the signed url.
 */
export function downloadFileFromSignedUrl(signedUrl: string) {
	const link = document.createElement("a");
	link.href = signedUrl;
	console.log(signedUrl);

	document.body.appendChild(link);

	link.click();

	document.body.removeChild(link);
}
