import type { PayloadAction } from "@reduxjs/toolkit";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import type { RenameAssetRequest } from "@somewear-labs/swl-web-api/src/proto/api/user_pb";

import type { RootState } from "../../app/rootReducer";
import { createActionSet } from "../../common/EpicUtils";

type DeviceNameChange = {
	serial: string;
	name: string;
};

const adapter = createEntityAdapter<DeviceNameChange>({
	selectId: (it) => it.serial,
});

export const queuedDeviceNameChangesActions = {
	apply: createActionSet<void, RenameAssetRequest.AsObject[]>("device/account/changes"),
};

const _actions = queuedDeviceNameChangesActions;

const queuedDeviceNameChangesSlice = createSlice({
	name: "workspaceUserChanges",
	initialState: adapter.getInitialState(),
	reducers: {
		addChange: (state, action: PayloadAction<DeviceNameChange>) => {
			adapter.upsertOne(state, action.payload);
		},
		removeAllChanges: (state) => {
			adapter.removeAll(state);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(_actions.apply.fulfilled, (state, action) => {
			console.log("success");
			adapter.removeAll(state);
		});
		builder.addCase(_actions.apply.rejected, (state, action) => {
			console.error("error");
		});
		builder.addCase(_actions.apply.pending, (state, action) => {
			console.log("saving changes");
		});
	},
});

// Rename the exports for readability in component usage
export const {
	selectAll: selectAllQueuedDeviceNameChanges,
	selectEntities: selectQueuedDeviceNameChangesEntities,
} = adapter.getSelectors((state: RootState) => state.workspaceUserChanges);

export default queuedDeviceNameChangesSlice;
