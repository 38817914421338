import type {
	CreateShapeResponse,
	DeleteShapeResponse,
	GetShapesResponse,
	UpdateShapeResponse,
} from "@somewear-labs/swl-web-api/src/proto/api/shape_pb";
import type { Shape } from "@somewear-labs/swl-web-api/src/proto/shape_proto_pb";

import { createActionSet } from "../../common/EpicUtils";

export const shapeActions = {
	getShapes: createActionSet<void, GetShapesResponse.AsObject>("shape/get"),
	createShape: createActionSet<Shape.AsObject, CreateShapeResponse.AsObject>("shape/create"),
	editShape: createActionSet<Shape.AsObject, UpdateShapeResponse.AsObject>("shape/edit"),
	deleteOne: createActionSet<string, DeleteShapeResponse>("shape/delete/one"),
	deleteAll: createActionSet<void, DeleteShapeResponse>("shape/delete/all"),
};
