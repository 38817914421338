import type { PayloadAction } from "@reduxjs/toolkit";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import type { GetDeviceUsageResponse } from "@somewear-labs/swl-web-api/src/proto/api/device_pb";
import type {
	ClaimOrganizationRequest,
	ConfirmOrganizationClaimCodeResponse,
	CreateOrganizationIntegrationRequest,
	CreateOrganizationIntegrationResponse,
	CreateOrganizationResourceResponse,
	CreateOrganizationTakServerRequest,
	CreateOrganizationWorkspaceRequest,
	CreateOrganizationWorkspaceResponse,
	GetOrganizationBillingInfoResponse,
	GetOrganizationDataUsageForDevicesResponse,
	GetOrganizationIdentitiesResponse,
	GetOrganizationIntegrationAccountsResponse,
	GetOrganizationLicenseSummaryResponse,
	GetOrganizationWorkspacesResponse,
	RemoveOrganizationIntegrationResponse,
	UpdateOrganizationMemberPasswordRequest,
	UpdateOrganizationMemberPasswordResponse,
} from "@somewear-labs/swl-web-api/src/proto/api/organization_pb";
import type {
	InviteMembersResponse,
	UpdateWorkspaceAccessResponse,
} from "@somewear-labs/swl-web-api/src/proto/api/workspace_pb";
import type { IdentityRecord } from "@somewear-labs/swl-web-api/src/proto/identity_record_proto_pb";
import type { OrganizationRecord } from "@somewear-labs/swl-web-api/src/proto/organization_proto_pb";
import _ from "lodash";

import type { RootState } from "../../app/rootReducer";
import { createActionSet } from "../../common/EpicUtils";
import type { IIdentity } from "../identity/identitiesSlice";
import type { IntegrationConfig } from "../integration/integration.model";
import type { CreateIntegrationRequest } from "./integrations/integrations.model";

export interface IOrganizationAsset extends IIdentity {
	isNew?: boolean;
}

export const getDisplayNameForOrganizationAsset = (asset?: IOrganizationAsset) => {
	return asset !== undefined ? asset.fullName.replaceIfEmpty(asset.email) : "";
};

export interface IOrganization extends OrganizationRecord.AsObject {
	hostedEditBillingUrl?: string;
}

const adapter = createEntityAdapter<IOrganization>({
	selectId: (org) => org.id,
});

// Rename the exports for readability in component usage
export const { selectAll: selectAllOrganizations, selectEntities: selectOrganizationEntities } =
	adapter.getSelectors((state: RootState) => state.organizations);

export const workspaceResourceActions: object = {};

export const organizationActions = {
	getLicense: createActionSet<void, GetOrganizationLicenseSummaryResponse.AsObject>(
		"organization/license/get"
	),
	getWorkspaces: createActionSet<void, GetOrganizationWorkspacesResponse.AsObject>(
		"organization/workspaces/get"
	),
	getAssets: createActionSet<void, GetOrganizationIdentitiesResponse.AsObject>(
		"organization/identities/get"
	),
	createWorkspace: createActionSet<
		CreateOrganizationWorkspaceRequest.AsObject,
		CreateOrganizationWorkspaceResponse.AsObject
	>("organization/workspaces/create"),
	removeWorkspace: createActionSet<string, UpdateWorkspaceAccessResponse.AsObject>(
		"organization/workspaces/remove"
	),
	updateMember: createActionSet<IdentityRecord.AsObject, any>("organization/identities/update"),
	joinWorkspace: createActionSet<string, InviteMembersResponse.AsObject>(
		"organization/workspace/join"
	),
	getDeviceUsage: createActionSet<string, GetDeviceUsageResponse.AsObject>(
		"organization/device/usage/get"
	),
	getAllDeviceUsage: createActionSet<void, GetOrganizationDataUsageForDevicesResponse.AsObject>(
		"organization/devices/usage/get"
	),
	getBillingInfo: createActionSet<undefined, GetOrganizationBillingInfoResponse.AsObject>(
		"organization/recurly/subscription/get"
	),
	createIntegration: createActionSet<
		CreateIntegrationRequest,
		CreateOrganizationIntegrationRequest.AsObject
	>("organization/integration/create"),
	createTakIntegration: createActionSet<
		CreateOrganizationTakServerRequest.AsObject,
		CreateOrganizationIntegrationResponse.AsObject
	>("organization/integration/create/tak"),
	removeIntegration: createActionSet<string, RemoveOrganizationIntegrationResponse.AsObject>(
		"organization/integration/remove"
	),
	configureIntegration: createActionSet<
		IntegrationConfig,
		GetOrganizationIntegrationAccountsResponse.AsObject
	>("organization/integration/configure"),
	getIntegrationAccounts: createActionSet<
		void,
		GetOrganizationIntegrationAccountsResponse.AsObject
	>("organization/integration/accounts/get"),
	createResource: createActionSet<string, CreateOrganizationResourceResponse.AsObject>(
		"organization/resources/create"
	),
	confirmCode: createActionSet<string, ConfirmOrganizationClaimCodeResponse.AsObject>(
		"organization/claim/confirm"
	),
	claim: createActionSet<ClaimOrganizationRequest.AsObject, OrganizationRecord.AsObject>(
		"organization/claim/redeem"
	),
	changeUserPassword: createActionSet<
		UpdateOrganizationMemberPasswordRequest.AsObject,
		UpdateOrganizationMemberPasswordResponse.AsObject
	>("organization/assets/password/update"),
};

const organizationsSlice = createSlice({
	name: "organizations",
	initialState: adapter.getInitialState(),
	reducers: {
		emitOrganizationsReceived(state, action: PayloadAction<OrganizationRecord.AsObject[]>) {
			adapter.upsertMany(state, action.payload);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(organizationActions.getBillingInfo.fulfilled, (state, action) => {
			const orgId = action.payload.requestId.split("|").last();
			const orig = adapter.getSelectors().selectById(state, orgId);
			if (orig === undefined) return;

			const copy = _.cloneDeep(orig);
			copy.hostedEditBillingUrl = action.payload.data.hostedEditBillingUrl;
			adapter.upsertOne(state, copy);
		});
	},
});

export default organizationsSlice;
