import * as React from "react";
import type { RouteComponentProps } from "react-router";

import type { IErrorLoading } from "../common/Common";
import { CenterContent, ErrorMessage } from "../common/Common";
import ControlledInputView from "../common/ControlledInputView";
import Paths from "../common/Paths";
import PhoneInputView from "../common/PhoneInputView";
import SetupCountrySelectView from "../common/SetupCountrySelectView";
import { Header, Title } from "./SetupCommon";
import type { UserInfoControllerData } from "./UserInfoController";

interface IProps extends IErrorLoading, RouteComponentProps {
	userInfoControllers: UserInfoControllerData;
	createUserInfo(): void;
}

const UserInfoView: React.FunctionComponent<IProps> = (props: IProps) => (
	<div className="content-rectangle">
		<form>
			<CenterContent>
				<Title text="EMERGENCY CONTACT SET UP" />
			</CenterContent>
			<CenterContent>
				<Header text="YOUR CONTACT INFORMATION" />
			</CenterContent>
			<ErrorMessage error={props.error} />
			<div className={`row`}>
				<div className={`col-12 col-md-6`}>
					<ControlledInputView
						type="text"
						className="setup-form"
						name="fname"
						placeholder="FIRST NAME"
						value={props.userInfoControllers.nameController.getValue()}
						controlledInput={props.userInfoControllers.nameController}
						maxSize={30}
					/>
				</div>
				<div className={`col-12 col-md-6`}>
					<ControlledInputView
						type="text"
						className="setup-form"
						name="lname"
						placeholder="LAST NAME"
						value={props.userInfoControllers.lastNameController.getValue()}
						controlledInput={props.userInfoControllers.lastNameController}
						maxSize={50}
					/>
				</div>
			</div>
			<div className={`row`}>
				<div className={`col-12`}>
					<ControlledInputView
						type="email"
						className="setup-form"
						id="email"
						placeholder="EMAIL"
						value={props.userInfoControllers.emailController!.getValue()}
						controlledInput={props.userInfoControllers.emailController!}
						maxSize={50}
					/>
				</div>
			</div>
			<div className={`row`}>
				<div className={`col-12`}>
					<PhoneInputView
						className="setup-form phone-input"
						placeholder="PHONE NUMBER"
						country="US"
						value={props.userInfoControllers.phoneController.getValue()}
						controlledInput={props.userInfoControllers.phoneController}
					/>
				</div>
			</div>
			<div className={`row`}>
				<div className={`col-12`}>
					<ControlledInputView
						type="text"
						className="setup-form"
						name="address"
						autoComplete="street-address"
						placeholder="ADDRESS"
						value={props.userInfoControllers.streetController.getValue()}
						controlledInput={props.userInfoControllers.streetController}
						maxSize={80}
					/>
				</div>
			</div>
			<div className={`row`}>
				<div className={`col-12 col-md-6`}>
					<ControlledInputView
						type="text"
						className="setup-form"
						name="city"
						autoComplete="address-level2"
						placeholder="CITY"
						value={props.userInfoControllers.cityController.getValue()}
						controlledInput={props.userInfoControllers.cityController}
						maxSize={40}
					/>
				</div>
				<div className={`col-12 col-md-6`}>
					<ControlledInputView
						type="text"
						className="setup-form"
						name="state"
						autoComplete="address-level1"
						placeholder="STATE"
						value={props.userInfoControllers.stateController.getValue()}
						controlledInput={props.userInfoControllers.stateController}
						maxSize={20}
					/>
				</div>
			</div>
			<div className={`row`}>
				<div className={`col-12 col-md-6`}>
					<SetupCountrySelectView
						className="setup-form setup-select-sm"
						name="country"
						autoComplete="country"
						value={props.userInfoControllers.countryController.getValue()}
						controlledInput={props.userInfoControllers.countryController}
					/>
				</div>
				<div className={`col-12 col-md-6`}>
					<ControlledInputView
						type="text"
						className="setup-form"
						name="zip"
						autoComplete="postal-code"
						placeholder="ZIP"
						value={props.userInfoControllers.zipCodeController.getValue()}
						controlledInput={props.userInfoControllers.zipCodeController}
						maxSize={20}
					/>
				</div>
			</div>
			<div className="setup-input-title">Citizenship</div>
			<div className={`row`}>
				<div className={`col-12`}>
					<SetupCountrySelectView
						className="setup-form setup-select"
						value={props.userInfoControllers.citizenshipController.getValue()}
						controlledInput={props.userInfoControllers.citizenshipController}
					/>
				</div>
			</div>
		</form>
		<CenterContent>
			<button
				onClick={(evt) => props.createUserInfo()}
				className="btn btn-lg register-button d-flex align-items-center"
			>
				<p className="SIGN-IN-WITH-EMAIL"> NEXT </p>
			</button>
		</CenterContent>
		<CenterContent>
			<div className="back-text" onClick={() => props.history.push(Paths.Device)}>
				BACK
			</div>
		</CenterContent>
	</div>
);

export default UserInfoView;
