import type { CreateHealthActivityResponse } from "@somewear-labs/swl-web-api/src/proto/api/health_activity_pb";
import type { AnyAction } from "redux";
import type { Epic } from "redux-observable";
import { combineEpics, ofType } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, switchMap, takeUntil } from "rxjs/operators";

import type { ActionSetEpic } from "../../common/EpicUtils";
import { createActionSetEpicHandler } from "../../common/EpicUtils";
import { grpc } from "../../common/GrpcClient";
import someGrpc from "../../common/SomewearGrpc";
import { signedOut } from "../appActions";
import { selectActiveUserAccountId } from "../appSelectors";
import type { RootState } from "../rootReducer";
import type { CreateHealthActivityForm } from "./biometric.model";
import { biometricActions } from "./biometricActions";
import type { IBiometrics } from "./biometricsSlice";
import {
	apiHealthActivityError,
	apiHealthActivityRequest,
	apiHealthActivitySuccess,
} from "./biometricsSlice";

const apiHealthActivityEpic: Epic<AnyAction, AnyAction> = (action$, state$) =>
	action$.pipe(
		ofType(apiHealthActivityRequest.type),
		switchMap(() => {
			return grpc.prepareRequest(someGrpc.getHealthActivity).pipe(
				map((activities) => {
					const activeUserAccountId = selectActiveUserAccountId(state$.value)!;
					const biometrics: IBiometrics[] = activities
						.toObject()
						.healthactivityresponseList.map((activity) => {
							if (activity.owner?.id !== undefined) {
								return {
									...activity,
									ownerId: activity.owner.id!,
								};
							} else {
								return {
									...activity,
									ownerId: activeUserAccountId,
								};
							}
						});
					return apiHealthActivitySuccess(biometrics);
				}),
				takeUntil(action$.pipe(ofType(signedOut.type))),
				catchError((error) => of(apiHealthActivityError(error)))
			);
		})
	);

const createHealthActivityEpic: ActionSetEpic<
	CreateHealthActivityForm,
	CreateHealthActivityResponse
> = (action$, state$) => {
	return createActionSetEpicHandler(
		action$,
		state$,
		biometricActions.createHealthActivity,
		(payload) => grpc.prepareRequestWithPayload(someGrpc.createHealthActivity, payload.data)
	);
};

export default combineEpics<any, any, RootState>(apiHealthActivityEpic, createHealthActivityEpic);
