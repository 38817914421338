import type { Dictionary, EntityState } from "@reduxjs/toolkit";
import { createEntityAdapter } from "@reduxjs/toolkit";

import { someFeatures } from "../../../common/somewearFeatures";
import { getDictionaryValue } from "../../../common/utils";
import { featuresLocalStorageKey } from "../features.config";
import type { IFeature } from "../features.model";
import { getFeatureConfig, getFeatureIdFromKey } from "../features.utils";

function createFeaturesAdapter() {
	return createEntityAdapter<IFeature>({
		selectId: (feature) => getFeatureIdFromKey(feature.key),
	});
}

export const featuresCoreAdapter = createFeaturesAdapter();
export const featuresOverridesAdapter = createFeaturesAdapter();

export const getInitialCoreFeatures = () => {
	const baseFeatureDict = someFeatures.features.reduce((acc, key) => {
		acc[key] = false;
		return acc;
	}, {} as Dictionary<boolean>);

	const envFeatures = import.meta.env.VITE_FEATURE_OVERRIDES?.split(",") || [];

	const initialFeatures: IFeature[] = [...Object.keys(baseFeatureDict), ...envFeatures].map(
		(key) => {
			const value = getDictionaryValue(baseFeatureDict, key) || envFeatures.includes(key);

			return getFeatureConfig(key, value);
		}
	);

	return featuresCoreAdapter.upsertMany(featuresCoreAdapter.getInitialState(), initialFeatures);
};

export const getFeatureOverridesFromLocalStorage = () => {
	const overrides: IFeature[] = JSON.parse(localStorage.getItem(featuresLocalStorageKey) ?? "[]");

	return featuresOverridesAdapter.upsertMany(
		featuresOverridesAdapter.getInitialState(),
		overrides
	);
};

/**
 * Takes a list of features, and for all the enabled features, it flips the feature on.
 */
export const switchFeatures = (state: EntityState<IFeature>, features: IFeature[]) => {
	const enabledFeatures = features.filter((it) => it.enabled);
	featuresCoreAdapter.upsertMany(state, enabledFeatures);
};
