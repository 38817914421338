import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import type { Shape } from "@somewear-labs/swl-web-api/src/proto/shape_proto_pb";

import type { RootState } from "../../app/rootReducer";
import { shapeActions } from "./shapeActions";

export interface IShape extends Shape.AsObject {}

const adapter = createEntityAdapter<IShape>({
	selectId: (shape) => shape.id,
});

export const {
	selectAll: selectAllShapes,
	selectById: selectShapeById,
	selectEntities: selectShapeEntities,
} = adapter.getSelectors((state: RootState) => state.shapes);

const shapeSlice = createSlice({
	name: "routes",
	initialState: adapter.getInitialState(),
	reducers: {
		_: (state) => {},
	},
	extraReducers: (builder) => {
		builder.addCase(shapeActions.createShape.fulfilled, (state, action) => {
			if (action.payload.data.shape === undefined) return;
			adapter.upsertOne(state, action.payload.data.shape);
		});
		builder.addCase(shapeActions.editShape.fulfilled, (state, action) => {
			if (action.payload.requestData === undefined) return;
			adapter.upsertOne(state, action.payload.requestData);
		});
		builder.addCase(shapeActions.getShapes.fulfilled, (state, action) => {
			adapter.upsertMany(state, action.payload.data.shapesList);
		});
	},
});

export default shapeSlice;
