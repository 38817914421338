import * as country from "country-list-js";
import * as React from "react";

import type { IControlledInputProps } from "./ControlledInputView";
import ControlledInputView from "./ControlledInputView";
import { modifyState } from "./utils";

interface ICountrySelectProps extends IControlledInputProps {}

class CountrySelectView extends ControlledInputView<ICountrySelectProps> {
	options: string[];

	constructor(props: ICountrySelectProps) {
		super(props);
		this.options = (country.names() as string[])
			.map((name) => name.slice(0, 30))
			.sort((a, b) => a.localeCompare(b));
		const upperCaseOptions = this.options.map((option) => option.toUpperCase());
		let value = props.value;
		if (value) {
			//this is needed because we didn't limit the input
			//in the first release and it's just to show the correct default if possible
			if (!upperCaseOptions.includes(value.toUpperCase())) {
				switch (value) {
					case "US":
						value = "United States";
						break;
					case "USA":
						value = "United States";
						break;
					case "U.S.A":
						value = "United States";
						break;
					case "UNITED STATES OF AMERICA":
						value = "United States";
						break;
					case "MX":
						value = "Mexico";
						break;
					case "MÉXICO":
						value = "Mexico";
						break;
					case "AUS":
						value = "Australia";
						break;
					default:
						value = "United States";
						break;
				}
			}
		}
		this.state = {
			value: value || "",
		};
	}

	updateSelectedValue(evt: React.FormEvent<HTMLSelectElement>) {
		this.setState(modifyState(this.state, { value: evt.currentTarget.value }));
		this.props.controlledInput.didUpdateValue(evt.currentTarget.value);
	}

	render(): React.ReactNode {
		return (
			<div className={this.props.className}>
				<select
					disabled={this.props.disabled}
					autoComplete="country"
					value={this.state.value}
					onChange={(evt) => this.updateSelectedValue(evt)}
				>
					<option value=""> Select </option>
					{this.options.map((option, index) => (
						<option key={index} value={option}>
							{option}
						</option>
					))}
				</select>
			</div>
		);
	}
}

export default CountrySelectView;
