/*
Get the users conversation and dispatch the action to set it on the store
 */
import type { PayloadAction } from "@reduxjs/toolkit";
import type { Action } from "redux";
import type { Epic} from "redux-observable";
import { ofType } from "redux-observable";
import { of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { catchError, filter, map, mergeMap, switchMap, takeUntil } from "rxjs/operators";

import { signedOut } from "../app/appActions";
import type {
	ActionSetEpic} from "../common/EpicUtils";
import {
	actionSetEpicHandlerBuilder,
	createActionSetEpicHandler,
} from "../common/EpicUtils";
import FirebaseClient from "../common/FirebaseClient";
import { grpc } from "../common/GrpcClient";
import { someGrpc } from "../common/SomewearGrpc";
import type {
	DownloadFileRequestPayload,
	DownloadFileResponse,
	GetWorkspaceFilesRequestPayload,
	GetWorkspaceFilesResponse,
	UploadFileRequest,
	UploadFileResponse,
} from "./files/files.model";
import { downloadFileFromSignedUrl } from "./files/files.utils";
import type { IMessageResponse } from "./messages/messageModel";
import type { IGetMessagesResponse } from "./messages/messagesSlice";
import type {
	ConversationTimestamp,	MessageRequestWithStateAndTmpId,
	MessagesRequestPayload} from "./messagingSlice";
import {
	getReadTimestamps,
	messageActions,
	setConversationTimestampRequest,
	setConversationTimestampSuccess,
	setReadTimestamps,
	setWorkspaceTimestampRequest,
	setWorkspaceTimestampSuccess,
} from "./messagingSlice";

/*export const apiConversationsEpic: Epic<Action<string>> = (action$) =>
	action$.pipe(
		ofType(apiConversationsRequest.type),
		switchMap(() =>
			Api.getConversations().pipe(
				mergeMap((conversationResponse) =>
					from([
						addContactsFromResponses(conversationResponse.toObject().conversationsList),
						apiConversationsSuccess(conversationResponse.toObject().conversationsList),
						getReadTimestamps(),
					])
				),
				takeUntil(action$.pipe(ofType(signedOut.type))),
				catchError((error) => of(apiConversationsError(error)))
			)
		)
	);*/

/*
Get the messages for a conversation and dispatch the action to set it on the store
 */

export const apiMessagesEpic: ActionSetEpic<MessagesRequestPayload, IGetMessagesResponse> = (
	action$,
	state$
) => {
	return createActionSetEpicHandler(
		action$,
		state$,
		messageActions.get,
		(payload) => {
			return of(payload.data).pipe(
				filter((data) => data.conversationInfo.conversationId !== "null"),
				switchMap((r) => of(r)),
				mergeMap((request) =>
					grpc.prepareRequestWithPayload(someGrpc.getMessages, request).pipe(
						map((response) => {
							return {
								conversationInfo: request.conversationInfo,
								messages: response.toObject().messagesList,
							};
						})
					)
				)
			);
		},
		(payload) => {
			return {
				onFulfilled: "",
				onPending: "",
				onRejected: "Error loading messages",
			};
		}
	);
};

/*
Send a message for a conversation and dispatch the action to set it on the store once the response comes back
 */

export const sendMessageEpic: ActionSetEpic<MessageRequestWithStateAndTmpId, IMessageResponse> = (
	action$,
	state$
) => {
	return createActionSetEpicHandler(action$, state$, messageActions.send, (payload) =>
		grpc
			.prepareRequestWithPayload(someGrpc.sendMessage, payload.data)
			.pipe(map((r) => r.toObject().message))
	);
};

/*
 * Upload a file to S3 and dispatch the action to set it on the store once the response comes back
 */
export const uploadFileEpic: ActionSetEpic<UploadFileRequest, UploadFileResponse> = (
	action$,
	state$
) => {
	return createActionSetEpicHandler(
		action$,
		state$,
		messageActions.uploadFile,
		(payload) => {
			return grpc.prepareRequestWithPayload(someGrpc.uploadFile, payload.data).pipe(
				mergeMap((response) => {
					const { signedUploadUrl, file: fileMetadata } = response.toObject();

					// Perform the actual S3 upload
					return ajax({
						url: signedUploadUrl,
						method: "PUT",
						headers: {
							// We have to set a string with some value here, otherwise rxJS automatically sets
							// the type as application/x-www-form-urlencoded, which S3 cannot parse for some types.
							"Content-Type": "*",
						},
						body: payload.data.file,
						async: true,
						crossDomain: true,
						timeout: 0,
					}).pipe(
						map(() => {
							return {
								signedUploadUrl,
								fileMetadata: fileMetadata!,
							};
						})
					);
				})
			);
		},
		(payload) => {
			return {
				onFulfilled: "",
				onPending: "",
				onRejected: "Error uploading file",
			};
		}
	);
};

export const getWorkspaceFilesEpics: ActionSetEpic<
	GetWorkspaceFilesRequestPayload,
	GetWorkspaceFilesResponse
> = (action$, state$) => {
	return createActionSetEpicHandler(
		action$,
		state$,
		messageActions.getFiles,
		(payload) =>
			grpc.prepareRequestWithPayload(someGrpc.getWorkspaceFiles, payload.data).pipe(
				map((r) => {
					return r.toObject().filesList;
				})
			),
		() => {
			return {
				onFulfilled: "",
				onPending: "",
				onRejected: "Error getting files",
			};
		}
	);
};

/*
 * Get the workspace files and dispatch the action to set it on the store
 */
export const getWorkspaceFilesEpic = actionSetEpicHandlerBuilder(
	messageActions.getFiles,
	(payload) =>
		grpc.prepareRequestWithPayload(someGrpc.getWorkspaceFiles, payload.data).pipe(
			map((r) => {
				return r.toObject().filesList;
			})
		),
	{
		onFulfilled: "",
		onPending: "",
		onRejected: "Error getting files",
	}
);

/*
 * Download a file and dispatch the action to set it on the store
 */
export const downloadFileEpic: ActionSetEpic<DownloadFileRequestPayload, DownloadFileResponse> = (
	action$,
	state$
) => {
	return createActionSetEpicHandler(
		action$,
		state$,
		messageActions.downloadFile,
		(payload) =>
			grpc.prepareRequestWithPayload(someGrpc.downloadFile, payload.data).pipe(
				mergeMap((response) => {
					downloadFileFromSignedUrl(response.toObject().signedDownloadUrl);
					return "successs";
				})
			),
		() => {
			return {
				onFulfilled: "",
				onPending: "",
				onRejected: "Error downloading file",
			};
		}
	);
};

/*export const apiSendMessageEpic: ActionSetEpic<MessageResponseWithState, MessageResponseWithTmpId> =
	(action$, state$) => {
		const requestBuilder = (
			payload: IRequestPayload<MessageResponseWithState>
		): Observable<MessageResponse | undefined> => {
			return Api.sendMessage(payload.data);
		};

		return createActionSetEpicHandler(action$, state$, messageActions.send, requestBuilder);
	};*/

/*
Delete a conversation. Filter conversation id = null since that's is the id for a new conversation
 */
/*
type ApiDeleteConversationInActions = PayloadAction<string>;
type ApiDeleteConversationOutActions = Action | PayloadAction<IError>;
export const apiDeleteConversationEpic: Epic<
	ApiDeleteConversationInActions | ApiDeleteConversationOutActions,
	ApiDeleteConversationOutActions
> = (action$) =>
	action$.pipe(
		ofType(apiDeleteConversationRequest.type),
		map((action) => action as ApiDeleteConversationInActions),
		filter((action) => action.payload !== "null"),
		switchMap((action) =>
			Api.deleteConversation(action.payload).pipe(
				map((conversation) => apiDeleteConversationSuccess()),
				takeUntil(action$.pipe(ofType(signedOut.type))),
				catchError((error) => of(apiDeleteConversationError(error)))
			)
		)
	);
*/

/*type ApiCreateConversationInActions = PayloadAction<MessageDto.AsObject>;
type ApiCreateConversationOutActions =
	| PayloadAction<MessageRequestWithStateAndTmpId>
	| Action
	| PayloadAction<
			| ConversationResponseWithMessage.AsObject[]
			| TrackingIntervalResponseWithLocation.AsObject[]
	  >
	| PayloadAction<ConversationResponseWithMessage.AsObject[]>
	| PayloadAction<IError>;
export const apiCreateConversationEpic: Epic<
	ApiCreateConversationInActions | ApiCreateConversationOutActions,
	ApiCreateConversationOutActions,
	RootState
> = (action$, state$) =>
	action$.pipe(
		ofType(apiCreateConversationRequest.type),
		map((action) => action as ApiCreateConversationInActions),
		switchMap((action) =>
			Api.createConversation(action.payload).pipe(
				mergeMap((conversation) => {
					let conversationId = conversation.getConversation()!.getId();
					// if there was already a conversation just add the message
					if (state$.value.messages.ids.includes(conversationId)) {
						return from([
							messageActions.send.fulfilled({
								requestId: "-1",
								data: conversation.getMostRecentMessage()!.toObject(),
							}),
							apiCreateConversationSuccess(conversation.toObject()),
						]);
					} else {
						return from([
							addContactsFromResponses([conversation.toObject()]),
							apiConversationsSuccess([conversation.toObject()]),
							apiCreateConversationSuccess(conversation.toObject()),
						]);
					}
				}),
				takeUntil(action$.pipe(ofType(signedOut.type))),
				catchError((error) => of(apiCreateConversationError(error)))
			)
		)
	);*/

export const getReadTimestampsEpic: Epic<Action> = (action$) =>
	action$.pipe(
		ofType(getReadTimestamps.type),
		switchMap(() => {
			return FirebaseClient.getReadTimestamps().pipe(
				map((timestamps) => setReadTimestamps(timestamps)),
				takeUntil(action$.pipe(ofType(signedOut.type))),
				catchError(() => of(setReadTimestamps({ conversations: {}, workspaces: {} })))
			);
		})
	);

export const setConversationTimestampEpic: Epic<PayloadAction<ConversationTimestamp>> = (action$) =>
	action$.pipe(
		ofType(setConversationTimestampRequest.type),
		switchMap((action: PayloadAction<ConversationTimestamp>) => {
			FirebaseClient.setConversationTimestamp(
				action.payload.conversationId,
				action.payload.timestamp
			);
			return of(setConversationTimestampSuccess(action.payload));
		})
	);

export const setWorkspaceTimestampEpic: Epic<PayloadAction<ConversationTimestamp>> = (action$) =>
	action$.pipe(
		ofType(setWorkspaceTimestampRequest.type),
		switchMap((action: PayloadAction<ConversationTimestamp>) => {
			FirebaseClient.setWorkspaceTimestamp(
				action.payload.conversationId,
				action.payload.timestamp
			);
			return of(setWorkspaceTimestampSuccess(action.payload));
		})
	);
