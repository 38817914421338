import { Capability } from "@somewear-labs/swl-web-api/src/proto/feature_proto_pb";

import { someFeatures } from "../../common/somewearFeatures";
import type { IFeature } from "./features.model";

export function getFeatureConfig(key: string, enabled: boolean) {
	return {
		key,
		name: key,
		enabled,
		displayName: key,
		path: "",
		status: Capability.Status.STATUSNONE,
		visibility: Capability.Visibility.VISIBILITYNONE,
		tagsList: [],
		description: "",
	};
}

export function getFeatureIdFromKey(featureKey: string) {
	return featureKey.toUpperCase();
}

export function isFeatureEnabled(feature?: IFeature) {
	// force disable global workspace picker to true for this branch
	if (feature?.key === someFeatures.DISABLE_GLOBAL_WORKSPACE_PICKER) {
		return true;
	}

	return Boolean(feature?.enabled);
}
