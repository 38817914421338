import type {
	UpdateWorkspaceAccount,
	UpdateWorkspaceAccountsResponse,
} from "@somewear-labs/swl-web-api/src/proto/api/user_pb";
import type {
	ArchiveMembersResponse,
	InviteMembersRequest,
	InviteMembersResponse,
	UpdateWorkspaceRoleRequest,
	UpdateWorkspaceRoleResponse,
} from "@somewear-labs/swl-web-api/src/proto/api/workspace_pb";

import { createActionSet } from "../../../common/EpicUtils";

export const workspaceMemberActions = {
	add: createActionSet<InviteMembersRequest.AsObject, InviteMembersResponse.AsObject>(
		"workspace/members/add"
	),
	remove: createActionSet<string[], ArchiveMembersResponse.AsObject>("workspace/members/remove"),
	update: createActionSet<
		UpdateWorkspaceAccount.AsObject,
		UpdateWorkspaceAccountsResponse.AsObject
	>("workspace/members/update"),
	assignRole: createActionSet<
		UpdateWorkspaceRoleRequest.AsObject,
		UpdateWorkspaceRoleResponse.AsObject
	>("workspace/members/role/assign"),
};
