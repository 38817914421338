import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { SubscriptionRequest } from "@somewear-labs/swl-web-api/src/proto/subscription_proto_pb";

import { apiSubscriptionsSuccess, setUser } from "../appActions";
import type { RootState } from "../rootReducer";

const adapter = createEntityAdapter<SubscriptionRequest.AsObject>({
	selectId: (entity) => entity.subscriptionCode,
});

// Rename the exports for readability in component usage
export const { selectAll: selectAllSubscriptions, selectById: selectSubscriptionByCode } =
	adapter.getSelectors((state: RootState) => state.subscriptions);

const slice = createSlice({
	name: "subscriptions",
	initialState: adapter.getInitialState(),
	reducers: {
		apiSubscriptionsRequest() {},
	},
	extraReducers: (builder) => {
		builder.addCase(setUser, (state, action) => {
			const user = action.payload;
			if (user) {
				if (user.isAnonymous || user.email?.includes("someweardev")) {
					const subscription = new SubscriptionRequest().toObject();
					subscription.plan = SubscriptionRequest.Plan.NONE;
					subscription.subscriptionCode = "none";
					adapter.upsertOne(state, subscription);
				}
			}
		});
		builder.addCase(apiSubscriptionsSuccess, (state, action) => {
			adapter.upsertMany(state, action.payload);
		});
	},
});

export default slice;
