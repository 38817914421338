import { createAction } from "@reduxjs/toolkit";
import type {
	GetFeaturesResponse,
	GetOrganizationCapabilitiesResponse,
} from "@somewear-labs/swl-web-api/src/proto/api/feature_pb";
import type {
	PongResponse,
	SendPingRequest,
} from "@somewear-labs/swl-web-api/src/proto/api/system_pb";
import type {
	GetAssetsResponse,
	UpdateUserPasswordResponse,
} from "@somewear-labs/swl-web-api/src/proto/api/user_pb";
import type { UpdateStyleSettingsRequest } from "@somewear-labs/swl-web-api/src/proto/style_proto_pb";
import type { SubscriptionRequest } from "@somewear-labs/swl-web-api/src/proto/subscription_proto_pb";
import type {
	SignInRequest,
	SignInResponse,
	UserIdentityResponse,
	UserResponse,
	WorkspaceResponse,
} from "@somewear-labs/swl-web-api/src/proto/user_proto_pb";
import type { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";

import type { UpdatePasswordArgs } from "@/common/AuthUtil";

import { createActionSet } from "../common/EpicUtils";
import type { IIdentity } from "../settings/identity/identitiesSlice";
import type { IWorkspace } from "../settings/workspace/workspacesSlice";
import type { IUser } from "./appSlice";
import type { IWorkspaceAsset } from "./assets/workspaceAssetsSlice";

export const signedOut = createAction("app/signedOut");

export const emitIdentityChangeFromServer = createAction<IIdentity>("emitIdentityChangeFromServer");

export const emitWorkspaceChangeFromServer = createAction<IWorkspace>(
	"emitWorkspaceChangeFromServer"
);

export const emitUserAccountChangeFromServer = createAction<IWorkspaceAsset>(
	"emitUserAccountChangeFromServer"
);

export const emitAddUserAccountFromServer = createAction<UserResponse.AsObject>(
	"emitAddUserAccountFromServer"
);

export const setUser = createAction<IUser | undefined>("app/setUser");

export const apiSubscriptionsSuccess = createAction<SubscriptionRequest.AsObject[]>(
	"app/apiSubscriptionsSuccess"
);

export const apiSubscriptionsError = createAction<SubscriptionRequest.AsObject[]>(
	"app/apiSubscriptionsError"
);

export const emitActiveWorkspaceChange = createAction<WorkspaceResponse.AsObject>(
	"app/activeWorkspace/change"
);

export const resetEpics = createAction("app/resetEpics");

export const appActions = {
	signIn: createActionSet<SignInRequest.AsObject, SignInResponse.AsObject>("app/signIn"),
	signUp: createActionSet<SignInRequest.AsObject, SignInResponse.AsObject>("app/signUp"),
	updatePassword: createActionSet<UpdatePasswordArgs, UpdateUserPasswordResponse.AsObject>(
		"app/updatePassword"
	),
	fetchAssets: createActionSet<void, GetAssetsResponse.AsObject>("workspace/assets/fetch"),
	fetchFeatures: createActionSet<void, GetFeaturesResponse.AsObject>("app/features/fetch"),
	fetchCapabilities: createActionSet<void, GetOrganizationCapabilitiesResponse.AsObject>(
		"app/capabilities/fetch"
	),
	updateIdentityStyle: createActionSet<
		UpdateStyleSettingsRequest.AsObject,
		UserIdentityResponse.AsObject
	>("identity/style/update"),
	updateWorkspaceStyle: createActionSet<
		UpdateStyleSettingsRequest.AsObject,
		WorkspaceResponse.AsObject
	>("workspace/style/update"),
	sendPing: createActionSet<Timestamp.AsObject, SendPingRequest.AsObject>("app/event/ping"),
	receivePongEvent: createAction<PongResponse.AsObject>("app/event/receive"),
};
