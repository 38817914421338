import { GetOrganizationIntegrationAccountsResponse } from "@somewear-labs/swl-web-api/src/proto/api/organization_pb";
import type { IntegrationResponse } from "@somewear-labs/swl-web-api/src/proto/integration_proto_pb";
import IntegrationIdentity = GetOrganizationIntegrationAccountsResponse.IntegrationIdentity;
import type { IIntegrationIdentity } from "./identitiesSlice";

export function getIntegrationType(integration: IntegrationResponse.AsObject) {
	if (Boolean(integration.takServer)) {
		return IntegrationIdentity.IntegrationType.TAK;
	}

	if (Boolean(integration.webhook)) {
		return IntegrationIdentity.IntegrationType.WEBHOOK;
	}

	return IntegrationIdentity.IntegrationType.NONE;
}

export function getIntegrationDisplayType(integration: IIntegrationIdentity) {
	if (integration.integrationType === IntegrationIdentity.IntegrationType.TAK) {
		return "TAK Server";
	}

	if (integration.integrationType === IntegrationIdentity.IntegrationType.WEBHOOK) {
		return "Webhook";
	}

	return null;
}

export function formatIntegration(
	integration: GetOrganizationIntegrationAccountsResponse.IntegrationIdentity.AsObject
): IIntegrationIdentity | undefined {
	if (integration.identity === undefined) return undefined;

	return {
		...integration.identity,
		beamEnabled: integration.beamEnabled,
		inboundEnabled: integration.inboundEnabled,
		outboundEnabled: integration.outboundEnabled,
		inboundLastUpdated: integration.inboundLastUpdated,
		outboundLastUpdated: integration.outboundLastUpdated,
		defaultWorkspaceId: integration.defaultWorkspaceId,
		integrationType: integration.integrationType,
	};
}
