import type { DeleteRouteRequest } from "@somewear-labs/swl-web-api/src/proto/api/tracking_pb";
import type { LocationResponse } from "@somewear-labs/swl-web-api/src/proto/location_proto_pb";
import type { RouteResponse } from "@somewear-labs/swl-web-api/src/proto/route_proto_pb";
import type { SosEventResponse } from "@somewear-labs/swl-web-api/src/proto/sos_message_proto_pb";

import { createActionSet } from "../../common/EpicUtils";
import type { ISaveWaypointPayload } from "../trackingModel";

export const trackingLocationActions = {
	shareLocation: createActionSet<GeolocationPosition, LocationResponse.AsObject[]>(
		"tracking/locations/create"
	),
	shareWaypoint: createActionSet<GeolocationPosition, RouteResponse.AsObject>(
		"tracking/waypoints/create"
	),
	saveWaypoint: createActionSet<ISaveWaypointPayload, RouteResponse.AsObject>(
		"tracking/waypoints/save"
	),
	deleteWaypoint: createActionSet<string, DeleteRouteRequest.AsObject>(
		"tracking/waypoints/delete"
	),
	startSosSession: createActionSet<LocationResponse.AsObject, SosEventResponse.AsObject>(
		"tracking/sos/start"
	),
	resolveSosSession: createActionSet<void, SosEventResponse.AsObject>("tracking/sos/stop"),
};
