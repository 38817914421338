import type { PayloadAction } from "@reduxjs/toolkit";
import type { GetWaypointsResponse } from "@somewear-labs/swl-web-api/src/proto/api/tracking_pb";
import type { RouteResponse } from "@somewear-labs/swl-web-api/src/proto/route_proto_pb";
import type { UserResponse } from "@somewear-labs/swl-web-api/src/proto/user_proto_pb";
import type { Epic } from "redux-observable";
import { combineEpics } from "redux-observable";
import { from } from "rxjs";
import { filter, map, mergeMap } from "rxjs/operators";

import type { ActionSetEpic } from "../../common/EpicUtils";
import { createActionSetEpicHandler } from "../../common/EpicUtils";
import { grpc } from "../../common/GrpcClient";
import someGrpc from "../../common/SomewearGrpc";
import { apiRoutesSuccess } from "../../tracking/trackingSlice";
import { addContacts } from "../contacts/contactsSlice";
import type { RootState } from "../rootReducer";
import { waypointActions } from "./waypointActions";

const fetchWaypointsEpic: ActionSetEpic<void, GetWaypointsResponse.AsObject> = (
	action$,
	state$
) => {
	return createActionSetEpicHandler(action$, state$, waypointActions.fetch, (payload) =>
		grpc.prepareRequest(someGrpc.fetchWaypoints)
	);
};

const fetchWaypointsFulfilledEpic: Epic<
	PayloadAction<RouteResponse.AsObject[] | UserResponse.AsObject[]>,
	PayloadAction<RouteResponse.AsObject[] | UserResponse.AsObject[]>
> = (action$, state$) =>
	action$.pipe(
		filter(waypointActions.fetch.fulfilled.match),
		map((response) => {
			return response.payload.data.routesList;
		}),
		mergeMap((routeList) =>
			from([
				addContacts(routeList.mapNotNull((routes) => routes.owner)),
				apiRoutesSuccess(routeList),
			])
		)
	);

export default combineEpics<any, any, RootState>(fetchWaypointsEpic, fetchWaypointsFulfilledEpic);
