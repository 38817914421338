
import "./index.css";
import "../node_modules/@fortawesome/fontawesome-free/css/all.css";
import "./app/WorkspaceExtension";

import { ThemeProvider as MuiThemeProvider } from "@mui/material";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import store from "./app/store";
import * as Extensions from "./common/Extensions";
import { MuiTheme } from "./common/MuiTheme";
import { SomewearTheme } from "./common/SomewearTheme";
import MainController from "./main/MainController";
import * as serviceWorker from "./serviceWorker";

if (import.meta.env.PROD) {
	console.log = () => {};
	console.log("this should be muted");
	console.time = () => {};
	console.timeEnd = () => {};
}

//Need to do this to actually load the extensions
// eslint-disable-next-line
const extensions = Extensions;

const container = document.getElementById("root")!;

const root = createRoot(container);

root.render(
	<Provider store={store}>
		<MuiThemeProvider theme={MuiTheme}>
			<ThemeProvider theme={SomewearTheme}>
				<BrowserRouter>
					<Switch>
						<Route component={MainController} />
					</Switch>
				</BrowserRouter>
			</ThemeProvider>
		</MuiThemeProvider>
	</Provider>
);

/*if (import.meta.env.NODE_ENV === "development" && import.meta.hot) {
	import.meta.hot.accept("./main/MainController", render);
}*/

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
