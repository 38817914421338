import "firebase/compat/messaging";

import { Client } from "@somewear-labs/swl-web-api/src/proto/client_proto_pb";
import type {
	DeviceDto} from "@somewear-labs/swl-web-api/src/proto/device_proto_pb";
import {
	DeviceEventResponseList,
	DeviceInfo,
	DeviceSettings,
	DeviceSettingsRequest,
	DeviceSettingsRequestList,
} from "@somewear-labs/swl-web-api/src/proto/device_proto_pb";
import { IridiumAccount } from "@somewear-labs/swl-web-api/src/proto/iridium_account_proto_pb";
import { MessageDto, MessageRequest } from "@somewear-labs/swl-web-api/src/proto/message_proto_pb";
import { PublicRecordResponse } from "@somewear-labs/swl-web-api/src/proto/public_record_proto_pb";
import {
	SubscriptionRequest,
	SubscriptionResponse,
} from "@somewear-labs/swl-web-api/src/proto/subscription_proto_pb";
import { TrackingIntervalResponseWithLocationList } from "@somewear-labs/swl-web-api/src/proto/tracking_interval_proto_pb";
import type { SignInRequest } from "@somewear-labs/swl-web-api/src/proto/user_proto_pb";
import { SignInResponse } from "@somewear-labs/swl-web-api/src/proto/user_proto_pb";
import firebase from "firebase/compat/app";
import { getMessaging } from "firebase/messaging";
import type { Message } from "google-protobuf";
import type { Observable } from "rxjs";
import { of, throwError } from "rxjs";
import type { AjaxConfig } from "rxjs/ajax";
import { ajax } from "rxjs/ajax";
import { catchError, map, mergeMap, switchMap, take } from "rxjs/operators";

import { selectActiveOrganizationId, selectActiveUserAccountId } from "../app/appSelectors";
import store from "../app/store";
import Config from "../config/Config";
import type { MessageRequestWithState } from "../messaging/messagingSlice";
import type { IAuthUser } from "./AuthUtil";
import { AuthController } from "./AuthUtil";
import { REST_BASE_URL } from "./RestClient";
import { TraceIdGenerator } from "./TraceIdGenerator";

export const IridiumAccountExistsMessage =
	"This device already has an iridium account. If you want " +
	"to transfer accounts please contact customer support at " +
	"hello@somewearlabs.com";

export class Api {
	private static fullUrl(api: string): string {
		return REST_BASE_URL + api;
	}

	private static buildRequest(
		url: string,
		tokenString?: string,
		method = "get",
		payload?: Uint8Array,
		userId?: string,
		skipUserCreate?: boolean,
		withCredentials?: boolean
	): AjaxConfig {
		const headers: any = {
			"Content-Type": "application/x-protobuf; charset=UTF-8",
			"x-api-key": Config.somewear.apiKey,
			"x-platform": "Web",
			"x-skip-user-create": skipUserCreate ? "true" : "false",
			"x-b3-traceid": TraceIdGenerator.instance.generateTraceId(),
			"x-b3-spanid": TraceIdGenerator.instance.generateSpanId(),
		};

		if (tokenString?.isNotEmpty()) headers["Authorization"] = tokenString;

		const state = store.getState();
		const userAccountId = userId ?? selectActiveUserAccountId(state);
		const organizationId = selectActiveOrganizationId(state);

		// let userAccountId = UserSource.getInstance().activeUserAccountId;
		if (userAccountId != null) {
			headers["x-user-account-id"] = userAccountId;
		}

		if (organizationId !== undefined) {
			headers["x-organization-id"] = organizationId;
		}

		return {
			url: Api.fullUrl(url),
			method: method,
			headers: headers,
			responseType: "arraybuffer",
			crossDomain: Config.somewear.cors,
			withCredentials: false, // todo: fix this withCredentials,
			body: payload,
			async: true,
			timeout: 0,
		};
	}

	private static getUser(): IAuthUser {
		const user = AuthController.service.getCurrentAuthUser();
		if (user === undefined) {
			throw Error("No logged in user");
		}
		return user;
	}

	private static createMessageRequest(message: MessageRequestWithState): MessageRequest {
		const messageRequest = new MessageRequest();
		messageRequest.setContent(message.content);
		messageRequest.setOutgoing(true);
		messageRequest.setConversationId(message.conversationId);
		messageRequest.setWorkspaceId(message.workspaceId);
		return messageRequest;
	}

	private static createNewConversationRequest(message: MessageDto.AsObject): MessageDto {
		const messageDto = new MessageDto();
		messageDto.setEmail(message.email);
		messageDto.setPhoneNumber(message.phoneNumber);
		messageDto.setUserId(message.userId);
		messageDto.setContent(message.content);
		return messageDto;
	}

	private static createSettingsRequest(
		settings: DeviceSettingsRequestList.AsObject
	): DeviceSettingsRequestList {
		const requestList = new DeviceSettingsRequestList();
		const requests = settings.requestsList.map((setting) => {
			const request = new DeviceSettingsRequest();
			request.setSerial(setting.serial);
			const settingObj = new DeviceSettings();
			settingObj.setBatteryReporting(setting.settings!.batteryReporting);
			settingObj.setEnableAltitudeReporting(setting.settings!.enableAltitudeReporting);
			settingObj.setGpsInterval(setting.settings!.gpsInterval);
			settingObj.setTrackingInterval(setting.settings!.trackingInterval);
			settingObj.setTrackingOn(setting.settings!.trackingOn);
			settingObj.setSentTimestamp(Math.round(new Date().getTime() / 1000));
			request.setSettings(settingObj);
			return request;
		});
		requestList.setRequestsList(requests);
		return requestList;
	}

	private static getResource(
		apiUrl: string,
		userId?: string,
		skipUserCreate?: boolean,
		omitAuth?: boolean
	) {
		return AuthController.tokenString$(omitAuth).pipe(
			mergeMap((token) =>
				ajax<ArrayBufferLike>(
					Api.buildRequest(apiUrl, token!, undefined, undefined, userId, skipUserCreate)
				)
			),
			catchError((error) => {
				console.log(`Failed to get ${apiUrl}`);
				console.log(error);
				return throwError({ message: "There was an error with the request" });
			}),
			take(1)
		);
	}

	private static setResource<T extends Message>(
		apiUrl: string,
		resource: T,
		method = "post",
		skipUserCreate?: boolean,
		omitAuth?: boolean,
		withCredentials?: boolean
	) {
		return AuthController.tokenString$(omitAuth).pipe(
			mergeMap((token) => {
				return ajax<ArrayBufferLike>(
					Api.buildRequest(
						apiUrl,
						token,
						method,
						resource.serializeBinary(),
						undefined,
						skipUserCreate,
						withCredentials
					)
				);
			}),
			catchError((error) => {
				console.log(`Failed to set ${apiUrl}`);
				console.log(error);
				return throwError({ message: "There was an error with the request" });
			}),
			take(1)
		);
	}

	private static postResource<T extends Message>(
		apiUrl: string,
		resource: T,
		skipUserCreate?: boolean,
		omitAuth?: boolean,
		withCredentials?: boolean
	) {
		return Api.setResource(apiUrl, resource, "post", skipUserCreate, omitAuth, withCredentials);
	}

	private static putResource<T extends Message>(
		apiUrl: string,
		resource: T,
		skipUserCreate?: boolean
	) {
		return Api.setResource(apiUrl, resource, "put", skipUserCreate);
	}

	private static deleteResource<T extends Message>(
		apiUrl: string,
		resource: T,
		skipUserCreate?: boolean
	) {
		return Api.setResource(apiUrl, resource, "delete", skipUserCreate);
	}

	static getMessaging() {
		try {
			if (firebase.messaging.isSupported()) return getMessaging();
		} catch (e) {
			console.warn("Could not load firebase messaging");
			console.log(e);
		}
	}

	static getSubscriptions(): Observable<SubscriptionResponse> {
		return Api.getResource(Config.somewear.api.subscriptions).pipe(
			map((ajaxResponse) =>
				SubscriptionResponse.deserializeBinary(new Uint8Array(ajaxResponse.response))
			)
		);
	}

	/*static getConversations(): Observable<ConversationResponseWithMessageList> {
		return Api.getResource(Config.somewear.api.conversationsPreview).pipe(
			map((ajaxResponse) =>
				ConversationResponseWithMessageList.deserializeBinary(
					new Uint8Array(ajaxResponse.response)
				)
			)
		);
	}*/

	static getTrackingIntervals(
		userId?: string
	): Observable<TrackingIntervalResponseWithLocationList> {
		return Api.getResource(Config.somewear.api.trackingPreview, userId).pipe(
			map((ajaxResponse) =>
				TrackingIntervalResponseWithLocationList.deserializeBinary(
					new Uint8Array(ajaxResponse.response)
				)
			)
		);
	}

	/*
		@deprecated - should be using new grpc endpoints
	 */
	/*static getRoutes(): Observable<RouteResponseList> {
		return Api.getResource(Config.somewear.api.routes).pipe(
			map((ajaxResponse) =>
				RouteResponseList.deserializeBinary(new Uint8Array(ajaxResponse.response))
			)
		);
	}*/

	static getTrackingForUuid(uuid: string): Observable<PublicRecordResponse> {
		return Api.getResource(Config.somewear.api.tracking + "/public/" + uuid).pipe(
			map((ajaxResponse) =>
				PublicRecordResponse.deserializeBinary(new Uint8Array(ajaxResponse.response))
			)
		);
	}

	/*static getLocations(
		userId: string,
		from?: moment.Moment,
		to?: moment.Moment
	): Observable<LocationResponseList> {
		let queryString = "";
		if (from && to) {
			queryString = `?from=${from.toISOString()}&to=${to.toISOString()}`;
		}
		return Api.getResource(
			Config.somewear.api.tracking + "/" + userId + "/locations" + queryString
		).pipe(
			map((ajaxResponse) =>
				LocationResponseList.deserializeBinary(new Uint8Array(ajaxResponse.response))
			)
		);
	}*/

	/*static downloadLocations(
		userIds: string[],
		from?: moment.Moment,
		to?: moment.Moment,
		name?: string
	): Observable<any> {
		let queryString = "";
		if (from && to) {
			queryString = `from=${from.toISOString()}&to=${to.toISOString()}`;
		}
		let usersUrl = "";
		userIds.forEach((userId) => (usersUrl += "id=" + userId + "&"));
		let fileName =
			"Somewear tracking " +
			(name ? name + " " : "") +
			(from ? from.format("MM-D-YY, h:mm A") : "") +
			(to ? "-" + to.format("MM-D-YY, h:mm A") : "");
		fileName += ".gpx";
		return Api.getResource(
			Config.somewear.api.tracking + "/locations/gpx?" + usersUrl + queryString
		).pipe(
			map((ajaxResponse) => {
				console.log(ajaxResponse);
				let data = ajaxResponse.response as ArrayBuffer;
				let blob = new Blob([data], {
					type: "application/xml",
				});

				let objectUrl = URL.createObjectURL(blob);
				let a = document.createElement("a");
				a.href = objectUrl;
				a.download = fileName;
				document.body.appendChild(a);
				a.click();
				a.remove();
			})
		);
	}*/

	/*static getMessages(
		conversationId: string,
		before?: Timestamp.AsObject
	): Observable<IGetMessagesResponse> {
		let query = before ? "?before=" + Date.DateFromTimestamp(before).toISOString() : "";
		return Api.getResource(Config.somewear.api.messages + "/" + conversationId + query).pipe(
			map(
				(ajaxResponse) => {
					return {
						conversationInfo: {
							conversationId: conversationId,
						},
						messages: MessageResponseList.deserializeBinary(
							new Uint8Array(ajaxResponse.response)
						).toObject().messagesList,
					};
				},
				catchError((e: Error) => {
					console.error(e);
					return of({
						conversationInfo: {
							conversationId: conversationId,
						},
						messages: [],
					});
				})
			)
		);
	}*/

	static getIridiumAccount(): Observable<IridiumAccount> {
		return Api.getResource(Config.somewear.api.iridiumAccount).pipe(
			map((ajaxResponse) =>
				IridiumAccount.deserializeBinary(new Uint8Array(ajaxResponse.response))
			)
		);
	}

	static getDeviceInfo(serial: string): Observable<DeviceInfo> {
		return Api.getResource(Config.somewear.api.device + "/" + serial).pipe(
			map((ajaxResponse) =>
				DeviceInfo.deserializeBinary(new Uint8Array(ajaxResponse.response))
			)
		);
	}

	static verifyDevice(serial: string): Observable<DeviceInfo> {
		return Api.getDeviceInfo(serial).pipe(
			switchMap((deviceInfo) => {
				if (deviceInfo.hasIridiumAccount()) {
					return throwError(Error(IridiumAccountExistsMessage));
				} else if (deviceInfo.hasDevice()) {
					return of(deviceInfo);
				} else {
					return throwError(Error("Invalid serial number"));
				}
			})
		);
	}

	static signIn(request: SignInRequest): Observable<SignInResponse> {
		return Api.postResource(Config.somewear.api.signIn, request, true, true, true).pipe(
			map((ajaxResponse) =>
				SignInResponse.deserializeBinary(new Uint8Array(ajaxResponse.response))
			),
			catchError(() => throwError(request))
		);
	}

	static signUp(request: SignInRequest): Observable<SignInResponse> {
		return Api.postResource(Config.somewear.api.signUp, request, true, true, true).pipe(
			map((ajaxResponse) =>
				SignInResponse.deserializeBinary(new Uint8Array(ajaxResponse.response))
			),
			catchError(() => throwError(request))
		);
	}

	static registerDevice(device: DeviceDto): Observable<DeviceDto> {
		return Api.postResource(Config.somewear.api.device, device).pipe(map(() => device));
	}

	static registerSubscription(
		subscription: SubscriptionRequest
	): Observable<SubscriptionRequest> {
		return Api.putResource(Config.somewear.api.subscriptions + "/register", subscription).pipe(
			map((ajaxResponse) =>
				SubscriptionRequest.deserializeBinary(new Uint8Array(ajaxResponse.response))
			)
		);
	}

	static registerClient(client: Client): Observable<Client> {
		return Api.putResource(Config.somewear.api.client, client).pipe(
			map((ajaxResponse) => Client.deserializeBinary(new Uint8Array(ajaxResponse.response)))
		);
	}

	static deleteClient(client: Client): Observable<Client> {
		return Api.deleteResource(Config.somewear.api.client, client).pipe(map(() => client));
	}

	/**
	 * @deprecated Use grpc send message going forward
	 */
	/*static sendMessage(message: MessageRequestWithState): Observable<MessageResponse> {
		let messageRequest = Api.createMessageRequest(message);
		return Api.postResource(Config.somewear.api.messages, messageRequest).pipe(
			map((ajaxResponse) => {
				return MessageResponse.deserializeBinary(new Uint8Array(ajaxResponse.response));
			})
		);
	}*/

	/*static deleteConversation(conversationId: string): Observable<ConversationResponseList> {
		let dummyConvo = new ConversationResponse();
		return Api.deleteResource(
			Config.somewear.api.conversations + `/?ids=${conversationId}`,
			dummyConvo
		).pipe(
			map((ajaxResponse) =>
				ConversationResponseList.deserializeBinary(new Uint8Array(ajaxResponse.response))
			)
		);
	}*/

	/*static createConversation(
		message: MessageDto.AsObject
	): Observable<ConversationResponseWithMessage> {
		let conversationRequest = Api.createNewConversationRequest(message);
		return Api.postResource(Config.somewear.api.conversations, conversationRequest).pipe(
			map((ajaxResponse) =>
				ConversationResponseWithMessage.deserializeBinary(
					new Uint8Array(ajaxResponse.response)
				)
			),
			catchError(() => throwError(message))
		);
	}*/

	/*static getDeviceStatus(): Observable<DeviceSummaryResponseList> {
		return Api.getResource(Config.somewear.api.device + "/status").pipe(
			map((ajaxResponse) =>
				DeviceSummaryResponseList.deserializeBinary(new Uint8Array(ajaxResponse.response))
			)
		);
	}*/

	static postDeviceSettings(
		settings: DeviceSettingsRequestList.AsObject
	): Observable<DeviceEventResponseList> {
		const request = Api.createSettingsRequest(settings);
		return Api.postResource(Config.somewear.api.device + "/settings", request).pipe(
			map((ajaxResponse) =>
				DeviceEventResponseList.deserializeBinary(new Uint8Array(ajaxResponse.response))
			)
		);
	}
}
