import type { DataSnapshot } from "@firebase/database";
import type { Dictionary } from "@reduxjs/toolkit";
import type { Timestamp } from "@somewear-labs/swl-web-api/src/proto/timestamp_proto_pb";
import CryptoJS from "crypto-js";
import { onValue, ref, set } from "firebase/database";
import Hashids from "hashids";
import _ from "lodash";
import { Observable, of } from "rxjs";
import { first, map } from "rxjs/operators";

import type { ReadTimestamps } from "../messaging/messagingSlice";
import type {
	ITrackingFilters,
	ITrackingSettings} from "../tracking/trackingSlice";
import {
	TRACKING_FILTER_DEFAULTS,
	TRACKING_SETTING_DEFAULTS,
} from "../tracking/trackingSlice";
import type { IAuthUser } from "./AuthUtil";
import { AuthController } from "./AuthUtil";
import { firebaseDb } from "./FirebaseUtil";
import { sanitizeObject } from "./utils";

const FIREBASE_PATH_TIMESTAMPS = "timestamps";
const FIREBASE_PATH_FILTERS = "filters";
const FIREBASE_PATH_TRACKING_SETTINGS = "trackingSettings";
const FIREBASE_PATH_WORKSPACE_FILTERS = "workspaceFilters";

const getFirebaseRef = (path: string): Observable<DataSnapshot> | undefined => {
	try {
		return new Observable<DataSnapshot>((obs$) => {
			onValue(ref(firebaseDb, path), (value) => {
				obs$.next(value);
			});
		});
	} catch (e) {
		console.error("Couldn't get data from Firebase Messaging");
		console.error(e);
	}
};

const setFirebaseRef = (path: string, value: any) => {
	try {
		const dbRef = ref(firebaseDb, path);
		set(dbRef, value).catch((error) => console.log(error));
	} catch (e) {
		console.error("Couldn't set data via Firebase Messaging");
		console.error(e);
	}
};

function getUser(): IAuthUser {
	const user = AuthController.service.getCurrentAuthUser();
	if (user === undefined) {
		throw Error("No logged in user");
	}
	return user;
}

const getUserId = () => getUser().id;

namespace FirebaseClient {
	export function getReadTimestamps(): Observable<ReadTimestamps> {
		const obs$ = getFirebaseRef(`${FIREBASE_PATH_TIMESTAMPS}/${getUserId()}`);

		if (obs$ === undefined)
			return of({
				conversations: {},
				workspaces: {},
			});

		return obs$.pipe(
			first(),
			map((snapshot) => {
				if (snapshot?.val()) {
					return snapshot.val() as ReadTimestamps;
				} else {
					return {
						conversations: {},
						workspaces: {},
					};
				}
			})
		);
	}

	export function getTrackingFilters(): Observable<ITrackingFilters> {
		const obs$ = getFirebaseRef(`${FIREBASE_PATH_FILTERS}/${getUserId()}`);

		if (obs$ === undefined) return of(TRACKING_FILTER_DEFAULTS);

		return obs$.pipe(
			first(),
			map((snapshot) => {
				if (snapshot?.val()) {
					return snapshot.val() as ITrackingFilters;
				} else {
					return TRACKING_FILTER_DEFAULTS;
				}
			})
		);
	}

	export function getTrackingSettings(): Observable<ITrackingSettings> {
		const obs$ = getFirebaseRef(`${FIREBASE_PATH_TRACKING_SETTINGS}/${getUserId()}`);
		if (obs$ === undefined) return of(TRACKING_SETTING_DEFAULTS);

		return obs$.pipe(
			first(),
			map((snapshot) => {
				if (snapshot?.val()) {
					const settings = snapshot.val() as ITrackingSettings;
					const decryptedSettings = _.cloneDeep(settings);

					// if we haven't set useBeta yet, initialize it to the default value
					if (decryptedSettings.useBetaMap === undefined)
						decryptedSettings.useBetaMap = TRACKING_SETTING_DEFAULTS.useBetaMap;

					if (decryptedSettings.useWorkspaceColor === undefined)
						decryptedSettings.useWorkspaceColor =
							TRACKING_SETTING_DEFAULTS.useWorkspaceColor;

					if (decryptedSettings.shareWaypointSettings === undefined)
						decryptedSettings.shareWaypointSettings =
							TRACKING_SETTING_DEFAULTS.shareWaypointSettings;

					if (decryptedSettings.mapStyle === undefined)
						decryptedSettings.mapStyle = TRACKING_SETTING_DEFAULTS.mapStyle;

					const salt = "is-there-a-true-north";
					const hasher = new Hashids(salt);
					const hashedId = hasher.encode(getUserId());
					if (settings.customMapUrl !== undefined) {
						decryptedSettings.customMapUrl = CryptoJS.AES.decrypt(
							settings.customMapUrl,
							hashedId
						).toString(CryptoJS.enc.Utf8);
					}

					return decryptedSettings;
				} else {
					return TRACKING_SETTING_DEFAULTS;
				}
			})
		);
	}

	export function getWorkspaceFilters(): Observable<Dictionary<boolean>> {
		const obs$ = getFirebaseRef(`${FIREBASE_PATH_WORKSPACE_FILTERS}/${getUserId()}`);

		if (obs$ === undefined) return of({});

		return obs$.pipe(
			first(),
			map((snapshot) => {
				if (snapshot?.val()) {
					return snapshot.val() as Dictionary<boolean>;
				} else {
					return {};
				}
			})
		);
	}

	export function setConversationTimestamp(id: string, timestamp: Timestamp.AsObject) {
		setFirebaseRef(`${FIREBASE_PATH_TIMESTAMPS}/${getUserId()}/conversations/${id}`, timestamp);
	}

	export function setWorkspaceTimestamp(id: string, timestamp: Timestamp.AsObject) {
		setFirebaseRef(`${FIREBASE_PATH_TIMESTAMPS}/${getUserId()}/workspaces/${id}`, timestamp);
	}

	export function setTrackingFilters(filters: ITrackingFilters | undefined) {
		if (filters === undefined) return;
		setFirebaseRef(`${FIREBASE_PATH_FILTERS}/${getUserId()}`, filters);
	}

	export function setTrackingSettings(settings: ITrackingSettings) {
		const encryptedSettings = _.cloneDeep(sanitizeObject(settings));

		// do not persist the useBetaMap setting
		encryptedSettings.useBetaMap = false;
		// do not persist the share waypoint settings
		encryptedSettings.shareWaypointSettings = {};

		// encrypt the tile provider url
		const salt = "is-there-a-true-north";
		const hasher = new Hashids(salt);
		const hashedId = hasher.encode(getUserId());
		// https://tile.thunderforest.com/outdoors/{z}/{x}/{y}.png?apikey=f7f1ce56d6aa421baae93cd6272b48b3

		if (encryptedSettings.customMapUrl !== undefined) {
			try {
				encryptedSettings.customMapUrl = CryptoJS.AES.encrypt(
					encryptedSettings.customMapUrl,
					hashedId
				).toString();
			} catch (e) {
				console.warn(e);
			}
		}

		setFirebaseRef(`${FIREBASE_PATH_TRACKING_SETTINGS}/${getUserId()}`, encryptedSettings);
	}

	export function setWorkspaceFilters(filters: Dictionary<boolean>) {
		setFirebaseRef(`${FIREBASE_PATH_WORKSPACE_FILTERS}/${getUserId()}`, filters);
	}
}

export default FirebaseClient;
