import type { LocationResponse } from "@somewear-labs/swl-web-api/src/proto/location_proto_pb";
import type { RouteResponse } from "@somewear-labs/swl-web-api/src/proto/route_proto_pb";

import { createActionSet } from "../../common/EpicUtils";
import type { DateRange } from "../trackingSlice";

export const trackingRouteActions = {
	getLive: createActionSet<void, RouteResponse.AsObject[]>("tracking/routes/get/live"),
	getLastKnown: createActionSet<undefined, RouteResponse.AsObject[]>(
		"tracking/routes/get/last_known"
	),
	getLocations: createActionSet<string[], LocationResponse.AsObject[]>(
		"tracking/route/get/locations",
		true
	),
	downloadLocations: createActionSet<string[], LocationResponse.AsObject[]>(
		"tracking/route/download/locations",
		true
	),
	getRoutes: createActionSet<DateRange, RouteResponse.AsObject[]>("tracking/route/get", true),
};
