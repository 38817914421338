import { GetConversationsResponse } from "@somewear-labs/swl-web-api/src/proto/api/messaging_pb";
import { combineEpics } from "redux-observable";
import { of } from "rxjs";
import { catchError } from "rxjs/operators";

import type { RootState } from "../../app/rootReducer";
import type {
	IRequestPayloadAction,
	VoidableRequestPayload} from "../../common/EpicUtils";
import {
	actionSetEpicHandlerBuilder
} from "../../common/EpicUtils";
import { grpc } from "../../common/GrpcClient";
import { someGrpc } from "../../common/SomewearGrpc";
import { conversationActions } from "./conversationActions";

const fetchConversationsEpic = actionSetEpicHandlerBuilder(conversationActions.fetch, () => {
	return grpc.prepareRequest(
		someGrpc.fetchConversations,
		undefined,
		undefined,
		catchError((e) => {
			// this results in silent errors
			console.error(e);
			const response = new GetConversationsResponse();
			response.setConversationsList([]);
			return of(response);
		})
	);
});

const createConversationsEpic = actionSetEpicHandlerBuilder(conversationActions.create, (payload) =>
	grpc.prepareRequestWithPayload(someGrpc.createConversation, payload.data)
);

const deleteConversationEpic = actionSetEpicHandlerBuilder(
	conversationActions.delete,
	(payload) => grpc.prepareRequestWithPayload(someGrpc.deleteConversation, payload.data),
	{
		onFulfilled: "Successfully deleted conversation",
		onPending: "Deleting conversation",
		onRejected: "Failed to delete the conversation",
	}
);

export default combineEpics<
	IRequestPayloadAction<VoidableRequestPayload>,
	IRequestPayloadAction<unknown>,
	RootState
>(createConversationsEpic, fetchConversationsEpic, deleteConversationEpic);
