import type { PayloadAction } from "@reduxjs/toolkit";
import { createAction, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import type { UserResponse } from "@somewear-labs/swl-web-api/src/proto/user_proto_pb";

import { conversationActions } from "../../messaging/conversations/conversationActions";
import { createContactFromConversationResponse } from "../../messaging/conversations/conversationsSlice";
import { workspaceMemberActions } from "../../settings/workspace/members/workspaceMemberActions";
import { trackingRouteActions } from "../../tracking/routes/trackingRouteActions";
import {
	appActions,
	emitAddUserAccountFromServer,
	emitUserAccountChangeFromServer,
} from "../appActions";
import type { IWorkspaceAsset } from "../assets/workspaceAssetsSlice";
import type { RootState } from "../rootReducer";
import type { Contact } from "./contactModels";

export const getDisplayNameForContact = (asset: Contact, isSelf: boolean) => {
	if (isSelf) return "Me";
	return asset !== undefined
		? asset.name
				.replaceIfEmpty(asset.email)
				.replaceIfEmpty(asset.username)
				.replaceIfEmpty(asset.phone)
		: "";
};

const adapter = createEntityAdapter<Contact>({
	selectId: (entity) => entity.id,
});

// Rename the exports for readability in component usage
export const {
	selectAll: selectAllContacts,
	selectById: selectContactById,
	selectIds: selectContactIds,
	selectEntities: selectContactEntities,
} = adapter.getSelectors((state: RootState) => state.contacts);

const buildWorkspaceContact = (userResponse: UserResponse.AsObject | IWorkspaceAsset): Contact => {
	let workspaceId = userResponse.workspaceId ?? -1;
	if (workspaceId === "null" || workspaceId.isEmpty()) workspaceId = "-1";
	return {
		id: userResponse.id,
		email: userResponse.email,
		username: userResponse.username ?? "",
		phone: "",
		name: userResponse.fullname,
		// displayName: userResponse.fullname.replaceIfEmpty(userResponse.email),
		workspace: workspaceId !== "-1",
		workspaceId: workspaceId,
		isArchived: userResponse.isArchived,
		type: userResponse.type,
		identityId: userResponse.identityId,
	};
};

export const addContacts = createAction<UserResponse.AsObject[]>("contactsSlice/addContacts");

/*export const addContactsFromResponses = createAction<
	ConversationResponseWithMessage.AsObject[] | TrackingIntervalResponseWithLocation.AsObject[]
>("contactsSlice/addContactsFromResponses");*/

/*export const deleteContactConversation = createAction<string>(
	"contactsSlice/deleteContactConversation"
);*/

const contactsSlice = createSlice({
	name: "contacts",
	initialState: adapter.getInitialState(),
	reducers: {
		upsertAsset: (state, action: PayloadAction<UserResponse.AsObject>) => {
			adapter.upsertOne(state, buildWorkspaceContact(action.payload));
		},
		upsertAssets: (state, action: PayloadAction<UserResponse.AsObject[]>) => {
			const contacts = action.payload.map((asset) => buildWorkspaceContact(asset));
			adapter.upsertMany(state, contacts);
		},
		upsertContact: (state, action: PayloadAction<Contact>) => {
			adapter.upsertOne(state, action.payload);
		},
		removeContactById: (state, action: PayloadAction<string>) => {
			adapter.removeOne(state, action.payload);
		},
	},
	extraReducers: (builder) => {
		/*builder.addCase(workspaceActions.fetchAccounts.fulfilled, (state, action) => {
			const workspaceMembers = action.payload.data;
			/!*
			// only do this if we get workspace members back
			const workspaceMembers = action.payload.filter(
				(asset) => asset.type === IdentityType.USER
			);
			 *!/
			if (workspaceMembers.length > 0) {
				const ids = workspaceMembers.map((user) => user.id);
				const removedUserIds = _.difference(state.ids, ids);
				const contacts = workspaceMembers.map((user) => buildContact(user));
				adapter.upsertMany(state, contacts);
				// todo: figure out if contact is a workspace

				// ensure that any workspace users that aren't found are removed
				adapter.removeMany(state, removedUserIds);
			}
		});*/
		builder.addCase(addContacts, (state, action) => {
			const contacts = action.payload.map((user) => {
				return {
					id: user.id,
					name: user.fullname,
					email: user.email,
					username: user.username,
					phone: user.phonenumber,
					displayName: user.fullname
						.replaceIfEmpty(user.email)
						.replaceIfEmpty(user.phonenumber),
					type: user.type,
				};
			});
			adapter.upsertMany(state, contacts);
		});
		builder.addCase(emitUserAccountChangeFromServer, (state, action) => {
			adapter.upsertOne(state, buildWorkspaceContact(action.payload));
		});
		builder.addCase(emitAddUserAccountFromServer, (state, action) => {
			adapter.upsertOne(state, buildWorkspaceContact(action.payload));
		});
		/*builder.addCase(deleteContactConversation, (state, action) => {
			const contact = adapter.getSelectors().selectById(state, action.payload);
			if (contact !== undefined) {
				const updatedContact = _.cloneDeep(contact);
				// updatedContact.conversationId = undefined;
				adapter.upsertOne(state, updatedContact);
			}
		});*/
		// todo: remove this, currently needed for global/public tracks
		builder.addCase(trackingRouteActions.getLastKnown.fulfilled, (state, action) => {
			action.payload.data.forEach((route) => {
				if (route.owner === undefined) return;
				// set the conversation to user relation
				const userId = route.ownerId;

				// if the interval contact does not have an email or a phone number they are likely from a public track
				adapter.upsertOne(state, {
					id: userId,
					name: route.owner.fullname,
					email: route.owner.email,
					username: route.owner.username,
					phone: route.owner.phonenumber,
					// displayName: getDisplayNameForWorkspaceAsset(route.owner!),
					type: route.owner.type,
					identityId: route.owner.identityId,
				});
			});
		});
		/*builder.addCase(apiTrackingIntervalSuccess, (state, action) => {
			const intervals = action.payload as TrackingIntervalResponseWithLocation.AsObject[];
			intervals.forEach((intervalResponse) => {
				// set the conversation to user relation
				let userId = intervalResponse.interval!.userId;

				// use this to catch hotspots or resources that are tracking
				const prevContactType = state.entities[userId]?.type;

				// if the interval contact does not have an email or a phone number they are likely from a public track
				adapter.upsertOne(state, {
					id: userId,
					name: intervalResponse.interval!.participantFullName,
					email: intervalResponse.interval!.participantEmail,
					phone: intervalResponse.interval!.participantPhone,
					displayName: intervalResponse
						.interval!.participantFullName.replaceIfEmpty(
							intervalResponse.interval!.participantEmail
						)
						.replaceIfEmpty(intervalResponse.interval!.participantPhone),
					// todo: this should be returned by the response, but for now this will do
					// we are notified of new resources before receiving locations for them
					type: prevContactType ?? IdentityType.USER,
				});

				if (intervalResponse.interval!.isSelf) {
					const contact = adapter.getSelectors().selectById(state, userId);
					if (contact !== undefined) {
						const updatedContact = _.cloneDeep(contact);
						updatedContact.displayName = "Me";
						adapter.upsertOne(state, updatedContact);
					}
				}
			});
		});*/
		builder.addCase(conversationActions.fetch.fulfilled, (state, action) => {
			const conversations = action.payload.data.conversationsList;
			conversations.forEach((conversationResponse) => {
				// set the contact info
				if (conversationResponse.conversation !== undefined) {
					adapter.upsertOne(
						state,
						createContactFromConversationResponse(conversationResponse.conversation)
					);
				}
			});
		});
		builder.addCase(conversationActions.create.fulfilled, (state, action) => {
			if (action.payload.data.conversation !== undefined) {
				adapter.upsertOne(
					state,
					createContactFromConversationResponse(action.payload.data.conversation)
				);
			}
		});
		/*builder.addCase(addContactsFromResponses, (state, action) => {
			if (action.payload.isEmpty()) {
				return;
			}
			if ("conversation" in action.payload.first()) {

			} else {

			}
		});*/

		builder.addCase(appActions.fetchAssets.fulfilled, (state, action) => {
			const workspaceMembers = action.payload.data.usersList;
			/*
			// only do this if we get workspace members back
			const workspaceMembers = action.payload.filter(
				(asset) => asset.type === IdentityType.USER
			);
			 */
			if (workspaceMembers.length > 0) {
				// const ids = workspaceMembers.map((user) => user.id);
				// const removedUserIds = _.difference(state.ids, ids);
				const contacts = workspaceMembers.map((user) => buildWorkspaceContact(user));
				adapter.upsertMany(state, contacts);
				// todo: figure out if contact is a workspace

				// ensure that any workspace users that aren't found are removed
				// update: don't remove these users anymore
				// adapter.removeMany(state, removedUserIds);
			}
		});
		builder.addCase(workspaceMemberActions.add.fulfilled, (state, action) => {
			if (action.payload.data.usersList !== undefined) {
				const contacts = action.payload.data.usersList.map((user) =>
					buildWorkspaceContact(user)
				);
				adapter.upsertMany(state, contacts);
			}
		});
	},
});

export default contactsSlice;
