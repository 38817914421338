import * as localforage from "localforage";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";

import type { MapView } from "./trackingSlice";

const KEY = "mapView";

type StoredMapView = MapView & {
	timestamp: number;
};

class Store {
	public loadStoredView() {
		return this.readStore();
	}

	// Read the stored data.
	private async readStore(): Promise<MapView | undefined> {
		const data: string | null = await localforage.getItem<string>(KEY);

		if (data) {
			const storedValue = JSON.parse(data) as StoredMapView;

			// expire the view after one hour of inactivity
			const oneHourInMs = 60 * 60 * 1000;
			if (storedValue.timestamp < Date.now() - oneHourInMs) {
				return await this.updateStore(undefined).pipe(take(1)).toPromise();
			}

			if (storedValue) {
				return storedValue;
			}
		}

		return undefined;
	}

	// Update the stored date.
	updateStore(view: MapView | undefined): Observable<MapView | undefined> {
		// update the local forage store
		return new Observable((subscriber) => {
			if (view !== undefined) {
				const storedView: StoredMapView = {
					...view,
					timestamp: Date.now(),
				};
				localforage
					.setItem<string>(KEY, JSON.stringify(storedView))
					.then((layers) => {
						subscriber.next(JSON.parse(layers));
					})
					.catch((e) => {
						console.error("Unable to save layers. The uploaded layer may be too large");
						subscriber.error(e);
					});
			} else {
				localforage
					.removeItem(KEY)
					.then(() => {
						subscriber.next(undefined);
					})
					.catch((e) => {
						console.error("Unable to remove all layers.");
						subscriber.error(e);
					});
			}
			subscriber.complete();
		});
	}
}

export const MapViewStore = new Store();
