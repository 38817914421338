import type { PayloadAction } from "@reduxjs/toolkit";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import type { HealthActivityResponse } from "@somewear-labs/swl-web-api/src/proto/health_activity_proto_pb";

import type { IError } from "../../common/errorReducer";
import type { RootState } from "../rootReducer";

export interface IBiometrics extends HealthActivityResponse.AsObject {
	ownerId: string;
}

const adapter = createEntityAdapter<IBiometrics>({
	selectId: (event) => event.ownerId,
});

// Rename the exports for readability in component usage
export const {
	selectAll: selectAllBiometrics,
	selectById: selectBiometricsById,
	selectEntities: selectBiometricEntities,
} = adapter.getSelectors((state: RootState) => state.biometrics);

const biometricsSlice = createSlice({
	name: "biometrics",
	initialState: adapter.getInitialState(),
	reducers: {
		apiHealthActivityRequest() {},
		apiHealthActivitySuccess(state, action: PayloadAction<IBiometrics[]>) {
			action.payload.forEach((event) => {
				const prevEvent = adapter.getSelectors().selectById(state, event.ownerId);
				if (prevEvent?.timestamp !== undefined) {
					if (event.timestamp!.seconds > prevEvent.timestamp.seconds) {
						adapter.upsertOne(state, event);
					}
				} else {
					adapter.upsertOne(state, event);
				}
			});

			/*action.payload.forEach((activity) => {

				if (activity.owner === undefined && state.selfUserId === undefined) {
					return;
				}

				let userId = activity.owner?.id ?? state.selfUserId!;
				if (!(userId in state.healthActivity)) {
					state.healthActivity[userId] = {
						data: {},
						loaded: true,
					};
				}

				state.healthActivity[userId].data[activity.id] = activity;
				state.healthActivity[userId].loaded = true;
			});*/
		},
		apiHealthActivityError(state, action: PayloadAction<IError>) {},
	},
	extraReducers: (builder) => {},
});

export const { apiHealthActivityRequest, apiHealthActivitySuccess, apiHealthActivityError } =
	biometricsSlice.actions;

export default biometricsSlice;
