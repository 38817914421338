import { DeviceRecord } from "@somewear-labs/swl-web-api/src/proto/device_record_proto_pb";

import type { IWorkspaceDevice } from "./workspaceDevicesSlice";
import type { IWorkspace } from "./workspacesSlice";

namespace WorkspaceUtil {
	export const isTeam = (workspace?: IWorkspace | string): boolean => {
		if (workspace === undefined) return false;

		let workspaceId = undefined;
		if (typeof workspace === "string") {
			workspaceId = workspace;
		} else {
			workspaceId = workspace.id;
		}

		return workspaceId.isNotEmpty() && workspaceId !== "-1";
	};

	export const isPersonal = (workspace?: IWorkspace | string): boolean => {
		return !isTeam(workspace);
	};

	export const hasTeamWorkspace = (workspaces: IWorkspace[]): boolean => {
		return workspaces.find((workspace) => isTeam(workspace)) !== undefined;
	};

	export const getDevice = (
		workspace?: IWorkspace,
		device?: IWorkspaceDevice
	): IWorkspaceDevice | undefined => {
		if (isPersonal(workspace)) {
			return { serial: "", userAccountId: "", model: DeviceRecord.Model.SWL1 };
		}
		return device;
	};
}

export default WorkspaceUtil;
