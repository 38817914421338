import { BillingInfo } from "@somewear-labs/swl-web-api/src/proto/billing_info_proto_pb";
import { DeviceDto, DeviceList } from "@somewear-labs/swl-web-api/src/proto/device_proto_pb";
import { PublicRecordResponse } from "@somewear-labs/swl-web-api/src/proto/public_record_proto_pb";
import {
	DataUsage,
	SubscriptionRequest,
	SubscriptionResponse,
} from "@somewear-labs/swl-web-api/src/proto/subscription_proto_pb";
import { UserInfoDto } from "@somewear-labs/swl-web-api/src/proto/user_info_proto_pb";
import type { Observable} from "rxjs";
import { firstValueFrom, from, of } from "rxjs";
import { catchError, map, mergeMap, take } from "rxjs/operators";

import { selectActiveOrganizationId, selectActiveUserAccountId } from "../app/appSelectors";
import store from "../app/store";
import Config from "../config/Config";
import type { IAuthUser } from "./AuthUtil";
import { AuthController } from "./AuthUtil";
import { TraceIdGenerator } from "./TraceIdGenerator";

export const REST_BASE_URL =
	Config.somewear.baseUrl !== "auto" ? Config.somewear.baseUrl : window.location.origin + "/";

const OK = 200;

interface IProto {
	deserializeBinary(bytes: Uint8Array): any;
}

interface IProtoInstance {
	serializeBinary(): Uint8Array;
}

export class RestClient {
	private static instance: RestClient;
	private user: IAuthUser;

	static init(user: IAuthUser) {
		this.instance = new RestClient(user);
	}

	private constructor(user: IAuthUser) {
		this.user = user;
	}

	static getInstance(): RestClient {
		if (!this.instance) throw Error("RestClient has not been initialized");
		return this.instance;
	}

	private static fullUrl(api: string): string {
		return REST_BASE_URL + api;
	}

	/*private static parseResponse(response: Response, resource: IProto): Promise<any> {
		return new Promise((resolve) => {
			response.arrayBuffer().then((arrayBuf) => {
				let proto = resource.deserializeBinary(new Uint8Array(arrayBuf));
				resolve(proto);
			});
		});
	}*/

	private getResource<T>(apiUrl: string, resource?: IProto): Observable<T> {
		return AuthController.tokenString$().pipe(
			mergeMap((tokenString) => {
				const headers: any = {
					Authorization: tokenString,
					"Content-Type": "application/x-protobuf; charset=UTF-8",
					"x-api-key": Config.somewear.apiKey,
					"x-platform": "Web",
					"x-b3-traceid": TraceIdGenerator.instance.generateTraceId(),
					"x-b3-spanid": TraceIdGenerator.instance.generateSpanId(),
				};

				const state = store.getState();
				const userAccountId = selectActiveUserAccountId(state);
				const organizationId = selectActiveOrganizationId(state);
				// let userAccountId = UserSource.getInstance().activeUserAccountId;
				if (userAccountId != null) {
					headers["x-user-account-id"] = userAccountId;
				}

				if (organizationId !== undefined) {
					headers["x-organization-id"] = organizationId;
				}

				const fetchPromise = fetch(RestClient.fullUrl(apiUrl), {
					mode: Config.somewear.cors ? "cors" : "same-origin",
					headers: headers,
				});

				return from(fetchPromise);
			}),
			mergeMap((response) => {
				if (response.status === OK) {
					if (resource === undefined) {
						return of();
					} else {
						return from(response.arrayBuffer()).pipe(
							map((payload) => {
								return resource.deserializeBinary(new Uint8Array(payload));
							})
						);
					}
				} else {
					throw new Error(`getResource: Failed to get ${apiUrl}`);
				}
			}),
			catchError((error) => {
				console.error(error);
				throw error;
			}),
			take(1)
		);
	}

	private setResource(
		method: string,
		apiUrl: string,
		resource: IProtoInstance
	): Observable<Response> {
		return AuthController.tokenString$().pipe(
			mergeMap((tokenString) => {
				const headers: any = {
					Authorization: tokenString,
					"Content-Type": "application/x-protobuf; charset=UTF-8",
					"x-api-key": Config.somewear.apiKey,
					"x-platform": "Web",
					"x-b3-traceid": TraceIdGenerator.instance.generateTraceId(),
					"x-b3-spanid": TraceIdGenerator.instance.generateSpanId(),
				};

				const state = store.getState();
				const userAccountId = selectActiveUserAccountId(state);
				const organizationId = selectActiveOrganizationId(state);
				// let userAccountId = UserSource.getInstance().activeUserAccountId;
				if (userAccountId != null) {
					headers["x-user-account-id"] = userAccountId;
				}

				if (organizationId !== undefined) {
					headers["x-organization-id"] = organizationId;
				}

				const fetchPromise = fetch(RestClient.fullUrl(apiUrl), {
					mode: Config.somewear.cors ? "cors" : "same-origin",
					headers: headers,
					method: method,
					body: resource.serializeBinary(),
				});

				return from(fetchPromise);
			}),
			mergeMap((response) => {
				if (response.status === OK) {
					return of(response);
				} else {
					const errorMessage = `putResource: Failed to set ${apiUrl};`;
					console.log(response);
					console.error();
					throw errorMessage;
				}
			}),
			catchError((error) => {
				console.error(error);
				throw error;
			}),
			take(1)
		);
	}

	private putResource(apiUrl: string, resource: IProtoInstance) {
		return firstValueFrom(this.setResource("PUT", apiUrl, resource));
	}

	private postResource(apiUrl: string, resource: IProtoInstance) {
		return firstValueFrom(this.setResource("POST", apiUrl, resource));
	}

	getUserInfo() {
		return firstValueFrom(
			this.getResource<UserInfoDto>(Config.somewear.api.userInfo, UserInfoDto)
		);
	}

	getUuid() {
		return firstValueFrom(
			this.getResource<PublicRecordResponse>(Config.somewear.api.uuid, PublicRecordResponse)
		);
	}

	getApp(android: boolean) {
		return firstValueFrom(
			this.getResource<null>(
				android ? Config.somewear.api.appAndroid : Config.somewear.api.appiOS
			)
		);
	}

	getDevice() {
		return firstValueFrom(this.getResource<DeviceDto>(Config.somewear.api.device, DeviceDto));
	}

	getBillingInfo() {
		return firstValueFrom(
			this.getResource<BillingInfo>(Config.somewear.api.billingInfo, BillingInfo)
		);
	}

	getOrderDevices() {
		return firstValueFrom<DeviceList>(
			this.getResource(Config.somewear.api.orderDevices, DeviceList)
		);
	}

	getSubscriptionsWithDevice() {
		return firstValueFrom(
			this.getResource<SubscriptionResponse>(
				Config.somewear.api.subscriptionWithDevice,
				SubscriptionResponse
			)
		);
	}

	getSubscriptionDetail(subscriptionCode: string) {
		return firstValueFrom(
			this.getResource<SubscriptionRequest>(
				Config.somewear.api.subscriptions + "/" + subscriptionCode,
				SubscriptionRequest
			)
		);
	}

	registerSubscription(subscriptionRequest: SubscriptionRequest) {
		return new Promise<SubscriptionRequest>((resolve, reject) => {
			this.putResource(Config.somewear.api.subscriptions + "/register", subscriptionRequest)
				.then((response) => {
					resolve(subscriptionRequest);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	updateUserInfo(userInfo: UserInfoDto) {
		return new Promise((resolve, reject) => {
			this.putResource(Config.somewear.api.userInfo, userInfo)
				.then((response) => {
					resolve(userInfo);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	updateSosInfo(userInfo: UserInfoDto) {
		return new Promise((resolve, reject) => {
			this.putResource(Config.somewear.api.sosRegister, userInfo)
				.then((response) => {
					resolve(userInfo);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	updateUser(userInfo: UserInfoDto): Promise<UserInfoDto> {
		return new Promise((resolve, reject) => {
			this.putResource(Config.somewear.api.user, userInfo)
				.then((response) => {
					response
						.arrayBuffer()
						.then((arrayBuf) => {
							try {
								const userInfoResponse = UserInfoDto.deserializeBinary(
									new Uint8Array(arrayBuf)
								);
								resolve(userInfoResponse);
							} catch (error) {
								console.error("updateUser: Failed to parse UserInfoDto");
								reject(Error("updateUser: Failed to parse UserInfoDto"));
							}
						})
						.catch((error) => {
							console.error(error);
							reject(Error(`updateUser: Failed to get bytes error`));
						});
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	updateSubscription(subscription: SubscriptionRequest): Promise<SubscriptionRequest> {
		return new Promise((resolve, reject) => {
			this.putResource(Config.somewear.api.subscriptions, subscription)
				.then((response) => {
					response
						.arrayBuffer()
						.then((arrayBuf) => {
							try {
								const subscriptionResponse = SubscriptionRequest.deserializeBinary(
									new Uint8Array(arrayBuf)
								);
								resolve(subscriptionResponse);
							} catch (error) {
								console.error(
									"updateSubscription: Failed to parse SubscriptionRequest"
								);
								reject(
									Error("updateSubscription: Failed to parse SubscriptionRequest")
								);
							}
						})
						.catch((error) => {
							console.error(error);
							reject(Error(`updateSubscription: Failed to get bytes`));
						});
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	addSubscription(subscription: SubscriptionRequest): Promise<SubscriptionRequest> {
		return new Promise((resolve, reject) => {
			this.postResource(Config.somewear.api.subscriptions, subscription)
				.then((response) => {
					response
						.arrayBuffer()
						.then((arrayBuf) => {
							try {
								const subscriptionResponse = SubscriptionRequest.deserializeBinary(
									new Uint8Array(arrayBuf)
								);
								resolve(subscriptionResponse);
							} catch (error) {
								console.error(
									"addSubscription: Failed to parse SubscriptionRequest"
								);
								reject(
									Error("addSubscription: Failed to parse SubscriptionRequest")
								);
							}
						})
						.catch((error) => {
							console.error("addSubscription: Failed to get bytes");
							console.error(error);
							reject(error);
						});
				})
				.catch((error) => {
					reject(error);
				});
		});
	}

	activateDevices(subscriptions: SubscriptionResponse): Promise<SubscriptionResponse> {
		return new Promise((resolve, reject) => {
			this.postResource(Config.somewear.api.activateDevices, subscriptions)
				.then((response) => {
					response
						.arrayBuffer()
						.then((arrayBuf) => {
							try {
								const subscriptionResponse = SubscriptionResponse.deserializeBinary(
									new Uint8Array(arrayBuf)
								);
								resolve(subscriptionResponse);
							} catch (error) {
								console.error(
									"activateDevices: Failed to parse subscriptionResponse"
								);
								reject(error);
							}
						})
						.catch((error) => {
							console.error("activateDevices: Fialed to get bytes");
							reject(error);
						});
				})
				.catch((error) => {
					console.error(error);
					reject(error);
				});
		});
	}

	getDataUsage(subscriptionCode: string) {
		return firstValueFrom(
			this.getResource<DataUsage>(
				Config.somewear.api.dataUsage + "/" + subscriptionCode,
				DataUsage
			)
		);
	}
}

export default RestClient;
