import type { GetRoutesResponse } from "@somewear-labs/swl-web-api/src/proto/api/tracking_pb";
import { GetRoutesRequest } from "@somewear-labs/swl-web-api/src/proto/api/tracking_pb";
import type { LocationResponse } from "@somewear-labs/swl-web-api/src/proto/location_proto_pb";
import type { RouteResponse } from "@somewear-labs/swl-web-api/src/proto/route_proto_pb";
import moment from "moment";
import { combineEpics } from "redux-observable";
import { forkJoin, of } from "rxjs";
import { filter, map, mergeMap, switchMap, takeUntil } from "rxjs/operators";

import { resetEpics } from "../../app/appActions";
import { selectContactEntities } from "../../app/contacts/contactsSlice";
import type { RootState } from "../../app/rootReducer";
import type {
	ActionSetEpic,
	IRequestPayload,
	IRequestPayloadAction,
	VoidableRequestPayload,
} from "../../common/EpicUtils";
import { createActionSetEpicHandler } from "../../common/EpicUtils";
import { grpc } from "../../common/GrpcClient";
import { someGrpc } from "../../common/SomewearGrpc";
import { timestampFromMoment, timestampToMoment } from "../../common/utils";
import { selectGlobalDateFilter } from "../filters/filterSelectors";
import { selectSortedLocationsForGlobalDateRangeByUserId } from "../locations/locationSelectors";
import type { DateRange } from "../trackingSlice";
import { trackingRouteActions } from "./trackingRouteActions";
import type { ITrackingRoute } from "./trackingRoutesSlice";
import { selectTrackingRoutesById } from "./trackingRoutesSlice";

const getLiveRoutesEpic: ActionSetEpic<void, ITrackingRoute[]> = (action$, state$) => {
	return createActionSetEpicHandler(action$, state$, trackingRouteActions.getLive, () =>
		grpc.prepareRequest(someGrpc.getLiveRoutes).pipe(map((r) => r.toObject().routesList))
	).pipe(takeUntil(action$.pipe(filter(resetEpics.match))));
};

const mapGetRoutesResponse = map<GetRoutesResponse, ITrackingRoute[]>((r) => {
	const routes = r.toObject().routesList;
	routes.forEach((route) => {
		if (route.location !== undefined) {
			route.location.userId = route.ownerId;
		}
	});
	return routes;
});

const getLastKnownRoutesEpic: ActionSetEpic<undefined, RouteResponse.AsObject[]> = (
	action$,
	state$
) => {
	return createActionSetEpicHandler(action$, state$, trackingRouteActions.getLastKnown, () =>
		grpc.prepareRequest(someGrpc.getLastKnownRoutes).pipe(mapGetRoutesResponse)
	).pipe(takeUntil(action$.pipe(filter(resetEpics.match))));
};

const getRoutesEpic: ActionSetEpic<DateRange, ITrackingRoute[]> = (action$, state$) => {
	return createActionSetEpicHandler(
		action$,
		state$,
		trackingRouteActions.getRoutes,
		(payload) => {
			const request = new GetRoutesRequest();

			const from = moment(payload.data.start);
			const to = moment(payload.data.end);

			request.setFrom(timestampFromMoment(from));
			request.setTo(timestampFromMoment(to));

			return grpc
				.prepareRequestWithPayload(someGrpc.getRoutes, request)
				.pipe(mapGetRoutesResponse);
		},
		(payload) => {
			return {
				onFulfilled: "Successfully loaded routes",
				onPending: "Loading routes...",
				onRejected: "Failed to load routes",
			};
		}
	).pipe(takeUntil(action$.pipe(filter(resetEpics.match))));
};

const getRouteLocationsEpic: ActionSetEpic<string[], LocationResponse.AsObject[]> = (
	action$,
	state$
) => {
	return createActionSetEpicHandler(
		action$,
		state$,
		trackingRouteActions.getLocations,
		(payload) =>
			grpc
				.prepareRequestWithPayload(someGrpc.getRouteLocations, payload.data)
				.pipe(map((r) => r.toObject().locationsList)),
		(payload) => {
			return {
				onRejected: "An error occurred loading locations for the route.",
				onPending: "Loading locations for the route...",
				onFulfilled: "",
			};
		}
	).pipe(takeUntil(action$.pipe(filter(resetEpics.match))));
};

const fetchLocationsAndGpx = (r: IRequestPayload<string[]>, state: RootState) => {
	const fileName = "Somewear tracking .gpx";

	const contacts = selectContactEntities(state);

	const routes = r.data.mapNotNull((routeId) => {
		const route = selectTrackingRoutesById(state, routeId);
		if (route === undefined) return undefined;

		const contact = contacts[route.ownerId];
		if (contact === undefined) return undefined;

		return Object.assign({}, route, { contact: contact });
	});

	const globalDateRange = selectGlobalDateFilter(state);

	const locations = Object.values(selectSortedLocationsForGlobalDateRangeByUserId(state) ?? [])
		.flatMap((loc) => loc)
		.mapNotNull((loc) => loc)
		.filter((loc) => r.data.includes(loc.routeId));

	return grpc
		.prepareRequestWithPayload(
			someGrpc.getRouteLocations,
			routes.map((route) => route.id)
		)
		.pipe(
			mergeMap((r) => {
				const allLocations = locations.concat(...r.toObject().locationsList);

				let filteredLocations = allLocations;
				if (globalDateRange !== undefined) {
					filteredLocations = allLocations.filter((location) => {
						return (
							timestampToMoment(location.timestamp!).valueOf() >=
								globalDateRange.start &&
							timestampToMoment(location.timestamp!).valueOf() <= globalDateRange.end
						);
					});
				}

				return forkJoin({
					locations: of(allLocations),
					file: grpc.prepareRequestWithPayload(someGrpc.downloadRouteLocations, {
						routes: routes,
						locations: filteredLocations,
					}),
				});
			}),
			map((r) => {
				console.log(r);
				const blob = new Blob([r.file.getData()], {
					type: "application/xml",
				});

				const objectUrl = URL.createObjectURL(blob);
				const a = document.createElement("a");
				a.href = objectUrl;
				a.download = fileName;
				document.body.appendChild(a);
				a.click();
				a.remove();

				return r.locations;
			})
		);
};

const downloadRouteLocationsEpic: ActionSetEpic<string[], LocationResponse.AsObject[]> = (
	action$,
	state$
) => {
	return createActionSetEpicHandler(
		action$,
		state$,
		trackingRouteActions.downloadLocations,
		(payload, state$) =>
			of(payload).pipe(switchMap((r) => fetchLocationsAndGpx(r, state$.value))),
		(payload) => {
			return {
				onRejected: "Failed to download the GPX",
				onPending: "Downloading GPX",
				onFulfilled: "Successfully downloaded the GPX",
			};
		}
	).pipe(takeUntil(action$.pipe(filter(resetEpics.match))));
};

export default combineEpics<
	IRequestPayloadAction<VoidableRequestPayload>,
	IRequestPayloadAction<unknown>,
	RootState
>(
	getLiveRoutesEpic,
	getLastKnownRoutesEpic,
	getRouteLocationsEpic,
	downloadRouteLocationsEpic,
	getRoutesEpic
);
