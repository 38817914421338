import ControlledInputController from "./ControlledInputController";

class EmailInputController extends ControlledInputController {
	isValid(): boolean {
		if (!this.getValue() || this.getValue() === "") return false;
		// prettier-ignore
		//http://regexlib.com/REDetails.aspx?regexp_id=26
		// eslint-disable-next-line
		let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return regex.test(this.getValue());
	}
}

export default EmailInputController;
