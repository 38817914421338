import { combineEpics } from "redux-observable";
import { map } from "rxjs/operators";

import { selectActiveWorkspaceId } from "../../app/appSelectors";
import type { ActionSetEpic} from "../../common/EpicUtils";
import { createActionSetEpicHandler } from "../../common/EpicUtils";
import { grpc } from "../../common/GrpcClient";
import { someGrpc } from "../../common/SomewearGrpc";
import type { IWorkspaceKey, IWorkspaceToken} from "./workspaceKeysSlice";
import { workspaceKeyActions } from "./workspaceKeysSlice";

const generateWorkspaceKeyEpic: ActionSetEpic<undefined, IWorkspaceKey> = (action$, state$) => {
	return createActionSetEpicHandler(action$, state$, workspaceKeyActions.generateKey, () =>
		grpc.prepareRequest(someGrpc.generateAdditionalWorkspaceKey).pipe(
			map((response) => {
				const workspaceId = selectActiveWorkspaceId(state$.value);
				return {
					id: workspaceId,
					key: response.toObject().key,
				} as IWorkspaceKey;
			})
		)
	);
};

const generateWorkspaceTokenEpic: ActionSetEpic<undefined, IWorkspaceToken> = (action$, state$) => {
	return createActionSetEpicHandler(action$, state$, workspaceKeyActions.generateToken, () =>
		grpc
			.prepareRequestWithPayload(
				someGrpc.generateWorkspaceToken,
				selectActiveWorkspaceId(state$.value)!
			)
			.pipe(
				map((response) => {
					return {
						id: response.getWorkspaceName(),
						token: response.getToken(),
					} as IWorkspaceToken;
				})
			)
	);
};

export default combineEpics(generateWorkspaceKeyEpic, generateWorkspaceTokenEpic);
