import * as React from "react";
import { Col, Row } from "reactstrap";

interface IContent {
	children: React.ReactNode;
	className?: string;
}

export interface IErrorLoading {
	error?: IError;
	loading: boolean;
}

export interface IError {
	message: string;
}

const CenterColumn: React.FunctionComponent<IContent> = (props: IContent) => (
	<Col
		md="12"
		className={"d-flex justify-content-center " + (props.className ? props.className : "")}
	>
		{props.children}
	</Col>
);

const CenterContent: React.FunctionComponent<IContent> = (props: IContent) => (
	<Row>
		<CenterColumn className={props.className}>{props.children}</CenterColumn>
	</Row>
);

const ErrorMessage: React.FC<{
	error?: IError;
	children?: React.ReactNode;
}> = (props) => (
	<Row className={props.error ? "" : "hidden"}>
		<Col md="12" className="d-flex justify-content-center">
			<div className="error-message">{props.error && props.error.message}</div>
		</Col>
	</Row>
);

export { CenterColumn };
export { CenterContent };
export { ErrorMessage };
