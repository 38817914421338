import {
	WorkspaceResponse,
	WorkspaceResponseList,
} from "@somewear-labs/swl-web-api/src/proto/user_proto_pb";

declare module "@somewear-labs/swl-web-api/src/proto/user_proto_pb" {
	interface WorkspaceResponseList {
		isWorkspaceUser(): boolean;
		personalResponses(): WorkspaceResponse[];
		teamResponses(): WorkspaceResponse[];
	}

	interface WorkspaceResponse {
		isTeam(): boolean;
		isPersonal(): boolean;
		isEmpty(): boolean;
		// toWorkspaceObject(): WorkspaceObject;
	}
}

WorkspaceResponseList.prototype.isWorkspaceUser = function () {
	return this.teamResponses().isNotEmpty();
};

WorkspaceResponseList.prototype.personalResponses = function () {
	return this.getResponsesList().filter((it) => {
		return it.getId().isEmpty();
	});
};

WorkspaceResponseList.prototype.teamResponses = function () {
	return this.getResponsesList().filter((it) => {
		return it.getId().isNotEmpty();
	});
};

WorkspaceResponse.prototype.isTeam = function () {
	return this.getId().isNotEmpty();
};

WorkspaceResponse.prototype.isPersonal = function () {
	return !this.isTeam();
};

WorkspaceResponse.prototype.isEmpty = function () {
	return this.getId().isEmpty() && this.getUserAccountId().isEmpty();
};

/*WorkspaceResponse.prototype.toWorkspaceObject = function () {
	let result: any = this.toObject();
	result.isTeam = result.id.isNotEmpty();
	result.isPersonal = !result.isTeam;
	return result;
};*/
