import { SosEventType } from "@somewear-labs/swl-web-api/src/proto/sos_message_proto_pb";
import moment from "moment";
import { createSelector } from "reselect";

import { timestampToMoment } from "../../common/utils";
import { selectAllSosEvents } from "./sosSlice";

/*export const sosEventsSelector = (state: RootState) => {
	return state.app.sosEvents;
};*/

export const activeSosEvents = createSelector([selectAllSosEvents], (sosEvents) => {
	/*return Object.values(sosEvents)
		.mapNotNull((events) => _.maxBy(events, (event) => event.timestamp!.seconds))
		.filter((event) => {
			return (
				event.type === SosEventType.SOSEVENTTYPEALARM &&
				timestampToMoment(event.timestamp!).isAfter(moment().subtract(1, "day"))
			);
		});*/
	// the slice only stores the most recent event for an sos session
	return sosEvents.filter((event) => {
		return (
			event.type === SosEventType.SOSEVENTTYPEALARM &&
			timestampToMoment(event.timestamp!).isAfter(moment().subtract(1, "day"))
		);
	});
});

export const activeSosUsers = createSelector([activeSosEvents], (events) => {
	const set = new Set<string>();
	events.forEach((event) => set.add(event.userId));
	return set;
});

export const activeSosSessions = createSelector([activeSosEvents], (events) => {
	return events.map((event) => event.sessionId);
});
