import * as localforage from "localforage";
import { Observable } from "rxjs";

import type { MapLayers} from "./mapLayers";
import { MapLayerType } from "./mapLayers";

class Store {
	MAP_LAYERS_KEY = "mapLayers";

	// Return the computed key name to retrieve a layer's data element from the stored data.
	static getFileDataKey(fileName: string) {
		return `layer-${fileName}-data`;
	}

	public loadStoredLayers() {
		return this.readStore();
	}

	// Read the stored Map Layer data.
	private async readStore(): Promise<MapLayers> {
		// clear anything that was previously in local storage
		localStorage.removeItem(this.MAP_LAYERS_KEY);

		const data: string | null = await localforage.getItem<string>(this.MAP_LAYERS_KEY);

		if (data) {
			const storedLayers = JSON.parse(data);

			if (storedLayers && storedLayers.length) {
				return storedLayers;
			}
		}

		return [];
	}

	// Update the stored date.
	updateStore(layers: MapLayers): Observable<MapLayers> {
		// clear anything that was previously in local storage
		localStorage.removeItem(this.MAP_LAYERS_KEY);

		const myLayers = layers.filter((it) => it.type === MapLayerType.Kml); //.filter((layer) => !layer.fromPublic);

		// update the local forage store
		return new Observable((subscriber) => {
			if (myLayers.length) {
				localforage
					.setItem<string>(this.MAP_LAYERS_KEY, JSON.stringify(myLayers))
					.then((layers) => {
						subscriber.next(JSON.parse(layers));
					})
					.catch((e) => {
						console.error("Unable to save layers. The uploaded layer may be too large");
						subscriber.error(e);
					});
			} else {
				localforage
					.removeItem(this.MAP_LAYERS_KEY)
					.then(() => {
						subscriber.next([]);
					})
					.catch((e) => {
						console.error("Unable to remove all layers.");
						subscriber.error(e);
					});
			}
			subscriber.complete();
		});
	}
}

export const MapLayersStore = new Store();
