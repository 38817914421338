import type { PayloadAction } from "@reduxjs/toolkit";
import { createAction, createSlice } from "@reduxjs/toolkit";
import type { FileMetadata } from "@somewear-labs/swl-web-api/src/proto/file_metadata_proto_pb";
import type {
	MessageRequest,
	MessageResponse,
} from "@somewear-labs/swl-web-api/src/proto/message_proto_pb";
import type { Timestamp } from "@somewear-labs/swl-web-api/src/proto/timestamp_proto_pb";

import type { Dict } from "../app/appModel";
import { createActionSet } from "../common/EpicUtils";
import type { IError } from "../common/errorReducer";
import { getConversationKey } from "../common/utils";
import { conversationActions } from "./conversations/conversationActions";
import type {
	DownloadFileRequestPayload,
	DownloadFileResponse,
	GetWorkspaceFilesRequestPayload,
	GetWorkspaceFilesResponse,
	UploadFileRequest,
	UploadFileResponse,
} from "./files/files.model";
import type { IMessageResponse } from "./messages/messageModel";
import type { IGetMessagesResponse } from "./messages/messagesSlice";

export interface ConversationOrWorkspace {
	conversationId?: string;
	workspaceId?: string;
}

export interface ReadTimestamps {
	conversations: Dict<Timestamp.AsObject>;
	workspaces: Dict<Timestamp.AsObject>;
}

export const WORKSPACE_ID_ALL = "_all";

type WorkspaceSidebarView = "members" | "files";

interface MessagesState {
	// conversation id to user id map
	// conversations: Dict<string>;
	// messages indexed by conversation id
	// messages: Dict<DataMap<MessageResponseWithState>>;
	// messages indexed by workspace id
	// workspaceMessages: Dict<DataMap<MessageResponseWithState>>;
	// read timestamp indexed by conversation id
	readTimestamps: ReadTimestamps;
	selectedConversationKey?: string;
	selectedMessage?: string;
	conversationsLoaded: boolean;
	timestampsLoaded: boolean;
	recipient: Recipient;
	workspaceSidebarView: WorkspaceSidebarView | null;
	filteredWorkspaceId: string;
}

const initialState: MessagesState = {
	// conversations: {},
	// messages: {},
	// workspaceMessages: {},
	readTimestamps: {
		conversations: {},
		workspaces: {},
	},
	selectedConversationKey: undefined,
	selectedMessage: undefined,
	conversationsLoaded: false,
	timestampsLoaded: false,
	recipient: { valid: false },
	filteredWorkspaceId: WORKSPACE_ID_ALL,
	workspaceSidebarView: null,
};

export interface MessagesRequestPayload {
	before?: Timestamp.AsObject;
	conversationInfo: ConversationOrWorkspace;
}

export interface MessageResponsePayload {
	conversationInfo: ConversationOrWorkspace;
	messages: MessageResponse.AsObject[];
}

export interface IMessageRequest extends Omit<MessageRequest.AsObject, "outgoing"> {}

export interface MessageRequestWithState extends IMessageRequest {
	state?: string;
}

export interface MessageRequestWithStateAndTmpId extends MessageRequestWithState {
	senderId: string;
	tmpId?: string;
}

export interface MessageResponseWithState extends IMessageResponse {
	state?: string;
}

export interface ConversationTimestamp {
	conversationId: string;
	timestamp: Timestamp.AsObject;
}

export interface Recipient {
	valid: boolean;
	email?: string;
	phone?: string;
	id?: string;
}

export const emitMessageReceivedFromStream = createAction<IMessageResponse>(
	"messaging/messageFromStream"
);

export const emitFileReceivedFromStream = createAction<FileMetadata.AsObject>(
	"messaging/fileFromStream"
);

/*export const apiDeleteConversationRequest = createAction<string>(
	"messaging/apiDeleteConversationRequest"
);*/

export const messageActions = {
	send: createActionSet<MessageRequestWithStateAndTmpId, IMessageResponse>("messages/send"),
	get: createActionSet<MessagesRequestPayload, IGetMessagesResponse>("messages/get"),
	uploadFile: createActionSet<UploadFileRequest, UploadFileResponse>("messages/uploadFile"),
	downloadFile: createActionSet<DownloadFileRequestPayload, DownloadFileResponse>(
		"messages/downloadFile"
	),
	getFiles: createActionSet<GetWorkspaceFilesRequestPayload, GetWorkspaceFilesResponse>(
		"messages/getFiles"
	),
};

export const messagingSlice = createSlice({
	name: "messages",
	initialState,
	reducers: {
		/*apiConversationsRequest() {},
		apiConversationsSuccess(
			state,
			action: PayloadAction<ConversationResponseWithMessage.AsObject[]>
		) {
			/!*
			action.payload.forEach((conversationResponse) => {
				// set the conversation to user relation
				let conversationId = conversationResponse.conversation!.id;
				state.conversations[conversationId] =
					conversationResponse.conversation!.participantId;

				// init data map
				state.messages[conversationId] = {
					data: {},
					loaded: false,
				};

				// add the message to the messages entity for that conversation
				if (conversationResponse.mostRecentMessage !== undefined) {
					state.messages[conversationId].data[conversationResponse.mostRecentMessage.id] =
						conversationResponse.mostRecentMessage;
				}
			});
			 *!/

			state.conversationsLoaded = true;
		},
		apiConversationsError(state, action: PayloadAction<IError>) {},*/
		// apiMessagesRequest(state, action: PayloadAction<MessagesRequestPayload>) {},
		// apiMessagesSuccess(state, action: PayloadAction<MessageResponsePayload>) {},
		// apiMessagesError(state, action: PayloadAction<IError>) {},
		// apiSendMessageRequest(state, action: PayloadAction<MessageResponseWithState>) {},
		// apiSendMessageSuccess(state, action: PayloadAction<MessageResponseWithTmpId>) {},
		// apiSendMessageError(state, action: PayloadAction<MessageResponseWithState>) {},
		setSelectedConversationKey(state, action: PayloadAction<string | undefined>) {
			if (action.payload === undefined) {
				state.selectedConversationKey = action.payload;
			} else if (action.payload.startsWith("w_") || action.payload.startsWith("c_")) {
				state.selectedConversationKey = action.payload;
			} else {
				console.error(`Invalid conversation key: ${action.payload}`);
			}
		},
		setSelectedMessage(state, action: PayloadAction<string | undefined>) {
			state.selectedMessage = action.payload;
		},
		apiDeleteConversationSuccess() {},
		apiDeleteConversationError(state, action: PayloadAction<IError>) {},
		setRecipientValid(state, action: PayloadAction<Recipient>) {
			state.recipient = action.payload;
		},
		/*apiCreateConversationRequest(state, action: PayloadAction<MessageDto.AsObject>) {},
		apiCreateConversationSuccess(
			state,
			action: PayloadAction<ConversationResponseWithMessage.AsObject>
		) {
			state.selectedConversation = action.payload.conversation;
		},
		apiCreateConversationError(state, action: PayloadAction<IError>) {
			window.alert("There was an error creating the conversation");
		},*/
		setWorkspaceSidebarView(state, action: PayloadAction<WorkspaceSidebarView | null>) {
			state.workspaceSidebarView = action.payload;
		},
		fetchTrackingFilters() {},
		fetchTrackingSettings() {},
		fetchWorkspaceFilters() {},
		getReadTimestamps() {},
		setReadTimestamps(state, action: PayloadAction<ReadTimestamps>) {
			state.readTimestamps = action.payload;
			if (action.payload.workspaces === undefined) {
				state.readTimestamps.workspaces = {};
			} else if (action.payload.conversations === undefined) {
				state.readTimestamps.conversations = {};
			}
			state.timestampsLoaded = true;
		},
		setConversationTimestampRequest(state, action: PayloadAction<ConversationTimestamp>) {},
		setConversationTimestampSuccess(state, action: PayloadAction<ConversationTimestamp>) {
			state.readTimestamps.conversations[action.payload.conversationId] =
				action.payload.timestamp;
		},
		setWorkspaceTimestampRequest(state, action: PayloadAction<ConversationTimestamp>) {},
		setWorkspaceTimestampSuccess(state, action: PayloadAction<ConversationTimestamp>) {
			state.readTimestamps.workspaces[action.payload.conversationId] =
				action.payload.timestamp;
		},
		setFilteredWorkspaceId(state, action: PayloadAction<string>) {
			state.filteredWorkspaceId = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(conversationActions.fetch.fulfilled, (state, action) => {
			state.conversationsLoaded = true;
		});
		builder.addCase(conversationActions.delete.request, (state, action) => {
			state.selectedConversationKey = undefined;
		});
		builder.addCase(conversationActions.delete.fulfilled, (state, action) => {
			state.selectedConversationKey = undefined;
		});
		builder.addCase(conversationActions.create.fulfilled, (state, action) => {
			if (action.payload.data.conversation !== undefined) {
				state.selectedConversationKey = getConversationKey(
					action.payload.data.conversation
				);
			}
		});
	},
});

export const {
	setSelectedConversationKey,
	setSelectedMessage,
	apiDeleteConversationSuccess,
	apiDeleteConversationError,
	setRecipientValid,
	setWorkspaceSidebarView,
	fetchWorkspaceFilters,
	fetchTrackingFilters,
	fetchTrackingSettings,
	getReadTimestamps,
	setReadTimestamps,
	setConversationTimestampRequest,
	setConversationTimestampSuccess,
	setWorkspaceTimestampRequest,
	setWorkspaceTimestampSuccess,
	setFilteredWorkspaceId,
} = messagingSlice.actions;

export default messagingSlice.reducer;
