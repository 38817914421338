import { Icon } from "@mui/material";
import * as React from "react";
import styled from "styled-components";

import { ViewIconHidden } from "../components/ViewIconHidden";
import { ViewIconVisible } from "../components/ViewIconVisible";
import type { IControlledInput } from "./ControlledInputController";
import { RenderIf } from "./RenderIf";
import { modifyState } from "./utils";

const Enter = "Enter";

export interface IControlledInputProps {
	value?: string;
	type?: string;
	id?: string;
	className?: string;
	placeholder?: string;
	required?: boolean;
	name?: string;
	autoComplete?: string;
	maxSize?: number;
	controlledInput: IControlledInput;
	autoFocus?: boolean;
	disabled?: boolean;
	enableShowPass?: boolean;
}

interface IControlledInputState {
	value: string;
	showPassword?: boolean;
}

class ControlledInputView<P extends IControlledInputProps> extends React.Component<
	P,
	IControlledInputState
> {
	ref: React.RefObject<HTMLInputElement>;

	constructor(props: P) {
		super(props);
		this.state = {
			value: props.value || "",
			showPassword: false,
		};

		this.ref = React.createRef();
	}

	handleKeyDown(evt: React.KeyboardEvent<HTMLInputElement>) {
		if (evt.key === Enter) {
			evt.preventDefault();
			this.props.controlledInput.didPressEnter(evt);
		}
	}

	updateValue(evt: React.FormEvent<HTMLInputElement>) {
		if (this.props.maxSize) {
			if (evt.currentTarget.value.length > this.props.maxSize) return;
		}
		this.setState(modifyState(this.state, { value: evt.currentTarget.value }));
		this.props.controlledInput.didUpdateValue(evt.currentTarget.value);
	}

	componentDidMount() {
		if (this.props.autoFocus) {
			this.ref.current!.focus();
		}
	}

	render(): React.ReactNode {
		// add support for show password
		const type =
			this.props.type === "password" && this.state.showPassword ? "text" : this.props.type;

		return (
			<InputWrapper
				className={
					this.props.className
						? [
								...this.props.className.split(" ").map((name) => `${name}-wrapper`),
						  ].join(" ")
						: "input-wrapper"
				}
			>
				<input
					value={this.state.value}
					type={type}
					id={this.props.id}
					className={this.props.className}
					placeholder={this.props.placeholder}
					required={this.props.required}
					name={this.props.name}
					onChange={(evt) => this.updateValue(evt)}
					disabled={this.props.disabled}
					ref={this.ref}
					onKeyDown={(evt) => this.handleKeyDown(evt)}
				/>
				<RenderIf
					condition={this.props.type === "password" && Boolean(this.props.enableShowPass)}
				>
					<Icon
						onClick={() => {
							this.setState({ showPassword: !this.state.showPassword });
						}}
					>
						{this.state.showPassword ? <ViewIconHidden /> : <ViewIconVisible />}
					</Icon>
				</RenderIf>
			</InputWrapper>
		);
	}
}

const InputWrapper = styled.div`
	position: relative;

	.MuiIcon-root {
		cursor: pointer;
		position: absolute;
		right: 50px;
		top: calc(50% - 12px);

		svg {
			fill: ${(props) => props.theme.colors.dark};
		}
	}
`;

export default ControlledInputView;
