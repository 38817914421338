import type { PayloadAction } from "@reduxjs/toolkit";
import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import type {
	AssignDeviceRequest,
	AssignDeviceResponse,
} from "@somewear-labs/swl-web-api/src/proto/api/device_pb";

import type { RootState } from "../../app/rootReducer";
import { createActionSet } from "../../common/EpicUtils";

export interface IDeviceTransfer {
	serial: string;
	targetIdentityId: string | undefined;
	targetWorkspaceId: string;
}

const adapter = createEntityAdapter<IDeviceTransfer>({
	selectId: (device) => device.serial,
});

export const deviceTransferActions = {
	applyQueuedTransfers: createActionSet<string, AssignDeviceResponse.AsObject[]>(
		"device/applyQueuedTransfers"
	),
	unaryAssign: createActionSet<AssignDeviceRequest.AsObject, AssignDeviceResponse.AsObject>(
		"device/transfer/apply"
	),
	bulkAssign: createActionSet<AssignDeviceRequest.AsObject[], AssignDeviceResponse.AsObject[]>(
		"device/transfer/bulk/apply"
	),
};

const _actions = deviceTransferActions;

// Rename the exports for readability in component usage
export const { selectAll: selectAllDeviceTransfers, selectEntities: selectDeviceTransferEntities } =
	adapter.getSelectors((state: RootState) => state.deviceTransfers);

const deviceTransfersSlice = createSlice({
	name: "deviceTransfers",
	initialState: adapter.getInitialState(),
	reducers: {
		queueDeviceChange: (state, action: PayloadAction<IDeviceTransfer>) => {
			adapter.upsertOne(state, action.payload);
		},
		removeAllChanges: (state) => {
			adapter.removeAll(state);
		},
	},
	extraReducers: (builder) => {
		builder.addCase(_actions.applyQueuedTransfers.fulfilled, (state, action) => {
			if (action.payload.data === undefined) return;

			const serials: string[] = action.payload.data.mapNotNull(
				(request) => request.device?.serial
			);
			adapter.removeMany(state, serials);
		});
	},
});

export default deviceTransfersSlice;
